import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Form from "@emberex/components/lib/Form";
import useInputState from "@emberex/react-utils/lib/useInputState";
import getErrorMessage from "../../common/utils/getErrorMessage";
import FormErrorText from "../../common/components/FormErrorText";
import isBlank from "shared/lib/utils/isBlank";
import FormSuccessText from "../../common/components/FormSuccessText";
import Input from "../../common/components/Input";
import Link from "../../common/components/Link";
import PillButton from "../../surveys/components/PillButton";

interface Props {
  onSubmit(email: string): any;
}

const ForgotPasswordForm: FC<Props> = (props) => {
  const { onSubmit, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [email, handleEmailChange] = useInputState("");

  const handleSubmit = useCallback(async () => {
    try {
      if (isBlank(email)) {
        throw new Error("You must enter an email.");
      }
      setSubmitting(true);
      await onSubmit(email);
      setSubmitSuccess(true);
      setError(null);
    } catch (error) {
      setError(error);
      setSubmitSuccess(false);
    } finally {
      setSubmitting(false);
    }
  }, [email, onSubmit]);

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <Link to="/">Back</Link>
      <Title>Forgot Password</Title>
      {submitSuccess && (
        <FormSuccessText>
          Password reset sent successfully. Check your email.
        </FormSuccessText>
      )}
      {!!error && <FormErrorText>{getErrorMessage(error)}</FormErrorText>}
      {!error && !submitSuccess && (
        <Instructions>Enter your email address.</Instructions>
      )}
      <Input
        required
        placeholder="Email"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
        disabled={submitSuccess}
      />
      <SubmitButton disabled={submitting || submitSuccess || !email}>
        Send Password Reset
      </SubmitButton>
    </Form>
  );
};

export default styled(ForgotPasswordForm)`
  display: flex;
  flex-direction: column;
  width: 267px;
  max-width: 100%;
  color: white;

  @media (min-width: 800px) {
    width: 400px;
  }
`;

const Instructions = styled("p")`
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 2rem;
`;

const Title = styled("h1")`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;

  @media (min-width: 800px) {
    font-size: 2rem;
  }
`;

const SubmitButton = styled(PillButton)`
  margin-top: 3rem;
  width: 100%;
  padding: 0 0 0 0;
  font-size: 1rem;
`;
