import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const WhatIsPositiveParentingOutroSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSectionComplete, setShowSectionComplete] = useState(false);

  const handleNext = useCallback(() => {
    if (showSectionComplete) {
      onNext();
    } else {
      setShowSectionComplete(true);
    }
  }, [onNext, showSectionComplete]);

  return (
    <SlidePage {...rest} title={module.name} onNext={handleNext}>
      <section>
        <h3>{section.name}</h3>
        <p>
          It’s easy to focus on negative behaviors. It is hard to notice when
          things are going well. But if you regularly respond to negative
          behaviors and ignore positive behaviors, you can end up increasing
          unwanted behaviors.
        </p>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSectionComplete}
        onNext={handleNext}
        module={module}
        currentSection={section}
        blurbText="Awesome"
      />
    </SlidePage>
  );
};

export default styled(WhatIsPositiveParentingOutroSlide)``;
