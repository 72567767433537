import React, {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import replaceWhere from "@emberex/array-utils/lib/replaceWhere";
import canParticipantViewModule from "shared/lib/utils/canParticipantViewModule";
import ModuleSlideView from "shared/lib/interfaces/ModuleSlideView";
import Participant from "shared/lib/interfaces/Participant";
import Mood from "shared/lib/enums/Mood";
import { replaceById } from "shared/lib/utils/replaceById";
import ParticipantModuleContext from "shared/lib/interfaces/ParticipantModuleContext";
import ParticipantPoints from "shared/lib/interfaces/ParticipantPoints";
import getParticipantOverallContext from "../api/getParticipantOverallContext";
import setParticipantMood from "../api/setParticipantMood";
import viewModuleSlide from "../../modules/api/viewModuleSlide";
import completeModuleSlide from "../../modules/api/completeModuleSlide";
import setParticipantWelcomed from "../api/setParticipantWelcomed";

export interface ParticipantContextValue {
  loading: boolean;
  user: Participant;
  slideViews: ModuleSlideView[];
  points: ParticipantPoints;
  moduleContexts: ParticipantModuleContext[];
  lastMood: Mood | null;
  showWelcomeBackOverlay: boolean;
  showWelcomeOverlay: boolean;
  refreshParticipantContext(): Promise<void>;
  refreshUser(): Promise<void>;
  changeMood(mood: Mood): Promise<void>;
  viewSlide(moduleId: number, slideId: number): Promise<void>;
  completeSlide(moduleId: number, slideId: number): Promise<void>;
  setShowWelcomeBackOverlay(welcomed: boolean): unknown;
  setShowWelcomeOverlay(welcomed: boolean): unknown;
  canViewModule(moduleId: number): boolean;
}

export const ParticipantContext = createContext<ParticipantContextValue>(
  {
    user: null as any,
    async refreshUser() {},
  } as any /* This will be replaced by the provider */
);

export function useParticipantContext(): ParticipantContextValue {
  const data = useContext(ParticipantContext);
  if (!data) {
    throw new Error(
      "useParticipantContext must be called by a component under a ParticipantContext.Provider."
    );
  }
  return data;
}

export const ParticipantContextProvider: FC<{
  user: Participant;
  refreshUser(): Promise<void>;
}> = ({ refreshUser, user, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState<ParticipantPoints | null>(null);
  const [lastMood, setLastMood] = useState<Mood | null>(null);
  const [moduleContexts, setModuleContexts] = useState<
    ParticipantModuleContext[] | null
  >(null);
  const [slideViews, setSlideViews] = useState<ModuleSlideView[]>([]);
  const [showWelcomeBackOverlay, setShowWelcomeBackOverlay] = useState(false);
  const [showWelcomeOverlay, setShowWelcomeOverlay] = useState(
    !user.welcomedAt
  );

  const refreshParticipantContext = useCallback(
    async (isCancelled?: () => boolean) => {
      const overallContext = await getParticipantOverallContext(user.id);
      if (!isCancelled || !isCancelled()) {
        const allSlideViews = overallContext.moduleContexts.flatMap(
          (moduleContext) => moduleContext.slideViews
        );
        setPoints(overallContext.points);
        setModuleContexts(overallContext.moduleContexts);
        setSlideViews(allSlideViews);
        setLoading(false);
      }
    },
    [user.id]
  );

  useAsyncEffect(refreshParticipantContext, [user.id]);

  useAsyncEffect(async () => {
    if (!showWelcomeOverlay && !user.welcomedAt) {
      await setParticipantWelcomed();
      await refreshUser();
      await refreshParticipantContext();
    }
  }, [showWelcomeOverlay, refreshParticipantContext, user.welcomedAt]);

  const changeMood = useCallback(
    async (mood: Mood) => {
      setLastMood(mood);

      await setParticipantMood(user.id, mood).catch((error) =>
        console.error("Failed to save mood", error)
      );
    },
    [user.id]
  );

  const viewSlide = useCallback(
    async (moduleId: number, slideId: number) => {
      const newSlideView = await viewModuleSlide({
        participantId: user.id,
        slideId,
        moduleId,
      });

      setSlideViews((slideViews) => {
        const replacedSlideView = slideViews.find(
          (other) => newSlideView.id === other.id
        );

        return replacedSlideView
          ? replaceById(slideViews, newSlideView.id, newSlideView)
          : [...slideViews, newSlideView];
      });
    },
    [user.id]
  );

  const completeSlide = useCallback(
    async (moduleId: number, slideId: number) => {
      await completeModuleSlide({
        participantId: user.id,
        slideId,
        moduleId,
      });

      setSlideViews((slideViews) =>
        replaceWhere(
          slideViews,
          (slideView) => slideView.slideId === slideId,
          (slideView) => ({
            ...slideView,
            completedAt: new Date().toISOString(),
          })
        )
      );
    },
    [user.id]
  );

  const canViewModule = useCallback(
    (moduleId: number): boolean => {
      if (!moduleContexts) {
        return false;
      }
      return canParticipantViewModule(
        user,
        moduleId,
        moduleContexts.map((moduleContext) => moduleContext.module),
        slideViews
      );
    },
    [user, moduleContexts, slideViews]
  );

  if (points === null || moduleContexts === null) {
    return null;
  }

  const value = {
    loading,
    points,
    lastMood,
    moduleContexts,
    user,
    slideViews,
    showWelcomeBackOverlay,
    showWelcomeOverlay,
    refreshParticipantContext,
    changeMood,
    viewSlide,
    completeSlide,
    refreshUser,
    setShowWelcomeBackOverlay,
    canViewModule,
    setShowWelcomeOverlay,
  };

  return <ParticipantContext.Provider {...rest} value={value} />;
};
