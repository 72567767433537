import React, { FC } from "react";
import styled from "styled-components/macro";
import ModuleSlideKind from "shared/lib/enums/ModuleSlideKind";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { SlidePageProps } from "./SlidePage";
import PlaceholderSlide from "./PlaceholderSlide";
import {
  SelfCareWelcomeSlide,
  SelfCareContent1Slide,
  SelfCareContent2Slide,
  SelfCarePlanSlide,
  ManagingDepressionWelcomeSlide,
  ManagingDepressionMoodSpiralSlide,
  ManagingDepressionContent1Slide,
  ManagingDepressionPlanSlide,
  ManagingStressWelcomeSlide,
  ManagingStressContent2Slide,
  ManagingStressContent3Slide,
  ManagingStressContent4Slide,
  HealthyRoutinesRankingsSlide,
  HealthyRoutinesExamplesSlide,
  HealthyRoutinesMakingChangesSlide,
  HealthyRoutinesPlanSlide,
  SleepWelcomeSlide,
  SleepPlanInstructionsSlide,
  SleepHabitsRoomSlide,
} from "./slides/WellnessAndSelfCare/_index";
import {
  EncouragementAndPraiseContent1Slide,
  EncouragementAndPraiseContent2Slide,
  EncouragementAndPraiseContent3Slide,
  EncouragementAndPraiseIntroSlide,
  EncouragementAndPraiseQuizSlide,
  GivingDirectionHelpfulPreQuizSlide,
  GivingDirectionHelpfulQuizSlide,
  GivingDirectionIntroSlide,
  GivingDirectionOutroSlide,
  GivingDirectionTipsSlide,
  RelationshipBuildingExampleSlide,
  RelationshipBuildingIntroSlide,
  RelationshipBuildingOutroSlide,
  RelationshipBuildingSituationExample1Slide,
  RelationshipBuildingSituationExample2Slide,
  UsingIncentivesBribeOrReward1Slide,
  UsingIncentivesBribeOrReward2Slide,
  UsingIncentivesBribesVsIncentivesSlide,
  UsingIncentivesExampleSlide,
  UsingIncentivesIncentiveDefinitionSlide,
  UsingIncentivesIntroSlide,
  UsingIncentivesRewardDefinitionSlide,
  UsingIncentivesUsageInstructionsSlide,
  WhatIsPositiveParentingIntroSlide,
  WhatIsPositiveParentingOutroSlide,
  WhatIsPositiveParentingQuizSlide,
} from "./slides/PositiveParenting/_index";
import {
  ParentingAndSubstanceUseWelcomeSlide,
  ParentingAndSubstanceUseMythOrFactSlide,
  ParentingAndSubstanceUseContent1Slide,
  ParentingAndSubstanceUseReasonsActivitySlide,
  ManagingCravingsSlide,
  ManagingCravingsSurfSlide,
  ParentingAndSubstanceUseTriggersActivitySlide,
  ParentingAndSubstanceUseTipBagSlide,
  ManagingCravingsOutroSlide,
  ParentingAndSubstanceUseVideoOutroSlide,
  UnhealthyVsHealthyRelationshipsIntroSlide,
  EffectiveCommunicationVideoSlide,
  EffectiveCommunicationContent1Slide,
  EffectiveCommunicationStatementComparisonSlide,
  UnhealthyVsHealthyRelationshipsOutroSlide,
  ParentingResponsibilitiesIntroSlide,
  ParentingResponsibilitiesContent1Slide,
  HomeSafetyPreQuizSlide,
  HomeSafetyQuizSlide,
  HomeSafetyOutroSlide,
} from "./slides/ParentingAndSubstanceUse/_index";
import {
  ProactiveParentingBedtimeRoutineVideoSlide,
  ProactiveParentingHealthyFamilyRoutines2Slide,
  ProactiveParentingPlayBarriersSlide,
  ProactiveParentingPlayVideoSlide,
  ProactiveParentingBenefitsOfFamilyRoutinesSlide,
  ProactiveParentingHowToPlanSlide,
  ProactiveParentingPlayCommitmentSlide,
  ProactiveParentingProactiveOrNotQuizSlide,
  ProactiveParentingDefinitionSlide,
  ProactiveParentingPlanExampleSlide,
  ProactiveParentingPlaySuggestionsSlide,
  ProactiveParentingRoutineTimesSlide,
  ProactiveParentingHealthyFamilyRoutines1Slide,
  ProactiveParentingPlanQuizSlide,
  ProactiveParentingPlaySummarySlide,
  ProactiveParentingTipBagSlide,
} from "./slides/ProactiveParenting/_index";
import {
  RulesAndConsequencesSlide1,
  RulesAndConsequencesSlide2,
  RulesAndConsequencesSlide3,
  RulesAndConsequencesSlide4,
  RulesAndConsequencesSlide5,
  RulesAndConsequencesSlide6,
  RulesAndConsequencesSlide7,
  RulesAndConsequencesSlide8,
  RulesAndConsequencesSlide9,
  RulesAndConsequencesSlide10,
  RulesAndConsequencesSlide11,
  RulesAndConsequencesSlide12,
  RulesAndConsequencesSlide13,
  RulesAndConsequencesSlide14,
  RulesAndConsequencesSlide15,
  RulesAndConsequencesSlide16,
  RulesAndConsequencesSlide17,
  RulesAndConsequencesSlide18,
  RulesAndConsequencesSlide19,
  RulesAndConsequencesSlide20,
  RulesAndConsequencesSlide21,
} from "./slides/rulesAndConsequences/_index";

const slideComponents: Record<ModuleSlideKind, FC<any>> = {
  [ModuleSlideKind.SELF_CARE_WELCOME]: SelfCareWelcomeSlide,
  [ModuleSlideKind.SELF_CARE_CONTENT_1]: SelfCareContent1Slide,
  [ModuleSlideKind.SELF_CARE_CONTENT_2]: SelfCareContent2Slide,
  [ModuleSlideKind.SELF_CARE_PLAN]: SelfCarePlanSlide,
  [ModuleSlideKind.MANAGING_DEPRESSION_WELCOME]: ManagingDepressionWelcomeSlide,
  [ModuleSlideKind.MANAGING_DEPRESSION_MOOD_SPIRAL]: ManagingDepressionMoodSpiralSlide,
  [ModuleSlideKind.MANAGING_DEPRESSION_CONTENT_1]: ManagingDepressionContent1Slide,
  [ModuleSlideKind.MANAGING_DEPRESSION_PLAN]: ManagingDepressionPlanSlide,
  [ModuleSlideKind.MANAGING_STRESS_WELCOME]: ManagingStressWelcomeSlide,
  [ModuleSlideKind.MANAGING_STRESS_CONTENT_2]: ManagingStressContent2Slide,
  [ModuleSlideKind.MANAGING_STRESS_CONTENT_3]: ManagingStressContent3Slide,
  [ModuleSlideKind.MANAGING_STRESS_CONTENT_4]: ManagingStressContent4Slide,
  [ModuleSlideKind.HEALTHY_ROUTINES_RANKINGS]: HealthyRoutinesRankingsSlide,
  [ModuleSlideKind.HEALTHY_ROUTINES_EXAMPLES]: HealthyRoutinesExamplesSlide,
  [ModuleSlideKind.HEALTHY_ROUTINES_MAKING_CHANGES]: HealthyRoutinesMakingChangesSlide,
  [ModuleSlideKind.HEALTHY_ROUTINES_PLAN]: HealthyRoutinesPlanSlide,
  [ModuleSlideKind.SLEEP_WELCOME]: SleepWelcomeSlide,
  [ModuleSlideKind.SLEEP_HABITS_ROOM]: SleepHabitsRoomSlide,
  [ModuleSlideKind.SLEEP_PLAN_INSTRUCTIONS]: SleepPlanInstructionsSlide,
  [ModuleSlideKind.WHAT_IS_POSITIVE_PARENTING_INTRO]: WhatIsPositiveParentingIntroSlide,
  [ModuleSlideKind.WHAT_IS_POSITIVE_PARENTING_QUIZ]: WhatIsPositiveParentingQuizSlide,
  [ModuleSlideKind.WHAT_IS_POSITIVE_PARENTING_OUTRO]: WhatIsPositiveParentingOutroSlide,
  [ModuleSlideKind.ENCOURAGEMENT_AND_PRAISE_INTRO]: EncouragementAndPraiseIntroSlide,
  [ModuleSlideKind.ENCOURAGEMENT_AND_PRAISE_CONTENT_1]: EncouragementAndPraiseContent1Slide,
  [ModuleSlideKind.ENCOURAGEMENT_AND_PRAISE_CONTENT_2]: EncouragementAndPraiseContent2Slide,
  [ModuleSlideKind.ENCOURAGEMENT_AND_PRAISE_CONTENT_3]: EncouragementAndPraiseContent3Slide,
  [ModuleSlideKind.ENCOURAGEMENT_AND_PRAISE_QUIZ]: EncouragementAndPraiseQuizSlide,
  [ModuleSlideKind.RELATIONSHIP_BUILDING_INTRO]: RelationshipBuildingIntroSlide,
  [ModuleSlideKind.RELATIONSHIP_BUILDING_EXAMPLE]: RelationshipBuildingExampleSlide,
  [ModuleSlideKind.RELATIONSHIP_BUILDING_SITUATION_EXAMPLE_1]: RelationshipBuildingSituationExample1Slide,
  [ModuleSlideKind.RELATIONSHIP_BUILDING_SITUATION_EXAMPLE_2]: RelationshipBuildingSituationExample2Slide,
  [ModuleSlideKind.RELATIONSHIP_BUILDING_OUTRO]: RelationshipBuildingOutroSlide,
  [ModuleSlideKind.GIVING_DIRECTION_INTRO]: GivingDirectionIntroSlide,
  [ModuleSlideKind.GIVING_DIRECTION_TIPS]: GivingDirectionTipsSlide,
  [ModuleSlideKind.GIVING_DIRECTION_HELPFUL_PRE_QUIZ]: GivingDirectionHelpfulPreQuizSlide,
  [ModuleSlideKind.GIVING_DIRECTION_HELPFUL_QUIZ]: GivingDirectionHelpfulQuizSlide,
  [ModuleSlideKind.GIVING_DIRECTION_OUTRO]: GivingDirectionOutroSlide,
  [ModuleSlideKind.USING_INCENTIVES_INTRO]: UsingIncentivesIntroSlide,
  [ModuleSlideKind.USING_INCENTIVES_INCENTIVE_DEFINITION]: UsingIncentivesIncentiveDefinitionSlide,
  [ModuleSlideKind.USING_INCENTIVES_REWARD_DEFINITION]: UsingIncentivesRewardDefinitionSlide,
  [ModuleSlideKind.USING_INCENTIVES_USAGE_INSTRUCTIONS]: UsingIncentivesUsageInstructionsSlide,
  [ModuleSlideKind.USING_INCENTIVES_EXAMPLE]: UsingIncentivesExampleSlide,
  [ModuleSlideKind.USING_INCENTIVES_BRIBES_VS_INCENTIVES]: UsingIncentivesBribesVsIncentivesSlide,
  [ModuleSlideKind.USING_INCENTIVES_BRIBE_OR_REWARD_1]: UsingIncentivesBribeOrReward1Slide,
  [ModuleSlideKind.USING_INCENTIVES_BRIBE_OR_REWARD_2]: UsingIncentivesBribeOrReward2Slide,
  [ModuleSlideKind.PROACTIVE_PARENTING_DEFINITION]: ProactiveParentingDefinitionSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_HOW_TO_PLAN]: ProactiveParentingHowToPlanSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PLAN_EXAMPLE]: ProactiveParentingPlanExampleSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PLAN_QUIZ]: ProactiveParentingPlanQuizSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_HEALTHY_FAMILY_ROUTINES_1]: ProactiveParentingHealthyFamilyRoutines1Slide,
  [ModuleSlideKind.PROACTIVE_PARENTING_BENEFITES_OF_FAMILY_ROUTINES]: ProactiveParentingBenefitsOfFamilyRoutinesSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_ROUTINE_TIMES]: ProactiveParentingRoutineTimesSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_BEDTIME_ROUTINE_VIDEO]: ProactiveParentingBedtimeRoutineVideoSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_HEALTHY_FAMILY_ROUTINES_2]: ProactiveParentingHealthyFamilyRoutines2Slide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PROACTIVE_OR_NOT_QUIZ]: ProactiveParentingProactiveOrNotQuizSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_TIP_BAG]: ProactiveParentingTipBagSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PLAY_VIDEO]: ProactiveParentingPlayVideoSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PLAY_BARRIERS]: ProactiveParentingPlayBarriersSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PLAY_SUGGESTIONS]: ProactiveParentingPlaySuggestionsSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PLAY_COMMITMENT]: ProactiveParentingPlayCommitmentSlide,
  [ModuleSlideKind.PROACTIVE_PARENTING_PLAY_SUMMARY]: ProactiveParentingPlaySummarySlide,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_1]: RulesAndConsequencesSlide1,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_2]: RulesAndConsequencesSlide2,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_3]: RulesAndConsequencesSlide3,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_4]: RulesAndConsequencesSlide4,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_5]: RulesAndConsequencesSlide5,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_6]: RulesAndConsequencesSlide6,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_7]: RulesAndConsequencesSlide7,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_8]: RulesAndConsequencesSlide8,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_9]: RulesAndConsequencesSlide9,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_10]: RulesAndConsequencesSlide10,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_11]: RulesAndConsequencesSlide11,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_12]: RulesAndConsequencesSlide12,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_13]: RulesAndConsequencesSlide13,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_14]: RulesAndConsequencesSlide14,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_15]: RulesAndConsequencesSlide15,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_16]: RulesAndConsequencesSlide16,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_17]: RulesAndConsequencesSlide17,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_18]: RulesAndConsequencesSlide18,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_19]: RulesAndConsequencesSlide19,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_20]: RulesAndConsequencesSlide20,
  [ModuleSlideKind.RULES_AND_CONSEQUENCES_21]: RulesAndConsequencesSlide21,
  [ModuleSlideKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_INTRO]: ParentingAndSubstanceUseWelcomeSlide,
  [ModuleSlideKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_MYTH_OR_FACT]: ParentingAndSubstanceUseMythOrFactSlide,
  [ModuleSlideKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_CONTENT_1]: ParentingAndSubstanceUseContent1Slide,
  [ModuleSlideKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_REASONS]: ParentingAndSubstanceUseReasonsActivitySlide,
  [ModuleSlideKind.MANAGING_CRAVINGS_CONTENT_1]: ManagingCravingsSlide,
  [ModuleSlideKind.MANAGING_CRAVINGS_CONTENT_2]: ManagingCravingsSurfSlide,
  [ModuleSlideKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_TRIGGERS]: ParentingAndSubstanceUseTriggersActivitySlide,
  [ModuleSlideKind.PARENTING_AND_SUBSTANCE_USE_TIP_BAG]: ParentingAndSubstanceUseTipBagSlide,
  [ModuleSlideKind.MANAGING_CRAVINGS_OUTRO]: ManagingCravingsOutroSlide,
  [ModuleSlideKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_OUTRO_VIDEO]: ParentingAndSubstanceUseVideoOutroSlide,
  [ModuleSlideKind.UNHEALTHY_VS_HEALTHY_RELATIONSHIPS]: UnhealthyVsHealthyRelationshipsIntroSlide,
  [ModuleSlideKind.EFFECTIVE_COMMUNICATION_VIDEO]: EffectiveCommunicationVideoSlide,
  [ModuleSlideKind.EFFECTIVE_COMMUNICATION_CONTENT_1]: EffectiveCommunicationContent1Slide,
  [ModuleSlideKind.EFFECTIVE_COMMUNICATION_COMPARISON_STATEMENT]: EffectiveCommunicationStatementComparisonSlide,
  [ModuleSlideKind.UNHEALTHY_VS_HEALTHY_RELATIONSHIPS_OUTRO]: UnhealthyVsHealthyRelationshipsOutroSlide,
  [ModuleSlideKind.PARENTING_RESPONSIBILITIES_INTRO]: ParentingResponsibilitiesIntroSlide,
  [ModuleSlideKind.PARENTING_RESPONSIBILITIES_CONTENT_1]: ParentingResponsibilitiesContent1Slide,
  [ModuleSlideKind.HOME_SAFETY_PRE_QUIZ]: HomeSafetyPreQuizSlide,
  [ModuleSlideKind.HOME_SAFETY_DO_OR_DONT_QUIZ]: HomeSafetyQuizSlide,
  [ModuleSlideKind.HOME_SAFETY_OUTRO]: HomeSafetyOutroSlide,
  [ModuleSlideKind.UNIMPLEMENTED_PLACEHOLDER]: PlaceholderSlide,
};

interface Props {
  kind: ModuleSlideKind;
  progress: SlidePageProps["progress"];
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  hideBackButton?: boolean;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const ModuleContentSlide: FC<Props> = (props) => {
  const { kind, ...rest } = props;
  const SlideComponent = slideComponents[kind] ?? PlaceholderSlide;

  return <SlideComponent {...rest} slideKind={kind} />;
};

export default styled(ModuleContentSlide)``;
