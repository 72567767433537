import React, { FC } from "react";
import PillButton from "../../surveys/components/PillButton";
import Column from "@emberex/components/lib/Column";
import AnimatedOverlay from "./AnimatedOverlay";
import styled from "styled-components/macro";

interface Props {
  open: boolean;
  onNext(): void;
  height?: number;
}

const ActivityOverlay: FC<Props> = (props) => {
  const { open, onNext, height } = props;

  return (
    <Root height={height} open={open}>
      <OverlayColumn>
        <Content>{props.children}</Content>
        <Footer>
          <PillButton onClick={onNext}>Continue</PillButton>
        </Footer>
      </OverlayColumn>
    </Root>
  );
};

export default styled(ActivityOverlay)``;

const Root = styled(AnimatedOverlay)<{ height?: number }>`
  height: ${(props) => (props.height ? `${props.height}%` : "80%")};
`;

const OverlayColumn = styled(Column)`
  height: 100%;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #ccfcee;
  border-radius: 8px 8px 0 0;
`;

const Content = styled("div")`
  height: calc(100% - 129px);
`;

const Footer = styled("div")`
  position: absolute;
  bottom: 0;
  height: 130px;
  width: 100%;
  background-color: #11355d;
  display: flex;
  justify-content: center;
  align-items: center;
`;
