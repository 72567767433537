import { useEffect, useRef } from "react";
import useOnUnmount from "./useOnUnmount";

/**
 * Set the document's title.
 * Will be prepended with "Family Check-up"
 * @param title
 * @param clearOnUnmount
 */
export default function useDocumentTitle(title: string | null, clearOnUnmount = true) {
  const originalTitle = useRef<string>(document.title);

  useEffect(() => {
    document.title = `Family Check-up${title ? `: ${title}` : ''}`;
  }, [title]);

  useOnUnmount(() => {
    if(clearOnUnmount) {
      document.title = originalTitle.current;
    }
  });
}