import React, { FC } from "react";
import styled from "styled-components/macro";
import RawHTML from "../../common/components/RawHTML";

interface Props {
  text?: string;
}

const SurveyQuestionText: FC<Props> = (props) => {
  const { text, ...rest } = props;

  return (
    <p {...rest}>
      <RawHTML html={text} />
    </p>
  );
};

export default styled(SurveyQuestionText)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: center;
  padding: 0 1rem 0 1rem;
  margin-top: 1.25rem;
`;
