import React, { FC, ReactNode } from "react";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import ActivityOverlay from "../../common/components/ActivityOverlay";
import styled from "styled-components/macro";
import Badge from "../../common/components//PointsBadge";
import bearBoySrc from "../images/bear_boy.png";
import thumbsUpSrc from "../images/thumbs_up_circle_icon.png";
import alertSrc from "../images/alert_circle_icon.png";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { getActivityPointValue } from "shared/lib/interfaces/ModuleActivity";

export type QuizCompleteOverlayIconType = "thumbs-up" | "alert";

interface Props {
  open: boolean;
  icon?: QuizCompleteOverlayIconType;
  activityKind?: ModuleActivityKind;
  activityMessage?: ReactNode;
  onNext(): unknown;
}

const QuizCompletionOverlay: FC<Props> = (props) => {
  const { activityKind, activityMessage, icon, ...rest } = props;
  const activityPoints = activityKind && getActivityPointValue(activityKind);
  const messageOnly = !activityPoints;

  return (
    <ActivityOverlay height={messageOnly ? 50 : 80} {...rest}>
      <Container>
        <MessageRow messageOnly={messageOnly}>
          <Message>
            {!!icon && <Icon icon={icon} />}
            {activityMessage}
          </Message>
        </MessageRow>
        {!messageOnly && (
          <BearRow>
            <BottomColumn>
              <BearBoy />
            </BottomColumn>
            <BottomColumn>
              <Badge>
                <Points>{activityPoints}</Points>
                <PointsLabel>PTS</PointsLabel>
              </Badge>
              <PointsMessage>
                You’ve earned {activityPoints} points for completing an
                activity!
              </PointsMessage>
            </BottomColumn>
          </BearRow>
        )}
      </Container>
    </ActivityOverlay>
  );
};

export default QuizCompletionOverlay;

const Container = styled(Column)`
  height: 100%;
  padding-top: 25px;
`;

const MessageRow = styled(Row)<{ messageOnly?: boolean }>`
  justify-content: center;
  align-content: center;
  align-items: center;
  height: ${(props) => (props.messageOnly ? "100%" : "50%")};
`;

const Message = styled("div")`
  position: relative;
  width: 90%;
  border-radius: 8px;
  background-color: #9dedd6;
  color: #0e3750;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 2rem 1rem;
`;

// prettier-ignore
const Icon = styled("div")<{ icon?: QuizCompleteOverlayIconType }>`
  position: absolute;
  background-image: url('${(props) => props.icon === 'thumbs-up' ? thumbsUpSrc : alertSrc}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: calc(50% - 1rem);
  top: -18px;
  width: 36px;
  height: 36px;
`;

const BearRow = styled(Row)`
  height: 50%;
`;

const BottomColumn = styled(Column)`
  justify-content: center;
  justify-content: space-between;
  text-align: center;
  align-content: center;
  align-items: center;
  width: 50%;

  ${Badge} {
    height: 50%;
    display: flex;
    flex-direction: column;
    line-height: 2rem;
    color: white;
  }
`;

const Points = styled("div")`
  font-size: 3rem;
  font-weight: 700;
`;

const PointsLabel = styled("div")`
  font-size: 1.5rem;
  font-weight: 500;
`;

const PointsMessage = styled(Column)`
  height: 50%;
  justify-content: center;
  align-content: center;
  color: #0e3750;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
`;

const BearBoy = styled("img").attrs({
  src: bearBoySrc,
  alt: "Bear Boy Graphic",
})`
  width: auto;
  height: 100%;
`;
