import apiAxios from "../../apiAxios";

interface Props {
  moduleId: number;
  slideId: number;
  participantId: number;
}

export default async function completeModuleSlide(
  props: Props
) {
  const { participantId, moduleId, slideId } = props;
  return apiAxios.post(
    `/participants/${participantId}/modules/${moduleId}/slides/${slideId}/complete`
  );

}