import { useCallback } from "react";

export default function useHelpfulUnhelpfulQuiz<T>(
  value: T,
  setValue: (updatedValue: T) => void
) {
  const handleCheckboxChange = useCallback(
    (key: keyof T) => (helpful: boolean) => {
      setValue({ ...value, [key]: helpful });
    },
    [setValue, value]
  );

  return {
    onCheckboxChange: handleCheckboxChange,
  };
}
