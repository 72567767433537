export const DARKEST_BLUE = "#0E3750";
export const DARKER_BLUE = "#1A355A";
export const DARK_BLUE = "#11355d";
export const LIGHT_BLUE = "#08adc0";
export const LIGHTER_BLUE = "#ccfcee";
export const LIGHTEST_BLUE = "#e0f9f2";
export const DARKER_GRAY = "#333333";
export const DARK_GRAY = "#4a4a4a";
export const LIGHT_GRAY = "#f3f5f7";
export const LIGHT_RED = "#ff595b";
export const RED = "#cc4747";
export const YELLOW = "#FDD965";
export const PURPLE = "#574B90";
