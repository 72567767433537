import React, { FC } from "react";
import styled from "styled-components/macro";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import Module from "shared/lib/interfaces/Module";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { RelationshipBulidingSituationValue } from "shared/lib/interfaces/ModuleActivity";
import { SlidePageProps } from "../../SlidePage";
import { SlidePageProgress } from "../../SlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import SensitiveResponsiveSlidePage from "../../SensitiveResponsiveSlidePage";

const activityKind =
  ModuleActivityKind.RELATIONSHIP_BUILDING_SITUATION_EXAMPLE_1;

const defaultValue: RelationshipBulidingSituationValue = {
  sensitive: "",
  responsive: "",
};

export const relationshipBuildingSituation1RecommendedResponses: RelationshipBulidingSituationValue = {
  sensitive:
    "Stop what you’re doing, and meet her at her level so she knows she has your attention.",
  responsive: "Ask her what she needs, and then give it to her.",
};

export const relationshipBuildingSituation1Title = "Your turn: Practice 1";

export const relationshipBuildingSituation1Situation = (
  <>
    Your toddler was playing quietly, but now she’s tugging at your leg saying
    “Mommy” over and over again.
  </>
);

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RelationshipBuildingSituationExample1Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);

  return (
    <SensitiveResponsiveSlidePage
      {...rest}
      title={module.name}
      value={value}
      onChange={setValue}
      recommendedResponses={relationshipBuildingSituation1RecommendedResponses}
      activityKind={activityKind}
      situationTitle={relationshipBuildingSituation1Title}
      situation={relationshipBuildingSituation1Situation}
    />
  );
};

export default styled(RelationshipBuildingSituationExample1Slide)``;
