import styled, { css } from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import TextButton from "@emberex/components/lib/TextButton";

const cardCss = css`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  padding: 1.25rem;
  font-size: 1rem;
`;

const Card = styled(Column)`
  ${cardCss}
`;

export const CardButton = styled(TextButton)`
  ${cardCss}
`;

export default Card;
