const messages: string[] = [
  "Awesome!",
  "Great!",
  "Sweet!",
  "Fantastic!",
  "Super Job!",
];
export default function getPositiveBlurbText(): string {
  return messages[(messages.length * Math.random()) | 0];
}
