import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { SatisfactionMoodValue } from "shared/lib/interfaces/ModuleActivity";
import Mood from "shared/lib/enums/Mood";
import { BearIcons } from "../../../../common/components/BearIcon";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import MoodInput from "../../../../surveys/components/MoodInput";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.HEALTHY_ROUTINES_SATISFACTION_MOODS;

const defaultValue: SatisfactionMoodValue = {
  eatHealthyMeals: null,
  get7HoursSleep: null,
  spentTimeWithChildren: null,
  haveBedTimeRoutine: null,
  getExercise: null,
};

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const HealthyRoutinesRankingsSlide: FC<Props> = (props) => {
  const { module, section, isReview, onNext, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);

  const handleMoodChange = (key: keyof SatisfactionMoodValue) => (
    mood: Mood | null
  ) => {
    setValue({
      ...value,
      [key]: mood,
    });
  };

  const handleSlideNext = useCallback(() => {
    setValue(value); // Force the value to save even if it is still the default
    onNext();
  }, [onNext, setValue, value]);

  return (
    <ActivitySlidePage
      {...rest}
      onNext={handleSlideNext}
      isReview={isReview}
      activityKind={activityKind}
      title={module.name}
      bearIcon={BearIcons.BABY_THUMBS_UP_WINKING}
    >
      <section>
        <h3>{section.name}</h3>
        <p>
          Rate your satisfaction with some of the things you probably do each
          day.
        </p>
      </section>
      <section>
        <MoodInput
          value={value.eatHealthyMeals}
          onChange={handleMoodChange("eatHealthyMeals")}
          lightLabel
          label="Eat heathy meals"
        />
        <MoodInput
          value={value.get7HoursSleep}
          onChange={handleMoodChange("get7HoursSleep")}
          lightLabel
          label="Get at least 7 hours of sleep"
        />
        <MoodInput
          value={value.spentTimeWithChildren}
          onChange={handleMoodChange("spentTimeWithChildren")}
          lightLabel
          label="Spend quality time with children"
        />
        <MoodInput
          value={value.haveBedTimeRoutine}
          onChange={handleMoodChange("haveBedTimeRoutine")}
          lightLabel
          label="Have a bedtime routine"
        />
        <MoodInput
          value={value.getExercise}
          onChange={handleMoodChange("getExercise")}
          lightLabel
          label="Get exercise"
        />
      </section>
    </ActivitySlidePage>
  );
};

export default styled(HealthyRoutinesRankingsSlide)``;
