import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ImageList, { ImageListItem } from "../../ImageList";
import stockImageSrc1 from "../../../images/stock/stock_12.png";
import stockImageSrc2 from "../../../images/stock/stock_13.png";
import stockImageSrc3 from "../../../images/stock/stock_14.png";
import stockImageSrc4 from "../../../images/stock/stock_15.png";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ProactiveParentingPlayBarriersSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>PLAY</h3>
        <BarrierListHeader>
          What keeps you from playing with your child?
        </BarrierListHeader>
        <ImageList>
          <ImageListItem imageSrc={stockImageSrc1}>
            You’re too busy
          </ImageListItem>
          <ImageListItem imageSrc={stockImageSrc4}>
            Your other children demand your attention 
          </ImageListItem>
          <ImageListItem imageSrc={stockImageSrc2}>
            You’re tired
          </ImageListItem>
          <ImageListItem imageSrc={stockImageSrc3}>Other?</ImageListItem>
        </ImageList>
      </section>
    </SlidePage>
  );
};

export default styled(ProactiveParentingPlayBarriersSlide)``;

const BarrierListHeader = styled("p")`
  color: #ff6762;
  font-weight: bold;
  margin: 0.75rem 0 1.25rem 0;
`;
