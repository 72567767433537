import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import VideoId from "shared/lib/enums/VideoId";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import VideoActivity from "../../VideoActivity";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind =
  ModuleActivityKind.POSITIVE_PARENTING_GIVING_DIRECTIONS_VIDEO;
const videoId = VideoId.POSITIVE_PARENTING_GIVING_DIRECTIONS_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const GivingDirectionIntroSlide: FC<Props> = (props) => {
  const { module, section, isReview, onNext, ...rest } = props;
  const [onPlayStart, setVideoPlayed, videoCompleted] = useVideoActivity(
    activityKind,
    videoId
  );

  const handleNext = useCallback(() => {
    setVideoPlayed(true);
    onNext();
  }, [setVideoPlayed, onNext]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      title={module.name}
      activityKind={activityKind}
      onNext={handleNext}
    >
      <section>
        <h3>{section.name}</h3>
        <p>
          When you give directions to your child in a simple and clear way you
          can increase cooperation, prevent arguments, and improve family
          relationships. Watch this video to see how to do it.
        </p>
      </section>
      <VideoActivity
        onStarted={onPlayStart}
        onEnded={setVideoPlayed}
        videoId={videoId}
      />
    </ActivitySlidePage>
  );
};

export default styled(GivingDirectionIntroSlide)``;
