import React, { FC } from "react";
import styled from "styled-components/macro";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import Module from "shared/lib/interfaces/Module";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { PositiveParentingQuizValue } from "shared/lib/interfaces/ModuleActivity";
import { SlidePageProps } from "../../SlidePage";
import ActivityBox, { InnerActivityBox } from "../../ActivityBox";
import { SlidePageProgress } from "../../SlidePage";
import ModuleCheckBox from "../../ModuleCheckBox";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import { DARKEST_BLUE } from "../../../../colors";
import { allTrue } from "../../../../common/utils/validateQuizResponse";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind =
  ModuleActivityKind.WHAT_IS_POSITIVE_PARENTING_DESCRIPTION_QUIZ;

const defaultValue: PositiveParentingQuizValue = {
  praiseAndRewardYourChild: false,
  smileAtYourChild: false,
  spendQualityTimeWithYourChild: false,
  payAttentionToYourChildsGoodBehavior: false,
};

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const correctResponse =
  "You really know what it takes to parent in a positive way!";
const incorrectResponse =
  "Actually, all of these are examples of positive parenting.";

const WhatIsPositiveParentingQuizSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);
  const allCorrect = allTrue(value);

  const handleCheckboxChange = (key: keyof PositiveParentingQuizValue) => () =>
    setValue({ ...value, [key]: !value[key] });

  return (
    <ActivitySlidePage
      {...rest}
      light
      lightNextButton
      nextButtonText="Submit"
      title={module.name}
      activityKind={activityKind}
      activityMessage={allCorrect ? correctResponse : incorrectResponse}
      showConfetti={allCorrect}
      completeOverlayIcon={allCorrect ? "thumbs-up" : "alert"}
    >
      <ActivityBox icon="checkbox">
        <Instructions>
          Your turn: Check all the boxes that describe what it means to use
          positive parenting.
        </Instructions>
        <InnerActivityBox>
          <ModuleCheckBox
            checked={value.praiseAndRewardYourChild}
            onChange={handleCheckboxChange("praiseAndRewardYourChild")}
            label="Praise and reward your child"
          />
          <ModuleCheckBox
            checked={value.smileAtYourChild}
            onChange={handleCheckboxChange("smileAtYourChild")}
            label="Smile at your child"
          />
          <ModuleCheckBox
            checked={value.spendQualityTimeWithYourChild}
            onChange={handleCheckboxChange("spendQualityTimeWithYourChild")}
            label="Spend quality time with your child"
          />
          <ModuleCheckBox
            checked={value.payAttentionToYourChildsGoodBehavior}
            onChange={handleCheckboxChange(
              "payAttentionToYourChildsGoodBehavior"
            )}
            label="Pay attention to your child’s good behavior"
          />
        </InnerActivityBox>
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(WhatIsPositiveParentingQuizSlide)``;

const Instructions = styled("p")`
  margin-bottom: 3rem;
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.35rem;
  text-align: center;
`;
