import React, { FC } from "react";
import styled from "styled-components/macro";

import { YELLOW } from "../../colors";
import checkboxIconSrc from "../images/checkbox_icon.png";
import pencilIconSrc from "../images/pencil_icon.png";
import pointerIconSrc from "../images/pointer_icon.png";
import filmIconSrc from "../images/film_icon.png";
import chatIconSrc from "../images/chat_icon.png";
import phoneIconSrc from "../images/phone_icon_white.png";
import Column from "@emberex/components/lib/Column";

const icons = {
  checkbox: checkboxIconSrc,
  pencil: pencilIconSrc,
  pointer: pointerIconSrc,
  film: filmIconSrc,
  chat: chatIconSrc,
  phone: phoneIconSrc,
};

export interface ActivityIconProps {
  icon: keyof typeof icons;
  borderColor?: "white" | "yellow";
}

const ActivityIcon: FC<ActivityIconProps> = (props) => {
  const { icon, ...rest } = props;

  return (
    <Root {...rest}>
      <IconImage src={icons[icon]} alt="" />
    </Root>
  );
};

export default styled(ActivityIcon)``;

const Root = styled(Column)<{ borderColor?: ActivityIconProps["borderColor"] }>`
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
  border: 2px solid
    ${(props) => (props.borderColor === "white" ? "white" : YELLOW)};
  border-radius: 50%;
  background-color: white;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;

const IconImage = styled("img")`
  width: 40%;
  height: 40%;
  object-fit: contain;
`;
