import React, { FC } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import { YELLOW, DARKEST_BLUE } from "../../colors";
import ActivityIcon, { ActivityIconProps } from "./ActivityIcon";

interface Props {
  icon?: ActivityIconProps["icon"];
  white?: boolean;
  instructions?: string;
}

const ActivityBox: FC<Props> = (props) => {
  const { children, icon, white, instructions, ...rest } = props;

  return (
    <Column {...rest}>
      {!!icon && (
        <ActivityIcon borderColor={white ? "white" : "yellow"} icon={icon} />
      )}
      {!!instructions && <Instructions>{instructions}</Instructions>}
      {children}
    </Column>
  );
};

export default styled(ActivityBox)<{ white?: boolean }>`
  padding: 2rem 0.75rem 1.5rem 0.75rem;
  background-color: ${(props) => (props.white ? "white" : YELLOW)};
  flex-shrink: 0;
  position: relative;

  ${ActivityIcon} {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
`;

const Instructions = styled("p")`
  color: ${DARKEST_BLUE};
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.25rem;
`;

export const InnerActivityBox = styled(Column)`
  flex-shrink: 0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  padding: 1rem;
`;
