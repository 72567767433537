import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import InfoIcon from "../../InfoIcon";
import livingRoomImage from "../../../images/rules_and_consequences_living_room.png";
import SceneTooltip from "../../../../common/components/SceneTooltip";

enum Tooltip {
  OUTLET = "OUTLET",
  BOOKCASE = "BOOKCASE",
  COFFEE_TABLE = "COFFEE_TABLE",
  BREAKABLES = "BREAKABLES",
  DRUGS = "DRUGS",
}

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps['isReview'];
}

const RulesAndConsequencesSlide4: FC<Props> = (props) => {
  const { module, section, isReview, ...rest } = props;
  const [visibleTooltip, setVisibleTooltip] = useState<null | Tooltip>(null);
  const [viewedTooltips, setViewedTooltips] = useState<Tooltip[]>([]);

  const showTooltip = useCallback((toolTip: Tooltip) => {
    setVisibleTooltip(toolTip);
    setViewedTooltips((value) =>
      value.includes(toolTip) ? value : [...value, toolTip]
    );
  }, []);

  const closeTooltip = useCallback(() => setVisibleTooltip(null), []);

  return (
    <SlidePage {...rest} title={module.name} showNextButton={viewedTooltips.length > 0 || isReview}>
      <section>
        <p>
          Tap on a <InfoIcon /> in the living room below to learn more about
          everyday safety concerns in your home.
        </p>
      </section>
      <LivingRoom>
        <LivingRoomImage />
        <OutletInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.OUTLET)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.OUTLET}
            text="Cover electrical outlets."
            position="topRight"
            textAlign="left"
            onClose={closeTooltip}
          />
        </OutletInfo>

        <BookcaseInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.BOOKCASE)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.BOOKCASE}
            text="Secure furniture that can topple (bookcases, TVs, chests of drawers) to the walls."
            position="leftTop"
            textAlign="left"
            onClose={closeTooltip}
          />
        </BookcaseInfo>

        <CoffeeTableInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.COFFEE_TABLE)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.COFFEE_TABLE}
            text="Cushion sharp corners, such as on coffee tables."
            position="topRight"
            textAlign="left"
            onClose={closeTooltip}
          />
        </CoffeeTableInfo>

        <BreakableInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.BREAKABLES)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.BREAKABLES}
            text="Move or store breakables out of child’s reach."
            position="topLeft"
            textAlign="left"
            onClose={closeTooltip}
          />
        </BreakableInfo>

        <AlcoholInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.DRUGS)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.DRUGS}
            text="Keep alcohol and other drugs out of reach."
            position="topLeft"
            textAlign="left"
            onClose={closeTooltip}
          />
        </AlcoholInfo>
      </LivingRoom>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide4)`
  p > ${InfoIcon} {
    transform: translate(0, 25%);
  }
`;

const LivingRoom = styled("div")`
  flex-shrink: 0;
  margin-top: 5rem;
  position: relative;
`;

const LivingRoomImage = styled("img").attrs({
  src: livingRoomImage,
  alt: "Living room",
})`
  width: 100%;
  height: auto;
`;

const SceneInfo = styled("div")`
  position: absolute;
  top: 0;
  left: 0;

  > ${InfoIcon} {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  > ${SceneTooltip} {
    position: absolute;
  }
`;

const OutletInfo = styled(SceneInfo)`
  left: 15%;
  top: 55%;

  > ${SceneTooltip} {
    top: -1rem;
    left: 0;
    width: 256px;
    transform: translate(-5%, -100%);
  }
`;

const BookcaseInfo = styled(SceneInfo)`
  left: 70%;
  top: 42%;

  > ${SceneTooltip} {
    top: 0;
    left: -0.75rem;
    width: 225px;
    transform: translate(-100%, -65%);
  }
`;

const CoffeeTableInfo = styled(SceneInfo)`
  left: 28%;
  top: 70%;

  > ${SceneTooltip} {
    top: -1rem;
    left: 0;
    width: 225px;
    transform: translate(-5%, -100%);
  }
`;

const BreakableInfo = styled(SceneInfo)`
  left: 74%;
  top: 75%;

  > ${SceneTooltip} {
    top: -0.5rem;
    left: 0;
    width: 225px;
    transform: translate(-85%, -100%);
  }
`;

const AlcoholInfo = styled(SceneInfo)`
  left: 53%;
  top: 60%;

  > ${SceneTooltip} {
    top: -0.75rem;
    left: 0;
    width: 225px;
    transform: translate(-84%, -100%);
  }
`;
