import React, { FC, useState, useEffect } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ignoringImage1Src from "../../../images/rules_and_consequences_ignoring_1.png";
import ignoringImage2Src from "../../../images/rules_and_consequences_ignoring_2.png";
import ignoringImage3Src from "../../../images/rules_and_consequences_ignoring_3.png";
import ignoringImage4Src from "../../../images/rules_and_consequences_ignoring_4.png";

const steps = [
  {
    image: ignoringImage1Src,
    text: (
      <>
        Here’s what intentional ignoring looks like:
        <br />
        <br />
        Jackson is whining because he didn't get a cookie. Mom ignores the
        whining at first, but when he sits down to play quietly, Mom immediately
        praises him.
      </>
    ),
  },
  {
    image: ignoringImage2Src,
    text: (
      <>
        Here’s what intentional ignoring looks like:
        <br />
        <br />
        Jackson is whining because he didn't get a cookie. Mom ignores the
        whining at first, but when he sits down to play quietly, Mom immediately
        praises him.
      </>
    ),
  },
  {
    image: ignoringImage3Src,
    text: (
      <>
        Here’s what intentional ignoring looks like:
        <br />
        <br />
        Jackson is whining because he didn't get a cookie. Mom ignores the
        whining at first, but when he sits down to play quietly, Mom immediately
        praises him.
      </>
    ),
  },
  {
    image: ignoringImage4Src,
    text: (
      <>
        Notice that though it seems like Mom is ignoring Jackson, she's actually
        aware of him because she immediately praises his positive behavior.
      </>
    ),
  },
];

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps['isReview'];
}

const RulesAndConsequencesSlide18: FC<Props> = (props) => {
  const { module, section, isReview, ...rest } = props;
  const [step, setStep] = useState(steps[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((value) => {
        const stepIndex = steps.indexOf(value);
        if (stepIndex < steps.length - 1) {
          return steps[stepIndex + 1];
        }
        clearInterval(interval);
        return value;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <SlidePage
      {...rest}
      title={module.name}
      showNextButton={(step === steps[steps.length - 1]) || isReview}
    >
      <TextSection>
        <p>{step.text}</p>
      </TextSection>
      <IgnoringImage src={step.image} alt="" />
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide18)``;

const TextSection = styled("section")`
  min-height: 10rem;
`;

const IgnoringImage = styled("img")`
  width: 100%;
  margin-top: 1.25rem;
`;
