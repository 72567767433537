import React, { FC } from "react";
import styled from "styled-components/macro";
import SurveyQuestion, {
  MultipleChoiceSurveyQuestion,
  RangeSurveyQuestion,
} from "shared/lib/interfaces/SurveyQuestion";
import SurveyResponseValue from "shared/lib/interfaces/SurveyResponseValue";
import SurveyQuestionKind from "shared/lib/enums/SurveyQuestionKind";
import MultipleChoiceInput, {
  Value as MultipleChoiceInputValue,
} from "./MultipleChoiceInput";
import RangeInput, { Value as RangeInputValue } from "./RangeInput";

interface Props {
  value: SurveyResponseValue | null;
  question: Pick<SurveyQuestion, "kind" | "options">;
  readonly?: boolean;
  onChange(answer: SurveyResponseValue | null): any;
}

const SurveyResponseInput: FC<Props> = ({
  question,
  onChange,
  value,
  readonly,
  ...rest
}) => {
  switch (question.kind) {
    case SurveyQuestionKind.MULTIPLE_CHOICE:
      const {
        choices,
      } = question.options as MultipleChoiceSurveyQuestion["options"];
      return (
        <MultipleChoiceInput
          {...rest}
          value={value as MultipleChoiceInputValue}
          onChange={onChange}
          choices={choices}
          readonly={readonly}
        />
      );
    case SurveyQuestionKind.RANGE:
      const { min, max } = question.options as RangeSurveyQuestion["options"];
      return (
        <RangeInput
          {...rest}
          value={value as RangeInputValue}
          onChange={onChange}
          min={min}
          max={max}
          readonly={readonly}
        />
      );
  }
};

export default styled(SurveyResponseInput)``;
