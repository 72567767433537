import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import ActivityBox, { InnerActivityBox } from "../../ActivityBox";
import ModuleCheckBox from "../../ModuleCheckBox";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const activityKind =
  ModuleActivityKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_REASONS;

const ParentingAndSubstanceUseReasonsActivitySlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [responseResults, setResponseResults] = useActivity(activityKind);

  const toggleOption = (key: string) => {
    return () =>
      setResponseResults({
        ...responseResults,
        [key]: !responseResults?.[key],
      });
  };

  return (
    <ActivitySlidePage
      {...rest}
      activityKind={activityKind}
      onNext={onNext}
      title={module.name}
      light
      lightNextButton
      nextButtonText="Submit"
    >
      <ActivityBox
        {...rest}
        instructions="Your turn: Reasons I have used substances. Check all that apply."
        icon="checkbox"
      >
        <InnerActivityBox>
          <ModuleCheckBox
            label="Reduce anxiety"
            checked={responseResults?.option1}
            onChange={toggleOption("option1")}
          />
          <ModuleCheckBox
            label="Be social with friends"
            checked={responseResults?.option2}
            onChange={toggleOption("option2")}
          />
          <ModuleCheckBox
            label="Relax"
            checked={responseResults?.option3}
            onChange={toggleOption("option3")}
          />
          <ModuleCheckBox
            label="Take a break from children"
            checked={responseResults?.option4}
            onChange={toggleOption("option4")}
          />
          <ModuleCheckBox
            label="Reduce physical pain"
            checked={responseResults?.option5}
            onChange={toggleOption("option5")}
          />
          <ModuleCheckBox
            label="Recreation purpose / for fun"
            checked={responseResults?.option6}
            onChange={toggleOption("option6")}
          />
          <ModuleCheckBox
            label="Help sleeping"
            checked={responseResults?.option7}
            onChange={toggleOption("option7")}
          />
        </InnerActivityBox>
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(ParentingAndSubstanceUseReasonsActivitySlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;
