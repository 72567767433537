import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import ProactiveParentingPlanQuiz from "../../ProactiveParentingPlanQuiz";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.PROACTIVE_PARENTING_PLAN_QUIZ;

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const ProactiveParentingPlanQuizSlide: FC<Props> = (props) => {
  const { module, section, isReview, onNext, ...rest } = props;
  const [quizResults, setQuizResults] = useActivity(activityKind);
  const [showNextButton, setShowNextButton] = useState(!!quizResults);

  return (
    <ActivitySlidePage
      {...rest}
      activityKind={activityKind}
      onNext={onNext}
      title={module.name}
      light
      lightNextButton
      showNextButton={showNextButton}
    >
      <ProactiveParentingPlanQuiz
        activityKind={activityKind}
        onChange={(value) => {
          if (value && value.length > 0) {
            setQuizResults(value);
          }
        }}
        onFinish={() => setShowNextButton(true)}
      />
    </ActivitySlidePage>
  );
};

export default styled(ProactiveParentingPlanQuizSlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;
