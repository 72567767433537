import React, { FC } from "react";
import styled from "styled-components/macro";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Column from "@emberex/components/lib/Column";
import Participant from "shared/lib/interfaces/Participant";
import { ParticipantContextProvider } from "./participant/contexts/ParticipantContext";
import ParticipantModuleListPage from "./participant/pages/ParticipantModuleListPage";
import ParticipantPlanPage from "./participant/pages/ParticipantPlanPage";
import ParticipantLibraryPage from "./participant/pages/ParticipantLibraryPage";
import ParticipantCoachPage from "./participant/pages/ParticipantCoachPage";
import ParticipantAccountPage from "./participant/pages/ParticipantAccountPage";
import ParticipantModulePage from "./participant/pages/ParticipantModulePage";
import ParticipantVisitTracker from "./analytics/components/ParticipantVisitTracker";

interface Props {
  user: Participant;
  refreshUser(): Promise<void>;
}

const ParticipantRoot: FC<Props> = ({ user, refreshUser }) => {
  return (
    <PageWrapper>
      <ParticipantContextProvider user={user} refreshUser={refreshUser}>
        <Router>
          <Switch>
            <Route exact path="/">
              <ParticipantModuleListPage />
            </Route>
            <Route exact path="/dashboard">
              <ParticipantModuleListPage />
            </Route>
            <Route exact path="/plan">
              <ParticipantPlanPage />
            </Route>
            <Route exact path="/library">
              <ParticipantLibraryPage />
            </Route>
            <Route exact path="/coach">
              <ParticipantCoachPage />
            </Route>
            <Route exact path="/account">
              <ParticipantAccountPage />
            </Route>
            <Route exact path="/module/:moduleNumber/:slideNumber?">
              {({ match }) =>
                /**
                 * Passing the module number as the key forces the module page to completely refresh
                 * when the user navigates from one module to another.
                 */
                match && (
                  <ParticipantModulePage key={match.params.moduleNumber} />
                )
              }
            </Route>
          </Switch>
          <ParticipantVisitTracker />
        </Router>
      </ParticipantContextProvider>
    </PageWrapper>
  );
};

export default ParticipantRoot;

const PageWrapper = styled(Column)`
  background-color: #ddd;

  @media (min-width: 600px) {
    > * {
      width: 600px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
`;
