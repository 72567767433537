import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BearIcons } from "../../../../common/components/BearIcon";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import SceneTooltip from "../../../../common/components/SceneTooltip";
import bearBedroomSrc from "../../../images/bear_bedroom.png";
import infoIconSrc from "../../../images/info_icon.png";
import sleepTips, { SleepTip } from "../../../activities/sleepTips";
import ActivitySlidePage from "../../ActivitySlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import finalBedroomImageSrc from "../../../images/bear_bedroom_asleep.png";

const activityKind = ModuleActivityKind.SLEEP_HABITS_ROOM;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const SleepHabitsRoomSlide: FC<Props> = (props) => {
  const { module, onNext, isReview, ...rest } = props;
  const setActivityValue = useActivity(activityKind)[1];
  const [tipNumber, setTipNumber] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [sceneImage, setSceneImage] = useState(bearBedroomSrc);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const currentTip: SleepTip = sleepTips[tipNumber];

  const handleNextTip = useCallback(() => {
    if (tipNumber + 1 > sleepTips.length - 1) {
      setActivityValue(null); // record activity completed
      setTooltipVisible(false);
      setSceneImage(finalBedroomImageSrc);
    } else {
      setTipNumber(tipNumber + 1);
      setTooltipVisible(false);
    }
  }, [tipNumber, setActivityValue, setTipNumber]);

  const displayTooltip = useCallback(() => {
    !showNextButton && setShowNextButton(true);
    setTooltipVisible(true);
    setActivityValue(null);
    if (currentTip.sceneImage && currentTip.sceneImage !== sceneImage) {
      setSceneImage(currentTip.sceneImage);
    }
  }, [setSceneImage, setActivityValue, currentTip, sceneImage, showNextButton]);

  const finalScene = sceneImage === finalBedroomImageSrc;

  return (
    <ActivitySlidePage
      {...rest}
      onNext={onNext}
      hideFooter={!showNextButton && !isReview}
      title={module.name}
      activityKind={activityKind}
      isReview={isReview}
      bearIcon={BearIcons.BABY_THUMBS_UP_WINKING}
    >
      <section style={{ flexShrink: 0 }}>
        <p>
          Tap on a{" "}
          <InlineImage>
            <InfoIcon />
          </InlineImage>{" "}
          in the room to see some habits that can improve how you sleep:
        </p>
      </section>

      <BearScene sceneImage={sceneImage}>
        <BearSceneImage src={sceneImage} />
        {!finalScene && (
          <InfoRow
            left={currentTip.scenePosition.left}
            bottom={currentTip.scenePosition.bottom}
            tooltipPosition={currentTip.tooltipPosition}
          >
            <InfoContainer onClick={displayTooltip}>
              <InfoIcon />
            </InfoContainer>
            <SceneTooltip
              visible={tooltipVisible}
              text={currentTip.message}
              onClose={handleNextTip}
              position={currentTip.tooltipPosition}
            />
          </InfoRow>
        )}
      </BearScene>
    </ActivitySlidePage>
  );
};

export default styled(SleepHabitsRoomSlide)`
  overflow: auto;
`;

const BearScene = styled("div")<{ sceneImage: string }>`
  flex-shrink: 0;
  width: 100%;
  position: relative;
  margin-top: 2rem;
`;

const BearSceneImage = styled("img")`
  width: 100%;
  height: auto;
`;

const InfoRow = styled("div")<{
  left: number;
  bottom: number;
  tooltipPosition?: string;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: ${(props) =>
    props.tooltipPosition === "left"
      ? "row-reverse"
      : props.tooltipPosition === "right"
      ? "row"
      : props.tooltipPosition === "top"
      ? "column-reverse"
      : props.tooltipPosition === "bottom"
      ? "column"
      : "row"};
  align-content: center;
  align-items: center;
  margin-left: ${(props) => `${props.left}%`};
  margin-bottom: ${(props) => `${props.bottom}%`};

  @media (min-width: 450px) {
    margin-bottom: ${(props) => `${props.bottom + 10}%`};
    margin-left: ${(props) => `${props.left + 3}%`};
  }
`;

const InfoContainer = styled("div")`
  padding: 1rem;
  width: 65px;
  height: 65px;
  @media (min-width: 450px) {
    width: 70px;
    height: 70px;
  }
`;

const InfoIcon = styled("img").attrs({ src: infoIconSrc, alt: "Info Icon" })`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const InlineImage = styled("div")`
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  margin-bottom: -10px;
  ${InfoIcon} {
    position: absolute;
    width: 100%;
    height: auto;
  }
`;
