export function someTrue(
  quizResponses: Record<string, boolean | null>,
  correctValues: string[]
) {
  const trueValues: string[] = Object.keys(quizResponses).filter(
    (key) => quizResponses[key] === true
  );

  if (trueValues.length !== correctValues.length) return false;

  for (let i = 0; trueValues.length > i; i++) {
    if (!correctValues.includes(trueValues[i])) {
      return false;
    }
  }

  return true;
}

export function allTrue(quizResponses: Record<string, boolean | null>) {
  for (let o in quizResponses) {
    if (quizResponses[o] === false) return false;
  }
  return true;
}
