import React, { FC } from "react";
import Column from "@emberex/components/lib/Column";
import styled from "styled-components/macro";

interface Props {
  text: string;
  visible: boolean;
  position?: string;
  textAlign?: "left" | "right" | "center";
  onClose(): void;
}

export interface ArrowDirection {
  [key: string]: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    border: string;
  };
}

const arrowDirections: ArrowDirection = {
  bottom: {
    bottom: 100,
    left: 46,
    border: "transparent transparent #fdd965 transparent",
  },
  bottomRight: {
    bottom: 100,
    left: 46,
    border: "transparent transparent #fdd965 transparent",
  },
  left: {
    top: 50,
    left: 100,
    border: "transparent transparent transparent #fdd965",
  },
  leftTop: {
    top: 65,
    left: 100,
    border: "transparent transparent transparent #fdd965",
  },
  top: {
    top: 100,
    left: 50,
    border: "#fdd965 transparent transparent transparent",
  },
  topRight: {
    top: 100,
    left: 5,
    border: "#fdd965 transparent transparent transparent",
  },
  topLeft: {
    top: 100,
    left: 85,
    border: "#fdd965 transparent transparent transparent",
  },
  right: {
    top: 40,
    right: 100,
    border: "transparent #fdd965 transparent transparent",
  },
};

const SceneTooltip: FC<Props> = (props) => {
  const {
    text,
    visible,
    onClose,
    position,
    textAlign = "center",
    ...rest
  } = props;

  const selectedArrowDirection = position
    ? arrowDirections[`${position}`]
    : arrowDirections.right;

  return (
    <Root {...rest} visible={visible}>
      <Close onClick={onClose}>CLOSE X</Close>
      <Content arrowDirection={selectedArrowDirection} textAlign={textAlign} onClick={onClose}>
        {text}
      </Content>
    </Root>
  );
};

export default styled(SceneTooltip)``;

const Root = styled(Column)<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  width: 75%;
  z-index: 10;
`;

const Content = styled("div")<{ arrowDirection: any; textAlign: string }>`
  border-radius: 10px;
  background-color: #fdd965;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  text-align: ${(props) => props.textAlign};
  padding: 11px 18px;
  z-index: 1;
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  cursor: pointer;
  ::after {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
    top: ${(props) => `${props.arrowDirection.top}%` || ""};
    right: ${(props) => `${props.arrowDirection.right}%` || ""};
    bottom: ${(props) => `${props.arrowDirection.bottom}%` || ""};
    left: ${(props) => `${props.arrowDirection.left}%` || ""};
    border-color: ${(props) => `${props.arrowDirection.border}` || ""};
  }
`;

const Close = styled("div")`
  z-index: 2;
  position: absolute;
  right: 0;
  background-color: #fdd965;
  border-radius: 6px 6px 0 0;
  width: 65px;
  height: 18px;
  text-align: center;
  color: #0e3750;
  font-size: 0.8rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: -15px;
`;
