import React, { FC, useMemo } from "react";
import styled from "styled-components/macro";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import Module from "shared/lib/interfaces/Module";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import {
  HelpfulUnhelpfulQuizAnswers,
  HelpfulUnhelpfulQuizValue,
} from "shared/lib/interfaces/ModuleActivity";
import useHelpfulUnhelpfulQuiz from "../../../hooks/useHelpfulUnhelpfulQuiz";
import { SlidePageProps, SlidePageHeader } from "../../SlidePage";
import ActivityBox from "../../ActivityBox";
import { SlidePageProgress } from "../../SlidePage";
import { DARKEST_BLUE } from "../../../../colors";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import HelpfulUnhelpfulQuiz from "../../HelpfulUnhelpfulQuiz";
import HelpfulUnhelpfulQuizQuestion from "../../HelpfulUnhelpfulQuizQuestion";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind =
  ModuleActivityKind.GIVING_DIRECTION_HELPFUL_OR_UNHELPFUL_QUIZ;

const defaultValue: HelpfulUnhelpfulQuizValue = {
  askPermissionToLeaveTable: null,
  wantToCleanYourRoom: null,
  lookAtMePlease: null,
  itsYourFaultTheHouseIsAMess: null,
  iWillTurnTheTvOff: null,
  putYourClothesInTheBasket: null,
  wowYouClearedYourPlate: null,
  brushYourTeethBeforeBed: null,
  putAwayYourToys: null,
  youveAlwaysBeenASlob: null,
  dontTalkWithFullMouth: null,
  dontGetInBedWithoutBrushing: null,
  youreBeingSoLoud: null,
  yourCleanUpSkillsNeedWork: null,
};

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const GivingDirectionHelpfulQuiz: FC<Props> = (props) => {
  const { module, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);
  const { onCheckboxChange } = useHelpfulUnhelpfulQuiz(value, setValue);

  const hideNext = useMemo(() => {
    const values = Object.values(value);
    return values.filter((v) => v !== null).length < 3;
  }, [value]);

  return (
    <ActivitySlidePage
      {...rest}
      light
      lightNextButton
      nextButtonText="Finish Activity"
      title={module.name}
      activityKind={activityKind}
      hideNextButton={hideNext}
    >
      <ActivityBox icon="pointer">
        <ActivityTitle>Your turn: Helpful or Unhelpful?</ActivityTitle>
        <Instructions>
          Decide if each statement is a helpful or an unhelpful way to give
          directions to your child.
        </Instructions>
        <HelpfulUnhelpfulQuiz title="Decide if these ways of giving direction are helpful or unhelpful.">
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["askPermissionToLeaveTable"]
            }
            value={value.askPermissionToLeaveTable}
            onChange={onCheckboxChange("askPermissionToLeaveTable")}
            questionText="Please ask permission to leave the table."
            feedback="This is a clear direction with a specific request."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["wantToCleanYourRoom"]
            }
            value={value.wantToCleanYourRoom}
            onChange={onCheckboxChange("wantToCleanYourRoom")}
            questionText="Do you want to clean your room?"
            feedback="Questions are not directions because they provide an option to say “no”."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={HelpfulUnhelpfulQuizAnswers["lookAtMePlease"]}
            value={value.lookAtMePlease}
            onChange={onCheckboxChange("lookAtMePlease")}
            questionText="Look at me, please."
            feedback="This is a clear direction with a specific request."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["itsYourFaultTheHouseIsAMess"]
            }
            value={value.itsYourFaultTheHouseIsAMess}
            onChange={onCheckboxChange("itsYourFaultTheHouseIsAMess")}
            questionText="It’s your fault the house is a mess."
            feedback="Blame statements tend to make children defensive, moodier and less likely to follow directions. "
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={HelpfulUnhelpfulQuizAnswers["iWillTurnTheTvOff"]}
            value={value.iWillTurnTheTvOff}
            onChange={onCheckboxChange("iWillTurnTheTvOff")}
            questionText="I will turn the TV off so I am sure you can hear me."
            feedback="This is a statement that conveys information to your child."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["putYourClothesInTheBasket"]
            }
            value={value.putYourClothesInTheBasket}
            onChange={onCheckboxChange("putYourClothesInTheBasket")}
            questionText="I can tell you put your clothes in the laundry basket, thank you!"
            feedback="This is a clear praise statement."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["wowYouClearedYourPlate"]
            }
            value={value.wowYouClearedYourPlate}
            onChange={onCheckboxChange("wowYouClearedYourPlate")}
            questionText="Wow, you cleared your plate without me asking, nicely done!"
            feedback="This praise statement gives your child information about the behavior you want them to continue."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["brushYourTeethBeforeBed"]
            }
            value={value.brushYourTeethBeforeBed}
            onChange={onCheckboxChange("brushYourTeethBeforeBed")}
            questionText="Brush your teeth before getting in bed."
            feedback="This is a clear direction with a specific request."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={HelpfulUnhelpfulQuizAnswers["putAwayYourToys"]}
            value={value.putAwayYourToys}
            onChange={onCheckboxChange("putAwayYourToys")}
            questionText="Put away your toys, wash your hands, then come to the table, please."
            feedback="Giving multiple directions at once is less effective than giving one direction at a time."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["youveAlwaysBeenASlob"]
            }
            value={value.youveAlwaysBeenASlob}
            onChange={onCheckboxChange("youveAlwaysBeenASlob")}
            questionText="You’ve always been a slob."
            feedback="Terms such as “always,” “never” and negative words reduce cooperation and self-esteem in children and adults."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["dontTalkWithFullMouth"]
            }
            value={value.dontTalkWithFullMouth}
            onChange={onCheckboxChange("dontTalkWithFullMouth")}
            questionText="Don’t talk with your mouth full."
            feedback="Directions that tell your children what to do, instead of what not to do, are more effective."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["dontGetInBedWithoutBrushing"]
            }
            value={value.dontGetInBedWithoutBrushing}
            onChange={onCheckboxChange("dontGetInBedWithoutBrushing")}
            questionText="Don’t get in bed without brushing your teeth."
            feedback="Directions that tell your children what to do, instead of what not to do, are more effective."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={HelpfulUnhelpfulQuizAnswers["youreBeingSoLoud"]}
            value={value.youreBeingSoLoud}
            onChange={onCheckboxChange("youreBeingSoLoud")}
            questionText="You’re being so loud, it’s annoying!"
            feedback="This is a statement, not a direction. It is unclear what behavior is being requested."
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              HelpfulUnhelpfulQuizAnswers["yourCleanUpSkillsNeedWork"]
            }
            value={value.yourCleanUpSkillsNeedWork}
            onChange={onCheckboxChange("yourCleanUpSkillsNeedWork")}
            questionText="I love you, but your clean up skills need some work."
            feedback="Do not pair praise statements with critical comments."
          />
        </HelpfulUnhelpfulQuiz>
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(GivingDirectionHelpfulQuiz)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const ActivityTitle = styled("h3")`
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Instructions = styled("div")`
  margin: 0 2.5rem 2.5rem 2.5rem;
  color: #0b4568;
  font-size: 0.9rem;
  line-height: 1.25rem;
`;
