import styled from "styled-components/macro";
import badgeSrc from "../../modules/images/badge.png";

export default styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 95px;
  background-image: url("${badgeSrc}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
