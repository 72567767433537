import React, { FC } from "react";
import styled from "styled-components/macro";
import { SurveyWithQuestionsAndResults } from "shared/lib/interfaces/Survey";
import SurveyTakeResponse from "shared/lib/interfaces/SurveyTakeResponse";
import SlidePage, { SlidePageProps } from "../../modules/components/SlidePage";
import bearImageSrc from "../images/lets_look_at_your_results_bear.png";

const SurveyResultsIntroPage: FC<
  Omit<SlidePageProps, "nextButtonText"> & {
    survey: Pick<
      SurveyWithQuestionsAndResults,
      "name" | "questions" | "results" | "groupHeadingOrder"
    >;
    responses: Pick<SurveyTakeResponse, "questionId" | "value">[];
  }
> = ({ survey, responses, ...rest }) => {
  return (
    <SlidePage {...rest}>
      <section>
        <Header>RESULTS</Header>
        <p>
          Thanks for answering those questions. Participating in this program
          shows how much you care. The results use green, yellow and red to show
          your family’s strengths and areas that would benefit from focused
          attention.
        </p>
      </section>
      <BearImage />
    </SlidePage>
  );
};

export default styled(SurveyResultsIntroPage)``;

const Header = styled("h4")`
  margin-bottom: 0.5rem;
`;

const BearImage = styled("img").attrs({
  src: bearImageSrc,
  alt: "Let's look at your results",
})`
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  padding-top: 1rem;
  background-color: #ccfcee;
`;
