import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide17: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>IGNORING</h3>
        <p>
          You may think ignoring is not a parenting skill. However, ignoring is
          one of the most effective parenting skills that you can use because it
          works quickly to decrease many problem behaviors.
          <br />
          <br />
          What is ignoring? Ignoring is an intentional parenting skill where you
          withhold attention to behaviors that you want your child to stop
          doing. Ignoring is always paired with providing positive attention for
          good behavior.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide17)``;
