import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import InfoIcon from "../../InfoIcon";
import bathRoomImage from "../../../images/rules_and_consequences_bath_room.png";
import SceneTooltip from "../../../../common/components/SceneTooltip";
import SegmentCompletionOverlay from "../../../../common/components/SegmentCompletionOverlay";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";

enum Tooltip {
  TOILET = "TOILET",
  MEDICINE_CABINETE = "MEDICINE_CABINETE",
  SHOWER = "SHOWER",
}

const activityKind = ModuleActivityKind.RULES_AND_CONSEQUENCES_ROOMS;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const RulesAndConsequencesSlide5: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const setActivityValue = useActivity(activityKind)[1];
  const [visibleTooltip, setVisibleTooltip] = useState<null | Tooltip>(null);
  const [viewedTooltips, setViewedTooltips] = useState<Tooltip[]>([]);
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const showTooltip = useCallback((toolTip: Tooltip) => {
    setVisibleTooltip(toolTip);
    setViewedTooltips((value) =>
      value.includes(toolTip) ? value : [...value, toolTip]
    );
  }, []);

  const closeTooltip = useCallback(() => setVisibleTooltip(null), []);

  const handleSlideNext = useCallback(() => {
    setActivityValue(null); // mark activity complete
    if (isReview) {
      onNext();
    } else {
      setShowSegmentOverlay(true);
    }
  }, [setActivityValue, onNext, isReview]);

  return (
    <ActivitySlidePage
      {...rest}
      activityKind={activityKind}
      title={module.name}
      onNext={handleSlideNext}
      showNextButton={viewedTooltips.length > 0 || isReview}
    >
      <section>
        <p>
          Tap on a <InfoIcon /> in the bathroom below to learn more about
          everyday safety concerns in your home.
        </p>
      </section>
      <BathRoom>
        <BathRoomImage />
        <ToiletInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.TOILET)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.TOILET}
            text="Keep the toilet seat down and latched."
            position="right"
            textAlign="left"
            onClose={closeTooltip}
          />
        </ToiletInfo>

        <MedicineCabinateInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.MEDICINE_CABINETE)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.MEDICINE_CABINETE}
            text="Keep medicine cabinets latched and dangerous items out of reach."
            position="bottomRight"
            textAlign="left"
            onClose={closeTooltip}
          />
        </MedicineCabinateInfo>

        <ShowerInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.SHOWER)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.SHOWER}
            text="Never leave children unattended in tub."
            position="topLeft"
            textAlign="left"
            onClose={closeTooltip}
          />
        </ShowerInfo>
      </BathRoom>

      <SegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        moduleName={module.name}
        currentSegment={2}
        segmentCount={rest.progress.length}
        blurbText="Awesome!"
      />
    </ActivitySlidePage>
  );
};

export default styled(RulesAndConsequencesSlide5)`
  p > ${InfoIcon} {
    transform: translate(0, 25%);
  }
`;

const BathRoom = styled("div")`
  flex-shrink: 0;
  margin-top: 5rem;
  position: relative;
`;

const BathRoomImage = styled("img").attrs({
  src: bathRoomImage,
  alt: "Living room",
})`
  width: 100%;
  height: auto;
`;

const SceneInfo = styled("div")`
  position: absolute;
  top: 0;
  left: 0;

  > ${InfoIcon} {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  > ${SceneTooltip} {
    position: absolute;
  }
`;

const ToiletInfo = styled(SceneInfo)`
  left: 12%;
  top: 50%;

  > ${SceneTooltip} {
    top: 0;
    left: 2.5rem;
    width: 256px;
    transform: translate(0, -40%);
  }
`;

const MedicineCabinateInfo = styled(SceneInfo)`
  left: 35%;
  top: 20%;

  > ${SceneTooltip} {
    top: 2.25rem;
    left: 0;
    width: 256px;
    transform: translate(-45%, 0);
  }
`;

const ShowerInfo = styled(SceneInfo)`
  left: 70%;
  top: 70%;

  > ${SceneTooltip} {
    top: -1rem;
    left: 0;
    width: 256px;
    transform: translate(-84%, -100%);
  }
`;
