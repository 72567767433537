import React, { FC, ReactNode, ComponentPropsWithoutRef } from "react";
import styled from "styled-components/macro";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import shoeImageSrc from "../images/shoe_dark.png";
import { DARKEST_BLUE } from "../../colors";

interface Props extends ComponentPropsWithoutRef<"textarea"> {
  number: number;
  label: ReactNode;
}

const ShoeTextArea: FC<Props> = (props) => {
  const { className, number, label, ...rest } = props;

  return (
    <Column className={className}>
      <Title>{label}</Title>
      <MainRow>
        <ShoeImageWrapper>
          <ShoeImage flipped={number % 2 === 0} alt={number.toString()} />
          <NumberText>{number}</NumberText>
        </ShoeImageWrapper>
        <TextArea {...rest} />
      </MainRow>
    </Column>
  );
};

export default styled(ShoeTextArea)`
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  padding: 1rem 1rem 1.25rem 0.75rem;
  color: #0e3750;
  flex-shrink: 0;

  & + & {
    margin-top: 0.75rem;
  }
`;

const Title = styled("h4")`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  padding-left: 2.25rem;
`;

const MainRow = styled(Row)``;

const TextArea = styled("textarea")`
  min-width: 0;
  border: 2px solid #ebebeb;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  min-height: 90px;
  max-width: 100%;
  overflow: auto;
  border-radius: 0.5rem;
  background-color: white;
  resize: none;
  font-family: inherit;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  flex: 1;
`;

const ShoeImage = styled("img").attrs({ src: shoeImageSrc })<{
  flipped: boolean;
}>`
  width: 1.5rem;
  height: 3.5rem;
`;

const ShoeImageWrapper = styled("div")`
  width: 1.5rem;
  height: 3.5rem;
  margin-right: 0.75rem;
  position: relative;
`;

const NumberText = styled("div")`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${DARKEST_BLUE};
  font-size: 1.25rem;
  font-weight: bold;
`;
