import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RelationshipBuildingOutroSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSectionComplete, setShowSectionComplete] = useState(false);

  const handleNext = useCallback(() => {
    if (showSectionComplete) {
      onNext();
    } else {
      setShowSectionComplete(true);
    }
  }, [onNext, showSectionComplete]);

  return (
    <SlidePage {...rest} title={module.name} onNext={handleNext}>
      <section>
        <h3>{section.name}</h3>
        <p>
          When you are sensitive and responsive to your child time after time,
          you let your child know they can count on you. You make your child
          feel loved, safe, and confident. From your actions they will learn to
          manage their own feelings and behaviors, and be prepared to explore,
          learn, and relate to other people.
        </p>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSectionComplete}
        onNext={handleNext}
        module={module}
        currentSection={section}
        blurbText="Amazing!"
      />
    </SlidePage>
  );
};

export default styled(RelationshipBuildingOutroSlide)``;
