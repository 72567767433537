import React, {
  FC,
  useContext,
  useCallback,
  useMemo,
  useState,
  createContext,
} from "react";
import ModuleActivity, {
  ModuleActivityValue,
} from "shared/lib/interfaces/ModuleActivity";
import replaceWhere from "shared/lib/utils/replaceWhere";
import ModuleActivityKind, {
  ModuleVideoActivityKind,
} from "shared/lib/enums/ModuleActivityKind";
import VideoId from "shared/lib/enums/VideoId";
import noop from "shared/lib/utils/noop";

export interface ModuleActivityContextValue {
  activities: Pick<ModuleActivity<any>, "kind" | "value">[];
  updateActivity<T extends ModuleActivityKind>(
    kind: T,
    value: ModuleActivityValue<T>
  ): unknown;
  updateVideoView(videoId: VideoId): unknown;
}

const ModuleActivityContext = createContext<ModuleActivityContextValue>({
  activities: [],
  updateActivity() {},
  updateVideoView() {},
});

export default ModuleActivityContext;

/**
 * Hook for easily getting and updating an activity value. Should only be used in
 * components below a `ModuleActivityContextValue.Provider`.
 *
 * The returned value will be type discriminated based on the passed activity kind.
 */
export function useActivity<T extends ModuleActivityKind>(
  kind: T
): [
  ModuleActivityValue<T> | undefined,
  (updatedValue: ModuleActivityValue<T>) => void,
  boolean
] {
  const { activities, updateActivity } = useContext(ModuleActivityContext);
  const activity = useMemo(
    () => activities.find((activity) => activity.kind === kind),
    [activities, kind]
  );

  const handleValueChange = useCallback(
    (updatedValue: ModuleActivityValue<T>) => {
      updateActivity(kind, updatedValue);
    },
    [kind, updateActivity]
  );

  return [activity && activity.value, handleValueChange, !!activity];
}

/**
 * Returns `true` if the user has no prior
 */
export function useActivityObject<K extends ModuleActivityKind>(
  kind: K
): Pick<ModuleActivity<K>, "kind" | "value"> | undefined {
  return useContext(ModuleActivityContext).activities.find(
    (activity) => activity.kind === kind
  );
}

/**
 * Hook for video activities.
 * @return [
 *  onPlayStart(): a function to call when the video starts playing
 *  onPlayEnd(toCompletion: boolean): a function to call when the video stops playing or is ended early
 *  videoCompleted: returns a boolean value on whether the video has been watched all the way through.
 * ]
 */
export function useVideoActivity<T extends ModuleVideoActivityKind>(
  kind: T,
  videoId: VideoId
): [
  // onPlayStart
  () => void,
  // onPlayEnd
  (toCompletion: boolean) => void,
  // videoCompleted
  boolean
] {
  const [playing, setPlaying] = useState(false);
  const [, handleValueChange] = useActivity(kind);
  const { updateVideoView } = useContext(ModuleActivityContext);
  const [videoCompleted, setVideoCompleted] = useState(false);

  const onPlayStart = useCallback(() => {
    // Don't trigger a view unless they are starting fresh or the onPlayEnd has been called.
    if (playing) {
      return;
    }
    setPlaying(true);
    updateVideoView(videoId);
  }, [updateVideoView, playing, videoId]);

  const onPlayEnd = useCallback(
    (toCompletion: boolean) => {
      handleValueChange(null as ModuleActivityValue<T>);
      setPlaying(!toCompletion);
      setVideoCompleted(toCompletion);
    },
    [handleValueChange]
  );

  return [onPlayStart, onPlayEnd, videoCompleted];
}

/**
 * Should only be used in storybook stories.
 */
export const StoryActivityProvider: FC<{
  activities?: ModuleActivityContextValue["activities"];
}> = ({ activities: initialActivities = [], children }) => {
  const [activities, setActivities] = useState(initialActivities);

  return (
    <ModuleActivityContext.Provider
      value={{
        activities,
        updateActivity(kind, value) {
          const existingActivity = activities.find(
            (activity) => activity.kind === kind
          );
          if (existingActivity) {
            setActivities(
              replaceWhere(
                activities,
                (other) => other.kind === kind,
                (other) => ({ ...other, value })
              )
            );
          } else {
            setActivities([...activities, { kind, value }]);
          }
        },
        updateVideoView: noop,
      }}
    >
      {children}
    </ModuleActivityContext.Provider>
  );
};
