import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "id" | "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const UnhealthyVsHealthyRelationshipsIntroSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const handleNext = useCallback(() => {
    setShowSegmentOverlay(true);
  }, []);

  return (
    <SlidePage {...rest} onNext={handleNext} title={module.name}>
      <section>
        <h3>{section.name}</h3>
      </section>
      <section>
        <h4>What do healthy relationships look like?</h4>
      </section>
      <section>
        <p>
          When you’re a parent, your relationships with other people may need to
          change. Your family members may also need to support you by making
          healthy changes.
        </p>
        <p>Healthy relationships include:</p>
      </section>
      <section>
        <RelationshipsList>
          <li>
            <h4>Communication</h4>
            It’s important that you can be honest with your friends and family,
            as well as be open to listening to what they have to say.
          </li>
          <li>
            <h4>Support</h4>
            Surround yourself with people that support your healthy decisions.
          </li>
          <li>
            <h4>Boundaries</h4>
            Good boundaries set limits and expectations for what is okay within
            your relationships.
          </li>
          <li>
            <h4>Trust</h4>
            Trust is a key ingredient to help you feel safe in any relationship.
          </li>
        </RelationshipsList>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Congratulations!"
      />
    </SlidePage>
  );
};

export default styled(UnhealthyVsHealthyRelationshipsIntroSlide)``;

const RelationshipsList = styled(YellowCircleList)`
  li > h4 {
    margin-bottom: 0.4rem;
  }
`;
