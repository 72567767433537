import styled from "styled-components/macro";

const FormErrorText = styled("div")`
  color: #d00;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 2rem;
`;

export default FormErrorText;
