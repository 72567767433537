import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ExampleSection, { ExampleSectionHeader } from "../../ExampleSection";
import ExpandableBlock from "../../ExpandableBlock";

const rewards = [
  {
    id: 1,
    header: "Encouragement and praise",
    content:
      'Say, “Thank you for using the potty, you’re getting to be such a big kid!”',
  },
  {
    id: 2,
    header: "Time and activities with you",
    content: "Play “Go fish” with Mom.",
  },
  { id: 3, header: "Entertainment", content: "Trip to the park." },
  { id: 4, header: "Privileges", content: "10 minutes of screen time." },
  {
    id: 5,
    header: "Things that cost money",
    content: "A small toy or stickers.",
  },
];

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const UsingIncentivesRewardDefinitionSlide: FC<Props> = (props) => {
  const { module, ...rest } = props;
  const [expandedRewardId, setExpandedRewardId] = useState<number | null>(rewards[0].id);

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <Heading>What is a reward?</Heading>
        <p>
          A reward is what you give to your child after they’ve completed a
          desired behavior.
        </p>
      </section>
      <section>
        <ExampleSection>Rewards come in many forms:</ExampleSection>
      </section>
      <section>
        {rewards.map((reward) => (
          <ExpandableBlock
            key={reward.id}
            header={reward.header}
            onClick={() => {
              setExpandedRewardId(
                reward.id === expandedRewardId ? null : reward.id
              );
            }}
            expanded={expandedRewardId === reward.id}
          >
            {reward.content}
          </ExpandableBlock>
        ))}
      </section>
      <section>
        <p>
          The most important thing to consider when making your list of rewards
          is that you can follow through.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(UsingIncentivesRewardDefinitionSlide)`
  ${ExampleSectionHeader} {
    margin-bottom: 0.5rem;
  }
`;

const Heading = styled("h4")`
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: bold;
`;
