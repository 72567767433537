import styled from "styled-components/macro";
import shoeImageSrc from "../images/shoe.png";
import darkShoeImageSrc from "../images/shoe_dark.png";
import { DARKEST_BLUE } from "../../colors";

const ShoeList = styled("ol")`
  min-height: 54px;
  list-style: none;
  padding-left: 38px;
  counter-reset: shoe-list-counter;
`;

export default ShoeList;

export const ShoeListItem = styled("li")<{ dark?: boolean }>`
  position: relative;
  min-height: 54px;
  color: white;
  font-size: 1rem;
  line-height: 1.25rem;
  counter-increment: shoe-list-counter;

  /* Number in the shoe */
  &::before {
    content: counter(shoe-list-counter);
    position: absolute;
    left: -38px;
    top: 7px;
    width: 20px;
    text-align: center;
    color: ${DARKEST_BLUE};
    font-size: 20px;
    font-weight: bold;
    z-index: 2;
  }

  /* Shoe image */
  &::after {
    content: '';
    position: absolute;
    left: -38px;
    top: 0;
    width: 20px;
    height: 54px;
    background-image: url('${getBackgroundImageSrc}');
    background-size: 100% 100%;
    z-index: 1;
  }

  /* Flip every other shoe */
  &:nth-child(2n)::after {
    transform: scale(-1, 1);
  }

  & + & {
    margin-top: 2rem;
  }
`;

function getBackgroundImageSrc(props: { dark?: boolean }) {
  return props.dark ? darkShoeImageSrc : shoeImageSrc;
}
