import React, { FC, useState, useMemo, useCallback, ReactNode } from "react";
import styled from "styled-components/macro";
import shuffle from "lodash.shuffle";
import ActivityBox from "./ActivityBox";
import Card from "../../common/components/Card";
import tipBagImageSrc from "../images/proactive_parenting_tip_bag.png";
import TextButton from "@emberex/components/lib/TextButton";

interface Tip {
  title: ReactNode;
  example: string;
}

interface Props {
  instructions?: string;
  tips: Tip[];
  notExample?: boolean;
  hideIcon?: boolean;
  hideInstructions?: boolean;
  onTipViewed?(): void;
}

const TipBagActivity: FC<Props> = (props) => {
  const {
    tips,
    notExample,
    instructions,
    hideIcon,
    hideInstructions,
    onTipViewed,
    ...rest
  } = props;

  const shuffledTips = useMemo(() => shuffle(tips), [tips]);
  const [tip, setTip] = useState<Tip>(shuffledTips[0]);

  const showNextTip = useCallback(() => {
    setTip(
      (value) =>
        // Show the next tip or loop back to the first tip
        shuffledTips[(shuffledTips.indexOf(value) + 1) % shuffledTips.length]
    );
    if(onTipViewed) {
      onTipViewed();
    }
  }, [shuffledTips, onTipViewed]);


  return (
    <ActivityBox {...rest} icon={hideIcon ? undefined : "pointer"}>
      {!hideInstructions && <Instructions>{instructions}</Instructions>}
      <TipCard faded={tip === null}>
        <TipTitle>{tip?.title}</TipTitle>
        <TipExample>
          {notExample ? (
            tip?.example
          ) : (
            <>
              (<i>example</i> {tip?.example})
            </>
          )}
        </TipExample>
      </TipCard>
      <BagButton onClick={showNextTip}>
        <BagImage />
      </BagButton>
    </ActivityBox>
  );
};

export default styled(TipBagActivity)``;

const Instructions = styled("h3")`
  text-align: center;
  margin: 0 0 0.75rem 0;
`;

const TipCard = styled(Card)<{ faded: boolean }>`
  text-align: center;
  margin: 0 1rem 0.5rem 1rem;
  padding-bottom: 3rem;
  transition: opacity 0.2s;
  opacity: ${(props) => (props.faded ? 0 : 1)};
`;

const TipTitle = styled("h4")`
  margin: 0 0 0.75rem 0;
  font-size: 0.9rem;
  color: #4dacbe;
`;

const TipExample = styled("p")`
  color: #0e3750;
`;

const BagButton = styled(TextButton)`
  flex-shrink: 0;
  margin: 0 1rem 0.5rem 1rem;
`;

const BagImage = styled("img").attrs({ src: tipBagImageSrc, alt: "Tip" })`
  max-width: 100%;
`;
