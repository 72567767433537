import { useRef, useEffect } from "react";

/**
 * Hook that runs a function when the current component unmounts.
 */
export default function useOnUnmount(fn: () => any) {
  // `fnRef` ensures the reference to `fn` is up-to-date without passing it as a dependency to `useEffect`
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => () => fnRef.current(), []);
}
