import React, { FC, MouseEventHandler, MouseEvent, useCallback } from "react";
import styled from "styled-components/macro";
import Overlay from "@emberex/components/lib/Overlay";
import TextButton from "@emberex/components/lib/TextButton";
import { DARKER_BLUE, DARKEST_BLUE, YELLOW, LIGHTER_BLUE } from "../../colors";
import Link from "../../common/components/Link";
import CircleXButton from "../../common/components/CircleXButton";
import { useParticipantContext } from "../contexts/ParticipantContext";
import logout from "../../auth/api/logout";
import { useHistory, useLocation } from "react-router-dom";
import ParticipantModuleList from "../../modules/components/ParticipantModuleList";

interface Props {
  onClose: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

interface LinkProps {
  to: string;
  title: string;
}

const ParticipantNavMenu: FC<Props> = ({ onClose, ...rest }) => {
  const history = useHistory();
  const { refreshUser } = useParticipantContext();
  const location = useLocation();

  const handleLogoutClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      await logout();
      await refreshUser();
      onClose(event);
      history.push("/");
    },
    [refreshUser, history, onClose]
  );

  const MenuLink: FC<LinkProps> = (props) => {
    const { to, title } = props;

    const handleClick = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        if (location.pathname === to) {
          onClose(event);
        }
      },
      [to]
    );

    return (
      <Link to={to} onClick={handleClick}>
        {title}
      </Link>
    );
  };

  return (
    <Overlay {...rest}>
      <Title>Menu</Title>
      <CloseButton onClick={onClose} />
      <Menu>
        <MenuItem>
          <MenuItemHeader>
            <div>Topics</div>
          </MenuItemHeader>
          <ModuleListContainer>
            <ParticipantModuleList
              showProgressBars={false}
              sectionListColor="white"
              showViewSurveyButtons={false}
              listItemHeaderColor="transparent"
            />
          </ModuleListContainer>
        </MenuItem>
        <MenuItem>
          <MenuItemHeader>
            <MenuLink to="/dashboard" title="Dashboard" />
          </MenuItemHeader>
        </MenuItem>
        <MenuItem>
          <MenuItemHeader>
            <MenuLink to="/plan" title="My Plan" />
          </MenuItemHeader>
        </MenuItem>
        <MenuItem>
          <MenuItemHeader>
            <MenuLink to="/library" title="Library" />
          </MenuItemHeader>
        </MenuItem>
        <MenuItem>
          <MenuItemHeader>
            <MenuLink to="/coach" title="My Coach" />
          </MenuItemHeader>
        </MenuItem>
        <MenuItem>
          <MenuItemHeader>
            <MenuLink to="/account" title="My Account" />
          </MenuItemHeader>
        </MenuItem>
        <MenuItem>
          <MenuItemHeader>
            <TextButton onClick={handleLogoutClick}>Log Out</TextButton>
          </MenuItemHeader>
        </MenuItem>
      </Menu>
    </Overlay>
  );
};

export default styled(ParticipantNavMenu)`
  background-color: ${DARKER_BLUE};
`;

const CloseButton = styled(CircleXButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  background-color: #0b4568;
  color: white;
  box-shadow: none;
`;

const Title = styled("h1")`
  width: 100%;
  color: ${YELLOW};
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  margin-top: 1.5rem;
`;

const Menu = styled("ul")`
  list-style: none;
  margin-top: 1.5rem;
`;

const MenuItem = styled("li")`
  & + & {
    margin-top: 2px;
  }
`;

const MenuItemHeader = styled("div")`
  background-color: ${LIGHTER_BLUE};
  color: ${DARKEST_BLUE};
  height: 4rem;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ModuleListContainer = styled("div")`
  padding: 1rem 0.75rem 1rem 0.75rem;
`;
