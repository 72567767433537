import React, { FC } from "react";
import styled from "styled-components/macro";
import circleStarImageSrc from "../images/circle_star.png";
import circleStarWhiteImageSrc from "../images/circle_star_white.png";

interface Props {
  dark?: boolean;
  light?: boolean;
}

const CircleStarImage: FC<Props> = (props) => {
  const { dark = true, light = !dark, ...rest } = props;

  return (
    <img
      {...rest}
      src={light ? circleStarWhiteImageSrc : circleStarImageSrc}
      alt=""
    />
  );
};

export default styled(CircleStarImage)`
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
`;
