import bearBedroomSrc from "../images/bear_bedroom.png";
import bearBedroomShadesSrc from "../images/bear_bedroom_shades.png";
import bearBedroomElectronicsSrc from "../images/bear_bedroom_electronics.png";
import bearBedroomMealsSrc from "../images/bear_bedroom_meals.png";
import bearBedroomDogSrc from "../images/bear_bedroom_dog.png";

export interface SleepTip {
  scenePosition: { left: number; bottom: number };
  message: string;
  sceneImage?: string;
  tooltipPosition?: string;
}

const sleepTips: SleepTip[] = [
  {
    scenePosition: { left: 2, bottom: 12 },
    message:
      "Get some exercise. Being physically active during the day can help you fall asleep more easily at night.",
    sceneImage: bearBedroomSrc,
  },
  {
    scenePosition: { left: 7, bottom: 29 },
    message:
      "Be consistent. Go to bed at the same time each night and get up at the same time each morning, including on the weekends.",
    sceneImage: bearBedroomSrc,
  },

  {
    scenePosition: { left: 8, bottom: 85 },
    message:
      "Make sure your bedroom is quiet, dark, relaxing, and at a comfortable temperature.",
    sceneImage: bearBedroomShadesSrc,
  },
  {
    scenePosition: { left: 20, bottom: 28 },
    message:
      "Remove electronic devices, such as TVs, computers, and smart phones, from the bedroom.",
    sceneImage: bearBedroomElectronicsSrc,
  },
  {
    scenePosition: { left: 10, bottom: 33 },
    message: "Avoid large meals, caffeine, and alcohol before bedtime.",
    sceneImage: bearBedroomMealsSrc,
    tooltipPosition: "left",
  },
  {
    scenePosition: { left: 0, bottom: 30 },
    message:
      "Sleeping next to a partner, kids or pets can keep you from getting the sleep you need because of increased noise and movement, excessive body heat or crowding. ",
    sceneImage: bearBedroomDogSrc,
    tooltipPosition: "top",
  },
];

export default sleepTips;
