import React, { FC, useMemo } from "react";
import styled from "styled-components/macro";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import Module from "shared/lib/interfaces/Module";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import {
  DoOrDontQuizAnswers,
  DoOrDontQuizValue,
} from "shared/lib/interfaces/ModuleActivity";
import { BearIcons } from "../../../../common/components/BearIcon";
import useHelpfulUnhelpfulQuiz from "../../../hooks/useHelpfulUnhelpfulQuiz";
import { SlidePageProps, SlidePageHeader } from "../../SlidePage";
import ActivityBox from "../../ActivityBox";
import { SlidePageProgress } from "../../SlidePage";
import { DARKEST_BLUE } from "../../../../colors";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import HelpfulUnhelpfulQuiz from "../../HelpfulUnhelpfulQuiz";
import HelpfulUnhelpfulQuizQuestion from "../../HelpfulUnhelpfulQuizQuestion";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.HOME_SAFETY_DO_OR_DONT_QUIZ;

const defaultValue: DoOrDontQuizValue = {
  storeSubstancesOutOfReachOfChild: null,
  smokeInSameRoomAsChild: null,
  smokeInRoomWhereChildSpendsTime: null,
  storeSubstancesWithChildSafeLocks: null,
  useSubstancesWhenOnlyParent: null,
  takeSmokeBreaksOutside: null,
  useSubstancesWhenOtherCaregiverExists: null,
  surroundYourselfWithSupportingPeople: null,
  maintainRelationshipsWithTemptingPeople: null,
};

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const helpfulUnhelpfulWords = { helpfulWord: "Do", unhelpfulWord: "Don't" };

const HomeSafetyQuizSlide: FC<Props> = (props) => {
  const { module, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);
  const { onCheckboxChange } = useHelpfulUnhelpfulQuiz(value, setValue);

  const hideNext = useMemo(() => {
    const values = Object.values(value);
    return values.filter((v) => v !== null).length < 3;
  }, [value]);

  return (
    <ActivitySlidePage
      {...rest}
      light
      darkHeader
      lightNextButton
      nextButtonText="Finish Activity"
      title={module.name}
      activityKind={activityKind}
      hideNextButton={hideNext}
      bearIcon={BearIcons.BABY_NEUTRAL}
    >
      <ActivityBox icon="pointer">
        <ActivityTitle>Your turn: Do or Don't?</ActivityTitle>
        <Instructions>
          Do you know the do’s and don’ts when it comes to substance use when
          you have children in your home.
        </Instructions>
        <HelpfulUnhelpfulQuiz
          icon="home"
          helpfulWord="Do"
          unhelpfulWord="Don't"
          title="Decide if each statement is a DO or a DON’T to keep children safe when substances are in your home."
        >
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              DoOrDontQuizAnswers["storeSubstancesOutOfReachOfChild"]
            }
            value={value.storeSubstancesOutOfReachOfChild}
            onChange={onCheckboxChange("storeSubstancesOutOfReachOfChild")}
            questionText="Store substances out-of-reach of children."
            feedback="This is a safety DO. Storing substances out-of-reach prevents children from accidental exposure to substances."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={DoOrDontQuizAnswers["smokeInSameRoomAsChild"]}
            value={value.smokeInSameRoomAsChild}
            onChange={onCheckboxChange("smokeInSameRoomAsChild")}
            questionText="Smoke in the same room as your child. "
            feedback="This is a safety DON’T. Secondhand smoke causes numerous health problems in infants and children; there is no risk-free level of exposure to secondhand smoke."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              DoOrDontQuizAnswers["smokeInRoomWhereChildSpendsTime"]
            }
            value={value.smokeInRoomWhereChildSpendsTime}
            onChange={onCheckboxChange("smokeInRoomWhereChildSpendsTime")}
            questionText="Smoke in the rooms that your child spends time in."
            feedback="This is a safety DON’T. Secondhand smoke causes numerous health problems in infants and children and can linger in rooms afterward; there is no risk-free level of exposure to secondhand smoke."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              DoOrDontQuizAnswers["storeSubstancesWithChildSafeLocks"]
            }
            value={value.storeSubstancesWithChildSafeLocks}
            onChange={onCheckboxChange("storeSubstancesWithChildSafeLocks")}
            questionText="Store substances in cabinets with child-safe locks."
            feedback="This is a safety DO. Using child-safe locks prevents children from accidental exposure to substances."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              DoOrDontQuizAnswers["useSubstancesWhenOnlyParent"]
            }
            value={value.useSubstancesWhenOnlyParent}
            onChange={onCheckboxChange("useSubstancesWhenOnlyParent")}
            questionText="Use substances when you are the only person available to care for your child."
            feedback="This is a safety DON’T. Only use substances if a second caregiver is available to care for children."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={DoOrDontQuizAnswers["takeSmokeBreaksOutside"]}
            value={value.takeSmokeBreaksOutside}
            onChange={onCheckboxChange("takeSmokeBreaksOutside")}
            questionText="Take smoke breaks outside (away from children)."
            feedback="This is a safety DO. Secondhand smoke causes numerous health problems in infants and children; there is no risk-free level of exposure to secondhand smoke."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              DoOrDontQuizAnswers["useSubstancesWhenOtherCaregiverExists"]
            }
            value={value.useSubstancesWhenOtherCaregiverExists}
            onChange={onCheckboxChange("useSubstancesWhenOtherCaregiverExists")}
            questionText="Only use substances if a second caregiver is available to care for children"
            feedback="This is a safety DO. Make sure another person is available to care for your child if you are using substances."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              DoOrDontQuizAnswers["surroundYourselfWithSupportingPeople"]
            }
            value={value.surroundYourselfWithSupportingPeople}
            onChange={onCheckboxChange("surroundYourselfWithSupportingPeople")}
            questionText="Surround yourself with people who support you not using substances while parenting."
            feedback="This is a safety DO. Having a support system that encourages you to avoid substance use when parenting is a healthy choice for both you and your child."
            {...helpfulUnhelpfulWords}
          />
          <HelpfulUnhelpfulQuizQuestion
            isHelpfulCorrect={
              DoOrDontQuizAnswers["maintainRelationshipsWithTemptingPeople"]
            }
            value={value.maintainRelationshipsWithTemptingPeople}
            onChange={onCheckboxChange(
              "maintainRelationshipsWithTemptingPeople"
            )}
            questionText="Maintain close relationships with people who tempt you to use substances while parenting."
            feedback="This is a safety DON’T. Having a support system that encourages you to avoid substance use when parenting is a healthy choice for both you and your child."
            {...helpfulUnhelpfulWords}
          />
        </HelpfulUnhelpfulQuiz>
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(HomeSafetyQuizSlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const ActivityTitle = styled("h3")`
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Instructions = styled("div")`
  margin: 0 2.5rem 2.5rem 2.5rem;
  color: #0b4568;
  font-size: 0.9rem;
  line-height: 1.25rem;
`;
