import React, { FC, FormEvent, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Row from "@emberex/components/lib/Row";
import BlockButton from "@emberex/components/lib/BlockButton";
import suggestionIconSrc from "../images/suggestion_icon.png";
import SelectOverlay from "./SelectOverlay";
import { DARKEST_BLUE } from "../../colors";

interface Props {
  value?: string;
  onChange?(value: string): any;
  onSuggestionsPicked?(values: string[]): any;
  placeholder?: string;
  suggestions?: string[];
  suggestionTitle?: string;
  maxSuggestionsPicked?: number;
}

const SuggestionTextArea: FC<Props> = (props) => {
  const {
    value,
    onChange,
    onSuggestionsPicked,
    placeholder,
    suggestions = [],
    suggestionTitle = "Suggestions",
    maxSuggestionsPicked = 3,
    ...rest
  } = props;
  const [showingSuggestions, setShowingSuggestions] = useState(false);

  const handleTextAreaChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event.currentTarget.value);
      }
    },
    [onChange]
  );

  const handleSuggestionButtonClick = useCallback(() => {
    setShowingSuggestions(true);
  }, []);

  const handlePickSuggestions = useCallback(
    (pickedSuggestions: string[]) => {
      if (onSuggestionsPicked && pickedSuggestions.length > 0) {
        onSuggestionsPicked(pickedSuggestions);
      }
      setShowingSuggestions(false);
    },
    [onSuggestionsPicked]
  );

  return (
    <Row {...rest}>
      <Textarea
        value={value}
        placeholder={placeholder}
        onChange={handleTextAreaChange}
      />
      <SuggestionButton onClick={handleSuggestionButtonClick}>
        <img src={suggestionIconSrc} alt="Suggestions" />
      </SuggestionButton>
      {showingSuggestions && (
        <SelectOverlay
          title={suggestionTitle}
          onSubmit={handlePickSuggestions}
          options={suggestions.map((suggestion) => ({
            value: suggestion,
            label: suggestion,
          }))}
          maxSelections={maxSuggestionsPicked}
        />
      )}
    </Row>
  );
};

export default styled(SuggestionTextArea)`
  & + & {
    margin-top: 1.25rem;
  }
`;

const Textarea = styled("textarea")`
  box-sizing: border-box;
  min-height: 60px;
  flex: 1 1 auto;
  min-width: none;
  border-top: 2px solid #ebebeb;
  border-left: 2px solid #ebebeb;
  border-bottom: 2px solid #ebebeb;
  border-right: none;
  resize: none;
  overflow: auto;
  border-radius: 8px 0 0 8px;
  background-color: #ffffff;
  margin: 0 0 0 0;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  font-family: inherit;
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  line-height: 1.1rem;
    
    ::placeholder {
      @media (max-width: 485px) {
        font-size: 0.7em;
      }
      
      @media (max-width: 400px) {
        line-height: 1em;
      }
    }

`;

const SuggestionButton = styled(BlockButton)`
  width: 59px;
  border-radius: 0 8px 8px 0;
  padding: 0 0 0 0;
  background-color: #0b4568;
  flex-shrink: 0;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
