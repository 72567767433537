import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ImageList, { ImageListItem } from "../../ImageList";
import stockImage1 from "../../../images/stock/stock_1.png";
import stockImage2 from "../../../images/stock/stock_2.png";
import stockImage3 from "../../../images/stock/stock_3.png";
import stockImage4 from "../../../images/stock/stock_4.png";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const HealthyRoutinesExamplesSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          When you have a routine, it’s easier to parent well. It helps you stay
          organized and on track, and it helps your child know what to expect.
        </p>
      </section>
      <section>
        <RoutineHeader>Routines also:</RoutineHeader>
        <ImageList>
          <ImageListItem imageSrc={stockImage1}>
            Reduce stress, depression &amp; anxiety
          </ImageListItem>
          <ImageListItem imageSrc={stockImage2}>
            Improve cognitive, social, and emotional development of children
          </ImageListItem>
          <ImageListItem imageSrc={stockImage3}>
            Improve quality time with children
          </ImageListItem>
          <ImageListItem imageSrc={stockImage4}>
            Reduce substance use
          </ImageListItem>
        </ImageList>
      </section>
    </SlidePage>
  );
};

export default styled(HealthyRoutinesExamplesSlide)`
  ${ImageList} {
    position: relative;
    left: -1rem;
  }
`;

const RoutineHeader = styled("h4")`
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
`;
