interface HasName {
  firstName: string;
  lastName: string;
}

/**
 * Takes a user object and returns their name in the format `Fname Lname`.
 */
export default function formatUserName({
  firstName,
  lastName
}: HasName): string {
  return (
    firstName[0].toUpperCase() +
    firstName.slice(1) +
    " " +
    lastName[0].toUpperCase() +
    lastName.slice(1)
  );
}
