import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const SleepPlanInstructionsSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const handleNext = useCallback(() => {
    setShowSegmentOverlay(true);
  }, []);

  return (
    <SlidePage {...rest} title={module.name} onNext={handleNext}>
      <section>
        <h3>{section.name}</h3>
        <p>
          Getting enough good sleep will set you and your child up for a
          successful day.
        </p>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Congratulations!"
      />
    </SlidePage>
  );
};

export default styled(SleepPlanInstructionsSlide)``;
