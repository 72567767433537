import React, { FC, ReactNode, useState, useCallback } from "react";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import styled from "styled-components/macro";
import { BearIcons } from "../../common/components/BearIcon";
import { useActivityObject } from "../contexts/ModuleActivityContext";
import ActivityCompletionOverlay from "./ActivityCompletionOverlay";
import Confetti from "./Confetti";
import QuizCompletionOverlay, {
  QuizCompleteOverlayIconType,
} from "./QuizCompletionOverlay";
import SlidePage, { SlidePageProps } from "./SlidePage";

export interface ActivitySlidePageProps extends SlidePageProps {
  activityKind: ModuleActivityKind;
  activityMessage?: ReactNode;
  completeOverlayIcon?: QuizCompleteOverlayIconType;
  showConfetti?: boolean;
  isReview?: boolean;
  bearIcon?: BearIcons;
}

const ActivitySlidePage: FC<ActivitySlidePageProps> = (props) => {
  const {
    activityKind,
    activityMessage,
    completeOverlayIcon,
    showConfetti,
    children,
    onNext,
    bearIcon = BearIcons.BABY_NEUTRAL,
    ...rest
  } = props;
  const activity = useActivityObject(activityKind);
  const [initialActivity] = useState(activity); // Cache the initial activity to check if it's the user's first interaction.
  const [showCompleteOverlay, setShowCompleteOverlay] = useState(false);
  const enableCompleteOverlay = !!(!initialActivity && activity); // Only true when the user interacts with the activity for the first time.

  const handleSlideNext = useCallback(() => {
    if (enableCompleteOverlay) {
      setShowCompleteOverlay(true);
      return;
    }
    onNext();
  }, [onNext, enableCompleteOverlay]);

  return (
    <SlidePage {...rest} onNext={handleSlideNext}>
      {children}
      {enableCompleteOverlay && (
        <>
          {showConfetti && <Confetti />}
          {activityMessage ? (
            <QuizCompletionOverlay
              open={showCompleteOverlay}
              onNext={onNext}
              activityKind={activityKind}
              activityMessage={activityMessage}
              icon={completeOverlayIcon}
            />
          ) : (
            <ActivityCompletionOverlay
              onNext={onNext}
              open={showCompleteOverlay}
              activityKind={activityKind}
              bearIcon={bearIcon}
            />
          )}
        </>
      )}
    </SlidePage>
  );
};

export default styled(ActivitySlidePage)``;
