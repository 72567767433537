import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import TextButton from "@emberex/components/lib/TextButton";
import Module from "shared/lib/interfaces/Module";
import first from "shared/lib/utils/first";
import PlanActivity from "shared/lib/interfaces/PlanActivity";
import ModuleIcon from "../../common/components/ModuleIcon";
import PlusMinusIcon from "../../common/components/PlusMinusIcon";
import ParticipantPlanActivity from "./ParticipantPlanActivity";

interface Props {
  module: Pick<Module, "id" | "name" | "icon">;
  planActivities: Pick<
    PlanActivity,
    "id" | "name" | "moduleId" | "activityKind"
  >[];
  expanded: boolean;
  onToggleExpanded(): unknown;
}

const ParticipantPlanList: FC<Props> = (props) => {
  const {
    module: mod,
    planActivities,
    expanded,
    onToggleExpanded,
    ...rest
  } = props;
  const [expandedActivityId, setExpandedActivityId] = useState<number | null>(
    first(planActivities)?.id ?? null
  );

  return (
    <li {...rest}>
      <ModuleListItemHeader onClick={onToggleExpanded}>
        <ModuleIcon icon={mod.icon} />
        <ModuleNameText>{mod.name}</ModuleNameText>
        <PlusMinusIcon expanded={expanded} />
      </ModuleListItemHeader>
      {expanded && (
        <ModuleExpandContent>
          <ActivityList>
            {planActivities.map((planActivity) => {
              const activityExpanded = expandedActivityId === planActivity.id;

              return (
                <ActivityListItem key={planActivity.id}>
                  <ActivityListItemHeader
                    onClick={() =>
                      setExpandedActivityId(
                        activityExpanded ? null : planActivity.id
                      )
                    }
                  >
                    <ActivityPlusMinusIcon expanded={activityExpanded} />
                    <ActivityNameText>{planActivity.name}</ActivityNameText>
                  </ActivityListItemHeader>
                  {activityExpanded && (
                    <ParticipantPlanActivity planActivity={planActivity} />
                  )}
                </ActivityListItem>
              );
            })}
          </ActivityList>
        </ModuleExpandContent>
      )}
    </li>
  );
};

export default styled(ParticipantPlanList)`
  & + & {
    margin-top: 1rem;
  }
`;

const ModuleListItemHeader = styled(TextButton)`
  flex-shrink: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 2rem 0 2rem;

  ${ModuleIcon} {
    width: 2rem;
    height: 2rem;
  }
`;

const ModuleNameText = styled("div")`
  flex: 1;
  margin-left: 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
`;

const ModuleExpandContent = styled("div")`
  padding-top: 1rem;
`;

const ActivityList = styled("ul")``;

const ActivityListItem = styled("li")`
  & + & {
    margin-top: 0.75rem;
  }
`;

const ActivityListItemHeader = styled(TextButton)`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 4.75rem 0 4.75rem;
`;

const ActivityPlusMinusIcon = styled(PlusMinusIcon)`
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: #ccfcee;
  color: #0e3750;
  font-size: 1.5rem;
  line-height: 1.25rem;
  text-align: center;
  font-weight: bold;
`;

const ActivityNameText = styled("div")`
  font-size: 0.9rem;
  padding-top: 0.1rem;
  margin-left: 0.75rem;
  font-weight: 500;
`;
