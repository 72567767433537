import React, { FC } from "react";
import styled from "styled-components/macro";
import RawHTML from "../../common/components/RawHTML";

interface Props {
  text?: string;
}

const SurveyPreQuestionText: FC<Props> = (props) => {
  const { text, ...rest } = props;

  return (
    <p {...rest}>
      <RawHTML html={text} />
    </p>
  );
};
export default styled(SurveyPreQuestionText)`
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  padding: 0 2rem 0 2rem;
`;
