import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const UnhealthyVsHealthyRelationshipsOutroSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const handleNext = useCallback(() => {
    setShowSegmentOverlay(true);
  }, []);

  return (
    <SlidePage {...rest} onNext={handleNext} title={module.name}>
      <section>
        <h3>{section.name}</h3>
      </section>
      <section>
        <p>
          Using these positive communication strategies can help you express
          yourself more clearly, and improve your important relationships.
        </p>
      </section>

      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Congratulations!"
      />
    </SlidePage>
  );
};

export default styled(UnhealthyVsHealthyRelationshipsOutroSlide)``;
