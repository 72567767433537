import React, { FC, useMemo } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import {
  ProactiveParentingProactiveOrNotQuizAnswers,
  ProactiveParentingProactiveOrNotQuizValue,
} from "shared/lib/interfaces/ModuleActivity";
import useHelpfulUnhelpfulQuiz from "../../../hooks/useHelpfulUnhelpfulQuiz";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import HelpfulUnhelpfulQuiz from "../../HelpfulUnhelpfulQuiz";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import HelpfulUnhelpfulQuizQuestion from "../../HelpfulUnhelpfulQuizQuestion";
import ActivityBox from "../../ActivityBox";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind =
  ModuleActivityKind.PROACTIVE_PARENTING_PROACTIVE_OR_NOT_QUIZ;

const defaultValue: ProactiveParentingProactiveOrNotQuizValue = {
  youCanPickYourShirt: null,
  goingToStoreIn5: null,
  stopHittingYourSister: null,
  iLikeHowYouCleanedTheLegos: null,
};

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ProactiveParentingProactiveOrNotQuizSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);
  const valueWithDefaults = value || defaultValue;
  const helpfulWord = "Proactive";
  const unhelpfulWord = "Not proactive";
  const { onCheckboxChange } = useHelpfulUnhelpfulQuiz(value, setValue);

  const hideNext = useMemo(() => {
    if (!value) {
      return true;
    }
    const values = Object.values(value);
    return values.filter((v) => v !== null).length < 3;
  }, [value]);

  return (
    <ActivitySlidePage
      {...rest}
      title={module.name}
      nextButtonText="Finish Activity"
      lightNextButton
      light
      activityKind={activityKind}
      hideNextButton={hideNext}
    >
      <ActivityBox icon="pointer">
        <Title>Your turn: Proactive or Not Proactive?</Title>
        <HelpfulUnhelpfulQuiz
          title="Decide if these are examples of proactive parenting or not."
          helpfulWord={helpfulWord}
          unhelpfulWord={unhelpfulWord}
        >
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.youCanPickYourShirt}
            isHelpfulCorrect={
              ProactiveParentingProactiveOrNotQuizAnswers["youCanPickYourShirt"]
            }
            questionText="You can pick your pink or purple shirt to wear today."
            feedback="Proactive Parenting: Giving your child choices"
            onChange={onCheckboxChange("youCanPickYourShirt")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.goingToStoreIn5}
            isHelpfulCorrect={
              ProactiveParentingProactiveOrNotQuizAnswers["goingToStoreIn5"]
            }
            questionText="In 5 minutes, we are going to leave and go to the store."
            feedback="Proactive Parenting: Giving your child a time limit"
            onChange={onCheckboxChange("goingToStoreIn5")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.stopHittingYourSister}
            isHelpfulCorrect={
              ProactiveParentingProactiveOrNotQuizAnswers[
                "stopHittingYourSister"
              ]
            }
            questionText="Stop hitting your sister!"
            feedback="This is not an example of proactive parenting."
            onChange={onCheckboxChange("stopHittingYourSister")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.iLikeHowYouCleanedTheLegos}
            isHelpfulCorrect={
              ProactiveParentingProactiveOrNotQuizAnswers[
                "iLikeHowYouCleanedTheLegos"
              ]
            }
            questionText="I like how you cleaned up the legos, let’s see how fast you can pick up the crayons!"
            feedback="Proactive Parenting: Giving your child a time limit"
            onChange={onCheckboxChange("iLikeHowYouCleanedTheLegos")}
          />
        </HelpfulUnhelpfulQuiz>
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(ProactiveParentingProactiveOrNotQuizSlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const Title = styled("h3")`
  text-align: center;
  margin: 0 0 2.5rem 0;
`;
