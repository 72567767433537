import React, { FC, ReactNode, useState, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import Overlay from "@emberex/components/lib/Overlay";
import Form from "@emberex/components/lib/Form";
import BlockButton from "@emberex/components/lib/BlockButton";
import toggleInArray from "@emberex/array-utils/lib/toggleInArray";
import { DARKEST_BLUE, DARKER_GRAY } from "../../colors";
import PillButton from "../../surveys/components/PillButton";
import Column from "@emberex/components/lib/Column";

interface Option {
  value: string;
  label: string;
}

interface Props {
  title?: ReactNode;
  value?: string[];
  options: Array<Option>;
  onSubmit(value: string[]): void;
  maxSelections?: number;
}

const SelectOverlay: FC<Props> = (props) => {
  const { title, value: initialValue, options, onSubmit, maxSelections, ...rest } = props;
  const [value, setValue] = useState(initialValue || []);

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  const handleSelect = useCallback((option: Option) => {
    setValue(prev => {
      const updates = toggleInArray(prev, option.value);
      return maxSelections && updates.length > maxSelections ? prev : updates;
    });
  }, [maxSelections]);

  const selected = (option: Option): boolean => value.includes(option.value);

  return (
    <Overlay {...rest}>
      <Form onSubmit={handleSubmit}>
        {!!title && <Title>{title}</Title>}
        <ButtonList>
          {options.map((option) => (
            <OptionButton
              key={option.value}
              selected={selected(option)}
              onClick={(event) => {
                event.preventDefault();
                handleSelect(option)
              }}
              disabled={maxSelections ? !selected(option) && value.length >= maxSelections : false}
            >
              {option.label}
            </OptionButton>
          ))}
        </ButtonList>
        <DoneButton>Done</DoneButton>
      </Form>
    </Overlay>
  );
};

export default styled(SelectOverlay)`
  background-color: white;
  padding: 1.25rem 2rem 0 2rem;

  /* Bottom space that won't be flex shrunk */
  &:after {
    content: "";
    height: 2rem;
    width: 100%;
    flex-shrink: 0;
  }

  > ${Form} {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const Title = styled("h1")`
  color: #4dacbe;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  width: 300px;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 1.25rem;
`;

const ButtonList = styled(Column)`
  flex: 1;
`;

const OptionButton = styled(BlockButton)<{ selected: boolean; disabled?: boolean }>`
  padding: 0.5rem 0.6rem 0.5rem 0.6rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  background-color: white;
  text-align: left;
  font-weight: normal;
  justify-content: flex-start;
  transition: background-color 0.2s, color 0.2s;
  background-color: ${(props) => (props.selected ? DARKEST_BLUE : "white")};
  color: ${(props) => (props.selected ? "white" : DARKER_GRAY)};
  flex-shrink: 0;
  font-weight: 500;
  
  ${props => props.disabled && !props.selected && css`color: rgba(51,51,51, 0.6);`}

  & + & {
    margin-top: 0.4rem;
  }
`;

const DoneButton = styled(PillButton)`
  align-self: center;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 1.25rem;
`;
