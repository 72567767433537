import React, { FC, ReactNode } from "react";
import styled, { keyframes } from "styled-components/macro";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import PillButton from "../../surveys/components/PillButton";

interface Props {
  onButtonClick(): unknown;
  buttonLabel?: ReactNode;
  hideButton?: boolean;
}

const BottomToast: FC<Props> = (props) => {
  const { buttonLabel = "Continue", children, onButtonClick, hideButton, ...rest } = props;

  return (
    <Column {...rest}>
      {children}
      { !hideButton &&
        <ButtonContainer>
          <PillButton onClick={onButtonClick}>{buttonLabel}</PillButton>
        </ButtonContainer>
      }
    </Column>
  );
};

export default styled(BottomToast)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #ccfcee;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;

  animation-name: ${keyframes`
    from {
      transform: translate(0, 100%);
    } to {
      transform: translate(0, 0);
    }
  `};
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
`;

const ButtonContainer = styled(Row)`
  flex-shrink: 0;
  justify-content: center;
  padding: 1.25rem 1rem 2rem 1rem;
  background-color: #11355d;
`;
