import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ExampleSection from "../../ExampleSection";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide15: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>When…then commands</h3>
        <p>
          Try to use rules that build in natural consequences to good behavior.
        </p>
      </section>
      <section>
        <ExampleSection>
          When you finish your dinner, then you can have a cookie. Here the
          “When…then” command helps you link the desired behavior with a natural
          (positive) consequence.
        </ExampleSection>
      </section>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide15)``;
