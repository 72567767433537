import React, { FC, useMemo } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import {
  RulesAndConsequencesLogicalOrNotQuizAnswers,
  RulesAndConsequencesLogicalOrNotQuizValue,
} from "shared/lib/interfaces/ModuleActivity";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import useHelpfulUnhelpfulQuiz from "../../../hooks/useHelpfulUnhelpfulQuiz";
import ActivityBox from "../../ActivityBox";
import ActivitySlidePage from "../../ActivitySlidePage";
import HelpfulUnhelpfulQuiz from "../../HelpfulUnhelpfulQuiz";
import HelpfulUnhelpfulQuizQuestion from "../../HelpfulUnhelpfulQuizQuestion";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import logicalImageSrc from "../../../images/logical.png";
import notLogicalImageSrc from "../../../images/not_logical.png";

const activityKind =
  ModuleActivityKind.RULES_AND_CONSEQUENCES_LOGICAL_OR_NOT_QUIZ;

const defaultValue: RulesAndConsequencesLogicalOrNotQuizValue = {
  cantStayInBackYard: null,
  cantPlayWithToys: null,
  throwBallInHouse: null,
  dontEatPeas: null,
};

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide14: FC<Props> = (props) => {
  const { module, section, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);
  const valueWithDefaults = value || defaultValue;
  const helpfulWord = "Logical";
  const unhelpfulWord = "Not Logical";
  const { onCheckboxChange } = useHelpfulUnhelpfulQuiz(value, setValue);

  const hideNext = useMemo(() => {
    if (!value) {
      return false;
    }
    const values = Object.values(value);
    return values.filter((v) => v !== null).length < 3;
  }, [value]);

  return (
    <ActivitySlidePage
      {...rest}
      title={module.name}
      nextButtonText="Finish Activity"
      lightNextButton
      light
      activityKind={activityKind}
      hideNextButton={hideNext}
    >
      <ActivityBox icon="pointer">
        <Title>Your turn: Logical or Not Logical?</Title>
        <HelpfulUnhelpfulQuiz
          title="Which of these consequences do you think are logical?"
          helpfulWord={helpfulWord}
          unhelpfulWord={unhelpfulWord}
          helpfulIcon={logicalImageSrc}
          unhelpfulIcon={notLogicalImageSrc}
        >
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.cantStayInBackYard}
            isHelpfulCorrect={
              RulesAndConsequencesLogicalOrNotQuizAnswers["cantStayInBackYard"]
            }
            questionText="If you can’t stay in the backyard, you need to play in the house."
            feedback="This is a logical consequence."
            onChange={onCheckboxChange("cantStayInBackYard")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.cantPlayWithToys}
            isHelpfulCorrect={
              RulesAndConsequencesLogicalOrNotQuizAnswers["cantPlayWithToys"]
            }
            questionText="You can’t play with your toys for 3 days because you hit your sister."
            feedback="This is too large and is not logical. Instead say, “You don’t get to keep playing with your blocks because you hit your sister.”"
            onChange={onCheckboxChange("cantPlayWithToys")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.throwBallInHouse}
            isHelpfulCorrect={
              RulesAndConsequencesLogicalOrNotQuizAnswers["throwBallInHouse"]
            }
            questionText="If you throw the ball in the house, I will need to take it away."
            feedback="This is a logical consequence."
            onChange={onCheckboxChange("throwBallInHouse")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.dontEatPeas}
            isHelpfulCorrect={
              RulesAndConsequencesLogicalOrNotQuizAnswers["dontEatPeas"]
            }
            questionText="If you don’t eat your peas, you need to go to timeout for 10 minutes."
            feedback="Instead say, “If you don’t eat your peas, you can’t have dessert with the rest of us.”"
            onChange={onCheckboxChange("dontEatPeas")}
          />
        </HelpfulUnhelpfulQuiz>
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(RulesAndConsequencesSlide14)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const Title = styled("h3")`
  text-align: center;
  margin: 0 0 2.5rem 0;
`;
