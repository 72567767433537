import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import TextButton from "@emberex/components/lib/TextButton";
import useToggleState from "@emberex/react-utils/lib/useToggleState";
import ParticipantNavMenu from "./ParticipantNavMenu";

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ParticipantNav: FC<Props> = (props) => {
  const [open, toggleOpen] = useToggleState(false);

  return (
    <>
      <TextButton {...props} onClick={toggleOpen}>
        <div />
        <div />
        <div />
      </TextButton>
      {open && <ParticipantNavMenu onClose={toggleOpen} />}
    </>
  );
};

export default styled(ParticipantNav)`
  position: absolute;
  right: 0.75rem;
  top: 1.25rem;
  height: 20px;
  width: 27px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 10;
  background-color: transparent;

  > div {
    height: 4px;
    border-radius: 2px;
    background-color: #4dacbe;
  }

  > div:nth-child(1) {
    width: 27px;
  }

  > div:nth-child(2) {
    width: 23px;
  }

  > div:nth-child(3) {
    width: 19px;
  }
`;
