import React, { FC, useState, useCallback } from "react";
import { formatFirstLast } from "shared/lib/utils/formatName";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import ParticipantOverallContext from "shared/lib/interfaces/ParticipantOverallContext";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import ParticipantNav from "../components/ParticipantNav";
import { DARK_BLUE, DARKEST_BLUE } from "../../colors";
import ParticipantInfoCircle from "../components/ParticipantInfoCircle";
import { FcuLogoCompact } from "../../common/components/FcuLogo";
import { ChevronRightIcon } from "../../common/components/Icons";
import getParticipantOverallContext from "../api/getParticipantOverallContext";
import { useParticipantContext } from "../contexts/ParticipantContext";
import ParticipantAccountForm, {
  Value as ParticipantAccountFormValue,
} from "../components/ParticipantAccountForm";
import updateParticipant from "../api/updateParticipant";
import Link from "../../common/components/Link";

interface State {
  overallContext: ParticipantOverallContext;
}

const ParticipantAccountPage: FC = (props) => {
  const { user, refreshUser } = useParticipantContext();
  const [state, setState] = useState<State | null>(null);

  useAsyncEffect(
    async (isCancelled) => {
      const overallContext = await getParticipantOverallContext(user.id);
      if (!isCancelled()) {
        setState({ overallContext });
      }
    },
    [user.id]
  );

  const handleSubmit = useCallback(
    async (value: ParticipantAccountFormValue) => {
      await updateParticipant(user.id, {
        password: value.password,
        profilePicture: value.profilePicture,
      });
      await refreshUser();
    },
    [user, refreshUser]
  );

  useDocumentTitle(`${user ? formatFirstLast(user) : ""} Account Details`);

  if (!state) {
    // Loading
    return (
      <Page {...props}>
        <ParticipantNav />
        <Header>
          <FcuLogoCompact />
        </Header>
      </Page>
    );
  }

  return (
    <Page {...props}>
      <ParticipantNav />
      <Header>
        <FcuLogoCompact />
        <ParticipantInfoCircle
          participant={user}
          points={state.overallContext.points}
        />
      </Header>
      <Content>
        <PlanLinkRow>
          <MyPlanLink to="/plan">
            My Plan <ChevronRightIcon />
          </MyPlanLink>
        </PlanLinkRow>
        <ParticipantAccountForm participant={user} onSubmit={handleSubmit} />
      </Content>
    </Page>
  );
};

export default styled(ParticipantAccountPage)`
  background-color: white;
`;

const Header = styled(Row)`
  height: 60px;
  width: 100%;
  background-color: ${DARK_BLUE};
  justify-content: center;
  margin-bottom: 50px;

  > ${FcuLogoCompact} {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
`;

const Content = styled(Column)`
  padding: 0 0.6rem 0 0.6rem;
  flex: 1;
`;

const PlanLinkRow = styled(Row)`
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const MyPlanLink = styled(Link)`
  width: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  font-weight: bold;

  > svg {
    color: #ff595b;
  }
`;
