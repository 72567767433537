import {
  ParticipantModuleContextWithSurvey,
  ParticipantModuleContextWithoutSurvey,
} from "shared/lib/interfaces/ParticipantModuleContext";
import apiAxios from "../../apiAxios";

interface Props {
  participantId: number;
  moduleNumber: number;
}

export default async function getParticipantModuleContext(
  props: Props
): Promise<
  ParticipantModuleContextWithSurvey | ParticipantModuleContextWithoutSurvey
> {
  const { participantId, moduleNumber } = props;
  const response = await apiAxios.get(
    `/participants/${participantId}/modules/${moduleNumber}/context`
  );
  return response.data;
}
