import styled from "styled-components/macro";
import starCheckImageSrc from "../images/star_check.png";

interface Props {
  faded?: boolean;
}

const StarCheckImage = styled("img").attrs({ src: starCheckImageSrc })<Props>`
  width: 20px;
  height: 20px;
  opacity: ${(props) => (props.faded ? 0.2 : 1)};
`;

export default StarCheckImage;
