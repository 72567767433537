import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import { LIGHT_RED } from "../../colors";
import PlusMinusIcon from "./PlusMinusIcon";


export interface ExpandableRowProps {
  header?: ReactNode;
  expanded: boolean;
  onClick(): void;
  icon?: ReactNode;
  disabled?: boolean;
}

const ExpandableRow: FC<ExpandableRowProps> = (props) => {
  const { header, onClick, expanded, children, icon, disabled, ...rest } = props;

  return (
    <Column {...rest}>
      <ExpandableRowHeader onClick={() => {
        if(!disabled) {
          onClick()
        }
      }} expanded={expanded}>
        {header}
        {icon ? icon : <ExpandableRowIcon expanded={expanded} />}
      </ExpandableRowHeader>
      {expanded && <ExpandableRowContent>{children}</ExpandableRowContent>}
    </Column>
  );
};

export default styled(ExpandableRow)``;

export const ExpandableRowIcon = styled(PlusMinusIcon)`
  position: absolute;
  right: 0;
  margin-right: 0;
  font-size: 1rem;
  color: ${LIGHT_RED};
  transform-origin: center center;
`;

export const ExpandableRowHeader = styled(Row)<{ expanded: boolean }>`
  align-items: center;
  cursor: pointer;
`;

export const ExpandableRowContent = styled(Column)``;
