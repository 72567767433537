import React, { FC } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import sendPasswordReset from "../api/sendPasswordReset";
import waveBackgroundCss from "../../common/utils/waveBackgroundCss";

interface Props {
  refreshUser(): any;
}

const ForgotPasswordPage: FC<Props> = ({ refreshUser, ...rest }) => {
  useDocumentTitle('Forgot Password')
  return (
    <Page {...rest}>
      <ForgotPasswordForm onSubmit={sendPasswordReset} />
    </Page>
  );
};

export default styled(ForgotPasswordPage)`
  ${waveBackgroundCss}
  align-items: center;
  justify-content: center;
`;
