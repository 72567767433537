import React, { FC, useEffect, useMemo, useState } from "react";
import { format as formatDate } from "date-fns";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import Page from "@emberex/components/lib/Page";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import Staff from "shared/lib/interfaces/Staff";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import ParticipantNav from "../components/ParticipantNav";
import { FcuLogoCompact } from "../../common/components/FcuLogo";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import { useParticipantContext } from "../contexts/ParticipantContext";
import Link from "../../common/components/Link";
import getParticipantCoach from "../api/getParticipantCoach";
import Card from "../../common/components/Card";
import formatUserName from "../../common/utils/formatUserName";
import CoachBadge from "../../common/components/CoachBadge";
import { CalendarIcon, PhoneIcon } from "../../common/components/Icons";
import { formatPhoneNumberPretty } from "shared/lib/utils/formatPhoneNumber";

const ParticipantCoachPage: FC = (props) => {
  const { user } = useParticipantContext();
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [coach, setCoach] = useState<Staff | null>(null);
  const [nextAppointmentDate, setNextAppointmentDate] = useState<string | null>(
    null
  );
  const formattedAppointmentDate = useMemo(() => {
    if (!nextAppointmentDate) {
      return null;
    }
    const dateObject = new Date(nextAppointmentDate);
    const formattedDate = formatDate(dateObject, "LLLL do, uuuu");
    const formattedTime = formatDate(dateObject, "h:mm a").toLowerCase(); // date-fns no longer supports am/pm with lowercase and no periods https://github.com/date-fns/date-fns/issues/946
    return (
      <>
        {formattedDate}
        <br />
        {formattedTime}
      </>
    );
  }, [nextAppointmentDate]);
  useDocumentTitle("Coach");

  /**
   * Load the participant's coach
   */
  useAsyncEffect(
    async (isCancelled) => {
      const response = await getParticipantCoach(user.id);
      if (!isCancelled()) {
        setCoach(response.coach);
        setNextAppointmentDate(response.nextAppointmentDate);
        setLoaded(true);
      }
    },
    [user.id]
  );

  /**
   * Redirect the participant to the dashboard if they have no coach.
   */
  useEffect(() => {
    if (loaded && !coach) {
      history.replace("/");
    }
  }, [history, loaded, coach]);

  return (
    <Page {...props}>
      <ParticipantNav />
      <Header>
        <Link to="/">
          <FcuLogoCompact />
        </Link>
      </Header>
      <Title>My Coach</Title>
      <ContentBox>
        {!!coach && (
          <CoachCard>
            <CoachNameRow>
              <CoachBadge />
              <CardTitle>{formatUserName(coach)}</CardTitle>
            </CoachNameRow>
            <CoachPhoneRow>
              <PhoneIcon />
              <Column>
                <CoachCardLabel>Phone:</CoachCardLabel>
                <CoachPhoneText>
                  {formatPhoneNumberPretty(coach.phoneNumber)}
                </CoachPhoneText>
              </Column>
            </CoachPhoneRow>
          </CoachCard>
        )}

        <AppointmentCard>
          <AppointmentTitleRow>
            <CalendarIcon />
            <CardTitle>Next Appointment</CardTitle>
          </AppointmentTitleRow>
          <AppointmentDateText dateTime={nextAppointmentDate ?? undefined}>
            {formattedAppointmentDate ??
              "You don’t currently have any appointments scheduled."}
          </AppointmentDateText>
        </AppointmentCard>
      </ContentBox>
    </Page>
  );
};

export default styled(ParticipantCoachPage)`
  background-color: #0e3750;
`;

const Header = styled(Row)`
  height: 60px;
  width: 100%;
  justify-content: center;

  > ${Link} {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
`;

const Title = styled("h1")`
  color: #4dacbe;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 0 0;
  font-weight: normal;
`;

const ContentBox = styled(Column)`
  width: 100%;
  margin-top: 2rem;
  padding: 0 0.5rem 0 0.5rem;
`;

const CardTitle = styled("h2")`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0 0 0;
`;

const CoachCard = styled(Card)`
  color: #11355d;
`;

const CoachCardLabel = styled("h3")`
  margin: 0 0 0 0;
  font-size: 0.9rem;
  font-weight: 600;
`;

const CoachNameRow = styled(Row)`
  align-items: center;

  ${CoachBadge} {
    transform: scale(1.25);
    margin-right: 0.5rem;
  }
`;

const CoachPhoneRow = styled(Row)`
  margin-top: 1rem;
  svg {
    margin-right: 0.5rem;
    transform: translate(0, 15%) rotate(225deg);
    color: #4dacbe;
  }
`;

const CoachPhoneText = styled("h4")`
  margin: 0 0 0 0;
  font-weight: 500;
  margin-top: 0.1rem;
`;

const AppointmentCard = styled(Card)`
  color: #11355d;
  margin-top: 0.5rem;
`;

const AppointmentTitleRow = styled(Row)`
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: #4dacbe;
  }
`;

const AppointmentDateText = styled("time")`
  margin-top: 1rem;
  font-weight: 600;
  padding-left: 1.5rem;
`;
