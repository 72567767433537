import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import CircleXButton from "../../../../common/components/CircleXButton";
import PlusMinusIcon from "../../../../common/components/PlusMinusIcon";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ExampleSection from "../../ExampleSection";
import kitchenImageSrc from "../../../images/kitchen_with_clipboard.png";
import clipboardClipImageSrc from "../../../images/clipboard_clip.png";

const clipboardChoices = [
  {
    id: 1,
    title: "ARE CLEAR",
    text:
      "Be specific about what you want, so your child knows exactly what you expect them to do.",
    example: '“Gentle touches with your baby sister”, or “Use walking feet."',
  },
  {
    id: 2,
    title: "ARE REASONABLE",
    text:
      "Be thoughtful about the number and size of tasks you give your child.",
    example: '"Put your toys in the box.” instead of, "Keep your room clean!"',
  },
  {
    id: 3,
    title: "SAY WHAT YOU WANT",
    text: "Not what you don’t want.",
    example: `"Close doors quietly, please" describes exactly what you expect instead of, "Don't slam the door!"`,
  },
  {
    id: 4,
    title: "ARE SIMPLE",
    text:
      "Giving one command at a time is more effective than giving multiple commands.",
    example:
      "“Brush your teeth please” is more effective than “brush your teeth, put on your pajamas, pick out a book, and get in bed.”",
  },
  {
    id: 5,
    title:
      "ARE REALISTIC (for your child’s age) AND EASY TO FOLLOW (and enforce)",
    example:
      "“If you eat your dinner, you can have dessert.” or “Help me brush your teeth.” vs. “Go get ready for bed.”",
  },
  {
    id: 6,
    title: "DON’T USE QUESTIONS",
    text:
      "Your child might say “no.” Be clear that your request is not optional.",
    example:
      "“Do you want to pick up the toys?” is less effective than “Pick up the toys!”",
  },
];

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const RulesAndConsequencesSlide9: FC<Props> = (props) => {
  const { module, section, isReview, ...rest } = props;
  const [showClipboard, setShowClipboard] = useState(false);
  const [expandedChoiceId, setExpandedChoiceId] = useState<number | null>(
    clipboardChoices[0].id
  );
  const [hideNextButton, setHideNextButton] = useState(!isReview);

  if (showClipboard) {
    return (
      <SlidePage {...rest} title={module.name} hideNextButton>
        <Clipboard>
          <CloseButton onClick={() => setShowClipboard(false)} />
          <ClipboardClipImage />
          <ClipboardPaper>
            <PaperTitle>
              Successful
              <br />
              Household Rules:
            </PaperTitle>
            <ClipboardList>
              {clipboardChoices.map((item) => {
                const expanded = expandedChoiceId === item.id;

                return (
                  <ClipboardListItem
                    key={item.id}
                    onClick={() =>
                      setExpandedChoiceId(expanded ? null : item.id)
                    }
                  >
                    <CheckboxPlusMinusContainer expanded={expanded}>
                      <PlusMinusIcon
                        expanded={expanded}
                        iconColor={expanded ? "#FFFFFF" : "#0e3750"}
                      />
                    </CheckboxPlusMinusContainer>
                    <ClipboardListItemContent>
                      <ClipboardListItemTitle>
                        {item.title}
                      </ClipboardListItemTitle>
                      {expanded && (
                        <>
                          <ClipboardText>{item.text}</ClipboardText>
                          <ExampleSection dark titleSpacing="8px">
                            {item.example}
                          </ExampleSection>
                        </>
                      )}
                    </ClipboardListItemContent>
                  </ClipboardListItem>
                );
              })}
            </ClipboardList>
          </ClipboardPaper>
        </Clipboard>
      </SlidePage>
    );
  }

  return (
    <SlidePage
      {...rest}
      title={module.name}
      hideNextButton={hideNextButton}
      hideFooter={hideNextButton}
    >
      <section>
        <p>Tap the clipboard to learn more about successful household rules.</p>
      </section>
      <KitchenImage
        onClick={() => {
          setShowClipboard(true);
          setHideNextButton(false);
        }}
      />
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide9)``;

const KitchenImage = styled("img").attrs({
  src: kitchenImageSrc,
  alt: "kitchen",
})`
  width: 100%;
  height: auto;
  margin-top: 2rem;
  cursor: pointer;
`;

const Clipboard = styled("div")`
  position: relative;
  flex-shrink: 0;
  border-radius: 13px;
  background-color: #544314;
  padding: 12px;
  margin: 0 1rem 0 1rem;
  max-width: 500px;
  align-self: center;
`;

const ClipboardClipImage = styled("img").attrs({
  src: clipboardClipImageSrc,
  alt: "",
})`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 185px;
`;

const ClipboardPaper = styled("div")`
  background-color: white;
  padding: 1rem 1.25rem 2rem 1rem;
  color: #0e3750;
`;

const PaperTitle = styled("h1")`
  color: #4dacbe;
  font-family: BorisBlackBloxx;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 31px;
  text-align: center;
`;

const ClipboardList = styled("ul")``;

const CheckboxPlusMinusContainer = styled("div")<{ expanded: boolean }>`
  height: 22px;
  width: 22px;
  border-radius: 2px;
  color: #bbd7e8;
  flex-shrink: 0;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${PlusMinusIcon} {
    height: 100%;
    width: 100%;
    background-color: ${(props) => (props.expanded ? "#0E3750" : "#bbd7e8")};
  }
`;

const ClipboardListItem = styled("div")`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  & + & {
    margin-top: 15px;
  }
`;

const ClipboardListItemContent = styled("div")`
  display: flex;
  flex-direction: column;
  color: #0e3750;
`;

const ClipboardListItemTitle = styled("h4")`
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`;

const ClipboardText = styled("p")`
  font-size: 15px;
  line-height: 19px;
  margin-top: 2px;
  margin-bottom: 15px;
`;

const CloseButton = styled(CircleXButton)`
  position: absolute;
  right: 20px;
  top: 20px;
`;
