import React, { FC } from "react";
import styled from "styled-components/macro";
import CircleStarImage from "./CircleStarImage";

interface Props {
  dark?: boolean;
}

const CircleStarItem: FC<Props> = (props) => {
  const { children, dark, ...rest } = props;

  return (
    <Root {...rest} color={dark ? "#0E3750" : "white"}>
      <CircleStarImage />
      {children}
    </Root>
  );
};

export default styled(CircleStarItem)``;

const Root = styled("div")<{ color: string }>`
  position: relative;
  padding-left: 2rem;
  color: ${(props) => props.color};

  > ${CircleStarImage} {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ExampleSectionHeader = styled("h3")<{
  bottomPadding: number | string;
}>`
  color: inherit;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.4rem;
  margin-bottom: ${(props) => props.bottomPadding};
`;
