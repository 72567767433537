import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import Modal from "@emberex/components/lib/Modal";
import { LIGHT_GRAY } from "../../colors";
import CircleXButton from "./CircleXButton";

interface Props {
  showCloseButton?: boolean;
  onClose: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
}

const AdminModal: FC<Props> = props => {
  const { onClose, children, showCloseButton = true, ...rest } = props;

  return (
    <Modal {...rest}>
      {showCloseButton && <CloseButton onClick={onClose} />}
      {children}
    </Modal>
  );
};

export default styled(AdminModal)`
  max-width: 100%;
  border-radius: 1px;
  background-color: ${LIGHT_GRAY};
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 3rem 6rem 3rem 6rem;
`;

const CloseButton = styled(CircleXButton)`
  position: absolute;
  right: -46px;
  top: 2px;
`;
