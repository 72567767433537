import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide2: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>HOME SAFETY</h3>
        <h3>Toddlers and Preschoolers</h3>
        <p>
          Young children have the highest risk of being injured at home because
          that's where they spend most of their time. Many parents child-proof
          their house for their infants, but do not remember to adapt as their
          children grow.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide2)``;
