import styled, { css } from "styled-components/macro";

interface PlanLetterProps {
  faded?: boolean;
  size?: "small" | "large";
}

const PlanLetter = styled("div")<PlanLetterProps>`
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #4dacbe;
  flex-shrink: 0;
  transition: color 0.2s, border-color 0.2s;

  ${({ faded = false, size = "small" }) => {
    const color = faded ? "#3C8E9D" : "white";
    const width = size === "small" ? 2.5 : 9; // rem
    const fontSize = size === "small" ? 1 : 3.5; // rem
    const borderWidth = size === "small" ? 1 : 3.5; // px
    const borderColor = faded ? "#4dacbe" : "#ccfcee";

    return css`
      color: ${color};
      font-size: ${fontSize}rem;
      height: ${width}rem;
      width: ${width}rem;
      border: ${borderWidth}px solid ${borderColor};
    `;
  }};
`;

export default PlanLetter;
