import React, { FC } from "react";
import styled from "styled-components/macro";
import Row from "@emberex/components/lib/Row";

export type SegmentedProgressValue = ReadonlyArray<{
  current: number;
  total: number;
}>;

interface Props {
  segments: SegmentedProgressValue;
  dark?: boolean;
}

const SegmentedProgressBar: FC<Props> = (props) => {
  const { segments, dark = true, ...rest } = props;

  return (
    <Row {...rest}>
      {segments.map((segment, i) => {
        const ratio = segment.current / segment.total || 0;

        return (
          <Segment key={i} dark={dark}>
            <SegmentFill style={{ width: `${ratio * 100}%` }} />
          </Segment>
        );
      })}
    </Row>
  );
};

export default styled(SegmentedProgressBar)``;

const Segment = styled("div")<{ dark: boolean }>`
  flex: 1;
  height: 12px;
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid
    ${(props) => (props.dark ? "#0e4c63" : "rgba(8, 173, 192, .18)")};
  background-color: white;

  & + & {
    margin-left: 10px;
  }
`;

const SegmentFill = styled("div")`
  height: 8px;
  border-radius: 4px;
  transition: width 0.4s;
  background: linear-gradient(
    90deg,
    rgba(8, 173, 192, 0.09) 0%,
    rgba(8, 173, 192, 0.55) 55.28%,
    #08adc0 100%
  );
`;
