import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const EncouragementAndPraiseContent2Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>Specific Praise</h3>
        <p>
          Specific praise is direct and focuses on the behavior your child is
          doing that you want to increase. Specific praise helps your child
          learn the skills you want them to do more of on a daily basis. It is
          the most effective praise to use with your child.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(EncouragementAndPraiseContent2Slide)``;
