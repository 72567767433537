import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ExampleSection from "../../ExampleSection";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide13: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <p>
          Use logical consequences if you can. Small consequences work just as
          well as large ones.
        </p>
      </section>
      <section>
        <ExampleSection>
          If you draw on the table when using the markers,
          they will be taken away.
        </ExampleSection>
      </section>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide13)``;
