import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Credentials from "shared/lib/interfaces/Credentials";
import Form from "@emberex/components/lib/Form";
import useInputState from "@emberex/react-utils/lib/useInputState";
import getErrorMessage from "../../common/utils/getErrorMessage";
import FormErrorText from "../../common/components/FormErrorText";
import Input from "../../common/components/Input";
import Link from "../../common/components/Link";
import PillButton from "../../surveys/components/PillButton";
import { DEFAULT_LOGIN_USERNAME, DEFAULT_LOGIN_PASSWORD } from "../../env";

interface Props {
  onSubmit(credentials: Credentials): any;
  initialUsername?: string;
  initialPassword?: string;
}

const LoginForm: FC<Props> = (props) => {
  const {
    onSubmit,
    initialUsername = DEFAULT_LOGIN_USERNAME,
    initialPassword = DEFAULT_LOGIN_PASSWORD,
    ...rest
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [email, handleEmailChange] = useInputState(initialUsername);
  const [password, handlePasswordChange] = useInputState(initialPassword);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit({ username: email, password });
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit, email, password]);

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <FormErrorText>{error && getErrorMessage(error)}</FormErrorText>
      <LoginInput
        required
        placeholder="Email"
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
      />
      <LoginInput
        required
        type="password"
        placeholder="password"
        autoComplete="current-password"
        value={password}
        onChange={handlePasswordChange}
      />
      <ForgotPasswordLink to="/forgot-password">
        Forgot Password
      </ForgotPasswordLink>
      <LoginButton disabled={submitting}>Login</LoginButton>
    </Form>
  );
};

export default styled(LoginForm)`
  display: flex;
  flex-direction: column;
  width: 267px;
  max-width: 100%;
  align-items: center;

  @media (min-width: 800px) {
    width: 400px;
  }
`;

const LoginInput = styled(Input)`
  height: 3rem;
  width: 100%;

  & + & {
    margin-top: 1rem;
  }

  @media (min-width: 800px) {
    & + & {
      margin-top: 1.5rem;
    }
  }
`;

const ForgotPasswordLink = styled(Link)`
  margin-top: 0.5rem;
  align-self: flex-end;
  color: white;
`;

const LoginButton = styled(PillButton)`
  width: 210px;
  margin: 3rem auto;
`;
