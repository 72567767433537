import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { BearIcons } from "../../../../common/components/BearIcon";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import VideoId from "shared/lib/enums/VideoId";

const activityKind =
  ModuleActivityKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_OUTRO_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const ParentingAndSubstanceUseVideoOutroSlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [onPlayStart, onPlayed, videoCompleted] = useVideoActivity(
    activityKind,
    VideoId.COPING_WITHOUT_ALCOHOL_AND_DRUGS_OUTRO_VIDEO
  );
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const handleNext = useCallback(() => {
    onPlayed(true);
    setShowSegmentOverlay(true);
  }, [onPlayed]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      onNext={handleNext}
      title={module.name}
      activityKind={activityKind}
      bearIcon={BearIcons.GIRL_NEUTRAL}
    >
      <section>
        <h3>{section.name}</h3>
        <p>
          Breathing can help reduce stress and anxiety that may cause cravings,
          give it a try using this video.
        </p>
      </section>

      <VideoActivity
        onStarted={onPlayStart}
        onEnded={onPlayed}
        videoId={VideoId.COPING_WITHOUT_ALCOHOL_AND_DRUGS_OUTRO_VIDEO}
      />

      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Congratulations!"
      />
    </ActivitySlidePage>
  );
};

export default styled(ParentingAndSubstanceUseVideoOutroSlide)`
  ${VideoActivity} {
    margin-top: 2rem;
  }
`;
