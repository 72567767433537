import React, { FC } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import WarningSignImage from "./WarningSignImage";

const WarningSign: FC = (props) => {
  return (
    <Column {...props}>
      <WarningSignImage />
      <WarningText>{props.children}</WarningText>
    </Column>
  );
};

export default styled(WarningSign)`
  align-items: center;
`;

const WarningText = styled("h4")`
  color: #ff6762;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.3rem;
  text-align: center;
  margin-top: 0.75rem;
`;
