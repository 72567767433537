import React, { FC } from "react";
import styled from "styled-components/macro";
import ModuleIconKind from "shared/lib/enums/ModuleIconKind";
import { DARKEST_BLUE } from "../../colors";
import parentingAndSubstanceUseSrc from "../images/module_icons/module_badge_parenting_and_substance_use.png";
import positiveParentingSrc from "../images/module_icons/module_badge_positive_parenting.png";
import proactiveParentingSrc from "../images/module_icons/module_badge_proactive_parenting.png";
import rulesAndConsequencesSrc from "../images/module_icons/module_badge_rules_and_consequences.png";
import wellnessAndCareSrc from "../images/module_icons/module_badge_wellness_and_care.png";

interface Props {
  icon: ModuleIconKind;
  faded?: boolean;
}

const ModuleIcon: FC<Props> = (props) => {
  const { icon, ...rest } = props;
  const { src, alt } = getIconImage(icon);

  return (
    <div {...rest}>
      <img src={src} alt={alt} />
    </div>
  );
};

function getIconImage(icon: ModuleIconKind): { src: string; alt: string } {
  switch (icon) {
    case ModuleIconKind.PARENTING_AND_SUBSTANCE_USE:
      return {
        src: parentingAndSubstanceUseSrc,
        alt: "Parenting and substance use.",
      };
    case ModuleIconKind.POSITIVE_PARENTING:
      return { src: positiveParentingSrc, alt: "Positive parenting" };
    case ModuleIconKind.PROACTIVE_PARENTING:
      return { src: proactiveParentingSrc, alt: "Proactive parenting" };
    case ModuleIconKind.RULES_AND_CONSEQUENCES:
      return {
        src: rulesAndConsequencesSrc,
        alt: "Rules and Consequences",
      };
    case ModuleIconKind.WELLNESS_AND_CARE:
      return { src: wellnessAndCareSrc, alt: "Wellness and care" };
    default:
      return { src: "", alt: "" };
  }
}

export default styled(ModuleIcon)<Props>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${DARKEST_BLUE};
  flex-shrink: 0;

  > img {
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.faded ? 0.4 : 1)};
  }
`;
