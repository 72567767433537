import ModuleSlideView from "shared/lib/interfaces/ModuleSlideView";
import apiAxios from "../../apiAxios";

interface Props {
  moduleId: number;
  slideId: number;
  participantId: number;
}

export default async function viewModuleSlide(
  props: Props
): Promise<ModuleSlideView> {
  const { participantId, moduleId, slideId } = props;
  const response = await apiAxios.post(
    `/participants/${participantId}/modules/${moduleId}/slides/${slideId}/views`
  );

  return response.data;
}
