import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ManagingDepressionContent1Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          The quickest way to improve your mood is to do something you enjoy.
          Activities take your mind off your problems, and help you feel better.
        </p>
      </section>
      <section>
        <p>Plan things you can do easily that:</p>
      </section>
      <section>
        <YellowCircleList>
          <li>
            <b>Are nearby</b>
          </li>
          <li>
            <b>Don’t cost much</b>
          </li>
          <li>
            <b>Can be done anytime</b>
          </li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(ManagingDepressionContent1Slide)``;
