import React, { FC, useState, useCallback } from "react";
import { Correctness } from "shared/lib/constants/Correctness";
import styled from "styled-components/macro";
import ProactiveParentingPlanProblem, {
  ProactiveParentingPlanQuestion,
} from "shared/lib/interfaces/ProactiveParentingPlanProblem";
import {
  proactiveParentingPlanProblems,
  meaningsAndQuestions,
} from "shared/lib/constants/proactiveParentingPlanProblems";
import { ProactiveParentingPlanQuizValue } from "shared/lib/interfaces/ModuleActivity";
import { CardButton } from "../../common/components/Card";
import CorrectIncorrectMessageToast from "../../common/components/CorrectIncorrectMessageToast";
import stock1Src from "../images/stock/stock_9.png";
import stock2Src from "../images/stock/stock_10.png";
import stock3Src from "../images/stock/stock_11.png";
import Row from "@emberex/components/lib/Row";
import ActivityBox from "./ActivityBox";
import ChevronCircleButton from "./ChevronCircleButton";
import * as colors from "../../colors";
import PlanLetter from "./PlanLetter";
import MultipleChoiceQuestionCard from "./MultipleChoiceQuestionCard";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";

const stockImages = [stock1Src, stock2Src, stock3Src];

interface Props {
  activityKind: ModuleActivityKind;
  onChange(value: ProactiveParentingPlanQuizValue): unknown;
  onFinish?(): unknown;
}

const ProactiveParentingPlanQuiz: FC<Props> = (props) => {
  const { activityKind, onChange, onFinish, ...rest } = props;
  const [results, setResults] = useState<Record<string, string>>({});
  const [problem, setProblem] = useState<ProactiveParentingPlanProblem | null>(
    null
  );
  const [
    question,
    setQuestion,
  ] = useState<ProactiveParentingPlanQuestion | null>(null);
  const [choiceId, setChoiceId] = useState<string | null>();
  const [completedProblems, setCompletedProblems] = useState<string[]>([]);

  const returnToProblemList = useCallback(() => {
    setChoiceId(null);
    setProblem(null);
    setQuestion(null);
  }, []);

  const showNextQuestion = useCallback(() => {
    if (!problem) {
      return;
    }
    const questionIndex = question ? problem.questions.indexOf(question) : -1;
    if (questionIndex === -1) {
      setQuestion(problem.questions[0]);
    } else {
      const nextQuestion = problem.questions[questionIndex + 1];
      if (nextQuestion) {
        setQuestion(nextQuestion);
      } else {
        const value: ProactiveParentingPlanQuizValue = Object.entries(
          results
        ).map(([questionId, choiceId]) => ({
          questionId,
          choiceId,
        }));

        onChange(value);
        onFinish?.();
        returnToProblemList();
        setCompletedProblems((prev) => [...prev, problem.id]);
      }
    }
  }, [returnToProblemList, problem, question, onChange, onFinish, results]);

  if (!problem || !question) {
    return (
      <ActivityBox {...rest} icon="pointer">
        <Title>Your turn:</Title>
        <LetterRow>
          <PlanLetter>P</PlanLetter>
          <PlanLetter>L</PlanLetter>
          <PlanLetter>A</PlanLetter>
          <PlanLetter>N</PlanLetter>
        </LetterRow>
        <ProblemList>
          {proactiveParentingPlanProblems
            .filter((problem) => !completedProblems.includes(problem.id))
            .map((problem, i) => (
              <li key={problem.id}>
                <ProblemCard
                  onClick={() => {
                    setChoiceId(null);
                    setProblem(problem);
                    setQuestion(problem.questions[0]);
                  }}
                >
                  <CircleImage
                    src={stockImages[i % stockImages.length]}
                    alt=""
                  />
                  <ProblemCardTitle>Problem Situation {i + 1}</ProblemCardTitle>
                  <ProblemCardSituation>
                    {problem.situation}
                  </ProblemCardSituation>
                  <ChevronCircleButton />
                </ProblemCard>
              </li>
            ))}
        </ProblemList>
      </ActivityBox>
    );
  }

  const selectedChoice =
    (choiceId && question.choices.find((choice) => choiceId === choice.id)) ||
    null;

  const { letterMeaning, cardQuestion } = meaningsAndQuestions[question.letter];

  return (
    <ActivityBox {...rest} icon="pointer">
      <Title>Your turn:</Title>
      <LetterRow>
        <PlanLetter faded={question.letter !== "P"}>P</PlanLetter>
        <PlanLetter faded={question.letter !== "L"}>L</PlanLetter>
        <PlanLetter faded={question.letter !== "A"}>A</PlanLetter>
        <PlanLetter faded={question.letter !== "N"}>N</PlanLetter>
      </LetterRow>
      <LetterMeaning>{letterMeaning}</LetterMeaning>
      <SituationText>
        <b>Situation</b>
        <br />
        {problem.situation}
      </SituationText>
      <MultipleChoiceQuestionCard
        disabled={!!selectedChoice}
        selectedChoiceId={selectedChoice?.id ?? null}
        onChange={(choiceId) => {
          setChoiceId(choiceId);
          setResults({ ...setResults, [question.id]: choiceId });
        }}
        question={cardQuestion}
        choices={question.choices}
      />
      {selectedChoice && (
        <CorrectIncorrectMessageToast
          message={question.feedback}
          onButtonClick={showNextQuestion}
          correctness={
            selectedChoice.correct ? Correctness.CORRECT : Correctness.INCORRECT
          }
        />
      )}
    </ActivityBox>
  );
};

export default styled(ProactiveParentingPlanQuiz)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 3rem;
`;

const Title = styled("h2")`
  color: ${colors.DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0 0.4rem 0;
`;

const LetterRow = styled(Row)`
  justify-content: center;
  margin-bottom: 1.25rem;

  > ${PlanLetter} + ${PlanLetter} {
    margin-left: 10px;
  }
`;

const LetterMeaning = styled("p")`
  text-align: center;
  margin-bottom: 30px;
`;

const CircleImage = styled("img")`
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  flex-shrink: 0;
`;

const ProblemCard = styled(CardButton)`
  position: relative;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  padding: 1.25rem;
  font-size: 1rem;
  width: 100%;
  color: ${colors.DARKEST_BLUE};
  font-size: 1rem;

  > ${CircleImage} {
    margin-bottom: 1.25rem;
    align-self: center;
  }

  > ${ChevronCircleButton} {
    position: absolute;
    top: 2.5rem;
    right: 0;
    transform: translate(50%, 0);
  }

  &:hover > ${ChevronCircleButton} {
    background-color: #083149;
  }
`;

const ProblemCardTitle = styled("h4")`
  font-weight: bold;
  margin: 0 0 0.25rem 0;
`;

const ProblemCardSituation = styled("p")`
  font-weight: 500;
`;

const ProblemList = styled("ul")`
  list-style: none;
  padding: 0 1.25rem 0 1.25rem;

  > li + li {
    margin-top: 1.25rem;
  }
`;

const SituationText = styled("p")`
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0 1.5rem 1.25rem 1.5rem;
`;
