import React, { FC, useMemo } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import Column from '@emberex/components/lib/Column';
import shuffle from 'lodash.shuffle';
import random from 'lodash.random';
import cloud from '../images/cloud.png';
import babyBear from '../images/baby_bear_looking_up.png';

// Create 5 copies of this list of words
const rainDrops = new Array(5).fill([
  'BE QUIET',
  'GO TO BED',
  'STOP',
  "NOT YET",
  "WAIT",
  "INSIDE VOICE",
  "NO"
]).reduce((list: string[], current: string[]) => [...list, ...current], []);


interface RainDropData {
  id: number;
  text: string;
  rotationAngle: number; // degrees
  top: number; // percent
  left: number; // percent
  delay: number; // second
  duration: number; // second
}

const RuleCloud: FC = props => {

  const shuffledRainDrops = useMemo((): RainDropData[] => {
    const shuffledRaindrops = shuffle(rainDrops);
    return shuffledRaindrops.map((text, idx) => ({
      id: idx,
      text,
      // Randomly rotate the text
      rotationAngle: random(-320, 345),
      // Randomly adjust where the "rain" starts so that some end sooner than others
      top: random(-5, 0),
      // Randomly distribute among the x-axis
      left: random(100),
      // Start the animations at different times and make them last differing amounts of time
      delay: random(0, 10),
      duration: random(10, 20),
    }));
  }, []);

  return (
    <Root {...props}>
      <CloudContainer>
        <Cloud />
      </CloudContainer>
      <Words>
        {shuffledRainDrops.map(({text, id, ...restOfRaindrop}) => (
          <RainDrop
            key={id}
            {...restOfRaindrop}
          >
            {text}
          </RainDrop>
        ))}
      </Words>
      <BabyBear />
    </Root>
  );
};

export default styled(RuleCloud)``;

const Root = styled(Column)`
  flex: 1 0 auto;
  width: 100%;
  background-color: #CCFCEE;
  align-items: center;
  overflow: hidden;
`;

const Words = styled.div`
  flex: 1 0 auto;
  z-index: 1;
  overflow: hidden;
`;

const raindropAnimation = ({left, top}: Omit<RainDropData, 'text' | 'id'>) => keyframes`
  0% {
    left: ${left}%;
    top: ${top}%;
  }
  100% {
    top: ${top + 100}%
  }
`;

const RainDrop = styled.span<Omit<RainDropData, 'text' | 'id'>>`
  color: #0E3750;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.375rem;
  position: absolute;
  animation-delay: ${props => props.delay}s;
  animation-duration: ${props => props.duration}s;
  left: ${props => props.left}%;
  top: ${props => props.top}%;
  animation-iteration-count: infinite;
  animation-name: ${raindropAnimation};
  transform: rotate(${props => props.rotationAngle}deg);
`;

const CloudContainer = styled(Column)`
  width: 100%;
  height: 120px;
  color: #0E3750;
  font-family: Montserrat;
  font-size: 0.9375rem;
  letter-spacing: 0;
  line-height: 1.1875rem;
  justify-content: center;
  align-items: center;
  p {
    z-index: 2;
    padding: 0 2.8125rem;
  }
  z-index: 2;
`;
const Cloud = styled.img.attrs({src: cloud, alt: 'Rain cloud'})`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const BabyBear = styled.img.attrs({src: babyBear, alt: 'Baby Bear getting rained on.'})`
  position: relative;
  height: auto;
  width: auto;
  z-index: 2;
  @media (max-width: 600px) {
    height: 250px;
  }
`;
