import React, { FC } from 'react';
import styled from 'styled-components/macro';
import bearBabyNeutral from '../images/bear_baby_neutral.png';
import bearBabyThumbsUp from '../images/bear_baby_thumbsUp.png';
import bearBabyThumbsUpWinking from '../images/bear_baby_thumbsUpWinking.png';
import bearBabyWinking from '../images/baby_bear_winking.png';
import bearGirlNeutral from '../images/bear_girl_neutral.png';
import bearGirlThumbsUp from '../images/bear_girl_thumbsUp.png';
import bearGirlWinking from '../images/bear_girl_winking.png';
import bearGirlThumbsUpWinking from '../images/bear_girl_thumbsUpWinking.png';


export enum BearIcons {
  BABY_NEUTRAL,
  BABY_THUMBS_UP,
  BABY_THUMBS_UP_WINKING,
  BABY_WINKING,
  GIRL_NEUTRAL,
  GIRL_THUMBS_UP,
  GIRL_WINKING,
  GIRL_THUMBS_UP_WINKING
}

interface BearImage {
  src: string;
  alt: string;
}

const imageMapping: Record<BearIcons, BearImage> = {
  [BearIcons.BABY_NEUTRAL]: { src: bearBabyNeutral, alt: 'Baby Bear' },
  [BearIcons.BABY_THUMBS_UP]: {src: bearBabyThumbsUp, alt: 'Baby Bear with thumbs up'},
  [BearIcons.BABY_THUMBS_UP_WINKING]: {src: bearBabyThumbsUpWinking, alt: 'Baby Bear with thumbs up and winking' },
  [BearIcons.BABY_WINKING]: { src: bearBabyWinking, alt: 'Baby Bear winking'},
  [BearIcons.GIRL_NEUTRAL]: {src: bearGirlNeutral, alt: 'Girl Bear'},
  [BearIcons.GIRL_THUMBS_UP]: {src: bearGirlThumbsUp, alt: 'Girl Bear with thumbs up.'},
  [BearIcons.GIRL_WINKING]: {src: bearGirlWinking, alt: 'Girl Bear winking'},
  [BearIcons.GIRL_THUMBS_UP_WINKING]: { src: bearGirlThumbsUpWinking, alt: 'Girl Bear with thumbs up and winking' }
}


interface Props {
  icon: BearIcons;
}

const BearIcon: FC<Props> = props => {
  const {  icon, ...rest } = props;
  const { src, alt } = imageMapping[icon];
  return <img src={src} alt={alt} {...rest} />;
};

export default styled(BearIcon)`
  height: 372px;
  width: 278px;
`;
