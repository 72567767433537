import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";
import WarningSign from "../../WarningSign";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const GivingDirectionTipsSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>Your directions will be more effective if you:</p>
      </section>
      <section>
        <YellowCircleList>
          <li>
            <b>Have your child’s attention:</b>
            <br />
            Be in the same room, make eye contact
          </li>
          <li>
            <b>Make only one request at a time</b>
          </li>
          <li>
            <b>Be specific</b> about what you want your child to do: Say, “Pick
            up your blocks, please.”
          </li>
          <li>
            <b>Focus on what you want</b>, rather than what you don't: Say,
            “Chew with your mouth closed, please.” Instead of, “Don’t chew with
            your mouth open.”
          </li>
          <li>
            <b>Be polite &amp; respectful:</b> Keep your expression neutral, use
            a pleasant tone of voice, say "please" and "thank you"
          </li>
        </YellowCircleList>
      </section>
      <section>
        <WarningSign>
          Caution: When your requests sound like questions you open the door to
          an argument.
        </WarningSign>
      </section>
    </SlidePage>
  );
};

export default styled(GivingDirectionTipsSlide)``;
