import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ManagingCravingsSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>MANAGING CRAVINGS</h3>
      </section>
      <section>
        <p>A craving is an intense desire to use a substance.</p>
      </section>
      <section>
        <p>
          Everyone experiences cravings; they are completely normal. Sometimes a
          craving can be so strong that it completely overwhelms you, and you
          feel like you can’t resist.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(ManagingCravingsSlide)``;
