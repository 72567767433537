import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import praiseImageSrc from "../../../images/rules_and_consequences_praise_example.png";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide10: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <p>
          Finally, don’t forget to use praise when your child follows your
          rules. When you use these guidelines, both you and your child will
          know what to expect.
        </p>
      </section>
      <PraiseImage />
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide10)``;

const PraiseImage = styled("img").attrs({
  src: praiseImageSrc,
  alt: "Good job putting your toys in the bin.",
})`
  width: 100%;
  height: auto;
  flex-shrink: 0;
  margin-top: 1.5rem;
`;
