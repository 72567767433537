import React, { FC, useState, useCallback, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ModuleSlideView from "shared/lib/interfaces/ModuleSlideView";
import getModuleNumber from "shared/lib/utils/getModuleNumber";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import last from "@emberex/array-utils/lib/last";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import ParticipantModuleContext, {
  ParticipantModuleContextWithSurvey,
  ParticipantModuleContextWithoutSurvey,
} from "shared/lib/interfaces/ParticipantModuleContext";
import { ModuleSlideWithSection } from "shared/lib/interfaces/ModuleSlide";
import { SurveyWithQuestionsAndResults } from "shared/lib/interfaces/Survey";
import { SurveyTakeWithResponses } from "shared/lib/interfaces/SurveyTake";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import getCompletedModuleSlides from "../../modules/api/getCompletedModuleSlides";
import useModuleTimer from "../../modules/hooks/useModuleTimer";
import ParticipantNav from "../components/ParticipantNav";
import getParticipantModuleContext from "../api/getParticipantModuleContext";
import { useParticipantContext } from "../contexts/ParticipantContext";
import ModuleContentSlide from "../../modules/components/ModuleContentSlide";
import ModuleActivityContext from "../../modules/contexts/ModuleActivityContext";
import completeSurveyTake from "../../surveys/api/completeSurveyTake";
import {
  DraftActivity,
  ModuleActivityValue,
} from "shared/lib/interfaces/ModuleActivity";
import replaceWhere from "@emberex/array-utils/lib/replaceWhere";
import saveModuleActivity from "../../modules/api/saveModuleActivity";
import { SHOW_MODULE_KINDS } from "../../env";
import SurveyResponseValue from "shared/lib/interfaces/SurveyResponseValue";
import SurveyTakePage, {
  SurveyTakePageChangeEvent,
} from "../../surveys/components/SurveyTakePage";
import saveSurveyTakeResponses from "../../surveys/api/saveSurveyTakeResponses";
import { useSearchParamBooleanState } from "../../common/hooks/useSearchParamState";
import ModuleCompleteSlide from "../../modules/components/ModuleCompleteSlide";
import ProgramCompleteSlide from "../../modules/components/ProgramCompleteSlide";
import { useMemo } from "react";
import VideoId from "shared/lib/enums/VideoId";
import viewVideo from "../../videos/viewVideo";
import {
  ModulePageContextProvider,
  ModulePageContextValue,
} from "../contexts/ModulePageContext";

interface DraftSurveyResponse {
  questionId: number;
  value: SurveyResponseValue | null;
  unsaved?: boolean;
}

interface State {
  moduleContext:
    | ParticipantModuleContextWithSurvey
    | ParticipantModuleContextWithoutSurvey;
  slides: ModuleSlideWithSection[];
  draftActivities: DraftActivity<any>[];
  draftSurveyResponses: DraftSurveyResponse[];
  showingCompleteSlide: boolean;
  hideProgramCompleteSlide: boolean;
}

interface UrlParams {
  moduleNumber: string;
  slideNumber?: string;
}

const ParticipantModulePage: FC = () => {
  const {
    user,
    viewSlide,
    completeSlide,
    setShowWelcomeBackOverlay,
    canViewModule,
  } = useParticipantContext();
  const history = useHistory();
  const location = useLocation();
  const [forceShowSurvey = false, setForceShowSurvey] =
    useSearchParamBooleanState("view-survey");
  const params = useParams<UrlParams>();
  const moduleNumber = parseInt(params.moduleNumber, 10);
  const slideNumber = params.slideNumber ? parseInt(params.slideNumber) : null;
  const slideIndex = slideNumber ? slideNumber - 1 : null;
  const participantId = user.id;
  const [state, setState] = useState<State | null>(null);
  const moduleContext = state?.moduleContext;
  const initialSurveyId = moduleContext?.initialSurvey?.id;
  const draftActivities = state?.draftActivities ?? [];
  const draftSurveyResponses = state?.draftSurveyResponses ?? [];
  const isModuleUnlocked = moduleContext
    ? canViewModule(moduleContext.module.id)
    : true;
  const slides = state?.slides ?? [];
  const currentSlide = slideIndex === null ? null : slides[slideIndex];
  const isLastSlide = slideIndex === slides.length - 1;
  const showingSurvey = !!(
    state?.moduleContext?.initialSurvey &&
    (!state.moduleContext.initialSurveyTake.completedAt || forceShowSurvey)
  );
  const [completedSlides, setCompletedSlides] = useState<ModuleSlideView[]>([]);
  const isLastModule = !!state?.moduleContext.module.isLast;

  // Load module context
  useAsyncEffect(
    async (isCancelled) => {
      const moduleContext = await getParticipantModuleContext({
        moduleNumber,
        participantId,
      });

      if (isCancelled()) {
        return;
      }

      const slides = getModuleSlides(moduleContext);

      setState({
        moduleContext,
        slides,
        showingCompleteSlide: false,
        hideProgramCompleteSlide: true,
        draftActivities: moduleContext.activities,
        draftSurveyResponses: moduleContext.initialSurvey
          ? moduleContext.initialSurveyTake.responses
          : [],
      });
      setCompletedSlides(
        moduleContext.slideViews.filter((slide) => slide.completedAt !== null)
      );
    },
    [participantId, moduleNumber]
  );

  /**
   * Disable the welcome overlay for the session if the participant views a module.
   */
  useEffect(() => {
    setShowWelcomeBackOverlay(false);
  }, [setShowWelcomeBackOverlay]);

  // Kick user back to the module list if the module is still locked.
  useEffect(() => {
    if (!isModuleUnlocked) {
      history.replace("/");
    }
  }, [isModuleUnlocked, history]);

  // Show initial slide if the slide number isn't in the URL
  useEffect(() => {
    if (!state) {
      return;
    }

    const slides = getModuleSlides(state.moduleContext);

    // Navigate to the initial slide if the current slide number isn't defined or is out of bounds
    if (
      slideNumber === null ||
      slideNumber < 1 ||
      slideNumber > slides.length
    ) {
      // Displays the most recently viewed slide or the first slide if none have been viewed
      const mostRecentSlideView = last(state.moduleContext.slideViews);
      const initialSlideIndex = Math.max(
        0,
        mostRecentSlideView
          ? slides.findIndex(
              (slide) => slide.id === mostRecentSlideView.slideId
            )
          : -1
      );

      history.replace(
        `/module/${getModuleNumber(state.moduleContext.module)}/${
          initialSlideIndex + 1
        }${location.search}`
      );
    }
  }, [state, slideNumber, history, location.search]);

  // Record slide views
  useAsyncEffect(async () => {
    if (!currentSlide || showingSurvey || !moduleContext) {
      return;
    }
    await viewSlide(moduleContext.module.id, currentSlide.id).catch((error) => {
      console.error("Failed to record slide view", error);
    });
  }, [participantId, currentSlide, showingSurvey, moduleContext]);

  // Save unsaved activities (debounced)
  useEffect(() => {
    const timeout = setTimeout(async () => {
      const hasUnsavedActivities = draftActivities.some(
        (activity) => activity.unsaved
      );

      if (!hasUnsavedActivities) {
        return;
      }

      setState((state) => {
        if (!state) {
          return state;
        }
        return {
          ...state,
          draftActivities: state.draftActivities.map((response) => ({
            ...response,
            unsaved: false,
          })),
        };
      });

      if (moduleContext) {
        await saveUnsavedDraftActivities({
          participantId,
          moduleId: moduleContext.module.id,
          draftActivities,
        });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [participantId, draftActivities, moduleContext]);

  // Save unsaved survey responses (debounced)
  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!initialSurveyId) {
        return;
      }
      const hasUnsavedResponses = draftSurveyResponses.some(
        (response) => response.unsaved
      );

      if (!hasUnsavedResponses) {
        return;
      }

      setState((state) => {
        if (!state) {
          return state;
        }
        return {
          ...state,
          draftSurveyResponses: state.draftSurveyResponses.map((response) => ({
            ...response,
            unsaved: false,
          })),
        };
      });

      await saveUnsavedDraftSurveyResponses({
        participantId,
        surveyId: initialSurveyId,
        draftResponses: draftSurveyResponses,
      });
    }, 500);

    return () => clearTimeout(timeout);
  }, [initialSurveyId, participantId, draftSurveyResponses]);

  /**
   * Mark the last slide as completed immediately when it is displayed
   * because clicking the final "next" button is not required to progress
   * to the next module.
   */
  useAsyncEffect(async () => {
    if (isLastSlide && moduleContext && currentSlide) {
      await completeSlide(moduleContext.module.id, currentSlide.id);
    }
  }, [isLastSlide, slideIndex, currentSlide?.id, moduleContext?.module.id]);

  const handleNext = useCallback(async () => {
    if (slideIndex === null || !currentSlide || !moduleContext) {
      return;
    }
    await completeSlide(moduleContext.module.id, currentSlide.id);
    if (slideIndex < slides.length - 1) {
      history.push(`/module/${moduleNumber}/${slideIndex + 2}`);
    } else {
      setState((state) => state && { ...state, showingCompleteSlide: true });
    }
  }, [
    completeSlide,
    slides,
    slideIndex,
    history,
    moduleNumber,
    currentSlide,
    moduleContext,
  ]);

  const handleBack = useCallback(() => {
    if (slideIndex !== null && slideIndex > 0) {
      history.push(`/module/${moduleNumber}/${slideIndex}`);
    } else {
      history.push("/");
    }
  }, [slideIndex, history, moduleNumber]);

  const handleActivityChange = useCallback(
    <T extends ModuleActivityKind>(kind: T, value: ModuleActivityValue<T>) => {
      const existingActivity = draftActivities.find(
        (activity) => activity.kind === kind
      );

      setState((state) => {
        if (!state) {
          return state;
        }

        if (existingActivity) {
          return {
            ...state,
            draftActivities: replaceWhere(
              state.draftActivities,
              (activity) => activity.kind === kind,
              (activity) => ({ ...activity, value, unsaved: true })
            ),
          };
        }

        return {
          ...state,
          draftActivities: [
            ...state.draftActivities,
            {
              kind,
              value,
              unsaved: true,
            },
          ],
        };
      });
    },
    [draftActivities]
  );

  const handleSurveyResponseChange = useCallback(
    (event: SurveyTakePageChangeEvent) => {
      const existingResponse = draftSurveyResponses.find(
        (response) => response.questionId === event.questionId
      );

      setState((state) => {
        if (!state) {
          return state;
        }

        if (existingResponse) {
          return {
            ...state,
            draftSurveyResponses: replaceWhere(
              state.draftSurveyResponses,
              (response) => response.questionId === existingResponse.questionId,
              (response) => ({ ...response, value: event.value, unsaved: true })
            ),
          };
        }

        return {
          ...state,
          draftSurveyResponses: [
            ...state.draftSurveyResponses,
            {
              questionId: event.questionId,
              value: event.value,
              unsaved: true,
            },
          ],
        };
      });
    },
    [draftSurveyResponses]
  );

  const handleSurveyComplete = useCallback(async () => {
    if (initialSurveyId) {
      if (!state?.moduleContext.initialSurveyTake?.completedAt) {
        const updatedSurveyTake = await completeSurveyTake({
          participantId,
          surveyId: initialSurveyId,
        });
        setState((state) => {
          if (!state || !state.moduleContext.initialSurveyTake) {
            return state;
          }
          return {
            ...state,
            moduleContext: {
              ...state.moduleContext,
              initialSurveyTake: {
                ...state.moduleContext.initialSurveyTake,
                ...updatedSurveyTake,
              },
            },
          };
        });
      }
    }
    setForceShowSurvey(null);
  }, [state, participantId, initialSurveyId, setForceShowSurvey]);

  useAsyncEffect(
    async (isCancelled) => {
      if (!moduleContext) {
        return;
      }
      const completed = await getCompletedModuleSlides({
        moduleId: moduleContext.module.id,
        participantId: user.id,
      });
      if (!isCancelled()) {
        setCompletedSlides(completed);
      }
    },
    [moduleContext, slideIndex, user]
  );

  const pageTitle = useMemo(() => {
    if (!moduleContext) {
      return "";
    }
    return `${moduleContext.module.name}${
      slideIndex ? `, Slide ${slideIndex + 1}` : ""
    }`;
  }, [moduleContext, slideIndex]);

  useDocumentTitle(pageTitle);

  const handleVideoView = useCallback(
    (videoId: VideoId) => {
      viewVideo({ videoId, participantId }).catch((error) => {
        console.error(
          `An error occurred while marking a view of ${videoId}`,
          error
        );
      });
    },
    [participantId]
  );

  const handleHomeClickOnCompleted = useCallback(
    async (hideProgramCompleteSlide?: boolean) => {
      setState((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            hideProgramCompleteSlide:
              hideProgramCompleteSlide ?? prevState.hideProgramCompleteSlide,
          };
        }
        // is null
        return prevState;
      });
      if (slideIndex !== null && currentSlide && moduleContext) {
        await completeSlide(moduleContext.module.id, currentSlide.id);
      }
      if (hideProgramCompleteSlide || !isLastModule) {
        history.push("/dashboard");
      }
    },
    [
      history,
      slideIndex,
      currentSlide,
      moduleContext,
      isLastModule,
      completeSlide,
    ]
  );

  useModuleTimer(
    state?.moduleContext.module.id ?? null,
    currentSlide?.section.id ?? null
  );

  if (!state || !currentSlide || slideIndex === null) {
    // Loading
    return (
      <Page>
        <ParticipantNav />
      </Page>
    );
  }

  const modulePageContextValue: ModulePageContextValue = {
    moduleId: state.moduleContext.module.id,
    moduleSectionId: currentSlide.sectionId,
  };

  if (showingSurvey) {
    return (
      <ModulePageContextProvider value={modulePageContextValue}>
        <SurveyTakePage
          module={state.moduleContext.module}
          showIntroPages={!forceShowSurvey}
          moduleProgress={
            moduleContext ? getModuleProgressSegments(moduleContext) : []
          }
          survey={
            state.moduleContext.initialSurvey as SurveyWithQuestionsAndResults
          }
          surveyTake={
            state.moduleContext.initialSurveyTake as SurveyTakeWithResponses
          }
          responses={state.draftSurveyResponses}
          onAnswerChange={handleSurveyResponseChange}
          onComplete={handleSurveyComplete}
        />
      </ModulePageContextProvider>
    );
  }

  if (state.showingCompleteSlide) {
    // Display both the module complete and program complete overlays one after the other
    if (state.moduleContext.module.isLast) {
      if (state.hideProgramCompleteSlide) {
        return (
          <ModulePageContextProvider value={modulePageContextValue}>
            <ModuleCompleteSlide
              nextButtonText="Finish"
              module={state.moduleContext.module}
              onHomeClick={() => handleHomeClickOnCompleted(false)}
            />
          </ModulePageContextProvider>
        );
      }
      return (
        <ModulePageContextProvider value={modulePageContextValue}>
          <ProgramCompleteSlide />
        </ModulePageContextProvider>
      );
    }
    return (
      <ModulePageContextProvider value={modulePageContextValue}>
        <ModuleCompleteSlide
          module={state.moduleContext.module}
          onHomeClick={() => handleHomeClickOnCompleted()}
        />
      </ModulePageContextProvider>
    );
  }

  return (
    <ModulePageContextProvider value={modulePageContextValue}>
      <ModuleActivityContext.Provider
        value={{
          activities: draftActivities,
          updateActivity: handleActivityChange,
          updateVideoView: handleVideoView,
        }}
      >
        <ModuleContentSlide
          kind={currentSlide.kind}
          progress={
            moduleContext && currentSlide
              ? getModuleProgressSegments(moduleContext, currentSlide)
              : []
          }
          module={state.moduleContext.module}
          section={currentSlide.section}
          onNext={handleNext}
          onBack={handleBack}
          isReview={completedSlides.some((s) => s.slideId === currentSlide.id)}
        />
        {SHOW_MODULE_KINDS && (
          <ModuleKindDebugLabel>{currentSlide.kind}</ModuleKindDebugLabel>
        )}
      </ModuleActivityContext.Provider>
    </ModulePageContextProvider>
  );
};

const ModuleKindDebugLabel = styled("div")`
  position: fixed;
  top: 0.25rem;
  left: 0.5rem;
  color: #f00;
  z-index: 1000;
`;

function getModuleSlides(
  moduleContext: ParticipantModuleContext
): ModuleSlideWithSection[] {
  const { sections } = moduleContext.module;

  return sections.flatMap(
    (section) => section.slides.map((slide) => ({ ...slide, section })) // add section to slides
  );
}

async function saveUnsavedDraftActivities({
  participantId,
  moduleId,
  draftActivities,
}: {
  participantId: number;
  moduleId: number;
  draftActivities: DraftActivity<any>[];
}) {
  const unsavedActivities = draftActivities.filter(
    (activity) => activity.unsaved
  );

  if (unsavedActivities.length === 0) {
    return;
  }

  await Promise.all(
    unsavedActivities.map((activity) =>
      saveModuleActivity({
        moduleId,
        participantId,
        value: activity.value,
        kind: activity.kind,
      }).catch((error) =>
        console.error(`Failed to save activity ${activity.kind}`, error)
      )
    )
  );
}

async function saveUnsavedDraftSurveyResponses({
  participantId,
  surveyId,
  draftResponses,
}: {
  participantId: number;
  surveyId: number;
  draftResponses: DraftSurveyResponse[];
}) {
  const unsavedResponses = draftResponses.filter(
    (response) => response.unsaved
  );

  if (unsavedResponses.length === 0) {
    return;
  }

  await saveSurveyTakeResponses({
    participantId,
    surveyIdOrKind: surveyId,
    responses: draftResponses.map((response) => ({
      questionId: response.questionId,
      value: response.value,
    })),
  });
}

export function getModuleProgressSegments(
  moduleContext: State["moduleContext"],
  currentSlide?: ModuleSlideWithSection
) {
  const currentSectionIndex = currentSlide
    ? moduleContext.module.sections.findIndex((section) =>
        section.slides.some((slide) => slide.id === currentSlide.id)
      )
    : 0;

  // This shouldn't be possible
  if (currentSectionIndex === -1) {
    return [];
  }

  return moduleContext.module.sections.map((section, i) => {
    // Full bar
    if (i < currentSectionIndex) {
      return {
        current: 1,
        total: 1,
      };
    }
    // Empty bar
    if (i > currentSectionIndex) {
      return {
        current: 0,
        total: 1,
      };
    }
    // Partial bar
    const sectionSlideIndex = currentSlide
      ? section.slides.findIndex((slide) => slide.id === currentSlide.id)
      : 0;
    return {
      current: sectionSlideIndex + 1,
      total: section.slides.length + 1,
    };
  });
}

export default ParticipantModulePage;
