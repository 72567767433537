import React, { FC, useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import BribeRewardActivity, {
  EXPLANATION_DELAY,
} from "../../BribeRewardActivity";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BribeReward } from "shared/lib/interfaces/ModuleActivity";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.USING_INCENTIVES_BRIBE_VS_REWARD_2;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const UsingIncentivesBribeOrReward2: FC<Props> = (props) => {
  const { module, section, isReview, ...rest } = props;
  const setSavedValue = useActivity(activityKind)[1];
  const [bribeOrReward, setBribeOrReward] = useState<BribeReward | null>(null);
  const [showExplanation, setShowExplanation] = useState(false);

  const handleBribeOrRewardChange = useCallback(
    (value: BribeReward) => {
      setBribeOrReward(value);
      setSavedValue({ response: value });
    },
    [setSavedValue]
  );

  // Wait a moment after the user responds before showing the explanation.
  useEffect(() => {
    if (bribeOrReward === null) {
      setShowExplanation(false);
      return;
    }
    const timeout = setTimeout(() => {
      setShowExplanation(true);
    }, EXPLANATION_DELAY);

    return () => clearTimeout(timeout);
  }, [bribeOrReward]);

  return (
    <ActivitySlidePage
      {...rest}
      title={module.name}
      showNextButton={showExplanation || isReview}
      lightNextButton
      nextButtonText="Finish Activity"
      activityKind={activityKind}
      isReview={isReview}
    >
      <BribeRewardActivity
        isBribe={false}
        value={bribeOrReward}
        onChange={handleBribeOrRewardChange}
        showExplanation={showExplanation}
        situation={
          <>
            You are about to get on the phone and you say to your kids, “If you
            can play with your legos for 5 minutes while I am on the phone, you
            can have a cookie when I get off.”
          </>
        }
        explanation={
          <>
            This is a reward, because the cookie is given to the child after
            they are quiet for 5 minutes and is used as an incentive.
          </>
        }
      />
    </ActivitySlidePage>
  );
};

export default styled(UsingIncentivesBribeOrReward2)``;
