import React, { FC, useState, useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import {
  RulesAndConsequencesClearOrNotQuizAnswers,
  RulesAndConsequencesClearOrNotQuizValue,
} from "shared/lib/interfaces/ModuleActivity";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import useHelpfulUnhelpfulQuiz from "../../../hooks/useHelpfulUnhelpfulQuiz";
import ActivityBox from "../../ActivityBox";
import HelpfulUnhelpfulQuiz from "../../HelpfulUnhelpfulQuiz";
import HelpfulUnhelpfulQuizQuestion from "../../HelpfulUnhelpfulQuizQuestion";
import SlidePage, {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import clearImageSrc from "../../../images/clear.png";
import unclearImageSrc from "../../../images/unclear.png";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";

const activityKind =
  ModuleActivityKind.RULES_AND_CONSEQUENCES_CLEAR_OR_NOT_QUIZ;

const defaultValue: RulesAndConsequencesClearOrNotQuizValue = {
  seatBeltsNeedToBeOn: null,
  dontRunInHouse: null,
  useQuietVoice: null,
  bedtimeIsAt8: null,
  noYelling: null,
  wantToGoToBed: null,
};

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const RulesAndConsequencesSlide11: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);
  const valueWithDefaults = value || defaultValue;
  const helpfulWord = "Clear";
  const unhelpfulWord = "Unclear";

  const handleSlideNext = useCallback(() => {
    if (isReview) {
      onNext();
    } else {
      setShowSegmentOverlay(true);
    }
  }, [onNext, isReview]);

  const { onCheckboxChange } = useHelpfulUnhelpfulQuiz(value, setValue);

  const hideNext = useMemo(() => {
    if (!value) {
      return true;
    }
    const values = Object.values(value);
    return values.filter((v) => v !== null).length < 3;
  }, [value]);

  return (
    <SlidePage
      {...rest}
      title={module.name}
      nextButtonText="Finish Activity"
      onNext={handleSlideNext}
      lightNextButton
      light
      hideNextButton={hideNext && !isReview}
    >
      <ActivityBox icon="pointer">
        <Title>Your turn: clear or unclear rules?</Title>
        <HelpfulUnhelpfulQuiz
          title="Which of the following are clear rules that will set you up for success?"
          helpfulWord={helpfulWord}
          unhelpfulWord={unhelpfulWord}
          helpfulIcon={clearImageSrc}
          unhelpfulIcon={unclearImageSrc}
        >
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.seatBeltsNeedToBeOn}
            isHelpfulCorrect={
              RulesAndConsequencesClearOrNotQuizAnswers["seatBeltsNeedToBeOn"]
            }
            questionText="Seat belts need to be on in the car before we can go."
            feedback="This is a clear rule."
            onChange={onCheckboxChange("seatBeltsNeedToBeOn")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.dontRunInHouse}
            isHelpfulCorrect={
              RulesAndConsequencesClearOrNotQuizAnswers["dontRunInHouse"]
            }
            questionText="Don’t run in the house!"
            feedback="“Walk when you are in the house” would be a clear rule because it tells your child what you want them to do."
            onChange={onCheckboxChange("dontRunInHouse")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.useQuietVoice}
            isHelpfulCorrect={
              RulesAndConsequencesClearOrNotQuizAnswers["useQuietVoice"]
            }
            questionText="Use a quiet voice when your sister is napping."
            feedback="This is a clear rule."
            onChange={onCheckboxChange("useQuietVoice")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.bedtimeIsAt8}
            isHelpfulCorrect={
              RulesAndConsequencesClearOrNotQuizAnswers["bedtimeIsAt8"]
            }
            questionText="Bedtime is at 8:00."
            feedback="This is a clear rule."
            onChange={onCheckboxChange("bedtimeIsAt8")}
          />

          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.noYelling}
            isHelpfulCorrect={
              RulesAndConsequencesClearOrNotQuizAnswers["noYelling"]
            }
            questionText="No yelling in the house."
            feedback="“Use a quiet voice when inside” would be a clear rule because it tells your child what you want them to do."
            onChange={onCheckboxChange("noYelling")}
          />
          <HelpfulUnhelpfulQuizQuestion
            helpfulWord={helpfulWord}
            unhelpfulWord={unhelpfulWord}
            value={valueWithDefaults.wantToGoToBed}
            isHelpfulCorrect={
              RulesAndConsequencesClearOrNotQuizAnswers["wantToGoToBed"]
            }
            questionText="Do you want to go to bed now?"
            feedback="This is a question, not a clear rule."
            onChange={onCheckboxChange("wantToGoToBed")}
          />
        </HelpfulUnhelpfulQuiz>
      </ActivityBox>

      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Awesome!"
      />
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide11)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const Title = styled("h3")`
  text-align: center;
  margin: 0 0 2.5rem 0;
`;
