import React, { FC, useState, useCallback, FormEvent } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { SelfCareActivityChecklistValue } from "shared/lib/interfaces/ModuleActivity";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageFooter,
} from "../../SlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import Card from "../../../../common/components/Card";
import ModuleCheckBox from "../../ModuleCheckBox";
import ActivityBox from "../../ActivityBox";
import { YELLOW } from "../../../../colors";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.SELF_CARE_ACTIVITY_CHECKLIST;

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const SelfCarePlanSlide: FC<Props> = (props) => {
  const { module, onNext, section, ...rest } = props;
  const [activityValue, setActivityValue] = useActivity(activityKind);
  const [showSectionOverlay, setShowSectionOverlay] = useState(false);

  const handleSlideNext = useCallback(() => {
    setShowSectionOverlay(true);
  }, []);

  return (
    <ActivitySlidePage
      {...rest}
      light
      lightWhite
      lightNextButton
      title={module.name}
      onNext={handleSlideNext}
      activityKind={activityKind}
    >
      <ActivityBox
        icon="checkbox"
        instructions="Your turn: Start small. Think of one fun thing you could do this week."
      >
        <SelfCareActivityChecklistActivity
          value={activityValue}
          onChange={setActivityValue}
        />
      </ActivityBox>
      <ModuleSegmentCompletionOverlay
        open={showSectionOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
      />
    </ActivitySlidePage>
  );
};

export const SelfCareActivityChecklistActivity = styled(
  ({
    onChange,
    value = {
      exercise: false,
      bath: false,
      journal: false,
      nap: false,
      writeGratefulList: false,
      movie: false,
      practiceRelaxation: false,
      talkToFriend: false,
    },
    ...rest
  }: {
    value?: SelfCareActivityChecklistValue;
    onChange(value: SelfCareActivityChecklistValue): unknown;
  }) => {
    const handleToggleKey = (key: keyof SelfCareActivityChecklistValue) => (
      event: FormEvent<HTMLInputElement>
    ) => onChange({ ...value, [key]: event.currentTarget.checked });

    return (
      <Card {...rest}>
        <ModuleCheckBox
          checked={value.exercise}
          onChange={handleToggleKey("exercise")}
          label="Exercise"
        />
        <ModuleCheckBox
          checked={value.bath}
          onChange={handleToggleKey("bath")}
          label="Take a bath"
        />
        <ModuleCheckBox
          checked={value.journal}
          onChange={handleToggleKey("journal")}
          label="Journal"
        />
        <ModuleCheckBox
          checked={value.nap}
          onChange={handleToggleKey("nap")}
          label="Take a nap"
        />
        <ModuleCheckBox
          checked={value.writeGratefulList}
          onChange={handleToggleKey("writeGratefulList")}
          label="List what you’re grateful for"
        />
        <ModuleCheckBox
          checked={value.movie}
          onChange={handleToggleKey("movie")}
          label="Watch a movie"
        />
        <ModuleCheckBox
          checked={value.practiceRelaxation}
          onChange={handleToggleKey("practiceRelaxation")}
          label="Practice relaxation"
        />
        <ModuleCheckBox
          checked={value.talkToFriend}
          onChange={handleToggleKey("talkToFriend")}
          label="Talk to a friend"
        />
      </Card>
    );
  }
)``;

export default styled(SelfCarePlanSlide)`
  ${ActivityBox} {
    flex: 1;
  }

  ${SlidePageFooter} {
    background-color: ${YELLOW};
  }
`;
