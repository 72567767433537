import styled from "styled-components/macro";

const FormSuccessText = styled("div")`
  color: #0d0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 2rem;
`;

export default FormSuccessText;
