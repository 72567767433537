import React, { FC, useCallback, ReactNode } from "react";
import styled from "styled-components/macro";
import { DARKEST_BLUE } from "../../colors";
import Column from "@emberex/components/lib/Column";
import {
  HelpfulUnhelpfulQuizRightRow,
  HelpfulUnhelpfulQuizRightColumn,
  HelpfulUnhelpfulQuizRightLabel,
} from "./HelpfulUnhelpfulQuiz";
import Row from "@emberex/components/lib/Row";
import CorrectedRadio from "./CorrectedRadio";
import { ThumbsUpIcon, ExclamationIcon } from "../../common/components/Icons";

interface Props {
  questionText: ReactNode;
  value: boolean | null;
  isHelpfulCorrect: boolean;
  feedback?: ReactNode;
  helpfulWord?: string;
  unhelpfulWord?: string;
  onChange(value: boolean): any;
}

const correctIncorrectColor = (correct: boolean) => correct ? '#4DACBE' : '#FF6762';

const HelpfulUnhelpfulQuizQuestion: FC<Props> = (props) => {
  const {
    questionText,
    value,
    isHelpfulCorrect,
    feedback,
    onChange,
    helpfulWord = "Helpful",
    unhelpfulWord = "Unhelpful",
    ...rest
  } = props;

  const handleHelpfulClick = useCallback(() => onChange(true), [onChange]);
  const handleUnhelpfulClick = useCallback(() => onChange(false), [onChange]);

  return (
    <Column {...rest}>
      <QuestionRow>
        <QuestionText>{questionText}</QuestionText>
        <HelpfulUnhelpfulQuizRightRow>
          <HelpfulUnhelpfulQuizRightColumn onClick={handleHelpfulClick}>
            <HelpfulUnhelpfulQuizRightLabel>
              {helpfulWord}
            </HelpfulUnhelpfulQuizRightLabel>
            <CorrectedRadio
              checked={value === true}
              checkedCorrect={isHelpfulCorrect}
              inputType="checkbox"
              checkedColor={correctIncorrectColor(value === true && isHelpfulCorrect)}
            />
          </HelpfulUnhelpfulQuizRightColumn>
          <HelpfulUnhelpfulQuizRightColumn onClick={handleUnhelpfulClick}>
            <HelpfulUnhelpfulQuizRightLabel>
              {unhelpfulWord}
            </HelpfulUnhelpfulQuizRightLabel>
            <CorrectedRadio
              checked={value === false}
              checkedCorrect={!isHelpfulCorrect}
              inputType="checkbox"
              checkedColor={correctIncorrectColor(value === false && !isHelpfulCorrect)}
            />
          </HelpfulUnhelpfulQuizRightColumn>
        </HelpfulUnhelpfulQuizRightRow>
      </QuestionRow>
      {value !== null && feedback && (
        <FeedbackBox>
          <FeedbackIconCircle backgroundColor={correctIncorrectColor(value === isHelpfulCorrect)}>
            {value === isHelpfulCorrect ? (
              <ThumbsUpIcon />
            ) : (
              <ExclamationIcon />
            )}
          </FeedbackIconCircle>
          {feedback}
        </FeedbackBox>
      )}
    </Column>
  );
};

export default styled(HelpfulUnhelpfulQuizQuestion)`
  padding: 0.7rem 0 0.7rem 0;
  border-top: 1px solid #edeaeb;

  ${HelpfulUnhelpfulQuizRightColumn} {
    cursor: pointer;
  }
`;

const QuestionText = styled("div")`
  color: ${DARKEST_BLUE};
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.1rem;
  padding-top: 0.1rem;
  flex: 1 1 auto;
`;

const QuestionRow = styled(Row)`
  flex-shrink: 0;
`;

const FeedbackBox = styled(Row)`
  border-radius: 8px;
  background-color: #9dedd6;
  padding: 0.5rem;
  color: ${DARKEST_BLUE};
  font-size: 0.6rem;
  font-weight: bold;
  line-height: 0.8rem;
  margin-top: 0.75rem;
  flex-shrink: 0;
  align-items: center;
`;

const FeedbackIconCircle = styled("div")<{backgroundColor: string}>`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 0.5rem;
  background-color: ${props => props.backgroundColor};
  overflow: hidden;

  > svg {
    color: white;
    width: 100%;
    height: 100%;
    transform: scale(0.5, 0.5);
  }
`;
