import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import TextButton from "@emberex/components/lib/TextButton";
import PlusMinusIcon from "../../common/components/PlusMinusIcon";

interface Props {
  header: ReactNode;
  open: boolean;
  onClick(): void;
}

const PlusExpandable: FC<Props> = (props) => {
  const { header, children, open, onClick, ...rest } = props;

  return (
    <div {...rest}>
      <Header onClick={onClick}>
        <PlusMinusIcon expanded={open} />
        {header}
      </Header>
      {open && <Content>{children}</Content>}
    </div>
  );
};

export default styled(PlusExpandable)``;

const Header = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
`;

const Content = styled(Column)`
  padding-top: 1.25rem;
`;
