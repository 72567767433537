import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ExampleSection from "../../ExampleSection";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const UsingIncentivesIncentiveDefinition: FC<Props> = (props) => {
  const { module, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <Heading>What is an incentive?</Heading>
        <p>
          An incentive is something that you promise to give your child that
          will motivate or encourage them to do something in the future.
        </p>
      </section>
      <section>
        <ExampleSection>
          If you put your toys away, you can have extra time reading with mom.
        </ExampleSection>
      </section>
    </SlidePage>
  );
};

export default styled(UsingIncentivesIncentiveDefinition)``;

const Heading = styled("h4")`
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: bold;
`;
