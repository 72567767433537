import styled, { keyframes } from "styled-components/macro";

const slideAnimation = keyframes`
	 {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0;
    }
  }
`;

const AnimatedOverlay = styled("div")<{ open: boolean }>`
  background-color: #ddd;
  animation-name: ${slideAnimation};
  animation-duration: 0.8s;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: ${(props) => (props.open ? "" : "none")};
`;

export default AnimatedOverlay;
