import React, { FC, useCallback, useState, useMemo } from "react";
import {
  SurveyWithQuestions,
  SurveyWithQuestionsAndResults,
} from "shared/lib/interfaces/Survey";
import SurveyResponseValue from "shared/lib/interfaces/SurveyResponseValue";
import SurveyQuestion from "shared/lib/interfaces/SurveyQuestion";
import SurveyTake from "shared/lib/interfaces/SurveyTake";
import Module from "shared/lib/interfaces/Module";
import SurveyQuestionPage from "./SurveyQuestionPage";
import SurveyResultsPage from "./SurveyResultsPage";
import SurveyQuestionIntroPage from "./SurveyQuestionIntroPage";
import SurveyResultsIntroPage from "./SurveyResultsIntroPage";
import { SlidePageProgress } from "../../modules/components/SlidePage";
import { useHistory } from "react-router";

export interface SurveyTakePageChangeEvent {
  takeId: number;
  questionId: number;
  value: SurveyResponseValue;
}

interface Props {
  survey: SurveyWithQuestionsAndResults;
  surveyTake: SurveyTake;
  showIntroPages: boolean;
  responses: Array<{
    questionId: number;
    value: SurveyResponseValue | null;
  }>;
  module?: Pick<Module, "name" | "icon" | "defaultIndex">;
  moduleProgress: SlidePageProgress;
  hideNav?: boolean;
  initialSlideIndex?: number;
  readonly?: boolean;
  submitButtonText?: string;
  onAnswerChange(event: SurveyTakePageChangeEvent): unknown;
  onComplete(): unknown;
}

enum SlideKind {
  QUESTION_INTRO,
  QUESTION,
  RESULTS_INTRO,
  RESULTS,
}

interface QuestionSlide {
  id: number;
  kind: SlideKind.QUESTION;
  questionIndex: number;
  question: SurveyQuestion;
}

interface ResultsSlide {
  id: number;
  kind: SlideKind.RESULTS;
}

interface IntroSlide {
  id: number;
  kind: SlideKind.QUESTION_INTRO | SlideKind.RESULTS_INTRO;
}

type Slide = QuestionSlide | ResultsSlide | IntroSlide;

const SurveyTakePage: FC<Props> = (props) => {
  const history = useHistory();
  const {
    survey,
    surveyTake,
    responses,
    module,
    moduleProgress,
    showIntroPages,
    hideNav = false,
    readonly,
    initialSlideIndex,
    submitButtonText,
    onAnswerChange,
    onComplete,
    ...rest
  } = props;
  const slides = useMemo(
    () => getSlidesFromSurvey(survey, showIntroPages),
    [survey, showIntroPages]
  );
  const slideCount = slides.length;
  const [slideIndex, setSlideIndex] = useState(
    () => initialSlideIndex ?? getInitialSlideIndex(slides, responses)
  );
  const currentSlide = slides[slideIndex];
  const currentSlideValue = useMemo(() => {
    if (currentSlide.kind === SlideKind.QUESTION) {
      return (
        responses.find(
          (response) => response.questionId === currentSlide.question.id
        )?.value || null
      );
    }
    return null;
  }, [currentSlide, responses]);

  const handleValueChange = useCallback(
    (value: SurveyResponseValue) => {
      if (currentSlide.kind === SlideKind.QUESTION) {
        onAnswerChange({
          takeId: surveyTake.id,
          questionId: currentSlide.question.id,
          value,
        });
      }
    },
    [onAnswerChange, currentSlide, surveyTake.id]
  );

  const handleNext = useCallback(() => {
    setSlideIndex((slideIndex) => {
      if (slideIndex < slideCount - 1) {
        return slideIndex + 1;
      }
      return slideIndex;
    });
  }, [slideCount]);

  const handleBack = useCallback(() => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
    } else {
      history.push("/");
    }
  }, [slideIndex, history]);

  if (!currentSlide) {
    // No questions (this should only happen when the database isn't seeded properly)
    return null;
  }

  switch (currentSlide.kind) {
    case SlideKind.QUESTION:
      return (
        <SurveyQuestionPage
          {...rest}
          module={module}
          survey={survey}
          question={currentSlide.question}
          questionIndex={currentSlide.questionIndex}
          questionCount={survey.questions.length}
          value={currentSlideValue}
          hideNav={hideNav}
          readonly={readonly}
          onChange={handleValueChange}
          onNext={handleNext}
          onBack={handleBack}
        />
      );
    case SlideKind.RESULTS:
      return (
        <SurveyResultsPage
          {...rest}
          survey={survey}
          responses={responses}
          module={module}
          hideNav={hideNav}
          submitButtonText={submitButtonText}
          onNext={onComplete}
        />
      );
    case SlideKind.QUESTION_INTRO:
      return (
        <SurveyQuestionIntroPage
          {...rest}
          title={module?.name ?? "Survey"}
          progress={moduleProgress}
          onNext={handleNext}
          onBack={handleBack}
        />
      );
    case SlideKind.RESULTS_INTRO:
      return (
        <SurveyResultsIntroPage
          {...rest}
          title={module?.name ?? "Survey"}
          survey={survey}
          responses={responses}
          progress={moduleProgress}
          onNext={handleNext}
          onBack={handleBack}
        />
      );
  }
};

function getSlidesFromSurvey(
  survey: SurveyWithQuestions,
  includeIntroPages: boolean
): Slide[] {
  const slides: Slide[] = [];
  let idCounter = 1;

  if (includeIntroPages) {
    slides.push({ id: idCounter++, kind: SlideKind.QUESTION_INTRO });
  }

  slides.push(
    ...survey.questions.map((question, questionIndex) => ({
      id: idCounter++,
      kind: SlideKind.QUESTION,
      question,
      questionIndex,
    }))
  );

  if (includeIntroPages) {
    slides.push({ id: idCounter++, kind: SlideKind.RESULTS_INTRO });
  }

  slides.push({ id: idCounter++, kind: SlideKind.RESULTS });

  return slides;
}

function getInitialSlideIndex(
  slides: Slide[],
  responses: Props["responses"]
): number {
  // Find the first question slide with no response
  const questionIndex = slides.findIndex(
    (slide) => !getSlideResponseValue(slide, responses)
  );

  if (questionIndex !== -1) {
    return questionIndex;
  }

  // If all the questions are answered, show the last slide which is always the results slide
  return slides.length - 1;
}

/**
 * Get the user's response for a specific slide.
 * Returns `null` if the user hasn't responded.
 */
function getSlideResponseValue(
  slide: Slide,
  responses: Props["responses"]
): SurveyResponseValue | null {
  if (slide.kind === SlideKind.QUESTION) {
    return (
      responses.find((response) => response.questionId === slide.question.id)
        ?.value || null
    );
  }

  return null;
}

export default SurveyTakePage;
