import React, { FC, FormEventHandler } from "react";
import styled from "styled-components/macro";
import Mood from "shared/lib/enums/Mood";
import MoodImage from "./MoodImage";

interface Props {
  mood: Mood;
  name?: string;
  dark?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: FormEventHandler<HTMLInputElement>;
}

const MoodRadio: FC<Props> = (props) => {
  const { mood, name, dark, checked, disabled, onChange, ...rest } = props;

  return (
    <Root {...rest} disabled={disabled}>
      <input
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={mood}
      />
      <Image mood={mood} variant={dark ? "dark" : "light"} />
      <ActiveImage
        mood={mood}
        variant={dark ? "active_dark" : "active_light"}
      />
    </Root>
  );
};

const Image = styled(MoodImage)``;

const ActiveImage = styled(MoodImage)``;

const Root = styled("label")<{ disabled?: boolean }>`
  flex-shrink: 0;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  > input {
    display: none;
  }

  ${Image} {
    display: block;
  }

  ${ActiveImage} {
    display: none;
  }

  input:checked ~ ${Image} {
    display: none;
  }

  input:checked ~ ${ActiveImage} {
    display: block;
  }
`;

export default styled(MoodRadio)<Props>``;
