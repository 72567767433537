import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { PlanValue } from "shared/lib/interfaces/ModuleActivity";
import SuggestionTextArea from "./SuggestionTextArea";
import Card from "../../common/components/Card";

export const defaultPlan: PlanValue = {
  response1: "",
  response2: "",
  response3: "",
};
const planInputPlaceholder =
  "Type in your own ideas or use the button to choose from a list of options.";

interface Props {
  suggestions: string[];
  value: PlanValue | undefined | null;
  onChange: (plan: PlanValue) => any;
  suggestionTitle?: string;
}

const PlanActivityCard: FC<Props> = ({
  value,
  onChange,
  suggestions,
  suggestionTitle,
  ...rest
}) => {
  const plan = value || defaultPlan;

  const handleResponse1Change = useCallback(
    (response1: string) => onChange({ ...plan, response1 }),
    [onChange, plan]
  );

  const handleResponse2Change = useCallback(
    (response2: string) => onChange({ ...plan, response2 }),
    [onChange, plan]
  );

  const handleResponse3Change = useCallback(
    (response3: string) => onChange({ ...plan, response3 }),
    [onChange, plan]
  );

  const handleSuggestionsPicked = useCallback(
    (values: string[], entryOrder: (keyof PlanValue)[]) => {
      const [response1 = null, response2 = null, response3 = null] = values;
      const [entry1, entry2, entry3] = entryOrder;
      onChange({
        ...plan,
        [entry1]: response1 ?? plan[entry1] ?? "",
        [entry2]: response2 ?? plan[entry2] ?? "",
        [entry3]: response3 ?? plan[entry3] ?? "",
      });
    },
    [plan, onChange]
  );

  return (
    <Card {...rest}>
      <SuggestionTextArea
        placeholder={planInputPlaceholder}
        suggestionTitle={suggestionTitle}
        suggestions={suggestions}
        value={plan.response1}
        onChange={handleResponse1Change}
        onSuggestionsPicked={(values) =>
          handleSuggestionsPicked(values, [
            "response1",
            "response2",
            "response3",
          ])
        }
        maxSuggestionsPicked={3}
      />
      <SuggestionTextArea
        placeholder={planInputPlaceholder}
        suggestionTitle={suggestionTitle}
        suggestions={suggestions}
        value={plan.response2}
        onChange={handleResponse2Change}
        onSuggestionsPicked={(values) =>
          handleSuggestionsPicked(values, [
            "response2",
            "response3",
            "response1",
          ])
        }
        maxSuggestionsPicked={3}
      />
      <SuggestionTextArea
        placeholder={planInputPlaceholder}
        suggestionTitle={suggestionTitle}
        suggestions={suggestions}
        value={plan.response3}
        onChange={handleResponse3Change}
        onSuggestionsPicked={(values) =>
          handleSuggestionsPicked(values, [
            "response3",
            "response1",
            "response2",
          ])
        }
        maxSuggestionsPicked={3}
      />
    </Card>
  );
};

export default styled(PlanActivityCard)``;
