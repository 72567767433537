import apiAxios from "../../apiAxios";

interface Props {
  resetToken: string;
  password: string;
}

export default async function submitPasswordReset(props: Props): Promise<void> {
  await apiAxios.post("/password-reset/submit", props);
}
