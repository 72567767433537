import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Overlay from "@emberex/components/lib/Overlay";
import Row from "@emberex/components/lib/Row";
import VideoId from "shared/lib/enums/VideoId";
import VideoActivity from "../../modules/components/VideoActivity";
import bearManSrc from "../../modules/images/bear_man.png";
import waveBackgroundCss from "../../common/utils/waveBackgroundCss";
import Blurb from "../../common/components/Blurb";
import ActivityCompletionOverlay from "../../modules/components/ActivityCompletionOverlay";
import { BearIcons } from "../../common/components/BearIcon";

export interface WelcomeOverlayProps {
  onClose(): unknown;
}

const WelcomeOverlay: FC<WelcomeOverlayProps> = ({ onClose, ...rest }) => {
  const [open, setOpen] = useState(false);

  const handleVideoEnd = useCallback(() => setOpen(true), []);

  return (
    <Overlay {...rest} inRoot={false}>
      <Title>Welcome!</Title>
      <VideoActivity
        titleTextAlign="left"
        videoId={VideoId.WELCOME}
        onEnded={handleVideoEnd}
      />
      <BearRow>
        <BearManContainer>
          <BearMan />
        </BearManContainer>
        <BearBlurb>
          I'm so glad
          <br />
          you're here.
        </BearBlurb>
      </BearRow>
      <ActivityCompletionOverlay
        open={open}
        activityKind="WELCOME_VIDEO"
        onNext={onClose}
        bearIcon={BearIcons.GIRL_THUMBS_UP}
      />
    </Overlay>
  );
};

export default styled(WelcomeOverlay)`
  ${waveBackgroundCss}
  padding-top: 3rem;
  justify-content: space-between;
`;

const Title = styled("h1")`
  color: #4dacbe;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500;
`;

const BearRow = styled(Row)`
  height: 200px;
  width: 100%;
  justify-content: center;
  @media (min-width: 500px) {
    height: 300px;
  }
`;

const BearManContainer = styled("div")`
  position: relative;
  width: 50%;
`;

const BearMan = styled("img").attrs({ src: bearManSrc, alt: "Bear Character" })`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90%;
  height: auto;
`;

const BearBlurb = styled(Blurb)`
  padding-left: 1.5rem;
  width: 50%;
  height: 100%;
  text-align: center;
  color: #0e3750;
  font-size: 1rem;
  font-weight: bold;
  width: 170px;
  @media (min-width: 500px) {
    width: 200px;
  }
`;
