import apiAxios from "../../apiAxios";
import Participant from "shared/lib/interfaces/Participant";
import uploadFile from "../../media/api/uploadFile";
import { compressImage } from "../../common/utils/compressImage";

interface Props {
  email?: string;
  externalId?: string | null;
  phoneNumber?: string | null;
  firstName?: string;
  lastName?: string;
  password?: string;
  coachId?: number;
  withdrawn?: boolean;
  profilePicture?: File | null;
  timezone?: string;
}

export default async function updateParticipant(
  participantId: number,
  { profilePicture: profilePictureFile, ...rest }: Props
): Promise<Participant> {
  let profilePicture: string | null | undefined = undefined;

  // Upload the profile picture if one was provided
  if (profilePictureFile instanceof File) {
    profilePicture = await uploadFile(
      await compressImage(profilePictureFile, {
        maxWidth: 300,
        maxHeight: 300,
      }),
      profilePictureFile.name
    );
  } else if (profilePictureFile === null) {
    profilePicture = null;
  }

  const response = await apiAxios.put(`/participants/${participantId}`, {
    ...rest,
    profilePicture,
  });

  return response.data;
}
