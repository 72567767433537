import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import RuleCloud from "../../RuleCloud";
import SlidePage, {
  SlidePageProgress,
  SlidePageProps,
  SlidePageContent,
  SlidePageFooter,
} from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide8: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name} light>
      <section>
        <p>But to your child, rules might often feel like this.</p>
      </section>
      <RuleCloud />
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide8)`
  ${SlidePageContent} {
    flex: 1;
    background-color: white;
    min-height: 525px;
  }

  ${SlidePageFooter} {
    background-color: #0e3750;
  }
  
  section {
    text-align: center;
  }
`;

