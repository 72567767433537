import { useEffect } from "react";
import { SECOND } from "shared/lib/constants/time";
import updateModuleTimeElapsed from "../api/updateModuleTimeElapsed";

const TICK_INTERVAL = SECOND * 5;

/**
 * Update the elapsed time in a module or module section every 5 seconds.
 */
export default function useModuleTimer(
  moduleId: number | null,
  sectionId: number | null = null,
  enabled = true
): void {
  useEffect(() => {
    if (!enabled) {
      return;
    }
    let cancelled = false;
    let lastTick = Date.now();
    let promise = Promise.resolve();

    const interval = setInterval(() => {
      if (cancelled) {
        return;
      }
      const now = Date.now();
      const elapsed = now - lastTick;
      lastTick = now;

      // Queue a request (should run immediately unless the internet connection is really slow)
      promise = promise
        .then(() =>
          cancelled || moduleId === null
            ? undefined
            : updateModuleTimeElapsed({
                moduleId,
                sectionId,
                elapsed,
              })
        )
        .catch((error) =>
          console.error(`Failed to update elapsed time in module`, error)
        );
    }, TICK_INTERVAL);

    return () => {
      clearInterval(interval);
      cancelled = true;
    };
  }, [moduleId, sectionId, enabled]);
}
