import { MINUTE, SECOND } from "shared/lib/constants/time";

function getEnv(key: string): string | undefined {
  return process.env[`REACT_APP_${key}`];
}

export const DEFAULT_LOGIN_USERNAME = getEnv("DEFAULT_LOGIN_USERNAME");
export const DEFAULT_LOGIN_PASSWORD = getEnv("DEFAULT_LOGIN_PASSWORD");

/**
 * Whether or not to display the module slide kind in the upper left corner.
 * For debugging/development.
 */
export const SHOW_MODULE_KINDS = getEnv("SHOW_MODULE_KINDS") === "true";

/**
 * How long to wait while the user idles before automatically logging them out.
 */
export const IDLE_TIMEOUT = +(getEnv("IDLE_TIMEOUT") ?? MINUTE * 10);

/**
 * How long to wait while the user idles before showing the warning that they're
 * about to be logged out.
 * Defaults to 30 seconds before the idle timout.
 */
export const IDLE_WARNING_TIMEOUT = +(
  getEnv("IDLE_WARNING_TIMEOUT") ?? IDLE_TIMEOUT - SECOND * 30
);

/**
 * Whether or not to upload files directly to the server rather than to minio/s3 using signed URLs.
 */
export const USE_LOCAL_FILE_UPLOAD =
  process.env.REACT_APP_USE_LOCAL_FILE_UPLOAD === "true";
