import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import maxOf from "shared/lib/utils/maxOf";
import ParticipantNav from "../components/ParticipantNav";
import { DARK_BLUE, DARKEST_BLUE } from "../../colors";
import ParticipantInfoCircle from "../components/ParticipantInfoCircle";
import { FcuLogoCompact } from "../../common/components/FcuLogo";
import { ChevronRightIcon } from "../../common/components/Icons";
import MoodInput from "../../surveys/components/MoodInput";
import { useParticipantContext } from "../contexts/ParticipantContext";
import Link from "../../common/components/Link";
import ParticipantModuleList from "../../modules/components/ParticipantModuleList";
import WelcomeBackOverlay from "../../common/components/WelcomeBackOverlay";
import WelcomeOverlay from "../components/WelcomeOverlay";
import { useSearchParamBooleanState } from "../../common/hooks/useSearchParamState";

const ParticipantModuleListPage: FC = (props) => {
  const history = useHistory();
  const {
    loading,
    user,
    points,
    lastMood,
    slideViews,
    moduleContexts,
    showWelcomeOverlay,
    showWelcomeBackOverlay,
    refreshParticipantContext,
    setShowWelcomeOverlay,
    setShowWelcomeBackOverlay,
    changeMood,
  } = useParticipantContext();
  const [welcomeBack, setWelcomeBack] = useSearchParamBooleanState(
    "welcomeBack"
  );

  useEffect(() => {
    if (welcomeBack && !!slideViews.length) {
      setShowWelcomeBackOverlay(true);
    }
  }, [welcomeBack, slideViews, setShowWelcomeBackOverlay]);
  /**
   * When the page first mounts, refresh the participant info if it's not already loading.
   */
  useAsyncEffect(async () => {
    if (!loading) {
      refreshParticipantContext();
    }
  }, [refreshParticipantContext]);

  if (showWelcomeOverlay) {
    return (
      <Page {...props}>
        <WelcomeOverlay onClose={() => setShowWelcomeOverlay(false)} />
      </Page>
    );
  }

  return (
    <Page {...props}>
      {showWelcomeBackOverlay && (
        <WelcomeBackOverlay
          onContinue={() => {
            setShowWelcomeBackOverlay(false);

            const mostRecentSlideView = maxOf(slideViews, (slideView) =>
              new Date(slideView.lastViewedAt).getTime()
            );

            const moduleContext = mostRecentSlideView
              ? moduleContexts.find(
                  ({ module }) => module.id === mostRecentSlideView.moduleId
                )
              : null;

            if (!moduleContext || !mostRecentSlideView) {
              history.push(`/module/${moduleContexts[0].module.id}`);
              return;
            }

            const moduleSlides = moduleContext.module.sections.flatMap(
              (section) => section.slides
            );

            const moduleSlideIndex = moduleSlides.findIndex(
              (slide) => slide.id === mostRecentSlideView.slideId
            );
            const moduleIndex = moduleContexts.indexOf(moduleContext);
            const isLastSlide = moduleSlideIndex === moduleSlides.length - 1;
            const isLastModule = moduleIndex === moduleContexts.length - 1;

            // Last viewed slide was the last slide of a module other than the last module,
            // so we should jump to the next module instead of back to the last slide.
            if (isLastSlide && !isLastModule) {
              const nextModuleNumber = moduleIndex + 2;
              history.push(`/module/${nextModuleNumber}`);
            } else {
              const moduleNumber = moduleIndex + 1;
              history.push(
                moduleSlideIndex === -1
                  ? `/module/${moduleNumber}`
                  : `/module/${moduleNumber}/${moduleSlideIndex + 1}`
              );
            }
          }}
          onClose={() => {
            setWelcomeBack(null);
            setShowWelcomeBackOverlay(false);
          }}
        />
      )}
      <ParticipantNav />
      <Header>
        <FcuLogoCompact />
        <ParticipantInfoCircle participant={user} points={points} />
      </Header>
      <Content>
        <PlanButtonRow>
          <MyPlanLink to="/plan">
            My Plan <ChevronRightIcon />
          </MyPlanLink>
        </PlanButtonRow>
        <ParticipantModuleList />
        <Footer>
          <MoodRow>
            <MoodInput
              dark
              label="How are you doing today?"
              value={lastMood}
              onChange={changeMood}
            />
          </MoodRow>
          <TrademarkText>
            Family Check-up® is a Registered Trademark
            <br />
            of the University of Oregon
          </TrademarkText>
        </Footer>
      </Content>
    </Page>
  );
};

export default styled(ParticipantModuleListPage)`
  background-color: white;
`;

const Header = styled(Row)`
  height: 60px;
  width: 100%;
  background-color: ${DARK_BLUE};
  justify-content: center;
  margin-bottom: 50px;

  > ${FcuLogoCompact} {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
`;

const Content = styled(Column)`
  padding: 0 0.6rem 0 0.6rem;
  flex: 1;
`;

const Footer = styled(Column)`
  flex: 1;
  justify-content: center;
`;

const PlanButtonRow = styled(Row)`
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const MyPlanLink = styled(Link)`
  width: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  font-weight: bold;

  > svg {
    color: #ff595b;
  }
`;

const MoodRow = styled(Row)`
  justify-content: center;
  margin-top: 1rem;
  flex-shrink: 0;
`;

const TrademarkText = styled("p")`
  color: #0b4568;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  margin-top: 2rem;
  flex-shrink: 0;
`;
