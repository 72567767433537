import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import Survey from "shared/lib/interfaces/Survey";
import Module from "shared/lib/interfaces/Module";
import ModuleIcon from "../../common/components/ModuleIcon";
import { LIGHT_BLUE } from "../../colors";
import ParticipantNav from "../../participant/components/ParticipantNav";

interface Props {
  survey: Pick<Survey, "name">;
  module?: Pick<Module, "icon">;
  label?: ReactNode;
  hideNav?: boolean;
}

const SurveyPageHeader: FC<Props> = (props) => {
  const { survey, module, label, hideNav, ...rest } = props;

  return (
    <Column {...rest}>
      {hideNav ? null : <ParticipantNav />}
      <ModuleIconContainer>
        {module && <ModuleIcon icon={module.icon} />}
      </ModuleIconContainer>
      <SurveyName>{survey.name}</SurveyName>
      <SurveyLabel>{label}</SurveyLabel>
    </Column>
  );
};

export default styled(SurveyPageHeader)`
  align-items: center;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  background-color: white;
  color: ${LIGHT_BLUE};
  flex-shrink: 0;
`;

const ModuleIconContainer = styled(Column)`
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-bottom: 14px;
`;

const SurveyName = styled("h1")`
  font-size: 1.5rem;
  font-weight: 300;
`;

const SurveyLabel = styled("h2")`
  font-size: 1.25rem;
  font-weight: 700;
  min-height: 25px;
`;
