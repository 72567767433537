import { FC, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import ParticipantVisit from "shared/lib/interfaces/ParticipantVisit";
import createParticipantVisit from "../api/createParticipantVisit";
import tickParticipantVisit from "../api/tickParticipantVisit";

const TICK_INTERVAL = 30000; // 30 seconds

const ParticipantVisitTracker: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const [currentVisit, setCurrentVisit] = useState<ParticipantVisit | null>(
    null
  );
  // Used to reference the previous visit when creating a new visit without causing an effect loop
  const currentVisitRef = useRef<ParticipantVisit | null>(null);
  currentVisitRef.current = currentVisit;

  /**
   * Create a new visit entry whenever the URL path changes.
   */
  useAsyncEffect(
    async (isCancelled) => {
      const visit = await createParticipantVisit(
        pathname,
        currentVisitRef.current?.id ?? null
      );
      if (!isCancelled()) {
        setCurrentVisit(visit);
      }
    },
    [pathname]
  );

  /**
   * Tick the visit duration on an interval
   */
  useEffect(() => {
    if (!currentVisit) {
      return;
    }

    const interval = setInterval(() => {
      tickParticipantVisit(currentVisit.id).catch((error) =>
        console.error(`Failed to update visit duration`, error)
      );
    }, TICK_INTERVAL);

    return () => clearInterval(interval);
  }, [currentVisit]);

  return null;
};

export default ParticipantVisitTracker;
