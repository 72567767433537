import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Card from "../../common/components/Card";
import WideCheckCircle from "../../common/components/WideCheckCircle";

interface Props {
  selectedChoiceId: string | null;
  question: ReactNode;
  choices: ReadonlyArray<{
    id: string;
    label: ReactNode;
  }>;
  disabled?: boolean;
  onChange(choiceId: string | null): unknown;
}

const MultipleChoiceQuestionCard: FC<Props> = (props) => {
  const {
    onChange,
    question,
    choices,
    selectedChoiceId,
    disabled,
    ...rest
  } = props;

  return (
    <Card {...rest}>
      <QuestionText>{question}</QuestionText>
      <ChoiceList>
        {choices.map((choice) => (
          <li key={choice.id}>
            <WideCheckCircle
              checked={selectedChoiceId === choice.id}
              label={choice.label}
              disabled={disabled}
              onChange={(checked) => {
                if (checked) {
                  onChange(choice.id);
                } else if (choice.id === selectedChoiceId) {
                  onChange(null);
                }
              }}
            />
          </li>
        ))}
      </ChoiceList>
    </Card>
  );
};

export default styled(MultipleChoiceQuestionCard)`
  color: #0e3750;
`;

const QuestionText = styled("h3")`
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.4rem;
  text-align: center;
  margin: 0 0 1.25rem 0;
  padding: 0 2rem 0 2rem;
`;

const ChoiceList = styled("ul")`
  list-style: none;

  > li + li {
    margin-top: 1rem;
  }
`;
