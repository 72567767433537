import React, { useState, useCallback, useEffect, lazy, Suspense } from "react";
import { useIdleTimer } from "react-idle-timer";
import Session, {
  isStaffSession,
  isParticipantSession,
  isSuperAdminSession,
} from "shared/lib/interfaces/Session";
import User from "shared/lib/interfaces/User";
import Participant from "shared/lib/interfaces/Participant";
import Staff from "shared/lib/interfaces/Staff";
import SuperAdmin from "shared/lib/interfaces/SuperAdmin";
import getSession from "./auth/api/getSession";
import ParticipantRoot from "./ParticipantRoot";
import UnauthorizedRoot from "./UnauthorizedRoot";
import { IDLE_TIMEOUT, IDLE_WARNING_TIMEOUT } from "./env";
import logout from "./auth/api/logout";
import IdleTimeoutModal from "./common/components/IdleTimeoutModal";

const SuperAdminRoot = lazy(() => import("./SuperAdminRoot"));
const StaffRoot = lazy(() => import("./StaffRoot"));

const IDLE_TIMER_DEBOUNCE = 500;

interface State {
  loading: boolean;
  user: User | null;
  session: Session | null;
  showIdleWarning: boolean;
}

const initialState: State = {
  loading: true,
  user: null,
  session: null,
  showIdleWarning: false,
};

const App: React.FC = () => {
  const [
    { loading, user, session, showIdleWarning },
    setState,
  ] = useState<State>(initialState);

  const refreshUser = useCallback(async () => {
    const { session, user } = await getSession();
    setState({ loading: false, user, session, showIdleWarning: false });
  }, []);

  useIdleTimer({
    timeout: IDLE_WARNING_TIMEOUT,
    debounce: IDLE_TIMER_DEBOUNCE,
    onActive: useCallback(() => {
      setState((state) => {
        if (state.showIdleWarning) {
          return { ...state, showIdleWarning: false };
        }
        return state;
      });
    }, []),
    onIdle: useCallback(() => {
      setState((state) => {
        if (state.user) {
          return { ...state, showIdleWarning: true };
        }
        return state;
      });
    }, []),
  });

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    debounce: IDLE_TIMER_DEBOUNCE,
    onIdle: useCallback(async () => {
      setState((state) => {
        if (state.user) {
          logout().catch((error) => {
            console.error("Failed to logout", error);
          });
          return {
            loading: false,
            user: null,
            session: null,
            showIdleWarning: false,
          };
        }
        return state;
      });
    }, []),
  });

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  const idleTimeoutModal = showIdleWarning ? <IdleTimeoutModal /> : null;

  if (loading) {
    // Loading
    return null;
  } else if (isParticipantSession(session)) {
    return (
      // Participants
      <>
        <ParticipantRoot user={user as Participant} refreshUser={refreshUser} />
        {idleTimeoutModal}
      </>
    );
  } else if (isStaffSession(session)) {
    return (
      // Staff
      <Suspense fallback={null /* TODO: Add spinner */}>
        <StaffRoot user={user as Staff} refreshUser={refreshUser} />
        {idleTimeoutModal}
      </Suspense>
    );
  } else if (isSuperAdminSession(session)) {
    return (
      // Super admins
      <Suspense fallback={null /* TODO: Add spinner */}>
        <SuperAdminRoot user={user as SuperAdmin} refreshUser={refreshUser} />
        {idleTimeoutModal}
      </Suspense>
    );
  } else {
    return (
      // Logged out
      <UnauthorizedRoot refreshUser={refreshUser} />
    );
  }
};

export default App;
