import React, { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import last from "@emberex/array-utils/lib/last";
import getModuleNumber from "shared/lib/utils/getModuleNumber";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import ParticipantModuleContext from "shared/lib/interfaces/ParticipantModuleContext";
import Module from "shared/lib/interfaces/Module";
import ModuleProgressListItem from "./ModuleProgressListItem";
import { useParticipantContext } from "../../participant/contexts/ParticipantContext";
import { useModulePageContext } from "../../participant/contexts/ModulePageContext";

interface ParticipantModuleListProps {
  showProgressBars?: boolean;
  listItemHeaderColor?: string;
  sectionListColor?: string;
  showViewSurveyButtons?: boolean;
}

const ParticipantModuleList: FC<ParticipantModuleListProps> = ({
  showProgressBars = true,
  showViewSurveyButtons = true,
  listItemHeaderColor,
  sectionListColor,
  ...rest
}) => {
  const modulePageContext = useModulePageContext();
  const initiallyOpenModuleId = modulePageContext?.moduleId;
  const initiallyOpenSectionId = modulePageContext?.moduleSectionId;

  const history = useHistory();
  const { moduleContexts, slideViews, canViewModule } = useParticipantContext();
  const mostRecentlyUnlockedModule = last(
    moduleContexts.filter((moduleContext) =>
      canViewModule(moduleContext.module.id)
    )
  )?.module;
  const [openModuleId, setOpenModuleId] = useState<number | null>(
    () => initiallyOpenModuleId ?? mostRecentlyUnlockedModule?.id ?? null
  );

  /**
   * A cached set of every slide id the current user has viewed.
   * Used to check if the user has viewed a specific slide.
   */
  const viewedSlideIds = useMemo(
    () => new Set(slideViews.map((slideView) => slideView.slideId)),
    [slideViews]
  );

  /**
   * A cached set of every slide id the current user has viewed.
   * Used to check if the user has viewed a specific slide.
   */
  const completedSlideIds = useMemo(
    () =>
      new Set(
        slideViews
          .filter((slideView) => slideView.completedAt)
          .map((slideView) => slideView.slideId)
      ),
    [slideViews]
  );

  /**
   * The last module section the participant viewed.
   */
  const mostRecentlyViewedSection = useMemo(
    () =>
      mostRecentlyUnlockedModule &&
      last(
        mostRecentlyUnlockedModule.sections.filter((section) =>
          section.slides.some((slide) => viewedSlideIds.has(slide.id))
        )
      ),
    [mostRecentlyUnlockedModule, viewedSlideIds]
  );

  const handleViewSectionClick = useCallback(
    ({ sectionId, moduleId }: { sectionId: number; moduleId: number }) => {
      const module = getModuleById(moduleContexts ?? [], moduleId);
      if (!module) {
        return;
      }
      const slideIndex = getSectionSlideIndex(moduleContexts, {
        moduleId,
        sectionId,
      });

      history.push(`/module/${getModuleNumber(module)}/${slideIndex + 1}`);
    },
    [history, moduleContexts]
  );

  const handleViewSurveyClick = useCallback(
    (moduleId: number) => {
      const module = getModuleById(moduleContexts ?? [], moduleId);
      if (!module) {
        return;
      }
      history.push(`/module/${getModuleNumber(module)}?view-survey=true`);
    },
    [history, moduleContexts]
  );

  const handleViewModuleClick = useCallback(
    (moduleId: number) => {
      const module = getModuleById(moduleContexts ?? [], moduleId);
      if (!module) {
        return;
      }
      history.push(`/module/${getModuleNumber(module)}`);
    },
    [history, moduleContexts]
  );

  return (
    <Column {...rest}>
      {moduleContexts.map((moduleContext, moduleIndex) => {
        const { module } = moduleContext;
        const { sections } = module;
        const slides = sections.flatMap((section) => section.slides);
        const viewedSlides = slides.filter((slide) =>
          viewedSlideIds.has(slide.id)
        );
        const completedSectionIds = sections
          .filter((section) =>
            section.slides.every((sectionSlide) =>
              completedSlideIds.has(sectionSlide.id)
            )
          )
          .map((section) => section.id);

        return (
          <ModuleProgressListItem
            key={module.id}
            index={moduleIndex}
            open={module.id === openModuleId}
            onToggleOpen={() =>
              setOpenModuleId(openModuleId === module.id ? null : module.id)
            }
            module={moduleContext.module}
            highlighted={
              initiallyOpenModuleId
                ? module.id === initiallyOpenModuleId
                : module.id === mostRecentlyUnlockedModule?.id
            }
            locked={!canViewModule(module.id)}
            completedSectionIds={completedSectionIds}
            highlightedSectionId={
              initiallyOpenSectionId ?? mostRecentlyViewedSection?.id ?? null
            }
            showViewSurveyButton={
              showViewSurveyButtons &&
              !!moduleContext.initialSurveyTake?.completedAt
            }
            showProgressBar={showProgressBars}
            sectionListColor={sectionListColor}
            headerColor={listItemHeaderColor}
            progress={{
              current: viewedSlides.length,
              total: slides.length,
            }}
            onSectionClick={handleViewSectionClick}
            onViewSurveyClick={handleViewSurveyClick}
            onClick={handleViewModuleClick}
          />
        );
      })}
    </Column>
  );
};

export default styled(ParticipantModuleList)``;

/**
 * Gets the slide index for the first slide in a section.
 * Used to link directly to the beginning of a section.
 */
function getSectionSlideIndex(
  moduleContexts: ParticipantModuleContext[],
  { moduleId, sectionId }: { moduleId: number; sectionId: number }
): number {
  const moduleContext = moduleContexts.find(
    (moduleContext) => moduleContext.module.id === moduleId
  );

  if (moduleContext) {
    let slideIndex = 0;
    const { sections } = moduleContext.module;

    for (const section of sections) {
      if (section.id === sectionId) {
        return slideIndex;
      }
      slideIndex += section.slides.length;
    }
  } else {
    // This shouldn't happen
    throw new Error(`Module not found with id ${moduleId}`);
  }

  // This shouldn't happen
  throw new Error(`Section not found with id ${sectionId}`);
}

function getModuleById(
  moduleContexts: ParticipantModuleContext[],
  moduleId: number
): Module | null {
  return (
    moduleContexts.find((moduleContext) => moduleContext.module.id === moduleId)
      ?.module || null
  );
}
