import React, { FC } from "react";
import styled from "styled-components/macro";
import VideoId, { getVideoTitle } from "shared/lib/enums/VideoId";
import { DARKEST_BLUE } from "../../colors";
import ActivityBox from "./ActivityBox";
import Video from "./Video";

interface Props {
  videoId: VideoId;
  titleTextAlign?: "center" | "left" | "right";
  onEnded?(toCompletion: boolean): any;
  onStarted?(): any;
}

const VideoActivity: FC<Props> = (props) => {
  const {
    videoId,
    onEnded,
    onStarted,
    titleTextAlign = "center",
    ...rest
  } = props;

  return (
    <ActivityBox {...rest} icon="film">
      <Title textAlign={titleTextAlign}>{getVideoTitle(videoId)}</Title>
      <Video videoId={videoId} onEnded={onEnded} onStarted={onStarted} />
    </ActivityBox>
  );
};

export default styled(VideoActivity)`
  align-items: center;
  color: ${DARKEST_BLUE};
  padding-bottom: 2.5rem;
`;

const Title = styled("h4")<{ textAlign: string }>`
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: ${(props) => props.textAlign};
  margin-bottom: 0.75rem;
  padding: 0 1.25rem 0 1.25rem;
`;
