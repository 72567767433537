import React, { FC } from "react";
import styled from "styled-components/macro";

interface Props {}

const CoachBadge: FC<Props> = props => {
  const { ...rest } = props;

  return <div {...rest}>C</div>;
};

export default styled(CoachBadge)`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  font-size: 0.5rem;
  padding-top: 3px;
  margin-right: 0.25rem;
  flex-shrink: 0;
  text-align: center;
  background-color: #08adc0;
  display: inline-block;
`;
