import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ProactiveParentingDefinitionSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSectionComplete, setShowSectionComplete] = useState(false);

  const handleSlideNext = useCallback(() => {
    setShowSectionComplete(true);
  }, []);

  return (
    <SlidePage {...rest} title={module.name} onNext={handleSlideNext}>
      <section>
        <h3>WHAT IS PROACTIVE PARENTING?</h3>
        <p>
          Proactive parenting is planning ahead, anticipating what might be a
          challenge for your child, and taking action to manage your child’s
          behavior before the problem occurs.
          <br />
          <br />
          In other words, it is a style of parenting that sets you up for
          success.
        </p>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSectionComplete}
        onNext={onNext}
        module={module}
        currentSection={section}
      />
    </SlidePage>
  );
};

export default styled(ProactiveParentingDefinitionSlide)``;
