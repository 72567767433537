import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import Module from "shared/lib/interfaces/Module";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { EncouragementAndPraiseQuizValue } from "shared/lib/interfaces/ModuleActivity";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import { SlidePageProps, SlidePageHeader } from "../../SlidePage";
import ActivityBox, { InnerActivityBox } from "../../ActivityBox";
import { SlidePageProgress } from "../../SlidePage";
import ModuleCheckBox from "../../ModuleCheckBox";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import { DARKEST_BLUE } from "../../../../colors";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind =
  ModuleActivityKind.ENCOURAGEMENT_AND_PRAISE_EXAMPLES_OF_PRAISE_QUIZ;

const defaultValue: EncouragementAndPraiseQuizValue = {
  greatJob: false,
  thanksForPuttingAway: false,
  thankYou: false,
  iLikeTheWayYouShare: false,
};

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const message = (
  <>
    Specific Praise includes the behavior your child is doing that you want to
    increase.
    <br />
    <br />
    “Thanks for putting the legos away” and “I like the way you shared with your
    brother” were both examples of specific praise.
  </>
);

const EncouragementAndPraiseQuizSlide: FC<Props> = (props) => {
  const { module, onNext, section, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);
  const [showSectionComplete, setShowSectionComplete] = useState(false);

  const handleNext = useCallback(() => {
    if (showSectionComplete) {
      onNext();
    } else {
      setShowSectionComplete(true);
    }
  }, [onNext, showSectionComplete]);

  const handleCheckboxChange = (
    key: keyof EncouragementAndPraiseQuizValue
  ) => () => setValue({ ...value, [key]: !value[key] });

  return (
    <ActivitySlidePage
      {...rest}
      light
      lightNextButton
      nextButtonText="Submit"
      hideNextButton={
        !value.greatJob &&
        !value.thanksForPuttingAway &&
        !value.thankYou &&
        !value.iLikeTheWayYouShare
      }
      title={module.name}
      activityKind={activityKind}
      activityMessage={message}
      onNext={handleNext}
    >
      <ActivityBox icon="checkbox">
        <section>
          <Instructions>
            Your turn: Check all the boxes that are examples of specific praise
          </Instructions>
        </section>
        <InnerActivityBox>
          <ModuleCheckBox
            checked={value.greatJob}
            onChange={handleCheckboxChange("greatJob")}
            label="Great job!"
          />
          <ModuleCheckBox
            checked={value.thanksForPuttingAway}
            onChange={handleCheckboxChange("thanksForPuttingAway")}
            label="Thanks for putting the legos away"
          />
          <ModuleCheckBox
            checked={value.thankYou}
            onChange={handleCheckboxChange("thankYou")}
            label="Thank you!"
          />
          <ModuleCheckBox
            checked={value.iLikeTheWayYouShare}
            onChange={handleCheckboxChange("iLikeTheWayYouShare")}
            label="I like the way you shared with your brother"
          />
        </InnerActivityBox>
      </ActivityBox>
      <ModuleSegmentCompletionOverlay
        open={showSectionComplete}
        onNext={handleNext}
        module={module}
        currentSection={section}
        blurbText="Way to go!"
      />
    </ActivitySlidePage>
  );
};

export default styled(EncouragementAndPraiseQuizSlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const Instructions = styled("p")`
  margin-bottom: 1.25rem;
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.35rem;
  text-align: center;
`;
