import React, { FC, ComponentProps } from "react";
import styled, { css } from "styled-components/macro";
import BlockButton from "@emberex/components/lib/BlockButton";
import { LIGHT_GRAY, DARK_BLUE } from "../../colors";
import { XIcon } from "./Icons";

type Props = ComponentProps<typeof BlockButton> & {
  showShadow?: boolean;
};

const CircleXButton: FC<Props> = (props) => (
  <BlockButton {...props}>
    <XIcon />
  </BlockButton>
);

export default styled(CircleXButton)<Props>`
  height: 40px;
  width: 40px;
  line-height: 40px;
  padding: 0 0 0 0;
  overflow: hidden;
  border-radius: 50%;
  font-size: 3rem;
  background-color: ${LIGHT_GRAY};
  color: ${DARK_BLUE};

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${(props) => {
    const { showShadow = true } = props;
    if (showShadow) {
      return css`
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
      `;
    }
    return "";
  }}
`;
