import React, { FC } from "react";
import styled from "styled-components/macro";
import ExpandableRow, {
  ExpandableRowProps,
  ExpandableRowHeader,
  ExpandableRowContent,
  ExpandableRowIcon,
} from "../../common/components/ExpandableRow";
import { YELLOW, DARKEST_BLUE } from "../../colors";

const ExpandableBlock: FC<ExpandableRowProps> = (props) => {
  return <ExpandableRow {...props} />;
};

export default styled(ExpandableBlock)`
  border-radius: 0.5rem;
  background-color: #4dacbe;

  ${ExpandableRowHeader} {
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-weight: bold;
    color: ${DARKEST_BLUE};
  }

  ${ExpandableRowIcon} {
    color: ${YELLOW};
    right: 0.5rem;
  }

  ${ExpandableRowContent} {
    padding: 0.25rem 2rem 1rem 2rem;
    color: ${DARKEST_BLUE};
  }

  & + & {
    margin-top: 2px;
  }
`;
