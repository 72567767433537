import React, { FC, ReactNode, useCallback } from "react";
import styled from "styled-components/macro";
import BlockButton from "@emberex/components/lib/BlockButton";
import Column from "@emberex/components/lib/Column";
import { BribeReward } from "shared/lib/interfaces/ModuleActivity";
import ExampleSection from "./ExampleSection";
import { DARKEST_BLUE } from "../../colors";
import BribeRewardIcon from "./BribeRewardIcon";

// How long to wait in milliseconds between when the user makes a choice and displaying the explanation.
export const EXPLANATION_DELAY = 1500;

interface Props {
  situation: ReactNode;
  explanation: ReactNode;
  isBribe: boolean;
  showExplanation: boolean;
  value: BribeReward | null;
  onChange(value: BribeReward): any;
}

const BribeRewardActivity: FC<Props> = (props) => {
  const {
    situation,
    explanation,
    isBribe,
    showExplanation,
    value,
    onChange,
    ...rest
  } = props;

  const handleBribeClick = useCallback(() => {
    onChange(BribeReward.BRIBE);
  }, [onChange]);

  const handleRewardClick = useCallback(() => {
    onChange(BribeReward.REWARD);
  }, [onChange]);

  return (
    <Column {...rest}>
      <ExampleSection>
        <Situation>{situation}</Situation>
      </ExampleSection>
      {showExplanation ? (
        <ExplanationBox>
          <BribeRewardIcon type={isBribe ? "bribe" : "reward"} />
          <ExplanationText>{explanation}</ExplanationText>
        </ExplanationBox>
      ) : (
        <Column>
          <Question>
            Is this example a bribe
            <br /> or a reward?
          </Question>
          <ChoiceButton
            selected={value === BribeReward.BRIBE}
            onClick={handleBribeClick}
          >
            Bribe
          </ChoiceButton>
          <ChoiceButton
            selected={value === BribeReward.REWARD}
            onClick={handleRewardClick}
          >
            Reward
          </ChoiceButton>
        </Column>
      )}
    </Column>
  );
};

export default styled(BribeRewardActivity)`
  > ${ExampleSection} {
    margin-bottom: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

const ChoiceButton = styled(BlockButton)<{ selected?: boolean }>`
  height: 44px;
  width: 100%;
  border: 2px solid #08adc0;
  border-radius: 22px;
  background-color: ${(props) => (props.selected ? "#CCFCEE" : "white")};
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: 500;
  width: 180px;
  margin: 0 auto;
  padding: 0 0 0 0;

  & + & {
    margin-top: 1rem;
  }
`;

const Situation = styled("p")`
  margin-bottom: 1.5rem;
`;

const Question = styled("p")`
  color: #ff6762;
  font-weight: bold;
  margin: 0 auto 2.5rem;
  text-align: center;
`;

const ExplanationBox = styled(Column)`
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #ccfcee;
  padding: 1rem 1.25rem 1.75rem 1.25rem;
  align-items: center;
`;

const ExplanationText = styled("p")`
  border-radius: 0.5rem;
  background-color: #9dedd6;
  padding: 0.4rem 1.25rem 0.4rem 1.25rem;
  margin-top: 0.75rem;
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-align: center;
`;
