import React, { FC, useState } from "react";
import first from "shared/lib/utils/first";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import PlanActivity from "shared/lib/interfaces/PlanActivity";
import ParticipantPlanListItem from "./ParticipantPlanListItem";
import { useParticipantContext } from "../contexts/ParticipantContext";

interface Props {
  modules: Pick<Module, "id" | "name" | "icon">[];
  planActivities: Pick<
    PlanActivity,
    "id" | "name" | "moduleId" | "activityKind"
  >[];
}

const ParticipantPlanList: FC<Props> = (props) => {
  const { canViewModule } = useParticipantContext();
  const { modules, planActivities, ...rest } = props;

  const visibleModules = modules.filter((mod) => canViewModule(mod.id));

  const [expandedModuleId, setExpandedModuleId] = useState<number | null>(
    first(visibleModules)?.id ?? null
  );

  return (
    <div {...rest}>
      <ModuleList>
        {visibleModules.map((mod) => {
          const modulePlanActivities = planActivities.filter(
            (planActivity) => planActivity.moduleId === mod.id
          );

          return (
            <ParticipantPlanListItem
              key={mod.id}
              module={mod}
              planActivities={modulePlanActivities}
              expanded={mod.id === expandedModuleId}
              onToggleExpanded={() =>
                setExpandedModuleId(mod.id === expandedModuleId ? null : mod.id)
              }
            />
          );
        })}
      </ModuleList>
    </div>
  );
};

export default styled(ParticipantPlanList)`
  background-color: #0e3750;
  color: white;
`;

const ModuleList = styled("ul")`
  list-style: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
`;
