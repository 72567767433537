import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { BearIcons } from "../../../../common/components/BearIcon";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import VideoId from "shared/lib/enums/VideoId";

const activityKind = ModuleActivityKind.PROACTIVE_PARENTING_PLAY_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const ProactiveParentingPlayVideoSlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [onPlayStart, setVideoPlayed, videoCompleted] = useVideoActivity(
    activityKind,
    VideoId.PROACTIVE_PARENTING_PLAY_VIDEO
  );

  const handleNext = useCallback(() => {
    setVideoPlayed(true);
    onNext();
  }, [setVideoPlayed, onNext]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      title={module.name}
      activityKind={activityKind}
      bearIcon={BearIcons.GIRL_WINKING}
      onNext={handleNext}
    >
      <section>
        <h3>PLAY</h3>
        <p>
          Playing with your child is an essential part of your relationship.
          Watch this video for tips on how to get more out of play experiences.
        </p>
      </section>
      <VideoActivity
        onStarted={onPlayStart}
        onEnded={setVideoPlayed}
        videoId={VideoId.PROACTIVE_PARENTING_PLAY_VIDEO}
      />
      <p>Try to add play into your daily routine.</p>
    </ActivitySlidePage>
  );
};

export default styled(ProactiveParentingPlayVideoSlide)`
  p:nth-child(3) {
    text-align: center;
    margin-top: 3rem;
  }
`;
