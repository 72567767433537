import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "id" | "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const ManagingDepressionWelcomeSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          Parenting a young child is hard in the best of times. Add in any
          challenges and it can become overwhelming. You might feel stressed
          out, anxious, or even depressed. These are common feelings when you
          are parenting young children. You may also feel guilty for having
          those feelings, because you enjoy and love your child.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(ManagingDepressionWelcomeSlide)``;
