import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const HomeSafetyOutroSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const handleNext = useCallback(() => {
    setShowSegmentOverlay(true);
  }, []);

  return (
    <SlidePage {...rest} onNext={handleNext} title={module.name}>
      <section>
        <h3>Home Safety</h3>
        <p>
          Many parents worry that their children might be impacted by their
          substance use. There are many resources available to support parents.
          Find out what is available in your community or consider reaching out
          to your child’s primary care provider for further information.
        </p>
      </section>

      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Congratulations!"
      />
    </SlidePage>
  );
};

export default styled(HomeSafetyOutroSlide)``;
