import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import { PlanValue } from "shared/lib/interfaces/ModuleActivity";
import { BearIcons } from "../../common/components/BearIcon";
import { SlidePageContent } from "./SlidePage";
import ActivityBox from "./ActivityBox";
import ActivitySlidePage, { ActivitySlidePageProps } from "./ActivitySlidePage";
import PlanActivityCard from "./PlanActivityCard";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";

interface Props extends Omit<ActivitySlidePageProps, "dark"> {
  planTitle: ReactNode;
  suggestions: string[];
  activityKind: ModuleActivityKind;
  plan: PlanValue | undefined | null;
  onPlanChange: (plan: PlanValue) => any;
  suggestionTitle?: string;
  bearIcon: BearIcons;
}

const PlanSlidePage: FC<Props> = (props) => {
  const {
    plan,
    onPlanChange,
    planTitle,
    suggestions,
    suggestionTitle,
    nextButtonText = "Finish Plan",
    children,
    ...rest
  } = props;

  return (
    <ActivitySlidePage
      light
      lightNextButton
      nextButtonText={nextButtonText}
      {...rest}
    >
      <ActivityBox icon="pencil">
        <PlanTitle>{planTitle}</PlanTitle>
        <PlanActivityCard
          value={plan}
          onChange={onPlanChange}
          suggestions={suggestions}
          suggestionTitle={suggestionTitle}
        />
      </ActivityBox>
      {children}
    </ActivitySlidePage>
  );
};

export default styled(PlanSlidePage)`
  ${SlidePageContent} {
    margin-top: 2rem;
  }
`;

const PlanTitle = styled("h3")`
  color: #0b4568;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin: 0 2rem 0.75rem 2rem;
`;
