import styled from "styled-components/macro";
import blurbSrc from "../../modules/images/blurb.png";

export default styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${blurbSrc}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
