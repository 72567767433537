import React, { FC, ReactNode, ComponentPropsWithoutRef } from "react";
import styled from "styled-components/macro";
import { DARKEST_BLUE } from "../../colors";
import FakeCheckBox from "./FakeCheckbox";

interface Props extends ComponentPropsWithoutRef<"input"> {
  label?: ReactNode;
  disabled?: boolean;
  className?: string;
}

const ModuleCheckbox: FC<Props> = (props) => {
  const { label, className, disabled, checked, ...rest } = props;

  return (
    <Root className={className} disabled={disabled}>
      <input {...rest} type="checkbox" disabled={disabled} checked={checked} />
      <FakeCheckBox />
      {label && <LabelText>{label}</LabelText>}
    </Root>
  );
};

export default styled(ModuleCheckbox)`
  padding-left: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  position: relative;
  font-size: 1rem;
`;

const Root = styled("label")<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  > input {
    display: none;
  }

  & + & {
    margin-top: 1rem;
  }
`;

const LabelText = styled("span")`
  font-size: inherit;
  margin-left: 1rem;
  color: ${DARKEST_BLUE};
  font-weight: 500;
  line-height: 1.25rem;
  padding-top: 0.2rem;
`;
