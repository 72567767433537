import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import usePlanSlide, { PlanSection } from "../../../hooks/usePlanSlide";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import PlanLetter from "../../PlanLetter";
import Row from "@emberex/components/lib/Row";
import ChevronCircleButton from "../../ChevronCircleButton";
import Column from "@emberex/components/lib/Column";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}


const ProactiveParentingHowToPlanSlide: FC<Props> = (props) => {
  const { module, section, onBack, ...rest } = props;
  const { planSection, showNextPlanSection, onBack: handleBack } = usePlanSlide({onBack});

  let planSectionContent: ReactNode;

  switch (planSection) {
    case PlanSection.INTRO:
      planSectionContent = (
        <PlanIntroSection>
          <PlanLetterRow>
            <PlanLetter>P</PlanLetter>
            <PlanLetter>L</PlanLetter>
            <PlanLetter>A</PlanLetter>
            <PlanLetter>N</PlanLetter>
          </PlanLetterRow>
          <ChevronCircleButton onClick={showNextPlanSection} />
        </PlanIntroSection>
      );
      break;
    case PlanSection.LETTER_P:
      planSectionContent = (
        <PlanLetterSection>
          <CenterRow>
            <PlanLetter size="large">P</PlanLetter>
          </CenterRow>
          <LetterOverlapRow>
            <ChevronCircleButton onClick={showNextPlanSection} />
          </LetterOverlapRow>
          <section>
            <p>
              <b>Pay attention</b> and identify the problem situations for your
              child.
            </p>
            <YellowCircleList>
              <li>
                Bedtime, grocery store, transitions from one activity to the
                next.
              </li>
            </YellowCircleList>
          </section>
        </PlanLetterSection>
      );
      break;
    case PlanSection.LETTER_L:
      planSectionContent = (
        <PlanLetterSection>
          <CenterRow>
            <PlanLetter size="large">L</PlanLetter>
          </CenterRow>
          <LetterOverlapRow>
            <ChevronCircleButton onClick={showNextPlanSection} />
          </LetterOverlapRow>
          <section>
            <p>
              <b>Look realistically</b> at your child’s abilities in that
              situation.
            </p>
            <YellowCircleList>
              <li>
                Should you go into the grocery store with your child after a
                long busy day and expect good behavior?
              </li>
            </YellowCircleList>
          </section>
        </PlanLetterSection>
      );
      break;
    case PlanSection.LETTER_A:
      planSectionContent = (
        <PlanLetterSection>
          <CenterRow>
            <PlanLetter size="large">A</PlanLetter>
          </CenterRow>
          <LetterOverlapRow>
            <ChevronCircleButton onClick={showNextPlanSection} />
          </LetterOverlapRow>
          <section>
            <p>
              <b>Adjust</b> difficult activities to maximize success and
              minimize negative emotions.
            </p>
            <YellowCircleList>
              <li>
                Give clear commands, warnings, and prompts so that your child
                understands what is expected.
              </li>
            </YellowCircleList>
          </section>
        </PlanLetterSection>
      );
      break;
    case PlanSection.LETTER_N:
      planSectionContent = (
        <PlanLetterSection>
          <CenterRow>
            <PlanLetter size="large">N</PlanLetter>
          </CenterRow>
          <LetterOverlapRow />
          <section>
            <p>
              <b>Notice</b> and remember to prompt, suggest, and reward success.
            </p>
            <YellowCircleList>
              <li>Great job getting your pajamas on for bed!</li>
            </YellowCircleList>
          </section>
        </PlanLetterSection>
      );
      break;
  }

  return (
    <SlidePage
      {...rest}
      title={module.name}
      showNextButton={planSection === PlanSection.LETTER_N}
      onBack={handleBack}
    >
      <section>
        <h3>DO YOU KNOW HOW TO PLAN?</h3>
      </section>
      {planSectionContent}
    </SlidePage>
  );
};

export default styled(ProactiveParentingHowToPlanSlide)``;

const PlanLetterRow = styled(Row)`
  ${PlanLetter} + ${PlanLetter} {
    margin-left: 10px;
  }
`;

const CenterRow = styled(Row)`
  justify-content: center;
`;

const PlanIntroSection = styled(Column)`
  align-items: center;
  padding-top: 4rem;

  ${ChevronCircleButton} {
    margin-top: 2.5rem;
  }
`;

const PlanLetterSection = styled(Column)`
  padding-top: 1rem;
  ${YellowCircleList} {
    margin-top: 1rem;
  }
`;

const LetterOverlapRow = styled(CenterRow)`
  transform: translate(0, -40%);
  height: 3rem;
`;
