import React, { FC, useState } from "react";
import noop from "shared/lib/utils/noop";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BearIcons } from "../../../../common/components/BearIcon";
import SlidePage, {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import EffectiveCommunicationStatementComparisonActivity from "../../EffectiveCommunicationStatementComparisonActivity";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivityCompletionOverlay from "../../ActivityCompletionOverlay";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const activityKind =
  ModuleActivityKind.EFFECTIVE_COMMUNICATION_COMPARISON_STATEMENT;

const EffectiveCommunicationStatementComparisonSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [responseResult, setResponseResult] = useActivity(activityKind);
  const [initialResponseResult] = useState(responseResult);
  const [
    showActivityCompleteOverlay,
    setShowActivityCompleteOverlay,
  ] = useState(false);

  return (
    <SlidePage
      {...rest}
      onNext={noop}
      title={module.name}
      light
      lightNextButton
      hideNextButton
    >
      <EffectiveCommunicationStatementComparisonActivity
        responseResult={responseResult}
        onSubmit={(value) => {
          if (value) {
            setResponseResult(value);
          }
        }}
        onFinished={() =>
          initialResponseResult
            ? onNext()
            : setShowActivityCompleteOverlay(true)
        }
      />
      <ActivityCompletionOverlay
        onNext={onNext}
        open={showActivityCompleteOverlay}
        activityKind={activityKind}
        bearIcon={BearIcons.GIRL_THUMBS_UP_WINKING}
      />
    </SlidePage>
  );
};

export default styled(EffectiveCommunicationStatementComparisonSlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;
