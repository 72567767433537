import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const SelfCareWelcomeSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          Being the parent of young children can feel lonely, isolating, and
          stressful at times.
          <br />
          <br />
          Self-care can improve your health, well-being, and stress, and support
          you through the ups and downs of parenting young children.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(SelfCareWelcomeSlide)``;
