import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import TextButton from "@emberex/components/lib/TextButton";
import { ArrowLeftIcon } from "../../common/components/Icons";

interface Props {
  current: number;
  total: number;
  dark?: boolean;
  showLabel?: boolean;
  onBack?: MouseEventHandler<HTMLButtonElement>;
}

const ProgressBar: FC<Props> = (props) => {
  const {
    current,
    total,
    onBack,
    showLabel = true,
    dark = false,
    ...rest
  } = props;

  return (
    <Column {...rest}>
      {showLabel && (
        <LabelRow>
          {current > 1 && (
            <TextButton onClick={onBack}>
              <ArrowLeftIcon />
            </TextButton>
          )}
          <Label>
            {current} / {total}
          </Label>
        </LabelRow>
      )}
      <BarContainer dark={dark}>
        <Bar
          style={{ width: total > 0 ? `${(100 * current) / total}%` : 0 }}
          dark={dark}
        />
      </BarContainer>
    </Column>
  );
};

export default styled(ProgressBar)``;

const Label = styled("div")`
  color: #4dacbe;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  align-self: center;
  width: 60px;
`;

const LabelRow = styled(Row)`
  justify-content: center;
  height: 24px;

  svg {
    color: #fdd965;
    font-size: 1.25rem;
    opacity: 50%;
    margin-right: 1rem;
    margin-left: -36px;
  }
`;

const BarContainer = styled("div")<{ dark: boolean }>`
  height: 16px;
  border: 3px solid ${(props) => (props.dark ? "#0E2F4D" : "#d0e8ec")};
  background-color: ${(props) => (props.dark ? "#0E2F4D" : "white")};
  margin-top: 0.5rem;
  border-radius: 8px;
  overflow: hidden;
`;

const darkGradient =
  "linear-gradient(90deg, rgba(8,173,192,0.09) 0%, rgba(8,173,192,0.55) 55.28%, #08ADC0 100%)";
const lightGradient =
  "linear-gradient(90deg, rgba(8, 173, 192, 0.09) 0%, rgba(8, 173, 192, 0.55) 55.28%, #08adc0 100%)";

const Bar = styled("div")<{ dark: boolean }>`
  background-color: #d0e8ec;
  height: 100%;
  border-radius: 8px;
  transition: width 0.5s;
  background: ${(props) => (props.dark ? darkGradient : lightGradient)};
`;
