import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import HelpfulUnhelpfulIcon from "./HelpfulUnhelpfulIcon";
import { HelpfulUnhelpfulIconProps } from "./HelpfulUnhelpfulIcon";
import { DARKEST_BLUE } from "../../colors";

interface Props {
  title: ReactNode;
  helpfulWord?: string;
  unhelpfulWord?: string;
  helpfulIcon?: string;
  unhelpfulIcon?: string;
  icon?: HelpfulUnhelpfulIconProps["icon"];
}

const HelpfulUnhelpfulQuiz: FC<Props> = (props) => {
  const {
    title,
    children,
    helpfulWord = "Helpful",
    unhelpfulWord = "Unhelpful",
    helpfulIcon,
    unhelpfulIcon,
    icon,
    ...rest
  } = props;

  return (
    <Column {...rest}>
      <Header>
        <Title>{title}</Title>
        <HelpfulUnhelpfulQuizRightRow>
          <HelpfulUnhelpfulQuizRightColumn>
            <HelpfulUnhelpfulQuizRightLabel>
              {helpfulWord}
            </HelpfulUnhelpfulQuizRightLabel>
            <IconContainer>
              <HelpfulUnhelpfulIcon
                icon={icon}
                helpful
                alt={helpfulWord}
                src={helpfulIcon}
              />
            </IconContainer>
          </HelpfulUnhelpfulQuizRightColumn>
          <HelpfulUnhelpfulQuizRightColumn>
            <HelpfulUnhelpfulQuizRightLabel>
              {unhelpfulWord}
            </HelpfulUnhelpfulQuizRightLabel>
            <IconContainer>
              <HelpfulUnhelpfulIcon
                icon={icon}
                helpful={false}
                alt={unhelpfulWord}
                src={unhelpfulIcon}
              />
            </IconContainer>
          </HelpfulUnhelpfulQuizRightColumn>
        </HelpfulUnhelpfulQuizRightRow>
      </Header>
      {children}
    </Column>
  );
};

export default styled(HelpfulUnhelpfulQuiz)`
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
`;

const Header = styled(Row)`
  padding-bottom: 0.75rem;
`;

const Title = styled("h4")`
  color: #4dacbe;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
`;

export const HelpfulUnhelpfulQuizRightRow = styled(Row)`
  flex-shrink: 0;
  color: ${DARKEST_BLUE};
  font-size: 0.7rem;
  font-weight: bold;
  text-align: center;
  margin-left: 0.75rem;
`;

export const HelpfulUnhelpfulQuizRightColumn = styled(Column)`
  width: 50%;
  align-items: center;
  width: 60px;

  ${HelpfulUnhelpfulIcon} {
    width: 50px;
    height: 50px;
  }

  & + & {
    margin-left: 0.75rem;
  }
`;

const IconContainer = styled(Column)`
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
`;

export const HelpfulUnhelpfulQuizRightLabel = styled(Column)`
  margin-bottom: 0.5rem;
  justify-content: center;
  flex: 1 1 auto;
`;
