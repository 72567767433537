import React, { FC, ReactNode, useCallback } from "react";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import styled from "styled-components/macro";
import Overlay from "@emberex/components/lib/Overlay";
import PillButton from "../../surveys/components/PillButton";
import starApprovalSrc from "../../modules/images/star_approval.png";
import bearManSrc from "../../modules/images/bear_man.png";
import Blurb from "../../common/components/Blurb";
import getPositiveBlurbText from "../utils/getPositiveBlurbText";
import useToggleState from "@emberex/react-utils/lib/useToggleState";
import { useParticipantContext } from "../../participant/contexts/ParticipantContext";
import { useHistory } from "react-router-dom";
import logout from "../../auth/api/logout";
import Confetti from "../../modules/components/Confetti";

export interface Props {
  open: boolean;
  onNext(): void;
  moduleName: ReactNode;
  currentSegment: number;
  segmentCount: number;
  blurbText?: string;
  nextButtonText?: ReactNode;
  hideReturnButton?: boolean;
  showConfetti?: boolean;
}

const SegmentCompletionOverlay: FC<Props> = (props) => {
  const {
    moduleName,
    currentSegment,
    segmentCount,
    open,
    onNext,
    blurbText,
    nextButtonText = "Continue",
    showConfetti = false,
    hideReturnButton = false,
  } = props;
  const [returnLater, toggleReturnLater] = useToggleState();
  const { refreshUser } = useParticipantContext();
  const history = useHistory();

  const handleLogoutClick = useCallback(async () => {
    await logout();
    await refreshUser();
    history.push("/");
  }, [refreshUser, history]);

  if (!open) {
    return null;
  }

  if (returnLater) {
    return (
      <Overlay>
        <Content justifyContent="flex-start">
          <StarRow>
            <StarLogo />
          </StarRow>
          <Header>Congratulations!</Header>
          <ReturnLaterMessage>
            No problem, you’ll get a text
            <br />
            reminder to come back tomorrow!
          </ReturnLaterMessage>
          <ButtonColumn>
            <PillButton onClick={handleLogoutClick}>Log Out</PillButton>
            <ReturnButton onClick={onNext}>{nextButtonText}</ReturnButton>
          </ButtonColumn>
          <BearRow>
            <BearManContainer>
              <BearMan />
            </BearManContainer>
            <BearBlurb>
              See you
              <br />
              soon!
            </BearBlurb>
          </BearRow>
        </Content>
        {showConfetti && <Confetti />}
      </Overlay>
    );
  }

  return (
    <Overlay>
      <Content justifyContent="space-between">
        <StarRow>
          <StarLogo />
        </StarRow>
        <Header>Congratulations!</Header>
        <ModuleProgress>
          <div>You've finished</div>
          <div>
            <NumberCircle>{currentSegment}</NumberCircle> out of {segmentCount}
          </div>
          <div>parts of {moduleName}</div>
        </ModuleProgress>
        <ButtonColumn>
          <PillButton onClick={onNext}>{nextButtonText}</PillButton>
          {!hideReturnButton && (
            <ReturnButton onClick={toggleReturnLater}>
              Return Later
            </ReturnButton>
          )}
        </ButtonColumn>
        <BearRow>
          <BearManContainer>
            <BearMan />
          </BearManContainer>
          <BearBlurb>{blurbText || getPositiveBlurbText()}</BearBlurb>
        </BearRow>
      </Content>
      {showConfetti && <Confetti />}
    </Overlay>
  );
};

export default SegmentCompletionOverlay;

const Content = styled(Column)<{ justifyContent: string }>`
  height: 100%;
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  align-content: center;
  overflow: hidden;
  background-color: #4dacbe;
`;

const StarRow = styled(Row)`
  padding: 2rem 0 1rem 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
`;

const Header = styled(Row)`
  color: #ffffff;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0;
  text-align: center;
  flex-shrink:0;

  @media (min-width: 500px) {
    font-size: 3rem;
  }
`;

const StarLogo = styled("img").attrs({
  src: starApprovalSrc,
  alt: "Star Approval Logo",
})`
  align-self: center;
  width: 80px;
  height: auto;
  @media (min-width: 500px) {
    width: 120px;
  }
`;

const ModuleProgress = styled(Column)`
  padding: 2rem 0 35px 0;
  flex-shrink: 0;
  font-weight: 500;
  div {
    color: #0e3750;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: center;

    @media (min-width: 500px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  div:nth-child(2) {
    font-weight: 700;
  }
`;

const NumberCircle = styled("div")`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #459eaf;

  @media (min-width: 500px) {
    width: 30px;
    height: 30px;
    border-radius: 14px;
  }
`;

const ButtonColumn = styled(Column)`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${PillButton} {
    width: 240px;
    height: 70px;
    @media (min-width: 500px) {
      width: 300px;
      height: 75px;
    }
  }
`;

const ReturnButton = styled(PillButton)`
  background-color: #4dacbe;
  margin-top: 1rem;
`;

const BearRow = styled(Row)`
  height: 200px;
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
  @media (min-width: 500px) {
    height: 300px;
  }
`;

const BearManContainer = styled("div")`
  position: relative;
  width: 50%;
`;

const BearMan = styled("img").attrs({ src: bearManSrc, alt: "Bear Character" })`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90%;
  height: auto;
`;

const BearBlurb = styled(Blurb)`
  padding-left: 1.5rem;
  width: 50%;
  height: 100%;
  text-align: center;
  color: #0e3750;
  font-size: 1rem;
  font-weight: bold;
  width: 170px;
  @media (min-width: 500px) {
    width: 200px;
  }
`;

const ReturnLaterMessage = styled("p")`
  color: #0e3750;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 1.25rem 0;
  flex: 1;
`;
