import React, { FC } from "react";
import styled from "styled-components/macro";
import { MultipleChoiceValue } from "shared/lib/interfaces/SurveyResponseValue";
import SurveyQuestionKind from "shared/lib/enums/SurveyQuestionKind";
import SurveyQuestionChoice from "shared/lib/interfaces/SurveyQuestionChoice";
import { DARKEST_BLUE, LIGHTER_BLUE, LIGHTEST_BLUE } from "../../colors";
import TextButton from "@emberex/components/lib/TextButton";

export type Value = MultipleChoiceValue | null;

interface Props {
  choices: SurveyQuestionChoice[];
  value: Value;
  readonly?: boolean;
  onChange(answer: Value): any;
}

const MultipleChoiceInput: FC<Props> = ({
  choices,
  onChange,
  readonly,
  value,
  ...rest
}) => {
  return (
    <ul {...rest}>
      {choices.map((choice) => {
        const isActive = value ? value.choiceId === choice.id : false;
        return (
          <ChoiceListItem key={choice.id}>
            <ChoiceButton
              isActive={isActive}
              disabled={readonly}
              onClick={() => {
                if (!readonly) {
                  onChange(
                    isActive
                      ? null
                      : {
                          kind: SurveyQuestionKind.MULTIPLE_CHOICE,
                          choiceId: choice.id,
                        }
                  );
                }
              }}
            >
              {choice.choiceText}
            </ChoiceButton>
          </ChoiceListItem>
        );
      })}
    </ul>
  );
};

export default styled(MultipleChoiceInput)`
  color: ${DARKEST_BLUE};
  list-style: none;
`;

const ChoiceListItem = styled("li")`
  & + & {
    margin-top: 1rem;
  }
`;

const ChoiceButton = styled(TextButton)<{ isActive: boolean }>`
  height: 44px;
  min-width: 264px;
  border: 2px solid #08adc0;
  border-radius: 22px;
  background-color: #ffffff;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  padding: 0 0 0 0;
  transition: background-color 0.2s;
  color: ${DARKEST_BLUE};
  background-color: ${(props) => (props.isActive ? LIGHTER_BLUE : "white")};

  &:disabled {
    cursor: default;
  }

  &:not(:disabled):hover {
    background-color: ${(props) =>
      props.isActive ? LIGHTER_BLUE : LIGHTEST_BLUE};
  }
`;
