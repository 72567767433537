import React, { FC, MouseEvent, useCallback } from "react";
import styled from "styled-components/macro";
import AnimateHeight from "react-animate-height";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import TextButton from "@emberex/components/lib/TextButton";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { LIGHTER_BLUE, DARKEST_BLUE, LIGHT_RED, YELLOW } from "../../colors";
import ModuleIcon from "../../common/components/ModuleIcon";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  LockIcon,
} from "../../common/components/Icons";
import ProgressBar from "../../surveys/components/ProgressBar";
import StarCheckImage from "./StarCheckImage";

interface Props {
  index: number;
  open: boolean;
  module: {
    id: Module["id"];
    name: Module["name"];
    icon: Module["icon"];
    sections: Pick<ModuleSection, "id" | "name">[];
  };
  progress: {
    current: number;
    total: number;
  };
  highlighted: boolean;
  locked: boolean;
  completedSectionIds: number[];
  highlightedSectionId: number | null;
  showViewSurveyButton: boolean;
  showProgressBar?: boolean;
  headerColor?: string;
  sectionListColor?: string;
  onToggleOpen(): unknown;
  onClick(moduleId: number): any;
  onSectionClick(event: { moduleId: number; sectionId: number }): any;
  onViewSurveyClick(moduleId: number): any;
}

const ModuleProgressListItem: FC<Props> = (props) => {
  const {
    index,
    open,
    module,
    progress,
    highlighted,
    locked,
    completedSectionIds,
    highlightedSectionId,
    showViewSurveyButton,
    showProgressBar = true,
    headerColor = "#0b4568",
    sectionListColor = DARKEST_BLUE,
    onToggleOpen,
    onClick,
    onSectionClick,
    onViewSurveyClick,
    ...rest
  } = props;
  const moduleNumber = index + 1;

  const handleExpandButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onToggleOpen();
    },
    [onToggleOpen]
  );

  const handleHeaderClick = useCallback(() => {
    onClick(module.id);
  }, [onClick, module.id]);

  return (
    <Column {...rest}>
      {locked && (
        <LockedOverlay fontSize={showProgressBar ? "1rem" : "0.75rem"}>
          <LockIcon />
        </LockedOverlay>
      )}
      <Header
        onClick={locked ? undefined : handleHeaderClick}
        backgroundColor={highlighted ? "#CCFCEE" : headerColor}
        padding={showProgressBar ? "1rem" : "0.5rem"}
      >
        <IconColumn>
          <ModuleIcon
            icon={module.icon}
            faded={progress.current < progress.total}
          />
        </IconColumn>
        <RightColumn
          justifyContent={showProgressBar ? "space-between" : "center"}
        >
          <TitleRow>
            <ModuleNumber color={highlighted ? DARKEST_BLUE : LIGHTER_BLUE}>
              {moduleNumber}
            </ModuleNumber>
            <ModuleName color={highlighted ? DARKEST_BLUE : "white"}>
              {module.name}
            </ModuleName>
          </TitleRow>
          {showProgressBar && (
            <ModuleProgressBar
              dark
              showLabel={false}
              current={progress.current}
              total={progress.total}
            />
          )}
        </RightColumn>
        <ExpandButton onClick={handleExpandButtonClick}>
          {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </ExpandButton>
      </Header>
      <AnimateHeight height={open ? "auto" : "0"} duration={100}>
        <ExpandContent>
          <SectionList color={sectionListColor}>
            {module.sections.map((section) => (
              <li key={section.id}>
                <SectionButton
                  backgroundColor={
                    section.id === highlightedSectionId
                      ? LIGHTER_BLUE
                      : "trasnparent"
                  }
                  color={
                    section.id === highlightedSectionId
                      ? DARKEST_BLUE
                      : "inherit"
                  }
                  onClick={() =>
                    onSectionClick({
                      moduleId: module.id,
                      sectionId: section.id,
                    })
                  }
                >
                  <StarCheckImage
                    faded={
                      section.id === highlightedSectionId ||
                      !completedSectionIds.includes(section.id)
                    }
                  />
                  {section.name}
                </SectionButton>
              </li>
            ))}
          </SectionList>
          {showViewSurveyButton && (
            <ViewSurveyButton onClick={() => onViewSurveyClick(module.id)}>
              <span>View your {module.name} survey responses</span>
              <ChevronRightIcon />
            </ViewSurveyButton>
          )}
        </ExpandContent>
      </AnimateHeight>
    </Column>
  );
};

export default styled(ModuleProgressListItem)`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  & + & {
    margin-top: 0.6rem;
  }
`;

const Header = styled(Row) <{ backgroundColor: string; padding: string }>`
  width: 100%;
  border-radius: 0.5rem;
  background-color: ${(props) => props.backgroundColor};
  margin-bottom: 0.25rem;
  padding: ${(props) => props.padding};
  cursor: pointer;
`;

const IconColumn = styled(Column)`
  justify-content: center;
  margin-right: 0.5rem;
  width: 34px;
  flex-shrink: 0;

  > ${ModuleIcon} {
    width: 34px;
    height: 34px;
  }
`;

const RightColumn = styled(Column) <{ justifyContent: string }>`
  flex: 1 1 100%;
  justify-content: ${(props) => props.justifyContent};
`;

const TitleRow = styled(Row)`
  align-items: center;
  line-height: 1rem;

  > svg {
    color: #ffd84a;
    margin-top: 2px;
  }
`;

const ExpandButton = styled(TextButton)`
  color: ${YELLOW};
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  background-color: transparent;
`;

const ModuleNumber = styled("div") <{ color: string }>`
  color: ${(props) => props.color};
  font-family: "BorisBlackBloxx";
  font-size: 1.25rem;
  flex-shrink: 0;
  margin-right: 0.75rem;
  position: relative;
  top: -2px;
`;

const ModuleName = styled("div") <{ color: string }>`
  color: ${(props) => props.color};
  font-size: 1rem;
  font-weight: 500;
  flex: 1 1 auto;
`;

const ModuleProgressBar = styled(ProgressBar)`
  margin-right: 1rem;
`;

const ExpandContent = styled(Column)`
  flex-shrink: 0;
  opacity: 1;
`;

const SectionList = styled("ul") <{ color: string }>`
  list-style: none;
  color: ${(props) => props.color};
`;

const SectionButton = styled(TextButton) <{
  backgroundColor: string;
  color: string;
}>`
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 0.5rem 0 0.5rem 1rem;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};

  > ${StarCheckImage} {
    margin-right: 0.5rem;
  }
`;

const ViewSurveyButton = styled(TextButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.25rem;
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  font-weight: bold;
  margin-left: 1rem;

  > svg {
    color: ${LIGHT_RED};
  }
`;

const LockedOverlay = styled(Column) <{ fontSize: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: ${(props) => props.fontSize};
  background-color: rgba(11, 69, 104, 0.75);
  border-radius: 0.5rem;
  z-index: 2;
  color: white;
  justify-content: center;
  align-items: center;

  > svg {
    font-size: 1.5rem;
  }
`;
