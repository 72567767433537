import React, { FC, ReactNode, useCallback } from "react";
import styled from "styled-components/macro";
import { RelationshipBulidingSituationValue } from "shared/lib/interfaces/ModuleActivity";
import { SlidePageProps, SlidePageHeader } from "./SlidePage";
import ActivityBox from "./ActivityBox";
import SensitiveResponsiveActivityCard from "./SensitiveResponsiveActivityCard";
import { useSearchParamBooleanState } from "../../common/hooks/useSearchParamState";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import ActivitySlidePage from "./ActivitySlidePage";

interface Props extends SlidePageProps {
  situationTitle: ReactNode;
  situation: ReactNode;
  recommendedResponses: RelationshipBulidingSituationValue;
  value: RelationshipBulidingSituationValue | undefined;
  activityKind: ModuleActivityKind;
  onChange(value: RelationshipBulidingSituationValue): any;
}

const SensitiveResponsiveSlidePage: FC<Props> = (props) => {
  const {
    situationTitle,
    situation,
    recommendedResponses,
    value,
    children,
    onChange,
    onNext,
    onBack,
    ...rest
  } = props;
  const [
    showingResponses = false,
    setShowingResponses,
  ] = useSearchParamBooleanState("show-responses");

  const handleShowResponses = useCallback(() => setShowingResponses(true), [
    setShowingResponses,
  ]);

  const handleBack = useCallback(() => {
    if (showingResponses) {
      setShowingResponses(false);
    } else {
      onBack();
    }
  }, [onBack, showingResponses, setShowingResponses]);

  return (
    <ActivitySlidePage
      {...rest}
      light
      hideNextButton
      hideFooter
      onNext={handleShowResponses}
      onBack={handleBack}
    >
      <ActivityBox icon="chat">
        <SensitiveResponsiveActivityCard
          situation={situation}
          situationTitle={situationTitle}
          recommendedResponses={recommendedResponses}
          value={value}
          onChange={onChange}
          showResponses={showingResponses}
          onShowResponses={handleShowResponses}
          onNext={onNext}
        />
      </ActivityBox>
      {children}
    </ActivitySlidePage>
  );
};

export default styled(SensitiveResponsiveSlidePage)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }

  ${ActivityBox} {
    padding: 1rem 0 0 0;
  }
`;
