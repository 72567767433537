import React, { FC } from "react";
import styled from "styled-components/macro";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import Module from "shared/lib/interfaces/Module";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { RelationshipBulidingSituationValue } from "shared/lib/interfaces/ModuleActivity";
import { SlidePageProps } from "../../SlidePage";
import { SlidePageProgress } from "../../SlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import SensitiveResponsiveSlidePage from "../../SensitiveResponsiveSlidePage";

const activityKind =
  ModuleActivityKind.RELATIONSHIP_BUILDING_SITUATION_EXAMPLE_2;

const defaultValue: RelationshipBulidingSituationValue = {
  sensitive: "",
  responsive: "",
};

export const relationshipBuildingSituation2RecommendedResponses: RelationshipBulidingSituationValue = {
  sensitive: "Go to his room to find out why he’s upset.",
  responsive:
    "You discover that he’s feeling lonely. You put him back in bed, and give him a stuffed animal for company. Then, instead of closing the door, you leave it cracked, and reassure him that you are nearby.",
};

export const relationshipBuildingSituation2Title = "Your turn: Practice 2";

export const relationshipBuildingSituation2Situation = (
  <>
    You’ve just finished putting your son to bed and closed the door behind you,
    but now he’s out of bed and throwing a tantrum.
  </>
);

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RelationshipBuildingSituationExample2Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);

  return (
    <SensitiveResponsiveSlidePage
      {...rest}
      title={module.name}
      value={value}
      onChange={setValue}
      recommendedResponses={relationshipBuildingSituation2RecommendedResponses}
      situationTitle={relationshipBuildingSituation2Title}
      activityKind={activityKind}
      situation={relationshipBuildingSituation2Situation}
    />
  );
};

export default styled(RelationshipBuildingSituationExample2Slide)``;
