import React from "react";
import showModal from "@emberex/react-utils/lib/showModal";
import AlertModal from "../components/AlertModal";

export interface AlertOptions {
  title: string;
  message: string;
}

export default async function showAlert(options: AlertOptions): Promise<void> {
  await showModal(resolve => (
    <AlertModal
      title={options.title}
      message={options.message}
      onClose={() => resolve()}
    />
  ));
}
