import React, { FC, useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import Column from "@emberex/components/lib/Column";
import plantImage1Src from "../../../images/plant_phase_one.png";
import plantImage2Src from "../../../images/plant_phase_two.png";
import plantImage3Src from "../../../images/plant_phase_three.png";
import plantImage4Src from "../../../images/plant_phase_four.png";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";

const plantPhases = [
  plantImage1Src,
  plantImage2Src,
  plantImage3Src,
  plantImage4Src,
];

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide21: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [plantSrc, setPlantSrc] = useState(plantPhases[0]);
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const handleNext = useCallback(() => {
    setShowSegmentOverlay(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlantSrc((value) => {
        const phaseIndex = plantPhases.indexOf(value);
        if (phaseIndex < plantPhases.length - 1) {
          return plantPhases[phaseIndex + 1];
        }
        clearInterval(interval);
        return value;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <SlidePage {...rest} title={module.name} onNext={handleNext}>
      <section>
        <p>
          You learned that when you focus on your child’s positive behavior, and
          praise them for it, you increase the likelihood that they will behave
          the way you want them to. The same thing is true when you focus on
          your child’s unwanted behavior—another reason for you to get good at
          ignoring!
        </p>
      </section>
      <PlantContainer>
        <img src={plantSrc} alt="plant" />
      </PlantContainer>
      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        nextButtonText="Finish"
        blurbText="Congratulations!"
      />
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide21)``;

const PlantContainer = styled(Column)`
  justify-content: flex-end;
  flex-shrink: 0;
  max-width: 100%;
  width: 220px;
  height: 170px;
  margin: 2rem auto;

  img {
    width: 100%;
    height: auto;
  }
`;
