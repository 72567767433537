import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const GivingDirectionIntroSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSectionComplete, setShowSectionComplete] = useState(false);

  const handleNext = useCallback(() => {
    if (showSectionComplete) {
      onNext();
    } else {
      setShowSectionComplete(true);
    }
  }, [onNext, showSectionComplete]);

  return (
    <SlidePage {...rest} title={module.name} onNext={handleNext}>
      <section>
        <h3>{section.name}</h3>
        <p>
          Once your child knows what you expect of them, follow-up by making
          sure they did what you asked. Remember, expressing your appreciation
          can go a long way to help to reinforce their positive behavior.
        </p>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSectionComplete}
        onNext={handleNext}
        module={module}
        currentSection={section}
        blurbText="Great Job!"
      />
    </SlidePage>
  );
};

export default styled(GivingDirectionIntroSlide)``;
