import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import YellowCircleList from "../../../../common/components/YellowCircleList";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const SelfCareContent1Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <YellowCircleList>
          <li>
            <b>Identify activities</b> and practices that support your
            well-being and help you to sustain positive self-care now and into
            the future.
          </li>
          <li>
            <b>Build a network</b> of support, especially with friends who have
            children the same age as yours. Talking to someone with similar
            experiences can make you feel less alone.
          </li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(SelfCareContent1Slide)``;
