import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import PlusExpandable from "../../PlusExpandable";
import YellowCircleList from "../../../../common/components/YellowCircleList";

const routines = [
  {
    id: "1",
    header: "Bedtime routines",
    content: (
      <>
        <p>
          <b>Plan to give time to transition to bedtime.</b> Doing small steps
          ahead of time, like getting pajamas on, brushing teeth, and shutting
          down the house can help prepare kids for the idea that bedtime is
          coming.
        </p>
        <YellowCircleList>
          <li>
            Prompt your child that you are about to transition to bedtime: “In
            three minutes it will be time to put toys away for bedtime.”
          </li>
        </YellowCircleList>
        <p>
          <b>Consider an appropriate bedtime for your child.</b> Children need a
          lot more sleep than adults and sometimes they are harder to get to bed
          the more tired they are.
        </p>
        <p>
          <b>Give options.</b> Allow your child to choose their jammies, which
          book they read or song they sing. When you’re doing activities like
          teeth brushing, offer choices like, “would you like me to put the
          toothpaste on the toothbrush or do you want to do it?”
        </p>
      </>
    ),
  },
  {
    id: "2",
    header: "Morning routines",
    content: (
      <>
        <p>
          <b>
            You might need to help your child with transitions in the morning.
          </b>{" "}
          It’s helpful to model the behavior you want to see in your children.
        </p>
        <p>
          <b>Plan to get up before your child to prepare for the day.</b> If you
          are up and ready to go, it will be easier to help to your child get
          ready.
        </p>
        <p>
          <b>Consider tidying the house.</b> If your child sees that all the
          shoes, toys and clothes are put away then they are more likely to do
          that themselves.
        </p>
        <p>
          <b>Give options.</b> If you let your child choose which shirt to wear,
          they may be more likely to get dressed for you.
        </p>
      </>
    ),
  },
  {
    id: "3",
    header: "Mealtime routines",
    content: (
      <>
        <p>
          <b>
            Having a routine can help children know what to expect when it is
            time for meals or snacks.
          </b>{" "}
          Sitting down together for mealtime can teach your child how to eat and
          helps your child develop social skills.
        </p>
        <p>
          <b>Have clear rules for mealtimes like</b>
        </p>
        <YellowCircleList>
          <li>Wash hands before sitting down.</li>
          <li>Try at least one bite of each item on the plate.</li>
          <li>Ask to be excused before you leave the table.</li>
        </YellowCircleList>
        <p>
          <b>
            Plan simple healthy meals that your child is willing and able to
            eat.
          </b>
        </p>
        <YellowCircleList>
          <li>Keeping meals simple will make preparation easier for you.</li>
          <li>
            Choosing foods your child is likely to eat can help you avoid power
            struggles.
          </li>
          <li>
            Consider slowly introducing new foods to expand your child’s diet.
            Offering new foods at the same time that you offer foods your child 
            likes can ease mealtime conflicts. Remember, it’s common for children 
            not to like new foods when they are younger.
          </li>
        </YellowCircleList>
      </>
    ),
  },
];

interface Props { 
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ProactiveParentingRoutineTimesSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;
  const [expandedRoutineId, setExpandedRoutineId] = useState<string | null>(
    routines[0].id
  );

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>Routines</h3>
        <p>
          There are three times of day when routines can be most helpful. Think
          about your days. How could you use proactive parenting skills to set
          you and your child up for success?
        </p>
      </section>
      <section>
        <RoutineListInstructions>
          Tap on the plus below to learn more.
        </RoutineListInstructions>
        <RoutineList>
          {routines.map((routine) => (
            <li key={routine.id}>
              <PlusExpandable
                header={routine.header}
                open={expandedRoutineId === routine.id}
                onClick={() => {
                  setExpandedRoutineId(
                    expandedRoutineId === routine.id ? null : routine.id
                  );
                }}
              >
                {routine.content}
              </PlusExpandable>
            </li>
          ))}
        </RoutineList>
      </section>
    </SlidePage>
  );
};

export default styled(ProactiveParentingRoutineTimesSlide)``;

const RoutineListInstructions = styled("p")`
  color: #ff6762;
  font-weight: bold;
`;

const RoutineList = styled("ul")`
  list-style: none;
  margin-top: 1.25rem;

  p,
  ${YellowCircleList} {
    margin-bottom: 1.25rem;
  }

  li + li {
    margin-top: 2rem;
  }
`;
