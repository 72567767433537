import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { BearIcons } from "../../../../common/components/BearIcon";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import ActivitySlidePage from "../../ActivitySlidePage";
import VideoId from "shared/lib/enums/VideoId";

const activityKind =
  ModuleActivityKind.COPING_WITH_STRESS_LET_THOUGHTS_GO_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const ManagingStressContent2Slide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [onPlayStart, onPlayEnd, videoCompleted] = useVideoActivity(
    activityKind,
    VideoId.COPING_WITH_STRESS_LET_THOUGHTS_GO_VIDEO
  );

  const handleSlideNext = useCallback(() => {
    onPlayEnd(true);
    onNext();
  }, [onPlayEnd, onNext]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      activityKind={activityKind}
      title={module.name}
      bearIcon={BearIcons.BABY_NEUTRAL}
      onNext={handleSlideNext}
    >
      <section>
        <h3>MANAGING STRESS</h3>
        <p>
          One useful strategy to try when you’re stressed is to let go of the
          thoughts that are adding to your bad feelings. Watch this video to see
          how.
        </p>
      </section>
      <VideoActivity
        onEnded={onPlayEnd}
        onStarted={onPlayStart}
        videoId={VideoId.COPING_WITH_STRESS_LET_THOUGHTS_GO_VIDEO}
      />
    </ActivitySlidePage>
  );
};

export default styled(ManagingStressContent2Slide)``;
