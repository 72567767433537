import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import ActivityBox from "../../ActivityBox";
import { DARKEST_BLUE } from "../../../../colors";
import { CheckIcon } from "../../../../common/components/Icons";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { PraiseGrowthValue } from "shared/lib/interfaces/ModuleActivity";
import plantPhase1 from "../../../images/plant_phase_one.png";
import plantPhase2 from "../../../images/plant_phase_two.png";
import plantPhase3 from "../../../images/plant_phase_three.png";
import plantPhase4 from "../../../images/plant_phase_four.png";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.PRAISE_IDEA_GROWTH;

const defaultValue: PraiseGrowthValue = {
  thankYouForBrushing: false,
  greatJobPickingUp: false,
  imSoProudForGettingInBed: false,
};

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const EncouragementAndPraiseContent3Slide: FC<Props> = (props) => {
  const { module, ...rest } = props;
  const [value = defaultValue, setValue] = useActivity(activityKind);

  const handleCheckboxChange = (key: keyof PraiseGrowthValue) => () =>
    setValue({
      ...value,
      [key]: !value[key],
    });

  const plantSize =
    (value.thankYouForBrushing ? 1 : 0) +
    (value.greatJobPickingUp ? 1 : 0) +
    (value.imSoProudForGettingInBed ? 1 : 0);

  const plantImages = [plantPhase1, plantPhase2, plantPhase3, plantPhase4];

  return (
    <ActivitySlidePage
      {...rest}
      activityKind={activityKind}
      light
      darkHeader
      title={module.name}
      showNextButton={plantSize === 3}
      nextButtonText="Finish Activity"
      lightNextButton
    >
      <ActivityBox icon="pointer">
        <Instructions>
          Your turn: Click each praise idea and watch the growth!
        </Instructions>
      </ActivityBox>
      <section>
        <Choice>
          <input
            type="checkbox"
            checked={value.thankYouForBrushing}
            onChange={handleCheckboxChange("thankYouForBrushing")}
          />
          <span>Thank you for brushing your teeth.</span>
          <CheckIcon />
        </Choice>
        <Choice>
          <input
            type="checkbox"
            checked={value.greatJobPickingUp}
            onChange={handleCheckboxChange("greatJobPickingUp")}
          />
          <span>Great job picking up the toys, give me a high five!</span>
          <CheckIcon />
        </Choice>
        <Choice>
          <input
            type="checkbox"
            checked={value.imSoProudForGettingInBed}
            onChange={handleCheckboxChange("imSoProudForGettingInBed")}
          />
          <span>
            I am so proud of you for getting into bed when I asked, you get a
            big hug!
          </span>
          <CheckIcon />
        </Choice>
        <FooterText>What you focus on, grows!</FooterText>
        <RedFooterText>(so focus on the positive)</RedFooterText>
        <Plant>
          <PlantImage src={plantImages[plantSize]} alt="Plant Growing" />
        </Plant>
      </section>
    </ActivitySlidePage>
  );
};

export default styled(EncouragementAndPraiseContent3Slide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const Instructions = styled("p")`
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.35rem;
  text-align: center;
`;

const Choice = styled("label")`
  color: ${DARKEST_BLUE};
  position: relative;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  cursor: pointer;

  > svg {
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);
    color: #739442;
    font-size: 1rem;
  }

  > span {
    transition: color 0.2s;
  }

  input {
    display: none;
  }

  input:checked + span {
    font-style: italic;
    color: #708897;
  }

  input:checked ~ svg {
    opacity: 1;
  }

  & + & {
    margin-top: 0.5rem;
  }
`;

const Plant = styled("div")`
  position: relative;
  height: 120px;
  text-align: center;
  height: 168px;
  margin-bottom: 20px;
`;

const PlantImage = styled("img")`
  width: 212px;
  height: auto;
  position: absolute;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
`;

const FooterText = styled("h4")`
  color: #0b4568;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0;
`;

const RedFooterText = styled("h4")`
  color: #ff6762;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
`;
