import React, { FC, ReactNode, useMemo, useCallback, FormEvent } from "react";
import styled from "styled-components/macro";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import Mood from "shared/lib/enums/Mood";
import MoodRadio from "./MoodRadio";
import { DARKEST_BLUE } from "../../colors";

let nameCounter = 1;

interface Props {
  value?: Mood | null;
  label?: ReactNode;
  light?: boolean;
  dark?: boolean;
  lightLabel?: boolean;
  darkLabel?: boolean;
  onChange?(mood: Mood): any;
}

const MoodInput: FC<Props> = ({
  value,
  label,
  light = true,
  dark = !light,
  lightLabel = false,
  darkLabel = !lightLabel,
  onChange,
  ...rest
}) => {
  const name = useMemo(() => `mood input ${nameCounter++}`, []); // get a unique name for the radio group

  const handleMoodChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.currentTarget.value as Mood);
      }
    },
    [onChange]
  );

  return (
    <Column {...rest}>
      {!!label && <Label dark={darkLabel}>{label}</Label>}
      <Row>
        <MoodRadio
          name={name}
          dark={dark}
          checked={value === Mood.HAPPY}
          mood={Mood.HAPPY}
          onChange={handleMoodChange}
        />
        <MoodRadio
          name={name}
          dark={dark}
          checked={value === Mood.OKAY}
          mood={Mood.OKAY}
          onChange={handleMoodChange}
        />
        <MoodRadio
          name={name}
          dark={dark}
          checked={value === Mood.SAD}
          mood={Mood.SAD}
          onChange={handleMoodChange}
        />
      </Row>
    </Column>
  );
};

export default styled(MoodInput)`
  display: inline-flex;
  align-items: center;

  ${MoodRadio} + ${MoodRadio} {
    margin-left: 1rem;
  }

  & + & {
    margin-top: 1.25rem;
  }
`;

const Label = styled("label")<{ dark: boolean }>`
  color: ${(props) => (props.dark ? DARKEST_BLUE : "white")};
  font-size: 1rem;
  margin-bottom: 1rem;
  max-width: 230px;
  font-weight: bold;
  text-align: center;
`;
