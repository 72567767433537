import styled, { css } from "styled-components/macro";
import "react-phone-number-input/style.css";
import ReactPhoneNumberInput from "react-phone-number-input/input";

const inputStyles = css`
  height: 40px;
  padding: 0 0 0 20px;
  margin: 0 0 0 0;
  border: none;
  outline: none;
  font-size: 1rem;
  border-radius: 8px;
  background-color: #ffffff;

  &::placeholder {
    color: #aaa;
  }
`;

const Input = styled("input")`
  ${inputStyles}
`;

export const PhoneNumberInput = styled(ReactPhoneNumberInput).attrs({
  defaultCountry: "US",
})`
  ${inputStyles}
`;

export default Input;
