import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import LoginForm from "../components/LoginForm";
import login from "../api/login";
import { useHistory } from "react-router-dom";
import FcuLogo from "../../common/components/FcuLogo";
import waveBackgroundCss from "../../common/utils/waveBackgroundCss";
import { patchSearchParams } from "../../common/hooks/useSearchParamState";

interface Props {
  refreshUser(): any;
}

const LoginPage: FC<Props> = ({ refreshUser, ...rest }) => {
  const history = useHistory();

  const handleLoginSubmit = useCallback(
    async (credentials) => {
      await login(credentials);
      await refreshUser();
      history.replace("/");
      patchSearchParams(history, { welcomeBack: true });
    },
    [refreshUser, history]
  );

  useDocumentTitle("Login");

  return (
    <Page {...rest}>
      <Logo />
      <LoginForm onSubmit={handleLoginSubmit} />
      <TrademarkText>
        Family Check-up® is a Registered Trademark
        <br />
        of the University of Oregon
      </TrademarkText>
    </Page>
  );
};

export default styled(LoginPage)`
  ${waveBackgroundCss}
  padding-top: 2rem;
  align-items: center;
  justify-content: center;
`;

const Logo = styled(FcuLogo)`
  height: 171px;
  width: 166px;
  flex-shrink: 0;

  @media (min-width: 800px) {
    height: ${171 * 1.5}px;
    width: ${166 * 1.5}px;
  }
`;

const TrademarkText = styled("p")`
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  flex-shrink: 0;
`;
