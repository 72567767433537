import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ProactiveParentingPlaySuggestionsSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>PLAY</h3>
        <p>
          How can you add quality play time with your child into your regular
          routine?
        </p>
      </section>
      <section>
        <YellowCircleList>
          <li>Tie it to something you already do each day</li>
          <li>Start small</li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(ProactiveParentingPlaySuggestionsSlide)`
  ${YellowCircleList} li {
    font-weight: bold;
  }
`;
