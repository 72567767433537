import React, { FC } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "./auth/pages/LoginPage";
import ForgotPasswordPage from "./auth/pages/ForgotPasswordPage";
import ResetPasswordPage from "./auth/pages/ResetPasswordPage";
import AcceptInvitePage from "./invitation/pages/AcceptInvitePage";

interface Props {
  refreshUser(): Promise<void>;
}

const UnauthorizedRoot: FC<Props> = ({ refreshUser }) => (
  <Router>
    <Switch>
      <Route path="/forgot-password">
        <ForgotPasswordPage refreshUser={refreshUser} />
      </Route>
      <Route path="/reset-password">
        <ResetPasswordPage refreshUser={refreshUser} />
      </Route>
      <Route path="/accept-invite">
        <AcceptInvitePage />
      </Route>
      <Route path="/">
        <LoginPage refreshUser={refreshUser} />
      </Route>
    </Switch>
  </Router>
);

export default UnauthorizedRoot;
