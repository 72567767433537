import React, { FC, ReactNode, useState, useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ActivityBox from "../../ActivityBox";
import Card from "../../../../common/components/Card";
import xCircleIconSrc from "../../../images/x_circle_icon.png";
import thumbsUpCircleIconSrc from "../../../images/thumbs_up.png";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.RULES_AND_CONSEQUENCES_WHEN_THEN_MATCH;
const scenarios = [
  {
    id: 1,
    question: "You finish your vegetables",
    choices: [
      { id: "A", text: "You can pick out a shirt for school.", correct: false },
      { id: "B", text: "You can have a cookie.", correct: true },
      { id: "C", text: "You can pick out a book.", correct: false },
      { id: "D", text: "You can get out the play dough.", correct: false },
    ],
  },
  {
    id: 2,
    question: "You brush your teeth",
    choices: [
      { id: "A", text: "You can pick out a shirt for school.", correct: false },
      { id: "B", text: "You can have a cookie.", correct: false },
      { id: "C", text: "You can pick out a book.", correct: true },
      { id: "D", text: "You can get out the play dough.", correct: false },
    ],
  },
  {
    id: 3,
    question: "You put your blocks away",
    choices: [
      { id: "A", text: "You can pick out a shirt for school.", correct: false },
      { id: "B", text: "You can have a cookie.", correct: false },
      { id: "C", text: "You can pick out a book.", correct: false },
      { id: "D", text: "You can get out the play dough.", correct: true },
    ],
  },
];

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const RulesAndConsequencesSlide16: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const setActivityValue = useActivity(activityKind)[1];
  const [scenario, setScenario] = useState(scenarios[0]);
  const [selectedChoiceId, setSelectedChoiceId] = useState<string | null>(null);
  const [selectedChoiceIds, setSelectedChoiceIds] = useState<string[]>([]);
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);

  const handleNext = useCallback(() => {
    const scenarioIndex = scenarios.indexOf(scenario);
    if (scenarioIndex === scenarios.length - 1) {
      setActivityValue({ choices: selectedChoiceIds });
      if (isReview) {
        onNext();
      } else {
        setShowSegmentOverlay(true);
      }
    } else {
      setSelectedChoiceId(null);
      setScenario(scenarios[scenarioIndex + 1]);
    }
  }, [setActivityValue, scenario, selectedChoiceIds, isReview, onNext]);

  useEffect(() => {
    if (selectedChoiceId) {
      setSelectedChoiceIds((value) => [...value, selectedChoiceId]);
    }
  }, [selectedChoiceId]);

  return (
    <ActivitySlidePage
      {...rest}
      activityKind={activityKind}
      title={module.name}
      light
      lightNextButton
      nextButtonText="Continue"
      hideNextButton={!selectedChoiceId && !isReview}
      onNext={handleNext}
      isReview={isReview}
    >
      <ActivityBox icon="pointer">
        <Title>Your turn:</Title>
        <Instructions>
          Think about your clear rules, then see if you can match the following
          When/Then commands correctly.
        </Instructions>
        <ScenarioCard>
          <CardTitle>WHEN…</CardTitle>
          <ScenarioText>{scenario.question}</ScenarioText>
          <CardTitle>THEN…</CardTitle>
          <ChoiceList>
            {scenario.choices.map((choice) => {
              let circleContent: ReactNode;
              const isSelected = choice.id === selectedChoiceId;

              if (isSelected) {
                if (choice.correct) {
                  circleContent = (
                    <ChoiceIcon src={thumbsUpCircleIconSrc} alt="Correct" />
                  );
                } else {
                  circleContent = (
                    <ChoiceIcon src={xCircleIconSrc} alt="Incorrect" />
                  );
                }
              } else {
                circleContent = choice.id; // display the letter
              }

              return (
                <ChoiceListItem
                  key={choice.id}
                  onClick={() => {
                    setSelectedChoiceId(choice.id);
                  }}
                >
                  <ChoiceCircle filled={isSelected}>
                    {circleContent}
                  </ChoiceCircle>
                  <ChoiceText>{choice.text}</ChoiceText>
                </ChoiceListItem>
              );
            })}
          </ChoiceList>
        </ScenarioCard>
      </ActivityBox>
      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Awesome!"
      />
    </ActivitySlidePage>
  );
};

export default styled(RulesAndConsequencesSlide16)``;

const Title = styled("h3")`
  color: #0e3750;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
`;

const Instructions = styled("p")`
  text-align: center;
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.1rem;
  margin: 0.5rem 1rem 2.5rem 1rem;
`;

const ScenarioCard = styled(Card)`
  padding: 1rem 2.5rem 2rem 2.5rem;
`;

const CardTitle = styled("h4")`
  color: #0e3750;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 0 0.75rem 0;
`;

const ScenarioText = styled("p")`
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 2rem;
`;

const ChoiceList = styled("ul")``;

const ChoiceListItem = styled("li")`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 1rem;
  }
`;

const ChoiceCircle = styled("div")<{ filled: boolean }>`
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  border: 2px solid #4dacbe;
  text-align: center;
  padding-top: 0.35rem;
  margin-right: 0.75rem;
  color: #4dacbe;
  font-size: 29px;
  font-weight: 600;
  flex-shrink: 0;
  position: relative;
  background-color: ${(props) => (props.filled ? "#CCFCEE" : "white")};
`;

const ChoiceText = styled("p")`
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2rem;
`;

const ChoiceIcon = styled("img")`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 1.5rem;
  width: 1.5rem;
`;
