import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide6: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>CLEAR RULES</h3>
        <p>
          Why are rules important?
          <br />
          <br />
          Setting clear rules and limits is an important part of parenting.
          Parents who have clear rules and limits give their children many
          advantages in life.
        </p>
        <YellowCircleList>
          <li>Fewer emotional problems</li>
          <li>Improved brain development</li>
          <li>Higher grades and school performance</li>
          <li>Better behavior at home</li>
          <li>More friends</li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide6)`
  ${YellowCircleList} {
    margin-top: 1rem;
  }
`;
