import React, { FC } from "react";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import HelpfulUnhelpfulIcon from "../../HelpfulUnhelpfulIcon";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const HomeSafetyPreQuizSlide: FC<Props> = (props) => {
  const { module, ...rest } = props;

  return (
    <SlidePage
      {...rest}
      lightNextButton
      nextButtonText="Start Sorting"
      title={module.name}
    >
      <section>
        <h3>Home Safety</h3>
        <p>
          Do you know what to do to keep children safe when substances are in
          your home?
        </p>
      </section>
      <section>
        <p>
          Tap the button to test if you know the do’s and don’ts when it comes
          to substance use when you have children in your home.
        </p>
      </section>
      <section>
        <ExampleRow>
          <DoDontBox>
            <DoDontLabel>DO</DoDontLabel>
            <HelpfulUnhelpfulIcon icon="home" helpful alt="do" />
          </DoDontBox>
          <DoDontBox>
            <DoDontLabel>DON'T</DoDontLabel>
            <HelpfulUnhelpfulIcon icon="home" helpful={false} alt="don't" />
          </DoDontBox>
        </ExampleRow>
      </section>
    </SlidePage>
  );
};

export default styled(HomeSafetyPreQuizSlide)``;

const DoDontBox = styled(Column)`
  border: 2px dashed #4dacbe;
  padding: 0.75rem 1.25rem 1rem 1.25rem;
  margin-top: 3rem;

  & + & {
    margin-left: 0.75rem;
  }
`;

const ExampleRow = styled(Row)`
  justify-content: center;
`;

const DoDontLabel = styled("div")`
  color: #4dacbe;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3rem;
  text-align: center;
  margin-bottom: 0.5rem;
`;
