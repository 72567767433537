import React, { FC } from "react";
import noop from "shared/lib/utils/noop";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import PlanActivity from "shared/lib/interfaces/PlanActivity";
import { useActivity } from "../../modules/contexts/ModuleActivityContext";
import PlanActivityCard from "../../modules/components/PlanActivityCard";
import TipBagActivity from "../../modules/components/TipBagActivity";
import {
  healthyRoutinesPlanSuggestionTitle,
  healthyRoutinesPlanSuggestions,
} from "../../modules/components/slides/WellnessAndSelfCare/HealthyRoutinesPlanSlide";
import SensitiveResponsiveActivityCard, {
  SensitiveResponsiveActivityCardControlled,
} from "../../modules/components/SensitiveResponsiveActivityCard";
import {
  relationshipBuildingSituation1RecommendedResponses,
  relationshipBuildingSituation1Situation,
} from "../../modules/components/slides/PositiveParenting/RelationshipBuildingSituationExample1Slide";
import {
  relationshipBuildingSituation2RecommendedResponses,
  relationshipBuildingSituation2Situation,
} from "../../modules/components/slides/PositiveParenting/RelationshipBuildingSituationExample2Slide";
import {
  proactiveParentingTips,
  proactiveParentingTipBagInstructions,
} from "../../modules/components/slides/ProactiveParenting/ProactiveParentingTipBagSlide";
import CommitmentFormCard from "../../modules/components/CommitmentFormCard";
import { playCommitmentPlaceholder } from "../../modules/components/slides/ProactiveParenting/ProactiveParentingPlayCommitmentSlide";
import ProactiveParentingPlanQuiz from "../../modules/components/ProactiveParentingPlanQuiz";
import {
  parentingAndSubstanceUseCravingTips,
  parentingAndSubstanceUseCravingTipInstructions,
} from "../../modules/components/slides/ParentingAndSubstanceUse/ParentingAndSubstanceUseTipBagSlide";
import EffectiveCommunicationStatementComparisonActivity from "../../modules/components/EffectiveCommunicationStatementComparisonActivity";
import { DepressionManagementPlanActivity } from "../../modules/components/slides/WellnessAndSelfCare/ManagingDepressionPlanSlide";
import { SelfCareActivityChecklistActivity } from "../../modules/components/slides/WellnessAndSelfCare/SelfCarePlanSlide";

// TODO: Add Parenting & Substance Use module activities

interface Props {
  planActivity: Pick<PlanActivity, "activityKind">;
}

const ParticipantPlanActivity: FC<Props> = ({ planActivity, ...rest }) => {
  const [activityValue, setActivityValue] = useActivity<any>(
    planActivity.activityKind
  );

  switch (planActivity.activityKind) {
    case ModuleActivityKind.SELF_CARE_ACTIVITY_CHECKLIST:
      return (
        <Column {...rest}>
          <SelfCareActivityChecklistActivity
            value={activityValue}
            onChange={setActivityValue}
          />
        </Column>
      );
    case ModuleActivityKind.DEPRESSION_MANAGEMENT_PLAN:
      return (
        <Column {...rest}>
          <DepressionManagementPlanActivity
            value={activityValue}
            onChange={setActivityValue}
          />
        </Column>
      );
    case ModuleActivityKind.HEALTHY_ROUTINES_PLAN:
      return (
        <Column {...rest}>
          <PlanActivityCard
            value={activityValue}
            onChange={setActivityValue}
            suggestionTitle={healthyRoutinesPlanSuggestionTitle}
            suggestions={healthyRoutinesPlanSuggestions}
          />
        </Column>
      );
    case ModuleActivityKind.RELATIONSHIP_BUILDING_SITUATION_EXAMPLE_1:
      return (
        <Column {...rest}>
          <SensitiveResponsiveActivityCardControlled
            value={activityValue}
            onChange={setActivityValue}
            recommendedResponses={
              relationshipBuildingSituation1RecommendedResponses
            }
            situation={relationshipBuildingSituation1Situation}
          />
        </Column>
      );
    case ModuleActivityKind.RELATIONSHIP_BUILDING_SITUATION_EXAMPLE_2:
      return (
        <Column {...rest}>
          <SensitiveResponsiveActivityCardControlled
            value={activityValue}
            onChange={setActivityValue}
            recommendedResponses={
              relationshipBuildingSituation2RecommendedResponses
            }
            situation={relationshipBuildingSituation2Situation}
          />
        </Column>
      );
    case ModuleActivityKind.PROACTIVE_PARENTING_TIP_BAG:
      return (
        <Column {...rest}>
          <TipBagActivity
            hideIcon
            hideInstructions
            tips={proactiveParentingTips}
            instructions={proactiveParentingTipBagInstructions}
          />
        </Column>
      );
    case ModuleActivityKind.PROACTIVE_PARENTING_PLAY_COMMITMENT:
      return (
        <Column {...rest}>
          <CommitmentFormCard
            value={activityValue || { afterI: "", iWill: "" }}
            onChange={setActivityValue}
            placeholder={playCommitmentPlaceholder}
          />
        </Column>
      );
    case ModuleActivityKind.PROACTIVE_PARENTING_PLAN_QUIZ:
      return (
        <Column {...rest}>
          <ProactiveParentingPlanQuiz
            activityKind={ModuleActivityKind.PROACTIVE_PARENTING_PLAN_QUIZ}
            onChange={(value) => {
              if (value && value.length > 0) {
                setActivityValue(value);
              }
            }}
          />
        </Column>
      );
    case ModuleActivityKind.PARENTING_AND_SUBSTANCE_USE_CRAVING_TIP_BAG:
      return (
        <Column {...rest}>
          <TipBagActivity
            hideIcon
            hideInstructions
            tips={parentingAndSubstanceUseCravingTips}
            instructions={parentingAndSubstanceUseCravingTipInstructions}
          />
        </Column>
      );
    case ModuleActivityKind.EFFECTIVE_COMMUNICATION_COMPARISON_STATEMENT:
      return (
        <Column {...rest}>
          <EffectiveCommunicationStatementComparisonActivity
            hideIcon
            responseResult={activityValue}
            onSubmit={setActivityValue}
            onFinished={noop}
          />
        </Column>
      );
    default:
      return <Column {...rest}>TODO: Add {planActivity.activityKind}</Column>;
  }
};

export default styled(ParticipantPlanActivity)`
  padding: 1.5rem 0.75rem 0.5rem 0.75rem;

  /**
  * Add rounded corners and box shadows
  */
  ${SensitiveResponsiveActivityCard},
  ${TipBagActivity}, 
  ${EffectiveCommunicationStatementComparisonActivity} {
    border-radius: 0.5rem;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  }
`;
