import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import { Correctness } from "shared/lib/constants/Correctness";
import { parentingAndSubstanceAbuseMythOrFactProblems } from "shared/lib/constants/parentingAndSubstanceAbuseMythOrFactProblems";
import { DARKEST_BLUE } from "../../../../colors";
import CorrectIncorrectMessageToast from "../../../../common/components/CorrectIncorrectMessageToast";
import ActivityBox, { InnerActivityBox } from "../../ActivityBox";
import ChevronCircleButton from "../../ChevronCircleButton";
import last from "@emberex/array-utils/lib/last";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const activityKind =
  ModuleActivityKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_MYTH_OR_FACT;

const ParentingAndSubstanceUseMythOrFactSlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [quizResults, setQuizResults] = useActivity(activityKind);
  const [currentSelection, setCurrentSelection] = useState("");
  const [problemIndex, setProblemIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);

  const currentProblem =
    parentingAndSubstanceAbuseMythOrFactProblems[problemIndex];

  const handleSubmit = useCallback(
    (selection: "myth" | "fact") => {
      setShowFeedback(true);

      const otherQuizResults =
        quizResults?.filter(
          (questions) => questions.questionId !== currentProblem.id
        ) ?? [];
      setQuizResults([
        ...otherQuizResults,
        { questionId: currentProblem.id, choice: selection },
      ]);
    },
    [setQuizResults, quizResults, currentProblem.id]
  );

  const handleMythSelection = useCallback(() => {
    setCurrentSelection("myth");
    handleSubmit("myth");
  }, [handleSubmit]);

  const handleFactSelection = useCallback(() => {
    setCurrentSelection("fact");
    handleSubmit("fact");
  }, [handleSubmit]);

  const navigate = useCallback(
    (index: 1 | -1) => () => {
      setShowFeedback(false);
      const prevAnsweredQuestion = quizResults?.find((problem) => {
        return problem.questionId === `problem-${problemIndex + 1 + index}`;
      });
      const replaceVal = prevAnsweredQuestion?.choice ?? "";
      setCurrentSelection(replaceVal);
      setProblemIndex((curIndex) => curIndex + index);
    },
    [
      setShowFeedback,
      setCurrentSelection,
      setProblemIndex,
      problemIndex,
      quizResults,
    ]
  );

  useEffect(() => {
    if (quizResults) {
      setCurrentSelection(last([...quizResults])?.choice ?? "");
    }
  }, [quizResults]);

  /**
   * Auto-hide feedback after 5 seconds.
   */
  useEffect(() => {
    if (showFeedback) {
      const timeout = setTimeout(() => {
        setShowFeedback(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
    return;
  }, [showFeedback]);

  const minQuizRequirement = quizResults && quizResults.length;
  const canContinue = !!(isReview || minQuizRequirement);

  return (
    <ActivitySlidePage
      {...rest}
      onNext={onNext}
      title={module.name}
      light
      activityKind={activityKind}
      showNextButton={canContinue}
      lightNextButton
      nextButtonText="Continue"
      isReview={isReview}
    >
      <ActivityBox
        {...rest}
        instructions="Your turn: Play this Myth or Fact game to check what you know about
    substances."
        icon="pointer"
      >
        <InnerActivityBox>
          <Progress>
            {problemIndex + 1} /{" "}
            {parentingAndSubstanceAbuseMythOrFactProblems.length}
          </Progress>
          <Header>Myth or Fact?</Header>
          <Statement>{currentProblem.statement}</Statement>
          {!!problemIndex && (
            <ProgressButton side="left" onClick={navigate(-1)} />
          )}
          <OptionPill
            selected={currentSelection === "myth"}
            onClick={handleMythSelection}
          >
            Myth
          </OptionPill>
          <OptionPill
            selected={currentSelection === "fact"}
            onClick={handleFactSelection}
          >
            Fact
          </OptionPill>
          {!(
            problemIndex ===
            parentingAndSubstanceAbuseMythOrFactProblems.length - 1
          ) && (
            <ProgressButton
              side="right"
              onClick={navigate(1)}
              disabled={!currentSelection}
            />
          )}
        </InnerActivityBox>

        {showFeedback && (
          <CorrectIncorrectMessageToast
            correctness={
              currentProblem.correct === currentSelection
                ? Correctness.CORRECT
                : Correctness.INCORRECT
            }
            message={currentProblem.feedback}
            onButtonClick={() => {}}
            hideButton
          />
        )}
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(ParentingAndSubstanceUseMythOrFactSlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;

const Progress = styled("div")`
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.4rem;
  margin-left: 0.7rem;
`;

const Header = styled("div")`
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.8rem;
`;

const Statement = styled("div")`
  text-align: center;
  margin-bottom: 1.6rem;
`;

const OptionPill = styled("button")<{ selected?: boolean }>`
  margin: 0 auto 1.2rem auto;
  box-sizing: border-box;
  height: 44px;
  width: 204px;
  border: 2px solid #08adc0;
  border-radius: 20px;
  background-color: ${(props) => (props.selected ? "#CCFCEE" : "white")};
  color: #0e3750;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
`;

const ProgressButton = styled(ChevronCircleButton)<{ side: "left" | "right" }>`
  position: absolute;
  left: ${(props) => props.side === "left" && "9px"};
  right: ${(props) => props.side === "right" && "9px"};
  transform: ${(props) => props.side === "left" && "rotate(180deg)"};
  bottom: 27%;
`;
