import React, { FC } from "react";
import styled from "styled-components/macro";
import warningSignImageSrc from "../images/warning_sign.png";

const WarningSignImage: FC = (props) => (
  <img {...props} src={warningSignImageSrc} alt="Caution" />
);

export default styled(WarningSignImage)`
  width: 95px;
  height: 95px;
  border-radius: 50%;
`;
