import React, { FC, ComponentPropsWithoutRef } from "react";
import styled from "styled-components/macro";
import { CheckIcon, XIcon } from "../../common/components/Icons";

interface Props extends ComponentPropsWithoutRef<"input"> {
  checkedColor: string;
  checkedCorrect?: boolean;
  inputType?: string;
}

const CorrectedRadio: FC<Props> = (props) => {
  const {
    className,
    checkedCorrect,
    checkedColor,
    inputType = "radio",
    ...rest
  } = props;

  return (
    <label className={className}>
      <input {...rest} type={inputType} />
      <FakeRadio checkedColor={checkedColor}>
        {checkedCorrect ? <CorrectIcon /> : <IncorrectIcon />}
      </FakeRadio>
    </label>
  );
};

export default styled(CorrectedRadio)`
  cursor: pointer;
  input {
    display: none;
  }
`;

const FakeRadio = styled("div")<{ checkedColor: string }>`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  border: 2px solid #ebebeb;
  transition: background-color 0.2s, border-color 0.2s;

  input:checked + & {
    background-color: ${(props) => props.checkedColor};
    border-color: ${(props) => props.checkedColor};
  }

  > svg {
    width: 100%;
    height: 100%;
  }
`;

const CorrectIcon = styled(CheckIcon)`
  transform: scale(0.85, 0.85);
`;

const IncorrectIcon = styled(XIcon)`
  transform: scale(1.75, 1.75);
`;
