import apiAxios from "../../apiAxios";
import SurveyTake from "shared/lib/interfaces/SurveyTake";

interface Props {
  participantId: number;
  surveyId: number;
}

export default async function completeSurveyTake(
  props: Props
): Promise<SurveyTake> {
  const { participantId, surveyId } = props;
  const response = await apiAxios.post(
    `/participants/${participantId}/surveys/${surveyId}/complete`
  );
  return response.data;
}
