import React, { FC, FormEvent, ReactNode, useCallback, useState } from "react";
import styled from "styled-components/macro";
import { RelationshipBulidingSituationValue } from "shared/lib/interfaces/ModuleActivity";
import Row from "@emberex/components/lib/Row";
import TextButton from "@emberex/components/lib/TextButton";
import { DARKEST_BLUE } from "../../colors";
import ShoeTextArea from "./ShoeTextArea";
import ShoeList, { ShoeListItem } from "./ShoeList";
import CircleStarImage from "./CircleStarImage";
import Column from "@emberex/components/lib/Column";
import PillButton from "../../surveys/components/PillButton";

const defaultValue: RelationshipBulidingSituationValue = {
  sensitive: "",
  responsive: "",
};

interface Props {
  situationTitle?: ReactNode;
  situation: ReactNode;
  recommendedResponses: RelationshipBulidingSituationValue;
  value: RelationshipBulidingSituationValue | undefined;
  onChange(value: RelationshipBulidingSituationValue): unknown;
  showResponses: boolean;
  onShowResponses(): unknown;
  onNext?(): unknown;
}

const SensitiveResponsiveActivityCard: FC<Props> = (props) => {
  const {
    situationTitle,
    situation,
    recommendedResponses,
    value = defaultValue,
    showResponses,
    onShowResponses,
    onChange,
    onNext,
    ...rest
  } = props;
  const [
    showingRecommendedResponses,
    setShowingRecommendedResponses,
  ] = useState(false);

  const handleRecommendedClick = useCallback(
    () => setShowingRecommendedResponses(true),
    []
  );

  const handleYourResponsesClick = useCallback(
    () => setShowingRecommendedResponses(false),
    []
  );

  const handleSensitiveChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      const sensitive = event.currentTarget.value;
      onChange({ ...value, sensitive });
    },
    [value, onChange]
  );

  const handleResponsiveChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      const responsive = event.currentTarget.value;
      onChange({ ...value, responsive });
    },
    [value, onChange]
  );

  if (showResponses) {
    return (
      <Column {...rest}>
        <Header>How do your responses compare?</Header>
        <ResponseNav>
          <ResponseNavButton
            onClick={handleYourResponsesClick}
            selected={!showingRecommendedResponses}
          >
            Your Responses
          </ResponseNavButton>
          <ResponseNavButton
            onClick={handleRecommendedClick}
            selected={showingRecommendedResponses}
          >
            Recommended Responses
            <ResponseNavIcon dark={showingRecommendedResponses} />
          </ResponseNavButton>
        </ResponseNav>
        <ShoeList>
          <ShoeListItem dark>
            <h4>BE SENSITIVE</h4>
            <p>
              {showingRecommendedResponses
                ? recommendedResponses.sensitive
                : value.sensitive}
            </p>
          </ShoeListItem>
          <ShoeListItem dark>
            <h4>BE RESPONSIVE</h4>
            <p>
              {showingRecommendedResponses
                ? recommendedResponses.responsive
                : value.responsive}
            </p>
          </ShoeListItem>
        </ShoeList>
        <Footer>
          {!!onNext && <NextButton onClick={onNext}>Continue</NextButton>}
        </Footer>
      </Column>
    );
  }

  return (
    <Column {...rest}>
      {!!situationTitle && <Header>{situationTitle}</Header>}
      <SituationSection>
        <SituationHeader>Situation</SituationHeader>
        <SituationText>{situation}</SituationText>
      </SituationSection>
      <ShoeTextArea
        number={1}
        label="be sensitive"
        placeholder="Enter what you would say."
        value={value.sensitive}
        onChange={handleSensitiveChange}
      />
      <ShoeTextArea
        number={2}
        label="be responsive"
        placeholder="Enter what you would say."
        value={value.responsive}
        onChange={handleResponsiveChange}
      />
      <Footer>
        <NextButton
          onClick={onShowResponses}
          hide={!value.sensitive || !value.responsive}
        >
          Submit
        </NextButton>
      </Footer>
    </Column>
  );
};

/**
 * Same as `SensitiveResponsiveActivityCard` except the state for showing/revealing the
 * responses is controlled with internal state.
 */
export const SensitiveResponsiveActivityCardControlled: FC<
  Omit<Props, "showResponses" | "onShowResponses">
> = (props) => {
  const [showResponses, setShowResponses] = useState(false);
  const handleShowResponses = useCallback(() => setShowResponses(true), []);
  return (
    <SensitiveResponsiveActivityCard
      {...props}
      showResponses={showResponses}
      onShowResponses={handleShowResponses}
    />
  );
};

export default styled(SensitiveResponsiveActivityCard)`
  background-color: #fdd965;
  padding: 0.75rem 0.75rem 0 0.75rem;

  ${ShoeList} {
    margin-left: 1rem;
    margin-right: 2rem;
  }

  ${ShoeListItem} {
    color: ${DARKEST_BLUE};
  }

  ${ShoeListItem} > h4 {
    margin-bottom: 0.5rem;
  }
`;

const Header = styled("h4")`
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.35rem;
  text-align: center;
  margin-bottom: 1.25rem;
`;

const Footer = styled(Column)`
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 2.25rem;
`;

const NextButton = styled(PillButton).attrs({ dark: false })`
  width: 13rem;
  max-width: 100%;
  margin-top: 2.25rem;
`;

const SituationHeader = styled("h5")`
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const SituationText = styled("p")`
  color: #0e3750;
`;

const SituationSection = styled("section")`
  margin-bottom: 1.25rem;
`;

const ResponseNav = styled(Row)`
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  margin-bottom: 1.3rem;
`;

const ResponseNavButton = styled(TextButton)<{ selected?: boolean }>`
  border: 4px solid white;
  width: 50%;
  text-align: center;
  color: ${DARKEST_BLUE};
  padding: 0 0 0 0;
  height: 54px;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  background-color: ${(props) => (props.selected ? "white" : "transparent")};
  transition: background-color 0.2s;
  position: relative;

  &:first-child {
    border-radius: 27px 0 0 27px;
    border-right-width: 0px;
  }

  &:last-child {
    border-radius: 0 27px 27px 0;
    border-left-width: 0px;
    padding-right: 1.5rem;
  }
`;

const ResponseNavIcon = styled(CircleStarImage)`
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translate(0, -50%);
  width: 22px;
  height: 22px;
`;
