// See: https://react-icons.netlify.com/#/
export {
  FaCog as GearIcon,
  FaSearch as SearchIcon,
  FaTrashAlt as DeleteIcon,
  FaPlus as PlusIcon,
  FaArrowLeft as ArrowLeftIcon,
  FaChevronDown as ChevronDownIcon,
  FaChevronLeft as ChevronLeftIcon,
  FaChevronUp as ChevronUpIcon,
  FaChevronRight as ChevronRightIcon,
  FaPencilAlt as PencilIcon,
  FaRegCheckSquare as CheckboxIcon,
  FaCheck as CheckIcon,
  FaRegSquare as CheckboxUncheckedIcon,
  FaUser as UserIcon,
  FaRegClock as ClockIcon,
  FaExclamation as ExclamationIcon,
  FaThumbsUp as ThumbsUpIcon,
  FaLock as LockIcon,
  FaPhone as PhoneIcon,
  FaEnvelope as EnvelopeIcon,
} from "react-icons/fa";

export {
  IoIosCalendar as CalendarIcon,
  IoIosClose as XIcon,
  IoMdArrowDown as ArrowDownIcon,
  IoMdArrowUp as ArrowUpIcon,
} from "react-icons/io";
