import React, { FC, FormEvent, MouseEvent, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Form from "@emberex/components/lib/Form";
import useInputState from "@emberex/react-utils/lib/useInputState";
import Column from "@emberex/components/lib/Column";
import isBlank from "shared/lib/utils/isBlank";
import { formatPhoneNumberPretty } from "shared/lib/utils/formatPhoneNumber";
import Participant from "shared/lib/interfaces/Participant";
import Row from "@emberex/components/lib/Row";
import Input from "../../common/components/Input";
import TextButton from "@emberex/components/lib/TextButton";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";

export interface Value {
  password?: string;
  profilePicture?: File | null;
}

export interface Props {
  participant: Pick<
    Participant,
    "id" | "firstName" | "lastName" | "phoneNumber" | "email" | "profilePicture"
  >;
  onSubmit(value: Value): unknown;
}

const ParticipantAccountForm: FC<Props> = (props) => {
  const { participant, onSubmit, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [password, handlePasswordChange] = useInputState("");
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState("");
  const [profileImageFile, setProfileImageFile] = useState<
    File | null | undefined
  >(undefined);
  const [visibleProfileImageUrl, setVisibleProfileImageUrl] = useState<
    string | null
  >(participant.profilePicture);

  // password changed, profile picture uploaded, or profile picture removed
  const submitEnabled = !!(
    (password.length > 0 && passwordConfirm.length > 0) ||
    profileImageFile ||
    (profileImageFile === null && participant.profilePicture)
  );

  useAsyncEffect(
    async (isCancelled) => {
      if (profileImageFile === null) {
        setVisibleProfileImageUrl(null);
        return;
      }
      if (!profileImageFile) {
        return;
      }
      const fileReadter = new FileReader();
      fileReadter.onload = (event) => {
        if (
          !isCancelled() &&
          event.target &&
          typeof event.target.result === "string"
        ) {
          setVisibleProfileImageUrl(event.target.result);
        }
      };
      fileReadter.readAsDataURL(profileImageFile);
    },
    [profileImageFile]
  );

  const handleProfilePictureFileChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      const file = files && files.length > 0 ? files[0] : null;
      setProfileImageFile(file);
    },
    []
  );

  const handleRemoveProfilePictureClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setProfileImageFile(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    const fields: Value = {};

    try {
      if (password || passwordConfirm) {
        if (
          (!isBlank(password) || !isBlank(passwordConfirm)) &&
          password !== passwordConfirm
        ) {
          throw new Error("Passwords don't match.");
        }
        fields.password = password;
      }

      if (profileImageFile instanceof File || profileImageFile === null) {
        fields.profilePicture = profileImageFile;
      }

      setSubmitting(true);
      await onSubmit(fields);
      setError(null);
      setSuccess(true);
    } catch (error) {
      setError(error);
      setSuccess(false);
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit, password, passwordConfirm, profileImageFile]);

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <Box>
        {!!error && (
          <FormRow>
            <ErrorText>{error.message}</ErrorText>
          </FormRow>
        )}
        {success && (
          <FormRow>
            <SuccessText>Saved successfully.</SuccessText>
          </FormRow>
        )}
        <FormRow>
          <StretchColumn>
            <LabelText>First Name:</LabelText>
            <div>{participant.firstName}</div>
          </StretchColumn>
          <StretchColumn>
            <LabelText>Last Name:</LabelText>
            <div>{participant.lastName}</div>
          </StretchColumn>
        </FormRow>
        <FormRow>
          <Column>
            <LabelText>Phone:</LabelText>
            <div>
              {participant.phoneNumber
                ? formatPhoneNumberPretty(participant.phoneNumber)
                : "N/A"}
            </div>
          </Column>
        </FormRow>
        <FormRow>
          <Column>
            <LabelText>Email:</LabelText>
            <div>{participant.email}</div>
          </Column>
        </FormRow>
        <FormRow>
          <StretchColumn>
            <LabelText as="label">New Password:</LabelText>
            <Input
              value={password}
              onChange={handlePasswordChange}
              type="password"
              autoComplete="new-password"
            />
          </StretchColumn>
        </FormRow>
        <FormRow>
          <StretchColumn>
            <LabelText as="label">Confirm Password:</LabelText>
            <Input
              value={passwordConfirm}
              onChange={handlePasswordConfirmChange}
              type="password"
              autoComplete="new-password"
            />
          </StretchColumn>
        </FormRow>
        {!!visibleProfileImageUrl && (
          <FormRow>
            <ProfilePicturePreview>
              <img src={visibleProfileImageUrl} alt="Profile" />
            </ProfilePicturePreview>
          </FormRow>
        )}
        <FormRow>
          <UploadPhotoButton>
            upload new photo
            <input type="file" onChange={handleProfilePictureFileChange} />
          </UploadPhotoButton>
        </FormRow>
        {!!visibleProfileImageUrl && (
          <FormRow>
            <ClearPhotoButton onClick={handleRemoveProfilePictureClick}>
              remove photo
            </ClearPhotoButton>
          </FormRow>
        )}
      </Box>
      <SaveButton disabled={submitting || !submitEnabled}>Save</SaveButton>
    </Form>
  );
};

export default styled(ParticipantAccountForm)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-weight: 500;
`;

const Box = styled(Column)`
  box-sizing: border-box;
  border: 1px solid #11355d;
  border-radius: 8px;
  background-color: #0b4568;
  padding: 28px 53px 28px 22px;
  color: white;
`;

const UploadPhotoButton = styled("label")`
  color: #4dacbe;
  font-size: 0.9rem;
  font-weight: 500;
  input {
    display: none;
  }
`;

const ClearPhotoButton = styled(TextButton)`
  color: #4dacbe;
  font-size: 0.9rem;
  font-weight: 500;
`;

const SaveButton = styled(TextButton)`
  height: 70px;
  width: 210px;
  border: 5px solid #0f2f4d;
  border-radius: 35px;
  background-color: #4dacbe;
  text-align: center;
  color: white;
  margin: 22px auto;
  align-self: center;

  &:disabled {
    opacity: 0.5;
  }
`;

const StretchColumn = styled(Column)`
  flex: 1 1 50%;
`;

const FormRow = styled(Row)`
  flex-shrink: 0;
  & + & {
    margin-top: 1.5rem;
  }
`;

const LabelText = styled("span")`
  color: #bbd7e8;
  margin-bottom: 0.25rem;
  font-weight: 600;
`;

const ErrorText = styled("div")`
  color: #f00;
`;

const SuccessText = styled("div")`
  color: #0f0;
`;

const ProfilePicturePreview = styled(Row)`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  justify-content: center;

  > img {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 50%;
  }
`;
