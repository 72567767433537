import apiAxios from "../../apiAxios";
import SurveyKind from "shared/lib/enums/SurveyKind";
import SurveyResponseValue from "shared/lib/interfaces/SurveyResponseValue";

interface Props {
  participantId: number;
  surveyIdOrKind: number | SurveyKind;
  responses: Array<{
    questionId: number;
    value: SurveyResponseValue | null;
  }>;
}

export default async function saveSurveyTakeResponses(
  props: Props
): Promise<void> {
  const { participantId, surveyIdOrKind, responses } = props;
  await apiAxios.put(
    `/participants/${participantId}/surveys/${surveyIdOrKind}/responses`,
    {
      responses,
    }
  );
}
