import React, { FC } from "react";
import styled from "styled-components/macro";
import Mood from "shared/lib/enums/Mood";
import moodHappySrc from "../images/mood_happy.png";
import moodHappyDarkSrc from "../images/mood_happy_dark.png";
import moodHappyActiveSrc from "../images/mood_happy_active.png";
import moodOkaySrc from "../images/mood_okay.png";
import moodOkayDarkSrc from "../images/mood_okay_dark.png";
import moodOkayActiveSrc from "../images/mood_okay_active.png";
import moodSadSrc from "../images/mood_sad.png";
import moodSadDarkSrc from "../images/mood_sad_dark.png";
import moodSadActiveSrc from "../images/mood_sad_active.png";

type Variant = "light" | "dark" | "active_dark" | "active_light";

const variants: Record<Variant, Record<Mood, { src: string; alt: string }>> = {
  light: {
    [Mood.HAPPY]: { src: moodHappySrc, alt: "happy" },
    [Mood.SAD]: { src: moodSadSrc, alt: "sad" },
    [Mood.OKAY]: { src: moodOkaySrc, alt: "okay" },
  },
  dark: {
    [Mood.HAPPY]: { src: moodHappyDarkSrc, alt: "happy" },
    [Mood.SAD]: { src: moodSadDarkSrc, alt: "sad" },
    [Mood.OKAY]: { src: moodOkayDarkSrc, alt: "okay" },
  },
  active_light: {
    [Mood.HAPPY]: { src: moodHappyActiveSrc, alt: "happy" },
    [Mood.SAD]: { src: moodSadActiveSrc, alt: "sad" },
    [Mood.OKAY]: { src: moodOkayActiveSrc, alt: "okay" },
  },
  active_dark: {
    [Mood.HAPPY]: { src: moodHappyActiveSrc, alt: "happy" },
    [Mood.SAD]: { src: moodSadActiveSrc, alt: "sad" },
    [Mood.OKAY]: { src: moodOkayActiveSrc, alt: "okay" },
  },
};

interface Props {
  mood: Mood;
  variant?: Variant;
}

const MoodImage: FC<Props> = (props) => {
  const { mood, variant = "light", ...rest } = props;
  const { src, alt } = variants[variant][mood];

  return <img {...rest} src={src} alt={alt} />;
};

export default styled(MoodImage)`
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;
