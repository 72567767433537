import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import VideoId from "shared/lib/enums/VideoId";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind =
  ModuleActivityKind.POSITIVE_PARENTING_NOTICE_BEHAVIOR_VIDEO;
const videoId = VideoId.POSITIVE_PARENTING_NOTICE_BEHAVIOR_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const UsingIncentivesUsageInstructionsSlide: FC<Props> = (props) => {
  const { module, section, isReview, onNext, ...rest } = props;
  const [onPlayStart, setVideoPlayed, videoCompleted] = useVideoActivity(
    activityKind,
    videoId
  );

  const handleNext = useCallback(() => {
    setVideoPlayed(true);
    onNext();
  }, [setVideoPlayed, onNext]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      title={module.name}
      activityKind={activityKind}
      onNext={handleNext}
    >
      <section>
        <Heading>How to use incentives and rewards</Heading>
        <p>
          You start by knowing what motivates your child. To make it fun, you
          can work with your child to come up with a list of rewards you can
          give.
        </p>
      </section>
      <VideoActivity
        onStarted={onPlayStart}
        onEnded={setVideoPlayed}
        videoId={videoId}
      />
    </ActivitySlidePage>
  );
};

export default styled(UsingIncentivesUsageInstructionsSlide)``;

const Heading = styled("h4")`
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: bold;
`;
