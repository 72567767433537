import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import usePlanSlide, { PlanSection } from "../../../hooks/usePlanSlide";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import Column from "@emberex/components/lib/Column";
import ExampleSection from "../../ExampleSection";
import Row from "@emberex/components/lib/Row";
import PlanLetter from "../../PlanLetter";
import ChevronCircleButton from "../../ChevronCircleButton";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}


const ProactiveParentingPlanExampleSlide: FC<Props> = (props) => {
  const { module, section, onBack, ...rest } = props;
  const {
    onBack: handleBack,
    showNextPlanSection: showNextPlanExampleSection,
    planSection: planExampleSection
  } = usePlanSlide({
    onBack,
    includeReview: true
  });

  let planExampleSectionContent: ReactNode;
  switch (planExampleSection) {
    case PlanSection.INTRO:
      planExampleSectionContent = (
        <section>
          <ExampleSection>
            Your 4-year-old refuses to get dressed in the morning, and watches
            TV instead.
          </ExampleSection>
          <ControlRow>
            <PlanLetterRow>
              <PlanLetter>P</PlanLetter>
              <PlanLetter>L</PlanLetter>
              <PlanLetter>A</PlanLetter>
              <PlanLetter>N</PlanLetter>
            </PlanLetterRow>
            <ChevronCircleButton onClick={showNextPlanExampleSection} />
          </ControlRow>
        </section>
      );
      break;
    case PlanSection.LETTER_P:
      planExampleSectionContent = (
        <section>
          <ExampleSection>
            Your 4-year-old refuses to get dressed in the morning, and watches
            TV instead.
          </ExampleSection>
          <ControlRow>
            <PlanLetterRow>
              <PlanLetter>P</PlanLetter>
              <PlanLetter faded>L</PlanLetter>
              <PlanLetter faded>A</PlanLetter>
              <PlanLetter faded>N</PlanLetter>
            </PlanLetterRow>
            <ChevronCircleButton onClick={showNextPlanExampleSection} />
          </ControlRow>
          <LetterMeaning> 
            P = <b>P</b>ay attention and identify the problem situations for your child. 
          </LetterMeaning>
          <h3>What is the problem?</h3>
          <YellowCircleList>
            <li>Getting dressed in the morning.</li>
          </YellowCircleList>
        </section>
      );
      break;
    case PlanSection.LETTER_L:
      planExampleSectionContent = (
        <section>
          <ExampleSection>
            Your 4-year-old refuses to get dressed in the morning, and watches
            TV instead.
          </ExampleSection>
          <ControlRow>
            <PlanLetterRow>
              <PlanLetter faded>P</PlanLetter>
              <PlanLetter>L</PlanLetter>
              <PlanLetter faded>A</PlanLetter>
              <PlanLetter faded>N</PlanLetter>
            </PlanLetterRow>
            <ChevronCircleButton onClick={showNextPlanExampleSection} />
          </ControlRow>
          <LetterMeaning>  
            L = <b>L</b>ook realistically at your child’s abilities in that situation. 
          </LetterMeaning>
          <h3>Can your child get dressed on their own?</h3>
          <YellowCircleList>
            <li>Yes, if you lay their clothes out.</li>
          </YellowCircleList>
        </section>
      );
      break;
    case PlanSection.LETTER_A:
      planExampleSectionContent = (
        <section>
          <ExampleSection>
            Your 4-year-old refuses to get dressed in the morning, and watches
            TV instead.
          </ExampleSection>
          <ControlRow>
            <PlanLetterRow>
              <PlanLetter faded>P</PlanLetter>
              <PlanLetter faded>L</PlanLetter>
              <PlanLetter>A</PlanLetter>
              <PlanLetter faded>N</PlanLetter>
            </PlanLetterRow>
            <ChevronCircleButton onClick={showNextPlanExampleSection} />
          </ControlRow>
          <LetterMeaning > 
            A = <b>A</b>djust difficult activities to maximize success and minimize negative emotions. 
          </LetterMeaning>
          <h3>Adjust for success.</h3>
          <YellowCircleList>
            <li>Lay out clothes, keep TV off until child is dressed.</li>
          </YellowCircleList>
        </section>
      );
      break;
    case PlanSection.LETTER_N:
      planExampleSectionContent = (
        <section>
          <ExampleSection>
            Your 4-year-old refuses to get dressed in the morning, and watches
            TV instead.
          </ExampleSection>
          <ControlRow>
            <PlanLetterRow>
              <PlanLetter faded>P</PlanLetter>
              <PlanLetter faded>L</PlanLetter>
              <PlanLetter faded>A</PlanLetter>
              <PlanLetter>N</PlanLetter>
            </PlanLetterRow>
            <ChevronCircleButton onClick={showNextPlanExampleSection} />
          </ControlRow>
          <LetterMeaning > 
            N = <b>N</b>otice and remember to prompt, suggest, and reward success! 
          </LetterMeaning>
          <h3>Don’t forget to tell your child they did a good job!</h3>
          <YellowCircleList>
            <li>Once your child is dressed, let them watch TV.</li>
          </YellowCircleList>
        </section>
      );
      break;
    case PlanSection.REVIEW:
      planExampleSectionContent = (
        <section>
          <ReviewHeader>Review the PLAN:</ReviewHeader>
          <PlanLetterList>
            <li>
              <PlanLetter>P</PlanLetter>
              <Column>
                <h4>What is the problem?</h4>
                <YellowCircleList>
                  <li>Getting dressed in the morning.</li>
                </YellowCircleList>
              </Column>
            </li>
            <li>
              <PlanLetter>L</PlanLetter>
              <Column>
                <h4>Can your child get dressed on their own?</h4>
                <YellowCircleList>
                  <li>Yes, if you lay their clothes out.</li>
                </YellowCircleList>
              </Column>
            </li>
            <li>
              <PlanLetter>A</PlanLetter>
              <Column>
                <h4>Adjust for success.</h4>
                <YellowCircleList>
                  <li>Lay out clothes, keep TV off until child is dressed.</li>
                </YellowCircleList>
              </Column>
            </li>
            <li>
              <PlanLetter>N</PlanLetter>
              <Column>
                <h4>Don’t forget to tell your child they did a good job!</h4>
                <YellowCircleList>
                  <li>Once your child is dressed, let them watch TV.</li>
                </YellowCircleList>
              </Column>
            </li>
          </PlanLetterList>
        </section>
      );
      break;
  }

  return (
    <SlidePage
      {...rest}
      title={module.name}
      showNextButton={planExampleSection === PlanSection.REVIEW}
      onBack={handleBack}
    >
      {planExampleSection !== PlanSection.REVIEW && (
        <section>
          <IntroParagraph
            hide={planExampleSection !== PlanSection.INTRO}
          >
            Next, we’ll look at an example situation using the P-L-A-N model.
          </IntroParagraph>
        </section>
      )}
      {planExampleSectionContent}
    </SlidePage>
  );
};

export default styled(ProactiveParentingPlanExampleSlide)`
  ${YellowCircleList} {
    margin-top: 1.25rem;
  }
`;

const ControlRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

const PlanLetterRow = styled(Row)`
  > ${PlanLetter} + ${PlanLetter} {
    margin-left: 10px;
  }
`;

const LetterMeaning = styled('p')`
  margin-bottom: 30px;
`;

const IntroParagraph = styled("p")<{ hide: boolean }>`
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

const PlanLetterList = styled("ul")`
  li {
    display: flex;
    flex-direction: row;

    ${PlanLetter} {
      margin-right: 1.25rem;
    }

    h4 {
      padding-top: 0.5rem;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  li + li {
    margin-top: 2rem;
  }

  ${YellowCircleList} {
    margin-top: 0.5rem;
  }
`;

const ReviewHeader = styled("div")`
  font-size: 1.25rem;
  font-weight: bold;
  color: #ff6762;
  margin-bottom: 1.5rem;
`;
