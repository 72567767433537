import React, { FC } from "react";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import HelpfulUnhelpfulIcon from "../../HelpfulUnhelpfulIcon";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const GivingDirectionHelpfulPreQuizSlide: FC<Props> = (props) => {
  const { module, ...rest } = props;

  return (
    <SlidePage
      {...rest}
      lightNextButton
      nextButtonText="Start Sorting"
      title={module.name}
    >
      <section>
        <p>
          Click the Start Sorting button to test if you can tell the difference
          between helpful and unhelpful ways of giving directions to your child.
        </p>
      </section>
      <section>
        <ExampleRow>
          <HelpfulUnhelpfulBox>
            <HelpfulUnhelpfulLabel>HELPFUL</HelpfulUnhelpfulLabel>
            <HelpfulUnhelpfulIcon helpful alt="helpful" />
          </HelpfulUnhelpfulBox>
          <HelpfulUnhelpfulBox>
            <HelpfulUnhelpfulLabel>UNHELPFUL</HelpfulUnhelpfulLabel>
            <HelpfulUnhelpfulIcon helpful={false} alt="unhelpful" />
          </HelpfulUnhelpfulBox>
        </ExampleRow>
      </section>
    </SlidePage>
  );
};

export default styled(GivingDirectionHelpfulPreQuizSlide)``;

const HelpfulUnhelpfulBox = styled(Column)`
  border: 2px dashed #4dacbe;
  padding: 0.75rem 1.25rem 1rem 1.25rem;
  margin-top: 3rem;

  & + & {
    margin-left: 0.75rem;
  }
`;

const ExampleRow = styled(Row)`
  justify-content: center;
`;

const HelpfulUnhelpfulLabel = styled("div")`
  color: #4dacbe;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3rem;
  text-align: center;
  margin-bottom: 0.5rem;
`;
