import React, { FC } from "react";
import styled from "styled-components/macro";
import SurveyResponseValue from "shared/lib/interfaces/SurveyResponseValue";
import Page from "@emberex/components/lib/Page";
import Survey from "shared/lib/interfaces/Survey";
import SurveyQuestion from "shared/lib/interfaces/SurveyQuestion";
import ProgressBar from "./ProgressBar";
import Column from "@emberex/components/lib/Column";
import SurveyPreQuestionText from "./SurveyPreQuestionText";
import SurveyPostQuestionText from "./SurveyPostQuestionText";
import SurveyQuestionText from "./SurveyQuestionText";
import SurveyResponseInput from "./SurveyResponseInput";
import PillButton from "./PillButton";
import SurveyPageHeader from "./SurveyPageHeader";
import Module from "shared/lib/interfaces/Module";

interface Props {
  survey: Survey;
  questionIndex: number;
  questionCount: number;
  question: Pick<
    SurveyQuestion,
    "kind" | "questionText" | "preQuestionText" | "postQuestionText" | "options"
  >;
  value: SurveyResponseValue | null;
  module?: Pick<Module, "icon">;
  hideNav?: boolean;
  readonly?: boolean;
  onChange(answer: SurveyResponseValue): any;
  onNext(): any;
  onBack(): any;
}

const SurveyQuestionPage: FC<Props> = (props) => {
  const {
    survey,
    module,
    question,
    value,
    questionIndex,
    questionCount,
    hideNav,
    readonly,
    onChange,
    onNext,
    onBack,
    ...rest
  } = props;

  return (
    <Page {...rest}>
      <SurveyPageHeader
        survey={survey}
        module={module}
        hideNav={hideNav}
        label="Survey"
      />
      <ProgressBar
        current={questionIndex + 1}
        total={questionCount}
        onBack={onBack}
      />
      {!!question.preQuestionText && (
        <SurveyPreQuestionText text={question.preQuestionText} />
      )}
      <SurveyQuestionText text={question.questionText} />
      {!!question.postQuestionText && (
        <SurveyPostQuestionText text={question.postQuestionText} />
      )}
      <QuestionInputContainer>
        <SurveyResponseInput
          question={question}
          onChange={onChange}
          value={value}
          readonly={readonly}
        />
      </QuestionInputContainer>
      <NextButton onClick={onNext} disabled={!value && !readonly}>
        Next
      </NextButton>
    </Page>
  );
};

export default styled(SurveyQuestionPage)`
  ${ProgressBar} {
    margin-bottom: 1.25rem;
    padding: 0 2rem 0 2rem;
  }

  ${PillButton} {
    margin: 0 auto;
  }

  ${SurveyPageHeader} {
    margin-bottom: 0.75rem;
  }

  background-color: #f4f6f7;
  padding-bottom: 4rem;
`;

const QuestionInputContainer = styled(Column)`
  align-items: center;
  width: 100%;
  padding: 3rem;
`;

const NextButton = styled(PillButton)`
  width: 210px;
`;
