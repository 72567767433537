import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const HealthyRoutinesMakingChangesSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage
      {...rest}
      title={module.name}
      lightNextButton
      nextButtonText="Build My Plan"
    >
      <section>
        <h3>{section.name}</h3>
        <p>Making changes can be hard, so:</p>
      </section>
      <section>
        <YellowCircleList>
          <li>Start small — change only one thing at a time</li>
          <li>Choose something realistic for your family</li>
          <li>Include your partner and children in your plan</li>
          <li>
            After you tried it, talk about how it went — did you like it? Did it
            make your life easier?
          </li>
          <li>Reward success!</li>
        </YellowCircleList>
      </section>
      <section>
        <p>
          On the next screen, choose 3 things you would like to add to your
          family’s daily routine.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(HealthyRoutinesMakingChangesSlide)``;
