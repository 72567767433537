import React, { FC } from "react";
import styled from "styled-components/macro";
import helpfulImageSrc from "../images/thumbs_up.png";
import unhelpfulImageSrc from "../images/thumbs_down.png";
import thumbsUpHomeSrc from "../images/thumbs_up_home.png";
import thumbsDownHomeSrc from "../images/thumbs_down_home.png";

const icons = {
  thumb: { helpfulIcon: helpfulImageSrc, unhelpfulIcon: unhelpfulImageSrc },
  home: { helpfulIcon: thumbsUpHomeSrc, unhelpfulIcon: thumbsDownHomeSrc },
};

export interface HelpfulUnhelpfulIconProps {
  icon?: keyof typeof icons;
}

interface Props extends HelpfulUnhelpfulIconProps {
  helpful: boolean;
  alt: string;
  src?: string;
}

const HelpfulUnhelpfulIcon: FC<Props> = (props) => {
  const { icon = "thumb", helpful, src: propSrc, alt, ...rest } = props;
  let src: string;

  if (propSrc) {
    src = propSrc;
  } else if (helpful) {
    src = icons[icon].helpfulIcon;
  } else {
    src = icons[icon].unhelpfulIcon;
  }

  return <img {...rest} src={src} alt={alt} />;
};

export default styled(HelpfulUnhelpfulIcon)`
  width: 119px;
  height: 119px;
  border-radius: 50%;
  flex-shrink: 0;
`;
