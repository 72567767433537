import React, { FC, useMemo } from "react";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import styled from "styled-components/macro";
import SegmentCompletionOverlay, {
  Props as RootOverlayProps,
} from "./SegmentCompletionOverlay";

interface Props
  extends Omit<
    RootOverlayProps,
    | "moduleName"
    | "currentSegment"
    | "segmentCount"
    | "nextButtonText"
    | "hideReturnButton"
  > {
  nextButtonText?: string;
  module: Pick<Module, "name" | "sections">;
  currentSection: Pick<ModuleSection, "id">;
  showConfetti?: boolean;
}

const ModuleSegmentCompletionOverlay: FC<Props> = (props) => {
  const { module, nextButtonText, currentSection, ...rest } = props;
  const { sections = [], name } = module;
  const currentSegment = useMemo(
    () =>
      Math.max(
        1,
        sections.findIndex((section) => section.id === currentSection.id) + 1
      ),
    [sections, currentSection]
  );
  const isFinished = currentSegment >= sections.length;
  return (
    <SegmentCompletionOverlay
      moduleName={name}
      segmentCount={sections.length}
      currentSegment={currentSegment}
      nextButtonText={nextButtonText ?? (isFinished ? "Finish" : "Continue")}
      hideReturnButton={isFinished}
      {...rest}
    />
  );
};

export default styled(ModuleSegmentCompletionOverlay)``;
