import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ShoeList, { ShoeListItem } from "../../ShoeList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RelationshipBuildingIntroSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          Parenting is hard work that can also be fun and rewarding. It’s
          important for parents to build a strong relationship with their child.
        </p>
      </section>
      <section>
        <HighlightedParagraph>
          There are 2 steps to building your relationship:
        </HighlightedParagraph>
      </section>
      <section>
        <ShoeList>
          <ShoeListItem>
            <b>BE SENSITIVE</b>
            <br />
            Recognize what your child needs in the moment.
          </ShoeListItem>
          <ShoeListItem>
            <b>BE RESPONSIVE</b>
            <br />
            Anticipate and meet your child’s need.
          </ShoeListItem>
        </ShoeList>
      </section>
    </SlidePage>
  );
};

export default styled(RelationshipBuildingIntroSlide)``;

const HighlightedParagraph = styled("p")`
  color: #ff6762;
  font-weight: bold;
`;
