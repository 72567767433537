import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import ResetPasswordForm from "../components/ResetPasswordForm";
import useSearchParams from "../../common/hooks/useSearchParams";
import submitPasswordReset from "../api/submitPasswordReset";
import waveBackgroundCss from "../../common/utils/waveBackgroundCss";

interface Props {
  refreshUser(): any;
}

const ResetPasswordPage: FC<Props> = ({ refreshUser, ...rest }) => {
  const resetToken = useSearchParams().get("resetToken");

  const handleSubmit = useCallback(
    async (password: string) => {
      if (!resetToken) {
        throw new Error("Invalid password reset token. Try resending.");
      }
      await submitPasswordReset({
        resetToken,
        password,
      });
    },
    [resetToken]
  );

  useDocumentTitle('Reset Password')

  return (
    <Page {...rest}>
      <ResetPasswordForm onSubmit={handleSubmit} />
    </Page>
  );
};

export default styled(ResetPasswordPage)`
  ${waveBackgroundCss}
  align-items: center;
  justify-content: center;
  color: white;
`;
