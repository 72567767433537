import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BearIcons } from "../../../../common/components/BearIcon";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import TipBagActivity from "../../TipBagActivity";
import ActivitySlidePage from "../../ActivitySlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";

const activityKind =
  ModuleActivityKind.PARENTING_AND_SUBSTANCE_USE_CRAVING_TIP_BAG;

const title_1 = (
  <>
    Get out of high-risk situations: <i>(avoid your triggers)</i> consider
    going...
  </>
);

const title_2 = (
  <>
    Get out of high-risk situations: <i>(avoid your triggers)</i> consider...
  </>
);

export const parentingAndSubstanceUseCravingTipInstructions =
  "Your turn: Each time you tap on the bag you will get a tip to help you through a strong craving.";

export const parentingAndSubstanceUseCravingTips = [
  {
    title: title_1,
    example: "home to be with supportive family members",
  },
  {
    title: "Distract yourself: try...",
    example: "getting outside, take your kid(s) for a walk",
  },
  {
    title: "Distract yourself: try...",
    example: "calling or visiting a supportive friend",
  },
  {
    title: title_1,
    example: "to the restroom",
  },
  {
    title: "Distract yourself: try...",
    example: "watching a movie",
  },
  {
    title: title_1,
    example: "outside to get fresh air",
  },
  {
    title: "Distract yourself: try...",
    example: "putting away dishes or laundry",
  },
  {
    title: title_1,
    example: "inside to relax",
  },
  {
    title: "Distract yourself: try...",
    example: "staying busy with healthy behaviors, like exercise",
  },
  {
    title: title_2,
    example:
      "limiting time with or avoiding friends and family that promote substance abuse",
  },
  {
    title: "Distract yourself: try...",
    example: "spending time doing a healthy hobby",
  },
  {
    title: "Distract yourself: try...",
    example: "practicing positive thinking (I can do this!)",
  },
];

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const ParentingAndSubstanceUseTipBagSlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const setActivityValue = useActivity(activityKind)[1];
  const [hideNext, setHideNext] = useState(true);

  const handleSlideNext = useCallback(() => {
    setActivityValue(null);
    onNext();
  }, [setActivityValue, onNext]);

  return (
    <ActivitySlidePage
      {...rest}
      title={module.name}
      activityKind={activityKind}
      onNext={handleSlideNext}
      light
      lightNextButton
      darkHeader
      hideNextButton={hideNext && !isReview}
      isReview={isReview}
      bearIcon={BearIcons.BABY_THUMBS_UP_WINKING}
    >
      <TipBagActivity
        tips={parentingAndSubstanceUseCravingTips}
        notExample
        instructions={parentingAndSubstanceUseCravingTipInstructions}
        onTipViewed={() => setHideNext(false)}
      />
    </ActivitySlidePage>
  );
};

export default styled(ParentingAndSubstanceUseTipBagSlide)``;
