import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { BearIcons } from "../../../../common/components/BearIcon";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import VideoId from "shared/lib/enums/VideoId";

const activityKind = ModuleActivityKind.RULES_AND_CONSEQUENCES_IGNORING_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const RulesAndConsequencesSlide20: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [onPlayStart, setVideoPlayed, videoCompleted] = useVideoActivity(
    activityKind,
    VideoId.RULES_AND_CONSEQUENCES_IGNORING_VIDEO
  );

  const handleNext = useCallback(() => {
    setVideoPlayed(true);
    onNext();
  }, [setVideoPlayed, onNext]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      title={module.name}
      activityKind={activityKind}
      bearIcon={BearIcons.GIRL_THUMBS_UP_WINKING}
      onNext={handleNext}
    >
      <section>
        <p>Let’s watch the bear family use ignoring to manage whining.</p>
      </section>
      <VideoActivity
        videoId={VideoId.RULES_AND_CONSEQUENCES_IGNORING_VIDEO}
        onStarted={onPlayStart}
        onEnded={setVideoPlayed}
      />
    </ActivitySlidePage>
  );
};

export default styled(RulesAndConsequencesSlide20)`
  ${VideoActivity} {
    margin-top: 2rem;
  }
`;
