import styled from "styled-components/macro";
import src from "../images/info_icon.png";

const InfoIcon = styled("img").attrs({ src, alt: "info" })`
  position: relative;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
`;

export default InfoIcon;
