import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Used to store state as search params at the end of the URL.
 */
function useSearchParamState<T extends string = string>(
  key: string
): [T | undefined, (newValue: string | number | boolean | null) => void] {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const value = searchParams.get(key) ?? undefined;

  const setValue = useCallback(
    (newValue: string | number | boolean | null) => {
      patchSearchParams(history, { [key]: newValue });
    },
    [history, key]
  );

  return [value as T | undefined, setValue];
}

export function useSearchParamNumberState(
  key: string
): [number | undefined, (value: number | null) => void] {
  const [value, setValue] = useSearchParamState(key);

  if (value === undefined) {
    return [undefined, setValue];
  }

  return [+value, setValue];
}

export function useSearchParamBooleanState(
  key: string
): [boolean | undefined, (value: boolean | null) => void] {
  const [value, setValue] = useSearchParamState(key);

  if (value === undefined) {
    return [undefined, setValue];
  }

  return [value === "true", setValue];
}

/**
 * For updating multiple search params at once.
 */
export function patchSearchParams(
  history: ReturnType<typeof useHistory>,
  fields: Record<string, string | number | boolean | null | undefined>
) {
  const updatedSearchParams = new URLSearchParams(window.location.search);

  for (const [key, value] of Object.entries(fields)) {
    if (value === null || value === undefined) {
      updatedSearchParams.delete(key);
    } else {
      updatedSearchParams.set(key, value.toString());
    }
  }

  const updatedSearchParamString = updatedSearchParams.toString();
  if (updatedSearchParamString.length > 0) {
    history.push(`${window.location.pathname}?${updatedSearchParamString}`);
  } else {
    history.push(window.location.pathname);
  }
}

export default useSearchParamState;
