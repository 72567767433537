import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import RibbonImage from "../../RibbonImage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const UsingIncentivesIntro: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          A helpful way to get your child to follow your directions is to use
          incentives and rewards for their good behavior. While very similar,
          incentives and rewards can be used differently.
        </p>
      </section>
      <section>
        <RibbonImage />
      </section>
    </SlidePage>
  );
};

export default styled(UsingIncentivesIntro)`
  ${RibbonImage} {
    margin-top: 2rem;
    align-self: center;
  }
`;
