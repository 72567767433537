import React, { FC } from "react";
import styled from "styled-components/macro";
import AlertModal from "./AlertModal";

const noop = () => {};

const IdleTimeoutModal: FC = (props) => {
  return (
    <AlertModal
      {...props}
      hideButton
      title="Inactive"
      message="You are about to be logged out due to inactivity."
      onClose={
        /* This modal should close automatically when the user does anything*/ noop
      }
    />
  );
};

export default styled(IdleTimeoutModal)``;
