import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import VideoId from "shared/lib/enums/VideoId";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ActivitySlidePage from "../../ActivitySlidePage";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import VideoActivity from "../../VideoActivity";

const activityKind = ModuleActivityKind.MOOD_SPIRAL_VIDEO;
const videoId = VideoId.MOOD_SPIRAL;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "id" | "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const ManagingDepressionMoodSpiralSlide: FC<Props> = (props) => {
  const { module, section, isReview, ...rest } = props;
  const [onPlayStart, onPlayed, videoCompleted] = useVideoActivity(
    activityKind,
    videoId
  );

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      title={module.name}
      activityKind={activityKind}
    >
      <section>
        <p>
          There are lots of things you can do to feel better. Watch this video
          to see how your mood, actions, and thoughts are related, and can work
          against you to make you feel worse.
        </p>
      </section>
      <VideoActivity
        onStarted={onPlayStart}
        onEnded={onPlayed}
        videoId={videoId}
      />
    </ActivitySlidePage>
  );
};

export default styled(ManagingDepressionMoodSpiralSlide)``;
