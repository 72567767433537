import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import PillButton from "../../../../surveys/components/PillButton";
import ChevronCircleButton from "../../ChevronCircleButton";
import surf0Src from "../../../images/surf_0.png";
import surf1Src from "../../../images/surf_1.png";
import surf2Src from "../../../images/surf_2.png";
import surf3Src from "../../../images/surf_3.png";
import surf4Src from "../../../images/surf_4.png";
import { useSearchParamNumberState } from "../../../../common/hooks/useSearchParamState";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const surfImages = [surf0Src, surf1Src, surf2Src, surf3Src, surf4Src];

const ManagingCravingsSurfSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [imageIndex = 0, setImageIndex] = useSearchParamNumberState("index");

  const handleNext = useCallback(() => {
    if (imageIndex <= surfImages.length - 2) {
      setImageIndex(imageIndex + 1);
    }
  }, [imageIndex, setImageIndex]);

  const showNext = imageIndex === surfImages.length - 1;

  return (
    <SlidePage
      {...rest}
      title={module.name}
      onNext={onNext}
      hideFooter
      hideNextButton
    >
      <section>
        <h3>MANAGING CRAVINGS</h3>
        <p>
          When cravings happen, imagine you are a surfer who is riding the wave of
          cravings. If you're able to surf the craving waves, the feeling will
          subside. And over time, as they are resisted, your cravings will grow
          weaker and happen less often.
        </p>
      </section>

      <SurfContainer>
        {showNext ? (
          <NextButton onClick={onNext}>Next</NextButton>
        ) : (
          <TapContainer didBegin={!!imageIndex} onClick={handleNext}>
            {!imageIndex && <p>tap to begin</p>}
            <Button />
          </TapContainer>
          )}
        <img
          src={surfImages[imageIndex]}
          alt={`Surfer Wave Progression Phase ${imageIndex}`}
        />
      </SurfContainer>
    </SlidePage>
  );
};

export default styled(ManagingCravingsSurfSlide)``;

const SurfContainer = styled("div")`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 3rem;
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }
`;

const NextButton = styled(PillButton)`
  width: 210px;
  margin-bottom: 1.8rem;
  position: absolute;
  bottom: 0;
`;

const TapContainer = styled("div")<{ didBegin: boolean }>`
  display: flex;
  padding-left: 2.5rem;
  align-items: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 3rem;
  height: 50px;
  width: ${(props) => (props.didBegin ? "50px" : "210px")};
  border-radius: 25px;
  background-color: ${(props) => (props.didBegin ? "transparent" : "#062a3f")};
  cursor: pointer;
`;

const Button = styled(ChevronCircleButton)`
  position: absolute;
  right: -5px;
  width: 50px;
  height: 50px;
`;
