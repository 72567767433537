import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BearIcons } from "../../../../common/components/BearIcon";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import PlanSlidePage from "../../PlanSlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";

const activityKind = ModuleActivityKind.HEALTHY_ROUTINES_PLAN;

export const healthyRoutinesPlanSuggestions = [
  "Increase physical activity/exercise",
  "Improve bed time routine",
  "Eat healthier meals",
  "Play with my child",
  "Find more time for relaxation",
  "Reduce screen time",
  "Improve morning routine",
  "Plan fun activities",
];

export const healthyRoutinesPlanSuggestionTitle =
  "Healthy Routines Suggestions";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const HealthyRoutinesPlanSlide: FC<Props> = (props) => {
  const { module, onNext, section, ...rest } = props;
  const [plan, setPlan] = useActivity(activityKind);
  const [showSectionOverlay, setShowSectionOverlay] = useState(false);

  const handleSlideNext = useCallback(() => {
    setShowSectionOverlay(true);
  }, []);

  return (
    <PlanSlidePage
      {...rest}
      plan={plan}
      onPlanChange={setPlan}
      title={module.name}
      onNext={handleSlideNext}
      planTitle="Your turn: Healthy routines"
      suggestionTitle={healthyRoutinesPlanSuggestionTitle}
      suggestions={healthyRoutinesPlanSuggestions}
      activityKind={activityKind}
      nextButtonText="Finish My Plan"
      bearIcon={BearIcons.GIRL_NEUTRAL}
    >
      <ModuleSegmentCompletionOverlay
        open={showSectionOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
      />
    </PlanSlidePage>
  );
};

export default styled(HealthyRoutinesPlanSlide)``;
