import apiAxios from "../../apiAxios";
import Session from "shared/lib/interfaces/Session";
import User from "shared/lib/interfaces/User";

interface Result {
  session: Session | null;
  user: User | null;
}

export default async function getSession(): Promise<Result> {
  const response = await apiAxios.get("/auth/session");
  return response.data || null;
}
