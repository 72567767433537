import React, { FC, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "@emberex/components/lib/Page";
import styled from "styled-components/macro";
import isBlank from "shared/lib/utils/isBlank";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import useSearchParams from "../../common/hooks/useSearchParams";
import acceptInvitation from "../api/acceptInvitation";
import AcceptInviteForm from "../components/AcceptInviteForm";
import waveBackgroundCss from "../../common/utils/waveBackgroundCss";
import showAlert from "../../common/utils/showAlert";

const AcceptInvitePage: FC = (props) => {
  const history = useHistory();
  const inviteToken = useSearchParams().get("inviteToken") || "";
  const handleSubmit = useCallback(
    async (password: string) => {
      await acceptInvitation({
        password,
        inviteToken,
      });
      history.push("/");
      await showAlert({
        title: "Success",
        message: "Thank you, please Login to start using Family Check-Up!",
      });
    },
    [history, inviteToken]
  );

  useEffect(() => {
    if (isBlank(inviteToken)) {
      history.push("/");
    }
  }, [history, inviteToken]);

  useDocumentTitle('Accept Invite')

  return (
    <Page {...props}>
      <AcceptInviteForm onSubmit={handleSubmit} />
    </Page>
  );
};

export default styled(AcceptInvitePage)`
  ${waveBackgroundCss}
  align-items: center;
  justify-content: center;
  color: white;
`;
