import VideoView from "shared/lib/interfaces/VideoView";
import VideoId from "shared/lib/enums/VideoId";
import apiAxios from "../apiAxios";

interface Props {
  videoId: VideoId
  participantId: number;
}

export default async function viewVideo({participantId, videoId}: Props): Promise<VideoView> {
  const response = await apiAxios.post(
    `/participants/${participantId}/video/${videoId}/view`
  );
  return response.data;
}