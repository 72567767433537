import React, { FC } from "react";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import styled from "styled-components/macro";
import Overlay from "@emberex/components/lib/Overlay";
import PillButton from "../../surveys/components/PillButton";
import bearManSrc from "../../modules/images/bear_man.png";
import Blurb from "../../common/components/Blurb";

export interface WelcomeBackOverlayProps {
  onContinue(): unknown;
  onClose(): unknown;
}

const WelcomeBackOverlay: FC<WelcomeBackOverlayProps> = ({
  onClose,
  onContinue,
  ...rest
}) => (
  <Overlay {...rest}>
    <Content>
      <Header>Welcome back!</Header>
      <Message>
        Do you want to continue
        <br />
        where you left off or head to
        <br />
        the dashboard?
      </Message>
      <ButtonColumn>
        <PillButton onClick={onContinue}>Continue</PillButton>
        <PillButton onClick={onClose}>Dashboard</PillButton>
      </ButtonColumn>
      <BearRow>
        <BearManContainer>
          <BearMan />
        </BearManContainer>
        <BearBlurb>
          I'm glad you're
          <br />
          back.
        </BearBlurb>
      </BearRow>
    </Content>
  </Overlay>
);

export default WelcomeBackOverlay;

const Content = styled(Column)`
  height: 100%;
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  overflow: hidden;
  background-color: #4dacbe;
  padding-top: 8rem;
`;

const Header = styled(Row)`
  color: #ffffff;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0;
  text-align: center;
  flex-shrink: 0;

  @media (min-width: 500px) {
    font-size: 3rem;
  }
`;

const Message = styled(Column)`
  padding: 2rem 0 35px 0;
  font-weight: 500;
  color: #0e3750;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  flex-shrink: 0;
`;

const ButtonColumn = styled(Column)`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;

  ${PillButton} {
    width: 240px;
    height: 70px;
    @media (min-width: 500px) {
      width: 300px;
      height: 75px;
    }
  }

  > *:first-child {
    margin-bottom: 1.25rem;
  }
`;

const BearRow = styled(Row)`
  height: 200px;
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
  @media (min-width: 500px) {
    height: 300px;
  }
`;

const BearManContainer = styled("div")`
  position: relative;
  width: 50%;
`;

const BearMan = styled("img").attrs({ src: bearManSrc, alt: "Bear Character" })`
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 90%;
  height: auto;
`;

const BearBlurb = styled(Blurb)`
  position: relative;
  left: -15px;
  padding-left: 1.5rem;
  width: 50%;
  height: 100%;
  text-align: center;
  color: #0e3750;
  font-size: 1rem;
  font-weight: bold;
  width: 170px;
  @media (min-width: 500px) {
    width: 200px;
  }
`;
