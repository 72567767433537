import React, { FC, FormEvent, useCallback } from "react";
import Row from "@emberex/components/lib/Row";
import styled from "styled-components/macro";
import Card from "../../common/components/Card";
import Column from "@emberex/components/lib/Column";
import { CommitmentValue } from "shared/lib/interfaces/ModuleActivity";

interface Props {
  value: CommitmentValue;
  placeholder?: CommitmentValue;
  onChange(value: CommitmentValue): unknown;
}

const CommitmentFormCard: FC<Props> = (props) => {
  const { value, placeholder, onChange, ...rest } = props;
  const { afterI, iWill } = value;

  const handleAfterIChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      onChange({
        afterI: event.currentTarget.value,
        iWill,
      });
    },
    [onChange, iWill]
  );

  const handleIWillChange = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      onChange({
        iWill: event.currentTarget.value,
        afterI,
      });
    },
    [onChange, afterI]
  );

  return (
    <Card {...rest}>
      <InputRow>
        <Label>After I</Label>
        <Textarea
          placeholder={placeholder?.afterI ?? ""}
          value={value.afterI}
          onChange={handleAfterIChange}
        />
        <Punctuation>,</Punctuation>
      </InputRow>
      <InputRow>
        <Label>I will</Label>
        <Textarea
          placeholder={placeholder?.iWill ?? ""}
          value={value.iWill}
          onChange={handleIWillChange}
        />
        <Punctuation>.</Punctuation>
      </InputRow>
    </Card>
  );
};

export default styled(CommitmentFormCard)``;

const InputRow = styled(Row)`
  & + & {
    margin-top: 0.75rem;
  }
`;

const Label = styled("label")`
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 0.75rem;
  width: 4rem;
  flex-shrink: 0;
  color: #0e3750;
`;

const Textarea = styled("textarea")`
  resize: none;
  border: 2px solid #ebebeb;
  flex: 1 1 auto;
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  font-family: inherit;
  min-width: 0;
  min-height: 0;
  border-radius: 0.5rem;
`;

const Punctuation = styled(Column)`
  justify-content: flex-end;
  padding-left: 0.2rem;
`;
