import apiAxios from "../../apiAxios";
import baseAxios from "axios";
import SignedUrl from "shared/lib/interfaces/SignedUrl";
import { USE_LOCAL_FILE_UPLOAD } from "../../env";

export default async function uploadFile(
  file: File | Blob,
  fileName: string
): Promise<string> {
  if (USE_LOCAL_FILE_UPLOAD) {
    const formData = new FormData();
    formData.append("file", file as any);
    const response = await apiAxios.post(
      `/media/upload/${fileName}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const { key } = response.data;
    return `api/media/${key}`;
  } else {
    const { key, url } = await getMediaUploadUrl(fileName);

    await baseAxios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    return `api/media/${key}`;
  }
}

async function getMediaUploadUrl(filename: string): Promise<SignedUrl> {
  const response = await apiAxios.get(`/media/upload-url/${filename}`);
  return response.data;
}
