import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BearIcons } from "../../../../common/components/BearIcon";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageContent,
} from "../../SlidePage";
import ActivityBox from "../../ActivityBox";
import CommitmentFormCard from "../../CommitmentFormCard";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.PROACTIVE_PARENTING_PLAY_COMMITMENT;
export const playCommitmentPlaceholder = { afterI: "(tie)", iWill: "(small)" };

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ProactiveParentingPlayCommitmentSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;
  const [commitment, setCommitment] = useActivity(activityKind);

  return (
    <ActivitySlidePage
      {...rest}
      title={module.name}
      light
      lightNextButton
      nextButtonText="Submit"
      activityKind={activityKind}
      hideNextButton={!commitment?.afterI || !commitment?.iWill}
      bearIcon={BearIcons.BABY_THUMBS_UP}
    >
      <Instructions>
        <p>
          Make it simple, like:
          <br />
          <b>After I</b> clean up from breakfast each day (Tie is cleaning up
          from breakfast),
          <br />
          <br />
          <b>I will</b> play patty cake with my child for one minute (playing
          for one minute is starting small).
        </p>
      </Instructions>

      <ActivityBox icon="pencil">
        <ActivityHeading>Your turn</ActivityHeading>
        <CommitmentFormCard
          value={commitment || { afterI: "", iWill: "" }}
          onChange={setCommitment}
          placeholder={playCommitmentPlaceholder}
        />
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(ProactiveParentingPlayCommitmentSlide)`
  ${SlidePageContent} {
    background-color: white;
  }

  ${ActivityBox} {
    flex: 1;
  }
`;

const ActivityHeading = styled("h3")`
  text-align: center;
  margin-bottom: 1.25rem;
`;

const Instructions = styled("section")`
  flex-shrink: 0;
  margin-bottom: 4rem;
`;
