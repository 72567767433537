import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { BearIcons } from "../../../../common/components/BearIcon";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import VideoId from "shared/lib/enums/VideoId";

const activityKind = ModuleActivityKind.EFFECTIVE_COMMUNICATION_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const EffectiveCommunicationVideoSlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [onPlayStart, onPlayEnd, videoCompleted] = useVideoActivity(
    activityKind,
    VideoId.EFFECTIVE_COMMUNICATION_VIDEO
  );

  const handleNext = useCallback(() => {
    onPlayEnd(true);
    onNext();
  }, [onPlayEnd, onNext]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      title={module.name}
      activityKind={activityKind}
      bearIcon={BearIcons.BABY_NEUTRAL}
      onNext={handleNext}
    >
      <section>
        <h3>{section.name}</h3>
      </section>
      <section>
        <h4>What does effective communication look like?</h4>
        <p>Watch this video to see.</p>
      </section>

      <VideoActivity
        onStarted={onPlayStart}
        onEnded={onPlayEnd}
        videoId={VideoId.EFFECTIVE_COMMUNICATION_VIDEO}
      />
    </ActivitySlidePage>
  );
};

export default styled(EffectiveCommunicationVideoSlide)`
  ${VideoActivity} {
    margin-top: 2rem;
  }
`;
