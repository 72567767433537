import React, { FC } from "react";
import styled from "styled-components/macro";
import ReactConfetti from "react-confetti";
import useWindowSize from "../../common/hooks/useWindowSize";

const Confetti: FC = (props) => {
  const windowSize = useWindowSize();

  return (
    <div {...props}>
      <ReactConfetti width={windowSize.width} height={windowSize.height} />
    </div>
  );
};

export default styled(Confetti)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
`;
