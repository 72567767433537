import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import BottomToast from "./BottomToast";

export interface BottomMessageToastProps {
  message: ReactNode;
  onButtonClick(): unknown;
  icon?: ReactNode;
  iconBackgroundColor?: string;
  buttonLabel?: ReactNode;
  hideButton?: boolean;
}

const BottomMessageToast: FC<BottomMessageToastProps> = (props) => {
  const {
    buttonLabel = "Continue",
    message,
    icon,
    onButtonClick,
    iconBackgroundColor = '#ff6762',
    ...rest
  } = props;

  return (
    <BottomToast
      {...rest}
      buttonLabel={buttonLabel}
      onButtonClick={onButtonClick}
    >
      <MessageContainer>
        {icon ? <Icon iconBackgroundColor={iconBackgroundColor}>{icon}</Icon> : null}
        <MessageText>{message}</MessageText>
      </MessageContainer>
    </BottomToast>
  );
};

export default styled(BottomMessageToast)``;

const MessageContainer = styled(Column)`
  padding: 3rem 1.25rem 1.75rem 1.25rem;
  background-color: #ccfcee;
`;

const MessageText = styled("p")`
  border-radius: 0.5rem;
  background-color: #9dedd6;
  padding: 1.5rem 1.25rem 1.5rem 1.25rem;
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
`;

const Icon = styled("div")<{iconBackgroundColor: string}>`
  position: absolute;
  top: 0.75rem;
  left: 50%;
  width: 2.25rem;
  height: 2.25rem;
  flex-shrink: 0;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  background-color: ${props => props.iconBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;

  > svg {
    font-size: 70%;
  }
`;
