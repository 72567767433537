import React, { FC } from "react";
import styled from "styled-components/macro";
import Row from "@emberex/components/lib/Row";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";
import sadWomanSrc from "../../../images/sad_woman.png";
import girlWithDollsSrc from "../../../images/girl_with_dolls.png";
import sadGirlSrc from "../../../images/sad_girl.png";
import whitePillsSrc from "../../../images/white_pills.png";
import toddlerSrc from "../../../images/toddler.png";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ParentingAndResponsibilitesContent1Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          If you said “Yes” to any of the statements listed, you’re not alone.
        </p>
      </section>
      <section>
        <ImageRow1>
          <div>
            <RoundImage src={sadWomanSrc} alt="Sad Woman" />
          </div>
          <div>
            <RoundImage src={whitePillsSrc} alt="White Pills" />
          </div>
          <div>
            <RoundImage src={sadGirlSrc} alt="Sad Child" />
          </div>
        </ImageRow1>
        <ImageRow2>
          <div>
            <RoundImage src={girlWithDollsSrc} alt="Girl Playing with Dolls" />
          </div>
          <div>
            <RoundImage src={toddlerSrc} alt="Toddler" />
          </div>
        </ImageRow2>
      </section>
      <section>
        <h4>To take care of your child in the best way you can, try to:</h4>
      </section>
      <section>
        <YellowCircleList>
          <li>
            Only use substances if a second caregiver is available to care for
            your children.
          </li>
          <li>
            Surround yourself with people who support you not using substances
            while you are parenting.
          </li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(ParentingAndResponsibilitesContent1Slide)``;

const ImageRow1 = styled(Row)`
  justify-content: space-between;
`;

const ImageRow2 = styled(Row)`
  justify-content: space-between;
  div {
    width: 100%;
    text-align: center;
  }
`;

const RoundImage = styled("img")`
  width: 60px;
  height: auto;
`;
