import styled from "styled-components/macro";

const YellowCircleList = styled("ul")`
  font-size: 1rem;
  line-height: 1.25rem;
  padding-left: 2rem;
  list-style: none;

  > li {
    position: relative;
  }

  > li::before {
    content: "";
    display: block;
    position: absolute;
    left: -2rem;
    top: 0.1rem;
    background-color: #fdd965;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  }

  > li + li {
    margin-top: 1rem;
  }
`;

export default YellowCircleList;
