import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import Row from "@emberex/components/lib/Row";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import CircleStarItem from "../../CircleStarItem";
import ChevronCircleButton from "../../ChevronCircleButton";
import sadWomanSrc from "../../../images/sad_woman.png";
import girlWithDollsSrc from "../../../images/girl_with_dolls.png";
import sadGirlSrc from "../../../images/sad_girl.png";
import whitePillsSrc from "../../../images/white_pills.png";
import toddlerSrc from "../../../images/toddler.png";
import {
  useSearchParamBooleanState,
  useSearchParamNumberState,
} from "../../../../common/hooks/useSearchParamState";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

interface Question {
  text: string;
  imgSrc: string;
  imgAlt: string;
}

const questions: Question[] = [
  {
    text: "Feeling down or depressed?",
    imgSrc: sadWomanSrc,
    imgAlt: "Sad Woman",
  },
  {
    text: "Focusing on substance use instead of your child?",
    imgSrc: whitePillsSrc,
    imgAlt: "White Pills",
  },
  {
    text: "Ignoring your child when they need you?",
    imgSrc: sadGirlSrc,
    imgAlt: "Sad Child",
  },
  {
    text: "Not playing with your child?",
    imgSrc: girlWithDollsSrc,
    imgAlt: "Girl Playing with Dolls",
  },
  {
    text: "Not paying attention to your child?",
    imgSrc: toddlerSrc,
    imgAlt: "Toddler",
  },
];

const ParentingResponsibilitiesIntroSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [didBegin = false, setDidBegin] = useSearchParamBooleanState("started");
  const [questionIndex = 0, setQuestionIndex] = useSearchParamNumberState(
    "index"
  );

  const handleNextClick = useCallback(() => {
    if (didBegin) {
      setQuestionIndex(
        questionIndex < questions.length - 1 ? questionIndex + 1 : questionIndex
      );
    } else {
      setDidBegin(true);
    }
  }, [setQuestionIndex, setDidBegin, didBegin, questionIndex]);

  const currentQuestion = questions[questionIndex];
  const isDone = questionIndex !== questions.length - 1;

  return (
    <SlidePage
      hideNextButton={isDone}
      onNext={onNext}
      {...rest}
      title={module.name}
    >
      <section>
        <h3>{section.name}</h3>
      </section>
      <section>
        <h4>How does substance use impact your parenting?</h4>
      </section>
      <section>
        <CircleStarItem>
          Many parents may have struggled with parenting and substance use.
          Think about some times that you have been using substances while
          trying to parent your child
        </CircleStarItem>

        <StatementRow>
          <QuestionStatement didBegin={didBegin}>
            <h4>Have you noticed: </h4>
            <QuestionText>{currentQuestion.text}</QuestionText>
          </QuestionStatement>

          {isDone && (
            <TapContainer didBegin={didBegin} onClick={handleNextClick}>
              {!didBegin && <p>tap to begin</p>}
              <Button />
            </TapContainer>
          )}
        </StatementRow>
      </section>

      {didBegin && (
        <CircularImage>
          <img src={currentQuestion.imgSrc} alt={currentQuestion.imgAlt} />
        </CircularImage>
      )}
    </SlidePage>
  );
};

export default styled(ParentingResponsibilitiesIntroSlide)``;

const TapContainer = styled("div")<{ didBegin: boolean }>`
  display: flex;
  padding-left: 1.25rem;
  align-items: center;
  position: relative;
  height: 50px;
  width: ${(props) => (props.didBegin ? "0px" : "280px")};
  border-radius: 25px;
  background-color: ${(props) => (props.didBegin ? "transparent" : "#062a3f")};
  cursor: pointer;
`;

const StatementRow = styled(Row)`
  height: 80px;
  justify-content: space-between;
  margin-top: 1.25rem;
`;

const QuestionStatement = styled("div")<{ didBegin: boolean }>`
  visibility: ${(props) => (props.didBegin ? "" : "hidden")};
  h4 {
    color: #ccfcee;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const QuestionText = styled("p")`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`;

const Button = styled(ChevronCircleButton)`
  margin-right: -1rem;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
`;

const CircularImage = styled("div")`
  text-align: center;
  img {
    width: 40%;
    height: auto;
  }
`;
