import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components/macro";
import TextButton from "@emberex/components/lib/TextButton";
import { ChevronRightIcon } from "../../common/components/Icons";

interface ChevronCircleButtonProps {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ChevronCircleButton: FC<ChevronCircleButtonProps> = (props) => (
  <TextButton {...props}>
    <ChevronRightIcon />
  </TextButton>
);

export default styled(ChevronCircleButton)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #0b4568;
  color: #ffd84a;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;

  &:not(:disabled):hover {
    background-color: #083149;
  }
`;
