import apiAxios from "../../apiAxios";

interface Props {
  moduleId: number;
  sectionId: number | null;
  elapsed: number;
}

export default async function updateModuleTimeElapsed(
  props: Props
): Promise<void> {
  const { moduleId, sectionId, elapsed } = props;
  await apiAxios.put(
    sectionId
      ? `/modules/${moduleId}/${sectionId}/elapsed`
      : `/modules/${moduleId}/elapsed`,
    { elapsed }
  );
}
