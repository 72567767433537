import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Row from "@emberex/components/lib/Row";
import { DARKEST_BLUE } from "../../colors";

type Color = "red" | "yellow" | "green";

interface Props {
  color: Color;
  label: ReactNode;
}

const HighlightedList: FC<Props> = (props) => {
  const { color, label, children, ...rest } = props;

  return (
    <Row {...rest}>
      <Label color={color}>
        <span>{label}</span>
      </Label>
      <List>{children}</List>
    </Row>
  );
};

function getHexColor(color: Color): string {
  switch (color) {
    case "red":
      return "#7D0B0B";
    case "yellow":
      return "#F7D20F";
    case "green":
      return "#4D9105";
  }
}

export default styled(HighlightedList)`
  width: 100%;
  justify-content: center;
  position: relative;
  min-height: 6.75rem;
  color: ${DARKEST_BLUE};

  & + & {
    margin-top: 8px;
  }
`;

const Label = styled("div")<{ color: Color }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 6.75rem;
  width: 13px;
  background-color: ${(props) => getHexColor(props.color)};

  > span {
    position: absolute;
    left: 22px;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    width: auto;
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: 500;
  }
`;

const List = styled("ul")`
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export const HighlightedListItem = styled("li")<{ color: Color }>`
  padding: 1rem 1rem 1rem 1rem;
  width: 15rem;
  border: 5px solid ${(props) => getHexColor(props.color)};
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.1rem;

  & + & {
    margin-top: 0.5rem;
  }
`;
