import React, { FC } from "react";
import styled from "styled-components/macro";
import bribeIconSrc from "../images/bribe_icon.png";
import rewardIconSrc from "../images/reward_icon.png";

interface Props {
  type: "bribe" | "reward";
}

const BribeRewardIcon: FC<Props> = (props) => {
  const { type, ...rest } = props;
  let src: string;
  let alt: string;

  if (type === "bribe") {
    src = bribeIconSrc;
    alt = "bribe";
  } else {
    src = rewardIconSrc;
    alt = "reward";
  }

  return <img {...rest} src={src} alt={alt} />;
};

export default styled(BribeRewardIcon)`
  height: 96px;
  width: 96px;
  flex-shrink: 0;
  border-radius: 50%;
`;
