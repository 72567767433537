import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import InfoIcon from "../../InfoIcon";
import kitchenImage from "../../../images/rules_and_consequences_kitchen.png";
import SceneTooltip from "../../../../common/components/SceneTooltip";

enum Tooltip {
  KNIVES = "KNIVES",
  CLEANERS = "CLEANERS",
  PLASTIC_BAGS = "PLASTIC_BAGS",
  CORDS = "CORDS",
  SAFETY_GATE = "SAFETY_GATE",
}

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps['isReview'];
}

const RulesAndConsequencesSlide3: FC<Props> = (props) => {
  const { module, section, isReview, ...rest } = props;
  const [visibleTooltip, setVisibleTooltip] = useState<null | Tooltip>(null);
  const [viewedTooltips, setViewedTooltips] = useState<Tooltip[]>([]);

  const showTooltip = useCallback((toolTip: Tooltip) => {
    setVisibleTooltip(toolTip);
    setViewedTooltips((value) =>
      value.includes(toolTip) ? value : [...value, toolTip]
    );
  }, []);

  const closeTooltip = useCallback(() => setVisibleTooltip(null), []);

  return (
    <SlidePage {...rest} title={module.name} showNextButton={viewedTooltips.length > 0 || isReview}>
      <section>
        <p>
          Tap on a <InfoIcon /> in the kitchen below to learn more about
          everyday safety concerns in your home.
        </p>
      </section>
      <Kitchen>
        <KitchenImage />
        <KnifeInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.KNIVES)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.KNIVES}
            text="Store knives out of reach."
            position="top"
            textAlign="left"
            onClose={closeTooltip}
          />
        </KnifeInfo>

        <CleanerInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.CLEANERS)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.CLEANERS}
            text="Latch cabinets with cleansers, solvents, bleaches, dishwasher detergent and other poisons."
            position="topLeft"
            textAlign="left"
            onClose={closeTooltip}
          />
        </CleanerInfo>

        <PlasticBagInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.PLASTIC_BAGS)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.PLASTIC_BAGS}
            text="Store plastic bags out of reach."
            position="top"
            textAlign="left"
            onClose={closeTooltip}
          />
        </PlasticBagInfo>

        <CordInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.CORDS)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.CORDS}
            text="Shorten the cords on blinds to get them up out of children’s reach."
            position="topLeft"
            textAlign="left"
            onClose={closeTooltip}
          />
        </CordInfo>

        <SafetyGateInfo>
          <InfoIcon onClick={() => showTooltip(Tooltip.SAFETY_GATE)} />
          <SceneTooltip
            visible={visibleTooltip === Tooltip.SAFETY_GATE}
            text="Use a safety gate at the top and bottom of the stairs."
            position="topLeft"
            textAlign="left"
            onClose={closeTooltip}
          />
        </SafetyGateInfo>
      </Kitchen>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide3)`
  p > ${InfoIcon} {
    transform: translate(0, 25%);
  }
`;

const Kitchen = styled("div")`
  flex-shrink: 0;
  margin-top: 5rem;
  position: relative;
`;

const KitchenImage = styled("img").attrs({
  src: kitchenImage,
  alt: "Living room",
})`
  width: 100%;
  height: auto;
`;

const SceneInfo = styled("div")`
  position: absolute;
  top: 0;
  left: 0;

  > ${InfoIcon} {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  > ${SceneTooltip} {
    position: absolute;
  }
`;

const KnifeInfo = styled(SceneInfo)`
  left: 51%;
  top: 35%;

  > ${SceneTooltip} {
    top: -1rem;
    left: 0;
    width: 256px;
    transform: translate(-49%, -100%);
  }
`;

const CleanerInfo = styled(SceneInfo)`
  left: 65%;
  top: 67%;

  > ${SceneTooltip} {
    top: -0.75rem;
    left: 0;
    width: 256px;
    transform: translate(-84%, -100%);
  }
`;

const PlasticBagInfo = styled(SceneInfo)`
  left: 45%;
  top: 74%;

  > ${SceneTooltip} {
    top: -1rem;
    left: 0;
    width: 256px;
    transform: translate(-49%, -100%);
  }
`;

const CordInfo = styled(SceneInfo)`
  left: 82%;
  top: 43%;

  > ${SceneTooltip} {
    top: -1rem;
    left: 0;
    width: 256px;
    transform: translate(-84%, -100%);
  }
`;

const SafetyGateInfo = styled(SceneInfo)`
  left: 84%;
  top: 77%;

  > ${SceneTooltip} {
    top: -1rem;
    left: 2.5rem;
    width: 256px;
    transform: translate(-100%, -100%);
  }
`;
