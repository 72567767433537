import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Form from "@emberex/components/lib/Form";
import useInputState from "@emberex/react-utils/lib/useInputState";
import validatePassword from "shared/lib/utils/validatePassword";
import getErrorMessage from "../../common/utils/getErrorMessage";
import FormErrorText from "../../common/components/FormErrorText";
import FormSuccessText from "../../common/components/FormSuccessText";
import Input from "../../common/components/Input";
import PillButton from "../../surveys/components/PillButton";
import Link from "../../common/components/Link";

interface Props {
  onSubmit(password: string): any;
}

const ResetPasswordForm: FC<Props> = (props) => {
  const { onSubmit, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [password, handlePasswordChange] = useInputState("");
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState("");

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match.`);
      }
      await onSubmit(validatePassword(password));
      setSubmitSuccess(true);
    } catch (error) {
      setSubmitError(error);
      setSubmitSuccess(false);
    } finally {
      setSubmitting(false);
    }
  }, [password, passwordConfirm, onSubmit]);

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <Title>Reset Password</Title>
      {submitSuccess && (
        <>
          <FormSuccessText>Password changed.</FormSuccessText>
          <Link to="/">
            <LoginButton>Login</LoginButton>
          </Link>
        </>
      )}
      {submitError && (
        <FormErrorText>{getErrorMessage(submitError)}</FormErrorText>
      )}
      {!submitSuccess && !submitError && (
        <>
          <Instructions>Enter a new password.</Instructions>
          <StyledInput
            placeholder="New password"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <StyledInput
            placeholder="New password (confirmed)"
            type="password"
            autoComplete="new-password"
            value={passwordConfirm}
            onChange={handlePasswordConfirmChange}
          />
          <SubmitButton disabled={submitting}>Change password</SubmitButton>
        </>
      )}
    </Form>
  );
};

export default styled(ResetPasswordForm)`
  width: 267px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    width: 400px;
  }
`;

const Title = styled("h1")`
  margin-bottom: 1rem;
  font-size: 2rem;
`;

const Instructions = styled("p")`
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 2rem;
`;

const StyledInput = styled(Input)`
  & + & {
    margin-top: 1rem;
  }
`;

const SubmitButton = styled(PillButton)`
  margin-top: 3rem;
  width: 100%;
  padding: 0 0 0 0;
  font-size: 1rem;
`;

const LoginButton = styled(SubmitButton)`
  background-color: #0d0;
  color: white;
`;
