import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import Column from "@emberex/components/lib/Column";
import Page from "@emberex/components/lib/Page";
import Module from "shared/lib/interfaces/Module";
import waveBackgroundCss from "../../common/utils/waveBackgroundCss";
import ModuleIcon from "../../common/components/ModuleIcon";
import PillButton from "../../surveys/components/PillButton";
import { YELLOW } from "../../colors";
import Confetti from "../components/Confetti";

interface Props {
  module: Pick<Module, "name" | "icon">;
  nextButtonText?: string;
  onHomeClick?(): unknown;
}

const ModuleCompleteSlide: FC<Props> = (props) => {
  const { module, nextButtonText = "Dashboard", onHomeClick, ...rest } = props;
  const history = useHistory();

  const handleHomeClick = useCallback(() => {
    history.push("/dashboard");
  }, [history]);

  return (
    <Page {...rest}>
      <Confetti />
      <Column>
        <Title>You did it!</Title>
        <SubTitle>You earned your badge!</SubTitle>
      </Column>

      <Column>
        <ModuleIcon icon={module.icon} />
        <ModuleName>{module.name}</ModuleName>
      </Column>

      <HomeButton onClick={onHomeClick || handleHomeClick}>
        {nextButtonText}
      </HomeButton>
    </Page>
  );
};

export default styled(ModuleCompleteSlide)`
  ${waveBackgroundCss}
  text-align: center;
  align-items: center;
  padding: 6rem 2rem 6rem 2rem;

  ${ModuleIcon} {
    margin-bottom: 0.5rem;
    align-self: center;
    width: 100px;
    height: 100px;
  }
`;

const Title = styled("h1")`
  color: ${YELLOW};
  font-size: 2rem;
  font-weight: 300;
  margin: 0 0 0.7rem 0;
`;

const SubTitle = styled("h2")`
  color: ${YELLOW};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0 0 2.5rem 0;
`;

const ModuleName = styled("h2")`
  color: white;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2rem;
  margin-bottom: 6rem;
`;

const HomeButton = styled(PillButton)`
  padding-left: 0;
  padding-right: 0;
  width: 200px;
`;
