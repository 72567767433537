import styled from "styled-components/macro";

const ImageList = styled("ul")`
  list-style: none;
`;

export default ImageList;

export const ImageListItem = styled("li")<{ imageSrc: string }>`
  color: white;
  position: relative;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.1rem;
  min-height: 56px;
  padding-left: 71px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;

  & + & {
    margin-top: 0.75rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 56px;
    height: 56px;
    background-image: url('${(props) => props.imageSrc}');
    background-size: cover;
  }
`;
