import apiAxios from "../../apiAxios";

interface Options {
  inviteToken: string;
  password: string;
}

export default async function acceptInvitation(
  options: Options
): Promise<void> {
  const { inviteToken, password } = options;
  await apiAxios.post("/invitations/accept", { inviteToken, password });
}
