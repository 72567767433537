import React, { FC } from "react";
import styled from "styled-components/macro";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import ParticipantPoints from "shared/lib/interfaces/ParticipantPoints";
import Participant from "shared/lib/interfaces/Participant";
import { DARK_BLUE, DARKEST_BLUE } from "../../colors";
import placeholderProfilePicSrc from "../images/placeholder_profile_pic.png";
import StarCheckImage from "../../modules/components/StarCheckImage";
import ShieldImage from "./ShieldImage";

interface Props {
  participant: Pick<Participant, "profilePicture" | "firstName" | "lastName">;
  points: ParticipantPoints;
}

const ParticipantInfoCircle: FC<Props> = (props) => {
  const { participant, points, ...rest } = props;

  return (
    <Column {...rest}>
      <TopOuterCircle>
        <TopRow>
          <CenteredColumn>
            <StarCheckImage alt="Points" />
            <StatText>{points.slideViewPoints}</StatText>
          </CenteredColumn>
          <InnerCircle>
            <ProfileImage
              src={participant.profilePicture || placeholderProfilePicSrc}
              alt={`${participant.firstName} ${participant.lastName}`}
            />
          </InnerCircle>
          <CenteredColumn>
            <ShieldImage alt="Bonus points" />
            <StatText>{points.activityPoints + points.welcomedPoints}</StatText>
          </CenteredColumn>
        </TopRow>
        <BottomRow>
          <StatText>
            {
              points.slideViewPoints + 
              points.activityPoints +
              points.welcomedPoints
            }/
            {
              points.totalSlideViewPoints + 
              points.totalActivityPoints + 
              points.welcomedPoints
            }
          </StatText>
        </BottomRow>
      </TopOuterCircle>
      <BottomPartialOuter />
    </Column>
  );
};

export default styled(ParticipantInfoCircle)`
  overflow: hidden;
  height: 160px;
  width: 191px;
`;

const OuterCircle = styled(Column)`
  position: relative;
  top: -52px;
  padding-top: 60px;
  width: 191px;
  height: 191px;
  border-radius: 50%;
  background-color: #befdec;
  flex-shrink: 0;
`;

const CenteredColumn = styled(Column)`
  align-items: center;
`;

const TopOuterCircle = styled(OuterCircle)``;

const BottomPartialOuter = styled(OuterCircle)`
  top: -62px;
`;

const TopRow = styled(Row)`
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
`;

const BottomRow = styled(Row)`
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  margin-top: 5px;
`;

const InnerCircle = styled(Row)`
  flex-shrink: 0;
  box-sizing: border-box;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  border: 9px solid ${DARK_BLUE};
  background-color: ${DARK_BLUE};
  overflow: hidden;
  margin: 0 1rem 0 1rem;
`;

const ProfileImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const StatText = styled("div")`
  color: ${DARKEST_BLUE};
  font-size: 0.9rem;
  font-weight: 500;
`;
