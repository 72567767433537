import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const UsingIncentivesBribesVsIncentivesSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <p>
          <b>Bribes &amp; how they differ from rewards and incentives</b>
        </p>
      </section>
      <section>
        <p>
          There is a third strategy parents use to make their children behave in
          a desired way, bribery.
        </p>
      </section>
      <section>
        <p>
          A bribe occurs before the desired behavior, and a reward occurs after
          the behavior. Bribes are less effective than rewards and incentives
          because they do not teach children what they should do instead.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(UsingIncentivesBribesVsIncentivesSlide)``;
