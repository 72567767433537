import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import ReactSlider from "react-slider";
import { RangeValue } from "shared/lib/interfaces/SurveyResponseValue";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import SurveyQuestionKind from "shared/lib/enums/SurveyQuestionKind";

export type Value = RangeValue | null;

interface Props {
  min: number;
  max: number;
  value: Value;
  readonly?: boolean;
  onChange(answer: Value): any;
}

const RangeInput: FC<Props> = ({
  min,
  max,
  value,
  readonly,
  onChange,
  ...rest
}) => {
  const rangeValue = value ? value.rangeValue : min;
  const showValueLabel = rangeValue !== min && rangeValue !== max;

  const handleChange = useCallback(
    (newRangeValue: number | number[] | null | undefined) => {
      if (typeof newRangeValue === "number" && !readonly) {
        onChange({
          kind: SurveyQuestionKind.RANGE,
          rangeValue: newRangeValue,
        });
      }
    },
    [onChange, readonly]
  );

  return (
    <Column {...rest}>
      <Bar>
        <Slider
          min={min}
          max={max}
          disabled={readonly}
          value={rangeValue === null ? undefined : rangeValue}
          onChange={handleChange}
          renderThumb={(props, state) => (
            <BarHandle {...props} invisible={value === null}>
              {showValueLabel && <ValueLabel>{state.valueNow}</ValueLabel>}
            </BarHandle>
          )}
          renderTrack={(props, state) => (
            <BarFill {...props} index={state.index} />
          )}
        />
      </Bar>
      <LabelBar>
        <div>{min}</div>
        <div>{max}</div>
      </LabelBar>
    </Column>
  );
};

export default styled(RangeInput)`
  width: 100%;
`;

const Slider = styled(ReactSlider)`
  height: 26px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const Bar = styled("div")`
  position: relative;
  border: 3px solid #d0e8ec;
  background-color: white;
  border-radius: 8px;
`;

const BarFill = styled("div")<{ index: number }>`
  height: 100%;
  transition: left 0.2s, right 0.2s;
  background: ${(props) =>
    props.index > 0
      ? "white"
      : "linear-gradient(90deg, rgba(8, 173, 192, 0.09) 0%, rgba(8, 173, 192, 0.55) 55.28%, #08adc0 100%)"};
`;

const BarHandle = styled("div")<{ invisible: boolean }>`
  height: 32px;
  width: 8px;
  border-radius: 4px;
  background-color: #08adc0;
  transform: translate(0, -3px);
  transition: left 0.2s, right 0.2s;
  opacity: ${(props) => (props.invisible ? 0 : 1)};
`;

const LabelBar = styled(Row)`
  margin-top: 0.5rem;
  justify-content: space-between;
  color: #0e3750;
  font-size: 1rem;
  font-weight: 500;
`;

const ValueLabel = styled("div")`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0.5rem);
  color: #0e3750;
  font-size: 1rem;
  font-weight: 500;
`;
