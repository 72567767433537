import React, { FC, ReactNode, useCallback } from "react";
import styled from "styled-components/macro";
import { CheckIcon } from "./Icons";

interface Props {
  label?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?(checked: boolean): unknown;
}

const WideCheckCircle: FC<Props> = (props) => {
  const {
    onChange,
    label,
    checked = false,
    disabled,
    children,
    ...rest
  } = props;

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange(!checked);
    }
  }, [onChange, checked]);

  return (
    <label {...rest}>
      <FakeCheckbox checked={checked}>
        <CheckIcon />
      </FakeCheckbox>
      {label ? <Text>{label}</Text> : null}
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
    </label>
  );
};

export default styled(WideCheckCircle)`
  display: flex;
  flex-direction: row;
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: 500;

  &:not(:disabled) {
    cursor: pointer;
  }

  input {
    display: none;
  }
`;

const FakeCheckbox = styled("div")<{ checked: boolean }>`
  flex-shrink: 0;
  box-sizing: border-box;
  height: 2.8rem;
  width: 3.62rem;
  border: 2px solid #08adc0;
  border-radius: 1.4rem;
  background-color: white;
  transition: background-color 0.2s, color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.checked ? "black" : "white")};
  background-color: ${(props) => (props.checked ? "#CCFCEE" : "white")};

  > svg {
    display: ${(props) => (props.checked ? "block" : "none")};
    font-size: 1rem;
  }
`;

const Text = styled("div")`
  margin-left: 0.75rem;
`;
