import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Form from "@emberex/components/lib/Form";
import useInputState from "@emberex/react-utils/lib/useInputState";
import isBlank from "shared/lib/utils/isBlank";
import getErrorMessage from "../../common/utils/getErrorMessage";
import FormErrorText from "../../common/components/FormErrorText";
import Input from "../../common/components/Input";
import PillButton from "../../surveys/components/PillButton";

interface Props {
  onSubmit(password: string): any;
}

const AcceptInviteForm: FC<Props> = (props) => {
  const { onSubmit, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [password, handlePasswordChange] = useInputState("");
  const [passwordConfirm, handlePasswordConfirmChange] = useInputState("");

  const handleSubmit = useCallback(async () => {
    try {
      if (isBlank(password)) {
        throw new Error("Password is required.");
      }
      if (password !== passwordConfirm) {
        throw new Error("Passwords don't match.");
      }
      setSubmitting(true);
      await onSubmit(password);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit, password, passwordConfirm]);

  return (
    <Form {...rest} onSubmit={handleSubmit}>
      <Title>Accept Invitation</Title>
      {error ? (
        <FormErrorText>{getErrorMessage(error)}</FormErrorText>
      ) : (
        <Instructions>Enter your new password.</Instructions>
      )}
      <StyledInput
        required
        type="password"
        placeholder="Password"
        autoComplete="new-password"
        value={password}
        onChange={handlePasswordChange}
      />
      <StyledInput
        required
        type="password"
        placeholder="Password (confirmed)"
        autoComplete="new-password"
        value={passwordConfirm}
        onChange={handlePasswordConfirmChange}
      />
      <SubmitButton disabled={submitting}>Register</SubmitButton>
    </Form>
  );
};

export default styled(AcceptInviteForm)`
  width: 267px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    width: 400px;
  }
`;

const Title = styled("h1")`
  margin-bottom: 1rem;
  font-size: 2rem;
`;

const Instructions = styled("p")`
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 2rem;
`;

const StyledInput = styled(Input)`
  & + & {
    margin-top: 1rem;
  }
`;

const SubmitButton = styled(PillButton)`
  margin-top: 3rem;
  width: 100%;
  padding: 0 0 0 0;
  font-size: 1rem;
`;
