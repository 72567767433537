import styled from "styled-components/macro";
import fcuLogoSrc from "../images/fcu_logo.png";
import fcuLogoCompactSrc from "../images/fcu_logo_compact.png";

const FcuLogo = styled("img").attrs({
  alt: "Family check-up",
  src: fcuLogoSrc, // TODO: Get better image from design
})`
  width: 95px;
  height: 92px;
  flex-shrink: 0;
`;

export const FcuLogoCompact = styled("img").attrs({
  alt: "Family check-up",
  src: fcuLogoCompactSrc,
})`
  height: 26px;
  width: 68px;
  flex-shrink: 0;
`;

export default FcuLogo;
