import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ManagingCravingsOutroSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
      </section>
      <section>
        <p>
          When you don’t give in to a craving because you are parenting your
          child, give yourself credit! Your resistance takes strength and
          deserves to be recognized.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(ManagingCravingsOutroSlide)``;
