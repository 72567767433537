import ModuleSlideView from "shared/lib/interfaces/ModuleSlideView";
import apiAxios from "../../apiAxios";

interface Props {
  moduleId: number;
  participantId: number;
}

export default async function getCompletedModuleSlides(
  props: Props
): Promise<ModuleSlideView[]> {
  const { participantId, moduleId } = props;
  const response = await apiAxios.get(
    `/participants/${participantId}/modules/${moduleId}/slides/completed`
  );
  return response.data;
}