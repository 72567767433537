import React, { FC, createContext, useContext } from "react";

const modulePageContext = createContext<ModulePageContextValue | null>(null);

export interface ModulePageContextValue {
  moduleId: number;
  moduleSectionId: number;
}

export function useModulePageContext(): ModulePageContextValue | null {
  return useContext(modulePageContext);
}

export const ModulePageContextProvider: FC<{
  value: ModulePageContextValue;
}> = ({ value, children }) => (
  <modulePageContext.Provider value={value}>
    {children}
  </modulePageContext.Provider>
);
