import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import ActivityBox, { InnerActivityBox } from "../../ActivityBox";
import ModuleCheckBox from "../../ModuleCheckBox";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const activityKind =
  ModuleActivityKind.COPING_WITHOUT_ALCOHOL_AND_DRUGS_TRIGGERS;

const ParentingAndSubstanceUseTriggersActivitySlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [responseResults, setResponseResults] = useActivity(activityKind);

  const toggleOption = (key: string) => {
    return () =>
      setResponseResults({
        ...responseResults,
        [key]: !responseResults?.[key],
      });
  };

  return (
    <ActivitySlidePage
      {...rest}
      onNext={onNext}
      title={module.name}
      light
      lightNextButton
      nextButtonText="Submit"
      activityKind={activityKind}
      header="Triggers"
      message="To fight your cravings, it is helpful to know what situations cause, or trigger, them. When you know your triggers you can avoid them, or be prepared for them, to help prevent cravings."
      isReview={isReview}
    >
      <ActivityBox
        instructions="Your turn: Check your most powerful trigger(s) from the list."
        icon="checkbox"
      >
        <InnerActivityBox>
          <ModuleCheckBox
            label="Feeling tired or stressed"
            checked={responseResults?.option1}
            onChange={toggleOption("option1")}
          />
          <ModuleCheckBox
            label="Overwhelmed with children"
            checked={responseResults?.option2}
            onChange={toggleOption("option2")}
          />
          <ModuleCheckBox
            label="Encountering someone using substance"
            checked={responseResults?.option3}
            onChange={toggleOption("option3")}
          />
          <ModuleCheckBox
            label="Visiting places where you have used substances in the past"
            checked={responseResults?.option4}
            onChange={toggleOption("option4")}
          />
          <ModuleCheckBox
            label="Substances that are available"
            checked={responseResults?.option5}
            onChange={toggleOption("option5")}
          />
          <ModuleCheckBox
            label="Thoughts about substance"
            checked={responseResults?.option6}
            onChange={toggleOption("option6")}
          />
          <ModuleCheckBox
            label="Physical pain due to health conditions"
            checked={responseResults?.option7}
            onChange={toggleOption("option7")}
          />
        </InnerActivityBox>
      </ActivityBox>
    </ActivitySlidePage>
  );
};

export default styled(ParentingAndSubstanceUseTriggersActivitySlide)`
  ${SlidePageHeader} {
    padding-bottom: 4rem;
  }
`;
