import styled from 'styled-components/macro';


const PlusMinusIcon = styled("div")<{ expanded: boolean; iconColor?: string }>`
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  background-color: #ccfcee;
  margin-right: 1.25rem;

  &::before,
  &::after {
    content: "";
    background-color: ${props => props.iconColor ?? '#0e3750'};
    width: 60%;
    height: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  &::before {
    display: ${(props) => (props.expanded ? "none" : "block")};
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(0deg);
  }
`;

export default PlusMinusIcon;