import React, { FC, useCallback, useState } from "react";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import {useActivity} from "../../../contexts/ModuleActivityContext";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import TipBagActivity from "../../TipBagActivity";

const activityKind =
  ModuleActivityKind.PROACTIVE_PARENTING_TIP_BAG;

export const proactiveParentingTips = [
  {
    title: "Explain what will happen next.",
    example: "After we finish reading this book, it will be time for bed.",
  },
  {
    title: "Give some choices.",
    example:
      "Would you like to brush your teeth before we read a story or after?",
  },
  {
    title: "Give warnings when activity will begin or end.",
    example:
      "In 5 minutes, we are going to pick up the toys and get ready for lunch.",
  },
  {
    title: "Distract child with alternative activity or toy.",
    example:
      "Let’s race to see who can get their shoes on first so we can leave!",
  },
  {
    title: "Redirect child with alternative activity.",
    example:
      "Now that the Legos are cleaned up, you can help put the plates on the table for dinner.",
  },
  {
    title: "Eliminate “trigger” that may create negative emotional responses.",
    example: "Avoid the candy aisle when you shop.",
  },
  {
    title: "Teach or suggest a way for child to cope.",
    example:
      "When I feel that way, I like to cuddle my favorite stuffed animal.",
  },
];

export const proactiveParentingTipBagInstructions =
  "Your turn:  Each time you tap on the bag you will get a tip to help you parent in a proactive way.";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps['isReview'];
}

const ProactiveParentingTipBagSlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const completeActivity = useActivity(activityKind)[1];
  const [showSectionComplete, setShowSectionComplete] = useState(false);
  const [hideNext, setHideNext] = useState(true);

  const handleSlideNext = useCallback(() => {
    completeActivity(null);
    setShowSectionComplete(true);
  }, [completeActivity]);

  return (
    <SlidePage
      {...rest}
      title={module.name}
      onNext={handleSlideNext}
      light
      lightNextButton
      darkHeader
      hideNextButton={hideNext && !isReview}
    >
      <TipBagActivity
        tips={proactiveParentingTips}
        instructions={proactiveParentingTipBagInstructions}
        onTipViewed={() => setHideNext(false)}
      />
      <ModuleSegmentCompletionOverlay
        open={showSectionComplete}
        onNext={onNext}
        module={module}
        currentSection={section}
      />
    </SlidePage>
  );
};

export default styled(ProactiveParentingTipBagSlide)``;
