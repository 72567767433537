import { css } from "styled-components/macro";
import waveBackgroundSrc from "../images/wave_background.png";
import { DARK_BLUE } from "../../colors";

const waveBackgroundCss = css`
  background-color: ${DARK_BLUE};
  background-image: url('${waveBackgroundSrc}');
  background-repeat: repeat;
  background-position: center center;
  background-blend-mode: multiply;
  background-size: auto 100%;
`;

export default waveBackgroundCss;
