import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const EffectiveCommunicationContent1Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h4>What does effective communication look like?</h4>
      </section>
      <section>
        <p>
          Communication is essential to any relationship.
          But sometimes it can be difficult to convey what you want.
          Here are some tips.
        </p>
        <p>Healthy relationships include:</p>
      </section>
      <section>
        <YellowCircleList>
          <li>
            When talking to your partner, avoid blaming or criticizing them.
          </li>
          <li>
            Use “I” statements rather than “You” statements
            to express what you want or how you feel.
          </li>
          <li>
            Try to listen without judgment, and take time to understand the
            other person’s perspective.
          </li>
          <li>
            Summarize and reflect back what you hear to ensure you understand
            what the person is trying to say.
          </li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(EffectiveCommunicationContent1Slide)``;
