import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const EncouragementAndPraiseContent1Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          When you use encouragement and praise you promote a strong sense of
          self in your child by sending them these messages:
        </p>
      </section>
      <section>
        <YellowCircleList>
          <li>
            <b>You can do it!</b>
          </li>
          <li>
            <b>You have good ideas!</b>
          </li>
          <li>
            <b>You are important!</b>
          </li>
          <li>
            <b>I like what you are doing right now.</b>
          </li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(EncouragementAndPraiseContent1Slide)``;
