import React, { FC, useState, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageHeader,
} from "../../SlidePage";
import TextButton from "@emberex/components/lib/TextButton";
import Row from "@emberex/components/lib/Row";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import ExampleSection from "../../ExampleSection";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import stock1 from "../../../images/stock/stock_19.png";
import stock2 from "../../../images/stock/stock_16.png";
import stock3 from "../../../images/stock/stock_17.png";
import stock4 from "../../../images/stock/stock_18.png";
import { ChevronDownIcon } from "../../../../common/components/Icons";
import Column from "@emberex/components/lib/Column";
import { useSearchParamNumberState } from "../../../../common/hooks/useSearchParamState";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind =
  ModuleActivityKind.RULES_AND_CONSEQUENCES_SUPERVISION_LESSON;

interface Topic {
  id: number;
  title: string;
  explanation: string;
  example: string | null;
  image: string;
}

const topics: Topic[] = [
  {
    id: 1,
    title: "CHECKING UP",
    explanation: "Be able to see young children at all times.",
    example: "Young children should be in the same room as you are when awake",
    image: stock1,
  },
  {
    id: 2,
    title: "CLEAR RULES",
    explanation: "Set up clear household rules.",
    example: "When we are inside the house, we use our inside voice.",
    image: stock2,
  },
  {
    id: 3,
    title: "CONSEQUENCES",
    explanation: "Use logical consequences to manage behavior.",
    example: "If you throw the Legos, you can’t play with them anymore today.",
    image: stock3,
  },
  {
    id: 4,
    title: "CONSISTENCY",
    explanation:
      "Whether you’re checking up, setting clear rules, or using consequences, being consistent — acting the same way each time — lets your child know what to expect.",
    example: null,
    image: stock4,
  },
];

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps["isReview"];
}

const RulesAndConsequencesSlide1: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const setActivityValue = useActivity(activityKind)[1];
  const [
    currentTopicIndex = 0,
    setCurrentTopicIndex,
  ] = useSearchParamNumberState("index");
  const [viewedTopicIds, setViewedTopicIds] = useState(() => [topics[0].id]);
  const activityComplete = viewedTopicIds.length === topics.length;
  const [showSegmentOverlay, setShowSegmentOverlay] = useState(false);
  const currentTopic = topics[currentTopicIndex];
  const nextTopic: Topic | undefined = topics[currentTopicIndex + 1];

  const handleSlideNext = useCallback(() => {
    setActivityValue(null);
    setShowSegmentOverlay(true);
  }, [setActivityValue]);

  return (
    <ActivitySlidePage
      {...rest}
      activityKind={activityKind}
      title={module.name}
      showNextButton={activityComplete || isReview}
      onNext={isReview ? onNext : handleSlideNext}
      isReview={isReview}
    >
      <section>
        <h3>MANAGING TODDLERS</h3>
        <h3>Supervision, clear rules, and consequences</h3>
        <p>
          Supervision is important to keep your children safe, help them learn,
          and build strong relationships.
        </p>
        <SupervisionHeader>The four C’s of supervision are:</SupervisionHeader>
      </section>
      <SupervisionSubHeader>Tap the images to learn more.</SupervisionSubHeader>
      <SupervisionButtonRow>
        {topics.map((topic, i) => (
          <SupervisionButton
            key={topic.id}
            selected={topic.id === currentTopic.id}
            onClick={() => {
              if (!viewedTopicIds.includes(topic.id)) {
                setViewedTopicIds([...viewedTopicIds, topic.id]);
              }
              setCurrentTopicIndex(i);
            }}
          >
            <img src={topic.image} alt={topic.title} />
            {nextTopic && nextTopic.id === topic.id && (
              <SupervisionButtonArrow>
                <ChevronDownIcon />
              </SupervisionButtonArrow>
            )}
          </SupervisionButton>
        ))}
      </SupervisionButtonRow>
      <section>
        <TopicTitle>{currentTopic.title}</TopicTitle>
        <TopicExplanation>{currentTopic.explanation}</TopicExplanation>
        {!!currentTopic.example && (
          <TopicExample>{currentTopic.example}</TopicExample>
        )}
      </section>

      <ModuleSegmentCompletionOverlay
        open={showSegmentOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
        blurbText="Awesome!"
      />
    </ActivitySlidePage>
  );
};

export default styled(RulesAndConsequencesSlide1)`
  ${SlidePageHeader} {
    padding-bottom: 0;
  }
`;

const SupervisionHeader = styled("h4")`
  text-align: center;
  margin-top: 2rem;
`;

const SupervisionSubHeader = styled("h5")`
  font-size: 0.75rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: calc(100% - 1rem);
  margin: 0.5rem 0.5rem 0 0.5rem;
  background-color: #ccfcee;
  color: #0e3750;
  font-weight: bold;
`;

const SupervisionButtonRow = styled(Row)`
  justify-content: center;
  margin-top: 2rem;
`;

const SupervisionButton = styled(TextButton)<{ selected?: boolean }>`
  position: relative;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: #d8d8d8;
  border: ${(props) => (props.selected ? "3px solid #CCFCEE" : "none")};

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & + & {
    margin-left: 1rem;
  }
`;

const SupervisionButtonArrow = styled(Column)`
  position: absolute;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  background-color: #ccfcee;
  top: -0.5rem;
  left: 50%;
  transform: translate(-50%, -100%);
  color: #0e3750;
  justify-content: center;
  align-items: center;
  font-size: 80%;

  &::after {
    content: "";
    position: absolute;
    width: 0.25rem;
    height: 0.75rem;
    left: 50%;
    top: 0;
    margin: -0.75rem 0 0 -0.125rem;
    background-color: #ccfcee;
  }
`;

const TopicTitle = styled("div")`
  color: #ff6762;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
`;

const TopicExplanation = styled("p")`
  margin-top: 1rem;
`;

const TopicExample = styled(ExampleSection)`
  margin: 1.25rem 0;
`;
