import styled from "styled-components/macro";
import ribbonImageSrc from "../images/ribbon.png";

const RibbonImage = styled("img").attrs({ src: ribbonImageSrc, alt: "" })`
  height: 200px;
  width: 119px;
  flex-shrink: 0;
`;

export default RibbonImage;
