import { DetailedParticipant } from "shared/lib/interfaces/Participant";
import apiAxios from "../../apiAxios";

export default async function getDetailedParticipant(
  participantId: number
): Promise<DetailedParticipant | null> {
  const response = await apiAxios.get(`/participants/${participantId}/details`);

  return response.data;
}
