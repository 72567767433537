import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import ShoeList, { ShoeListItem } from "../../ShoeList";
import ExampleSection from "../../ExampleSection";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RelationshipBuildingExampleSlide: FC<Props> = (props) => {
  const { module, ...rest } = props;

  return (
    <SlidePage
      {...rest}
      lightNextButton
      nextButtonText="Start Practice"
      title={module.name}
    >
      <section>
        <ExampleSection>
          <SituationHeader>Situation</SituationHeader>
          <SituationText>
            Your toddler has been playing with his brother and is tired and
            hungry.
          </SituationText>
          <ShoeList>
            <ShoeListItem>
              <b>BE SENSITIVE</b>
              <br /> You walk over to him and ask him why he is upset.
            </ShoeListItem>
            <ShoeListItem>
              <b>BE RESPONSIVE</b>
              <br /> You pick him up, calm him down, and offer him a snack.
            </ShoeListItem>
          </ShoeList>
        </ExampleSection>
      </section>
      <section>
        <p>Now it’s your turn to practice. Press start to begin.</p>
      </section>
    </SlidePage>
  );
};

export default styled(RelationshipBuildingExampleSlide)``;

const SituationText = styled("p")`
  margin-bottom: 1.5rem;
`;

const SituationHeader = styled("h3")`
  color: #ff6762;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;
