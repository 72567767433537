import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const RulesAndConsequencesSlide19: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <ListHeader>DO IGNORE</ListHeader>
        <YellowCircleList>
          <li>Whining</li>
          <li>Emotional outbursts</li>
          <li>Complaining</li>
          <li>Pouting</li>
        </YellowCircleList>
        <ListHeader>DON'T IGNORE</ListHeader>
        <YellowCircleList>
          <li>Aggressive behaviors</li>
          <li>Hitting</li>
          <li>Being unsafe</li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(RulesAndConsequencesSlide19)``;

const ListHeader = styled("h3")`
  color: #ff6762;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 0.7rem;

  * + & {
    margin-top: 2rem;
  }
`;
