import React, { FC, useCallback, useRef } from "react";
import styled from "styled-components/macro";
import VideoId, {
  getVideoCaptionName,
  getVideoFileName,
  getVideoPosterFileName,
} from "shared/lib/enums/VideoId";

export interface VideoProps {
  videoId: VideoId;
  onEnded?(playedToCompletion: boolean): unknown;
  onPause?(): unknown;
  onStarted?(): unknown;
}

const Video: FC<VideoProps> = (props) => {
  const { videoId, onPause, onEnded, onStarted, ...rest } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleEnd = useCallback(() => {
    const { current } = videoRef;
    const { duration, currentTime } = current || {
      duration: 0,
      currentTime: 0,
    };
    if (onEnded) {
      onEnded(currentTime >= duration);
    }
  }, [onEnded]);

  return (
    <video
      {...rest}
      controls
      onEnded={handleEnd}
      onPlay={onStarted}
      onPause={onPause}
      width="305px"
      poster={getVideoPosterFileName(videoId)}
      ref={videoRef}
      crossOrigin="anonymous"
      controlsList='nodownload'
    >
      <source
        src={`/api/media/video/${getVideoFileName(videoId, "mp4")}`}
        type="video/mp4"
      />
      <source
        src={`/api/media/video/${getVideoFileName(videoId, "ogg")}`}
        type="video/ogg"
      />
      <source
        src={`/api/media/video/${getVideoFileName(videoId, "webm")}`}
        type="video/webm"
      />
      <track src={`/api/media/caption/${getVideoCaptionName(videoId)}`} kind='subtitles' label='English' srcLang='en'/>
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
};

export default styled(Video)`
  flex-shrink: 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;
