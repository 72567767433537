import ParticipantVisit from "shared/lib/interfaces/ParticipantVisit";
import apiAxios from "../../apiAxios";

export default async function createParticipantVisit(
  path: string,
  parentVisitId: number | null
): Promise<ParticipantVisit> {
  const response = await apiAxios.post(
    `/a/visit/${path[0] === "/" ? path.slice(1) : path}`,
    { parentVisitId }
  );
  return response.data;
}
