import React, { FC, ReactNode } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import TextButton from "@emberex/components/lib/TextButton";
import Column from "@emberex/components/lib/Column";
import { ChevronLeftIcon } from "../../common/components/Icons";
import SegmentedProgressBar, {
  SegmentedProgressValue,
} from "../../surveys/components/SegmentedProgressBar";
import ParticipantNav from "../../participant/components/ParticipantNav";
import PillButton from "../../surveys/components/PillButton";
import { DARKEST_BLUE, YELLOW } from "../../colors";
import VideoActivity from "./VideoActivity";
import Row from "@emberex/components/lib/Row";

export type SlidePageProgress = SegmentedProgressValue;

export interface SlidePageProps {
  onBack(): any;
  onNext(): any;
  title: ReactNode;
  header?: ReactNode;
  message?: ReactNode;
  hideNextButton?: boolean;
  showNextButton?: boolean;
  hideBackButton?: boolean;
  showBackButton?: boolean;
  nextButtonText?: string;
  darkHeader?: boolean;
  dark?: boolean;
  light?: boolean;
  lightWhite?: boolean;
  lightNextButton?: boolean;
  darkNextButton?: boolean;
  hideFooter?: boolean;
  progress: SlidePageProgress;
  isReview?: boolean;
}

const SlidePage: FC<SlidePageProps> = (props) => {
  const {
    title,
    onBack,
    onNext,
    progress,
    header,
    message,
    hideNextButton = false,
    showNextButton = !hideNextButton,
    hideBackButton = false,
    showBackButton = !hideBackButton,
    nextButtonText = "Next",
    lightWhite,
    light = lightWhite ? true : false,
    darkHeader = !light,
    dark = !light,
    lightNextButton = false,
    darkNextButton = !lightNextButton,
    hideFooter,
    children,
    ...rest
  } = props;

  return (
    <Root {...rest} dark={dark}>
      <ParticipantNav />
      {showBackButton && (
        <BackButton onClick={onBack} dark={dark}>
          <ChevronLeftIcon />
        </BackButton>
      )}
      <SlidePageHeader dark={darkHeader}>
        <Title>{title}</Title>
        <SegmentedProgressBar segments={progress} dark={darkHeader} />
        <MessageContainer>
          <Header>{header}</Header>
          {message}
        </MessageContainer>
      </SlidePageHeader>
      <SlidePageContent dark={dark} lightWhite={lightWhite}>
        {children}
      </SlidePageContent>
      {!hideFooter && (
        <SlidePageFooter dark={dark} lightWhite={lightWhite}>
          {showNextButton && (
            <NextButton onClick={onNext} dark={darkNextButton}>
              {nextButtonText}
            </NextButton>
          )}
        </SlidePageFooter>
      )}
    </Root>
  );
};

export default styled(SlidePage)``;

const Root = styled(Page)<{ dark: boolean }>`
  background-color: ${(props) => (props.dark ? DARKEST_BLUE : "white")};
  height: 100vh;
  overflow: auto;
`;

const BackButton = styled(TextButton)<{ dark: boolean }>`
  position: absolute;
  color: ${(props) => (props.dark ? "white" : "#564B91")};
  padding: 0 0.75rem 0 0.75rem;
  left: 0;
  top: 1.5rem;
  z-index: 2;
  font-size: 1.25rem;
  background-color: transparent;
`;

export const SlidePageHeader = styled(Column)<{ dark: boolean }>`
  align-items: center;
  text-align: center;
  padding: 3rem 2rem 2rem 2rem;
  flex-shrink: 0;
  background-color: ${(props) => (props.dark ? DARKEST_BLUE : "white")};
  > * {
    width: 100%;
  }
`;

const MessageContainer = styled("div")`
  text-align: left;
  padding: 0 0.5rem 0 0.5rem;
`;

const Header = styled("div")`
  color: ${DARKEST_BLUE};
  margin: 2rem 0 0.7rem 0;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

const Title = styled("h1")`
  color: #4dacbe;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: normal;
`;

export const SlidePageContent = styled(Column)<{
  dark: boolean;
  lightWhite?: boolean;
}>`
  flex: 1 0 auto;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${(props) => (props.dark ? "white" : DARKEST_BLUE)};
  background-color: ${(props) =>
    props.dark ? DARKEST_BLUE : props.lightWhite ? "white" : YELLOW};
  section {
    display: flex;
    flex-direction: column;
    padding: 0 2.5rem 0 2.5rem;
  }

  section + section {
    margin-top: 1.5rem;
  }

  section + ${VideoActivity} {
    margin-top: 3rem;
  }

  section > h3 {
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.1rem;
  }
`;

export const SlidePageFooter = styled(Row)<{
  dark: boolean;
  lightWhite?: boolean;
}>`
  padding: 2rem 0 2rem 0;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${(props) =>
    props.dark ? DARKEST_BLUE : props.lightWhite ? "white" : YELLOW};
`;

const NextButton = styled(PillButton)`
  width: 200px;
  align-self: center;
  padding: 0 0 0 0;
  flex-shrink: 0;
`;
