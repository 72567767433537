import React, { FC } from "react";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import ActivityOverlay from "../../common/components/ActivityOverlay";
import BearIcon, { BearIcons } from "../../common/components/BearIcon";
import Blurb from "../../common/components/Blurb";
import Badge from "../../common/components//PointsBadge";
import styled from "styled-components/macro";
import getPositiveBlurbText from "../../common/utils/getPositiveBlurbText";
import { getActivityPointValue } from "shared/lib/interfaces/ModuleActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { WELCOME_VIDEO_POINTS } from "shared/lib/constants/points";

interface Props {
  open: boolean;
  activityKind: ModuleActivityKind | "WELCOME_VIDEO"; // WELCOME_VIDEO is not a module activity, so it's an edge case here.
  onNext(): void;
  bearIcon: BearIcons;
}

const ActivityCompletionOverlay: FC<Props> = (props) => {
  const { activityKind, bearIcon, ...rest } = props;
  const activityPoints =
    activityKind === "WELCOME_VIDEO"
      ? WELCOME_VIDEO_POINTS
      : getActivityPointValue(activityKind);
  const blurbText = getPositiveBlurbText();

  return (
    <ActivityOverlay {...rest}>
      <ContentStacked>
        <PointsRow>
          <Badge>
            <Column>
              <Points>{activityPoints}</Points>
              <PointsLabel>PTS</PointsLabel>
            </Column>
          </Badge>
        </PointsRow>
        <MessageRow>
          You’ve earned {activityPoints} points for completing an activity!
        </MessageRow>
        <BearMotivationRow>
          <MotivationColumn>
            <Bear icon={bearIcon} />
          </MotivationColumn>
          <MotivationColumn>
            <BearBlurb>{blurbText}</BearBlurb>
          </MotivationColumn>
        </BearMotivationRow>
      </ContentStacked>
    </ActivityOverlay>
  );
};

export default styled(ActivityCompletionOverlay)``;

const ContentStacked = styled(Column)`
  height: 100%;
  justify-content: space-between;
`;

const PointsRow = styled(Row)`
  height: 30%;
  padding-top: 0.8rem;
  justify-content: center;
  align-items: center;
  ${Badge} {
    height: 100%;
    width: 100%;
  }
`;

const Points = styled("div")`
  text-align: center;
  color: #ffffff;
  font-size: 2.1rem;
  font-weight: 700;
  height: 1.7rem;
  @media (min-width: 450px) {
    font-size: 3rem;
    height: 2.5rem;
  }
`;

const PointsLabel = styled("div")`
  text-align: center;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  @media (min-width: 450px) {
    font-size: 2rem;
  }
`;

const MessageRow = styled(Row)`
  height: 30%;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #0e3750;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 4rem 0.5rem 4rem;

  @media (min-width: 450px) {
    font-size: 2rem;
    line-height: 1em;
  }
`;

const BearMotivationRow = styled(Row)`
  width: 100%;
  height: 40%;
`;

const MotivationColumn = styled(Column)`
  position: relative;
  justify-content: center;
  width: 50%;
`;

const BearBlurb = styled(Blurb)`
  padding-left: 1.5rem;
  width: 90%;
  height: 60%;
  text-align: center;
  color: #0e3750;
  font-size: 1rem;
  font-weight: bold;
`;

const Bear = styled(BearIcon)`
  position: absolute;
  width: auto;
  top: -14px;
  height: 275px;
`;
