import { css } from 'styled-components/macro';

export default function hidable(props: { hide?: boolean }) {
  const { hide } = props;
  if (hide) {
    return css`
      opacity: 0;
      pointer-events: none;
      user-select: none;
    `;
  }
  return css`
    opacity: 1;
  `;
}