import React, { FC } from "react";
import styled from "styled-components/macro";
import PillButton from "../../surveys/components/PillButton";
import AdminModal from "./AdminModal";
import { DARK_BLUE } from "../../colors";

interface Props {
  title?: string;
  buttonText?: string;
  hideButton?: boolean;
  message: string;
  onClose(): any;
}

const AlertModal: FC<Props> = (props) => {
  const {
    title = "Alert",
    buttonText = "Close",
    hideButton = false,
    message,
    onClose,
    ...rest
  } = props;

  return (
    <AdminModal {...rest} onClose={onClose} showCloseButton={false}>
      <h1>{title}</h1>
      <p>{message}</p>
      {!hideButton && <PillButton onClick={onClose}>{buttonText}</PillButton>}
    </AdminModal>
  );
};

export default styled(AlertModal)`
  width: 600px;
  color: ${DARK_BLUE};

  > h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  > p {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  > ${PillButton} {
    width: 200px;
    align-self: center;
  }
`;
