import ModuleActivity, {
  ModuleActivityValue,
} from "shared/lib/interfaces/ModuleActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import apiAxios from "../../apiAxios";

interface Props<T extends ModuleActivityKind> {
  participantId: number;
  moduleId: number;
  kind: T;
  value: ModuleActivityValue<T>;
}

export default async function saveModuleActivity<T extends ModuleActivityKind>(
  props: Props<T>
): Promise<ModuleActivity<T>> {
  const { participantId, moduleId, kind, value } = props;
  const response = await apiAxios.put(
    `/participants/${participantId}/modules/${moduleId}/activities/${kind}`,
    {
      value,
    }
  );

  return response.data;
}
