import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import VideoId from "shared/lib/enums/VideoId";

const activityKind = ModuleActivityKind.ENCOURAGEMENT_AND_PRAISE_NOTICING_VIDEO;

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const EncouragementAndPraiseIntroSlide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [onPlayStart, onPlayed, videoCompleted] = useVideoActivity(
    activityKind,
    VideoId.ENCOURAGEMENT_AND_PRAISE_NOTICING_VIDEO
  );

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      activityKind={activityKind}
      title={module.name}
      onNext={onNext}
    >
      <section>
        <h3>{section.name}</h3>
        <p>
          Try to strengthen positive behaviors by noticing when your child does
          something right. To do this, get in the habit of giving praise and
          encouragement for the little things you like or appreciate that your
          child does each day.
        </p>
      </section>
      <VideoActivity
        onStarted={onPlayStart}
        onEnded={onPlayed}
        videoId={VideoId.ENCOURAGEMENT_AND_PRAISE_NOTICING_VIDEO}
      />
    </ActivitySlidePage>
  );
};

export default styled(EncouragementAndPraiseIntroSlide)``;
