import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ProactiveParentingPlaySummarySlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSectionComplete, setShowSectionComplete] = useState(false);

  const handleSlideNext = useCallback(() => {
    setShowSectionComplete(true);
  }, []);

  return (
    <SlidePage {...rest} title={module.name} onNext={handleSlideNext}>
      <section>
        <p>
          Planning ahead is a lot of work at first, but when you can prevent
          problem behavior in your child before it starts, it will be worth your
          efforts.
        </p>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSectionComplete}
        onNext={onNext}
        module={module}
        currentSection={section}
      />
    </SlidePage>
  );
};

export default styled(ProactiveParentingPlaySummarySlide)``;
