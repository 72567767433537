import React, { FC, useMemo } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import useToggleState from "@emberex/react-utils/lib/useToggleState";
import { SurveyWithQuestionsAndResults } from "shared/lib/interfaces/Survey";
import Module from "shared/lib/interfaces/Module";
import getSurveyResultGroups, {
  getSurveyResultGroupHeadings,
} from "shared/lib/utils/getSurveyResultGroups";
import SurveyTakeResponse from "shared/lib/interfaces/SurveyTakeResponse";
import capitalizeFirst from "shared/lib/utils/capitalizeFirst";
import SurveyPageHeader from "./SurveyPageHeader";
import PillButton from "./PillButton";
import HighlightedList, { HighlightedListItem } from "./HighlightedList";
import infoIconSrc from "../../modules/images/info_icon.png";

interface Props {
  survey: Pick<
    SurveyWithQuestionsAndResults,
    "name" | "questions" | "results" | "groupHeadingOrder"
  >;
  responses: Pick<SurveyTakeResponse, "questionId" | "value">[];
  module?: Pick<Module, "name" | "icon" | "defaultIndex">;
  hideNav?: boolean;
  submitButtonText?: string;
  onNext(): unknown;
}

const SurveyResultsPage: FC<Props> = (props) => {
  const {
    survey,
    responses,
    module,
    hideNav,
    submitButtonText,
    onNext,
    ...rest
  } = props;
  const [showHelpOverlay, toggleHelpOverlay] = useToggleState(false);
  const resultGroupHeadings = useMemo(
    () =>
      getSurveyResultGroupHeadings(
        getSurveyResultGroups({
          questions: survey.questions,
          results: survey.results,
          responses,
        }),
        survey.groupHeadingOrder ?? []
      ),
    [survey.questions, survey.results, survey.groupHeadingOrder, responses]
  );

  return (
    <Page {...rest}>
      <SurveyPageHeader survey={survey} module={module} hideNav={hideNav} />
      <Content>
        <Banner>Survey Results</Banner>
        <InstructionsText>
          We suggest you go through all the content, but based on your results,
          we recommend you pay close attention to the topics in the Focus Area.
          Tap the <InlineInfoIcon alt="View result categories icon" /> to learn
          more about the color categories.
        </InstructionsText>

        <HelpButton onClick={toggleHelpOverlay}>
          <img src={infoIconSrc} alt="View result categories" />
        </HelpButton>

        <HighlightedList color="red" label="Focus Area">
          {resultGroupHeadings.areaOfConcern.map((headingName) => (
            <HighlightedListItem key={headingName} color="red">
              {capitalizeFirst(headingName)}
            </HighlightedListItem>
          ))}
        </HighlightedList>
        <HighlightedList color="yellow" label="Needs Attention">
          {resultGroupHeadings.needsAttention.map((headingName) => (
            <HighlightedListItem key={headingName} color="yellow">
              {capitalizeFirst(headingName)}
            </HighlightedListItem>
          ))}
        </HighlightedList>
        <HighlightedList color="green" label="Area of Strength">
          {resultGroupHeadings.areaOfStrength.length > 0 ? (
            resultGroupHeadings.areaOfStrength.map((headingName) => (
              <HighlightedListItem key={headingName} color="green">
                {capitalizeFirst(headingName)}
              </HighlightedListItem>
            ))
          ) : (
            <HighlightedListItem color="green">
              Participating in this program
            </HighlightedListItem>
          )}
        </HighlightedList>
        {showHelpOverlay && (
          <HelpOverlay>
            <HelpHeader>Results Categories</HelpHeader>

            <HelpCategory borderColor="#7D0B0B">
              <HelpCategoryTitle color="white" backgroundColor="#7D0B0B">
                red
              </HelpCategoryTitle>
              <HelpCategoryDescription>
                Your answers show this topic is a concern for your family. This
                program can help you improve your child’s behavior and your
                family relationships.
              </HelpCategoryDescription>
            </HelpCategory>

            <HelpCategory borderColor="#F7D20F">
              <HelpCategoryTitle color="#11355D" backgroundColor="#F7D20F">
                yellow
              </HelpCategoryTitle>
              <HelpCategoryDescription>
                Your answers show this topic could use some attention in your
                family. By catching a problem early and using the skills in this
                program, you can make positive changes for your child and
                family.
              </HelpCategoryDescription>
            </HelpCategory>

            <HelpCategory borderColor="#4D9105">
              <HelpCategoryTitle color="white" backgroundColor="#4D9105">
                green
              </HelpCategoryTitle>
              <HelpCategoryDescription>
                Your answers show that you use good parenting skills that help
                you positively shape your child’s behavior. Keep up the good
                work!
              </HelpCategoryDescription>
            </HelpCategory>

            <HelpDoneButton onClick={toggleHelpOverlay}>Done</HelpDoneButton>
          </HelpOverlay>
        )}
      </Content>
      <NextButton onClick={onNext}>
        {submitButtonText ?? `Start ${module?.name ?? ""}`}
      </NextButton>
    </Page>
  );
};

export default styled(SurveyResultsPage)`
  background-color: #f4f6f7;
`;

const Banner = styled(Row)`
  align-items: center;
  justify-content: center;
  color: white;
  height: 70px;
  background-color: #4dacbe;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
`;

const InlineInfoIcon = styled("img").attrs({ src: infoIconSrc })`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 0.5rem;
`;

const InstructionsText = styled("p")`
  padding: 1.25rem 2.5rem 1rem 2.5rem;
`;

const NextButton = styled(PillButton)`
  width: 240px;
  padding: 0 0 0 0;
  margin: 3rem auto;
`;

const Content = styled(Column)`
  position: relative;
`;

const HelpButton = styled("button")`
  padding: 0.5rem;
  margin: 0 0 0 0;
  background-color: transparent;
  width: 4rem;
  display: inline-block;
  align-self: center;
  border: none;
  cursor: pointer;

  > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const HelpOverlay = styled(Column)`
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translate(-50%, 0);
  border-radius: 0.5rem;
  background-color: white;
  padding: 2.5rem;
  width: 22rem;
  max-width: 95vw;
  z-index: 10;
`;

const HelpHeader = styled("h3")`
  color: #11355d;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

const HelpCategory = styled(Column) <{ borderColor: string }>`
  box-sizing: border-box;
  border: 5px solid ${(props) => props.borderColor};
  border-radius: 0.5rem;
  background-color: #ffffff;
  margin-top: 2rem;
`;

const HelpCategoryTitle = styled("h4") <{
  color: string;
  backgroundColor: string;
}>`
  padding: 0.5rem;
  text-align: center;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
`;

const HelpCategoryDescription = styled("p")`
  color: #0e3750;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 0.9rem;
  padding: 0.75rem;
`;

const HelpDoneButton = styled(PillButton)`
  margin-top: 2rem;
  align-self: center;
`;
