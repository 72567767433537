import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import ParticipantNav from "../components/ParticipantNav";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import Row from "@emberex/components/lib/Row";
import Column from "@emberex/components/lib/Column";
import Link from "../../common/components/Link";
import { FcuLogoCompact } from "../../common/components/FcuLogo";
import TextButton from "@emberex/components/lib/TextButton";
import Video, { VideoProps } from "../../modules/components/Video";
import VideoId, { getVideoTitle } from "shared/lib/enums/VideoId";
import filmIconSrc from "../../modules/images/film_icon.png";
import { useParticipantContext } from "../contexts/ParticipantContext";
import viewVideo from "../../videos/viewVideo";
import { getParticipantLibraryVideos } from "../api/getParticipantLibraryVideos";
import {
  LibraryVideo,
  LibraryVideoCategory,
} from "shared/lib/interfaces/LibraryVideo";
import useModuleTimer from "../../modules/hooks/useModuleTimer";
import { getParticipantVideoViews } from "../../videos/getParticipantVideoViews";

SwiperCore.use([Pagination]);
const caroselItemWidth = 305;
const caroselItemSpacing = 10;

const ParticipantLibraryPage: FC = (props) => {
  const [tab, setTab] = useState<"stress-reduction" | "bear">("bear");

  useDocumentTitle("Library");
  const [loading, setLoading] = useState(true);
  const [allVideos, setAllVideos] = useState<LibraryVideo[]>([]);
  const bearVideos = useMemo(
    () =>
      allVideos.filter((video) => video.category === LibraryVideoCategory.BEAR),
    [allVideos]
  );
  const stressReductionVideos = useMemo(
    () =>
      allVideos.filter(
        (video) => video.category === LibraryVideoCategory.STRESS_REDUCTION
      ),
    [allVideos]
  );
  const visibleVideos = tab === "bear" ? bearVideos : stressReductionVideos;
  const [viewedVideoIds, setViewedVideoIds] = useState<VideoId[]>([]);
  const [playing, setPlaying] = useState<VideoId[]>([]);
  const { user } = useParticipantContext();
  const participantId = user.id;

  useAsyncEffect(
    async (isCancelled) => {
      const [libraryVideos, videoViews] = await Promise.all([
        getParticipantLibraryVideos(),
        getParticipantVideoViews(participantId),
      ]);

      if (!isCancelled()) {
        setAllVideos(libraryVideos);
        setViewedVideoIds(videoViews.map((v) => v.videoId));
        setLoading(false);
      }
    },
    [participantId]
  );

  const handleVideoPlayed = useCallback(
    (videoId: VideoId) => {
      if (playing.includes(videoId)) {
        return;
      }
      setPlaying((prev) => [...prev, videoId]);
      viewVideo({ participantId, videoId }).catch((error) =>
        console.error(
          `Error occurred while marking video ${videoId} played`,
          error
        )
      );
    },
    [participantId, playing]
  );

  const handleVideoEnd = useCallback(
    (videoId: VideoId, toCompletion: boolean) => {
      if (toCompletion) {
        setPlaying((prev) => prev.filter((v) => v !== videoId));
      }
    },
    []
  );

  return (
    <Page {...props}>
      <ParticipantNav />
      <Header>
        <Link to="/">
          <FcuLogoCompact />
        </Link>
      </Header>
      <Title>Video Library</Title>
      <ContentBox>
        <Icon>
          <img src={filmIconSrc} alt="Videos" />
        </Icon>
        <NavButtons>
          <NavButton onClick={() => setTab("bear")} selected={tab === "bear"}>
            Bear Videos
          </NavButton>
          <NavButton
            onClick={() => setTab("stress-reduction")}
            selected={tab === "stress-reduction"}
          >
            Stress Reduction Videos
          </NavButton>
        </NavButtons>
        {!loading && (
          <CaroselContainer>
            <Swiper
              key={tab}
              slidesPerView={Math.min(visibleVideos.length, 3)}
              spaceBetween={caroselItemSpacing}
              centeredSlides
              pagination={{
                clickable: true,
                el: ".custom-swiper-pagination",
              }}
            >
              {visibleVideos.map((libraryVideo) => {
                const hasViewed = viewedVideoIds.includes(libraryVideo.videoId);
                return (
                  <SwiperSlide key={libraryVideo.id}>
                    <CaroselItemContents>
                      <LibraryVideoPlayer
                        libraryVideo={libraryVideo}
                        onStarted={() => {
                          handleVideoPlayed(libraryVideo.videoId);
                          if (!hasViewed) {
                            setViewedVideoIds([
                              ...viewedVideoIds,
                              libraryVideo.videoId,
                            ]);
                          }
                        }}
                        onEnded={(toCompletion) =>
                          handleVideoEnd(libraryVideo.videoId, toCompletion)
                        }
                      />
                      <VideoTitle>
                        {getVideoTitle(libraryVideo.videoId)}
                      </VideoTitle>
                      {hasViewed && <VideoViewedLabel>viewed</VideoViewedLabel>}
                    </CaroselItemContents>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </CaroselContainer>
        )}
      </ContentBox>
      <Row className="custom-swiper-pagination">
        {/* Used by swiper to render the carosel pagination dots */}
      </Row>
    </Page>
  );
};

const LibraryVideoPlayer: FC<
  Omit<VideoProps, "videoId"> & { libraryVideo: LibraryVideo }
> = ({ libraryVideo, onStarted, onEnded, ...rest }) => {
  const [playing, setPlaying] = useState(false);
  // Keep track of time spent watching videos as if it were time spent in the module the video is from.
  useModuleTimer(libraryVideo.moduleId, null, playing);

  return (
    <Video
      {...rest}
      videoId={libraryVideo.videoId}
      onStarted={() => {
        setPlaying(true);
        if (onStarted) {
          onStarted();
        }
      }}
      onPause={() => {
        setPlaying(false);
      }}
      onEnded={(toCompletion) => {
        setPlaying(false);
        if (onEnded) {
          onEnded(toCompletion);
        }
      }}
    />
  );
};

export default styled(ParticipantLibraryPage)`
  background-color: #0e3750;
  overflow-x: hidden;

  .custom-swiper-pagination {
    margin-top: 1.25rem;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    box-sizing: border-box;
    height: 1.1rem;
    width: 1.1rem;
    border: 2px solid #ccfcee;
    background-color: transparent;
  }

  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 0.5rem;
  }

  .swiper-pagination-bullet-active {
    background-color: #ccfcee;
  }
`;

const Header = styled(Row)`
  height: 60px;
  width: 100%;
  background-color: #0e3750;
  justify-content: center;

  > ${Link} {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
`;

const Title = styled("h1")`
  color: #4dacbe;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 2.5rem 0;
  font-weight: normal;
`;

const ContentBox = styled(Column)`
  width: 100%;
  padding: 2.5rem 0 0;
  background-color: #fdd965;
`;

const Icon = styled(Column)`
  box-sizing: border-box;
  height: 3.25rem;
  width: 3.25rem;
  border: 2px solid #fdd965;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;

  > img {
    width: 35%;
    height: 40%;
  }
`;

const CaroselContainer = styled(Row)`
  overflow: hidden;
  align-self: center;
  width: ${(caroselItemWidth + caroselItemSpacing) * 3}px;
`;

const CaroselItemContents = styled(Column)`
  width: ${caroselItemWidth}px;
  padding-bottom: 1.5rem;
`;

const NavButtons = styled(Row)`
  margin-bottom: 2rem;
  padding: 0 0.5rem 0 0.5rem;
`;

const NavButton = styled(TextButton)<{ selected: boolean }>`
  box-sizing: border-box;
  height: 54px;
  width: 50%;
  border: 4px solid white;
  color: #0e3750;
  font-size: 0.9rem;
  line-height: 0.95rem;
  text-align: center;
  padding: 0 1.25rem 0 1.25rem;
  font-weight: ${(props) => (props.selected ? "bold" : "500")};
  background-color: ${(props) => (props.selected ? "white" : "transparent")};

  &:first-child {
    border-radius: 27px 0 0 27px;
  }

  &:last-child {
    border-radius: 0 27px 27px 0;
  }
`;

const VideoTitle = styled("h3")`
  color: #0e3750;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  margin: 0.6rem 0 0 0;
`;

const VideoViewedLabel = styled(Row)`
  width: 5rem;
  height: 1.25rem;
  color: #0e3750;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 0.675rem;
  background-color: #ccfcee;
  flex-shrink: 0;
  margin-top: 0.9rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
`;
