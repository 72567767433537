import React, { FC } from "react";
import { CheckIcon } from "../../common/components/Icons";
import styled from "styled-components/macro";

const FakeCheckBox: FC = () => {
  return (
    <FakeCheckbox>
      <CheckIcon />
    </FakeCheckbox>
  );
};

export default FakeCheckBox;
const FakeCheckbox = styled("div")`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px;
  border: 3px solid #ddd;
  left: 0;
  top: 0;
  border-radius: 3px;
  background-color: white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s, border-color 0.2s, background-color 0.2s;
  font-size: 1.25rem;

  input:disabled ~ & {
    background-color: #ddd;
  }

  input:checked ~ & {
    background-color: #4dacbe;
    border-color: #4dacbe;
  }
`;
