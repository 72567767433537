import styled from "styled-components/macro";
import shieldImageSrc from "../images/shield_icon.png";

const ShieldImage = styled("img").attrs({
  src: shieldImageSrc,
})`
  height: 16px;
  width: 17px;
`;

export default ShieldImage;
