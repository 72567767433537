import React, { FC } from "react";
import styled from "styled-components/macro";
import ModuleSlideKind from "shared/lib/enums/ModuleSlideKind";
import SlidePage, { SlidePageProps } from "./SlidePage";

interface Props extends Omit<SlidePageProps, "title" | "subTitle"> {
  slideKind: ModuleSlideKind;
}

const PlaceholderSlide: FC<Props> = (props) => {
  const { slideKind, ...rest } = props;

  return <SlidePage {...rest} title={`Missing Slide ${slideKind}`} />;
};

export default styled(PlaceholderSlide)``;
