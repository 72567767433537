import { useMemo } from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import useSearchParamState from "../../common/hooks/useSearchParamState";

interface Options {
  onBack(): any;
  includeReview?: boolean;
}

export enum PlanSection {
  INTRO = "INTRO",
  LETTER_P = "P",
  LETTER_L = "L",
  LETTER_A = "A",
  LETTER_N = "N",
  REVIEW = "REVIEW",
}

const navigationSequence = Object.values(PlanSection);

export default function usePlanSlide({
  onBack,
  includeReview,
}: Options) {

  const [
    planSection = PlanSection.INTRO,
    setPlanSection,
  ] = useSearchParamState<PlanSection>("step");
  const history = useHistory();

  const planSteps = useMemo(() =>
    navigationSequence.filter(section => section !== PlanSection.REVIEW || includeReview),
    [includeReview]
  );

  const showNextPlanSection = useCallback(() => {
    const nextIndex = planSteps.indexOf(planSection) + 1;
    setPlanSection(planSteps[Math.min(nextIndex, navigationSequence.length - 1)]);
  }, [setPlanSection, planSection, planSteps]);

  const handleBack = useCallback(() => {
    if(planSection === PlanSection.INTRO) {
      onBack();
      return;
    }
    history.goBack();
  }, [onBack, history, planSection]);

  return {
    planSection,
    showNextPlanSection,
    onBack: handleBack,
  }
}