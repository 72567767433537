import React, { FC, useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import BribeRewardActivity, {
  EXPLANATION_DELAY,
} from "../../BribeRewardActivity";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BribeReward } from "shared/lib/interfaces/ModuleActivity";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: SlidePageProps['isReview'];
}

const UsingIncentivesBribeOrReward1: FC<Props> = (props) => {
  const { module, section, isReview, ...rest } = props;
  const setSavedValue = useActivity(
    ModuleActivityKind.USING_INCENTIVES_BRIBE_VS_REWARD_1
  )[1];
  const [bribeOrReward, setBribeOrReward] = useState<BribeReward | null>(null);
  const [showExplanation, setShowExplanation] = useState(false);

  const handleBribeOrRewardChange = useCallback(
    (value: BribeReward) => {
      setBribeOrReward(value);
      setSavedValue({ response: value });
    },
    [setSavedValue]
  );

  // Wait a moment after the user responds before showing the explanation.
  useEffect(() => {
    if (bribeOrReward === null) {
      setShowExplanation(false);
      return;
    }
    const timeout = setTimeout(() => {
      setShowExplanation(true);
    }, EXPLANATION_DELAY);

    return () => clearTimeout(timeout);
  }, [bribeOrReward, setSavedValue]);

  return (
    <SlidePage
      {...rest}
      title={module.name}
      showNextButton={showExplanation || isReview}
      lightNextButton
      nextButtonText="Continue"
    >
      <BribeRewardActivity
        isBribe
        value={bribeOrReward}
        onChange={handleBribeOrRewardChange}
        showExplanation={showExplanation}
        situation={
          <>
            You are talking on the phone and your kids are demanding your
            attention, you give them a cookie to entertain them.
          </>
        }
        explanation={
          <>
            That is a bribe, because the cookie was given before the behavior
            happened.
          </>
        }
      />
    </SlidePage>
  );
};

export default styled(UsingIncentivesBribeOrReward1)``;
