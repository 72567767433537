import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import { BearIcons } from "../../../../common/components/BearIcon";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import VideoActivity from "../../VideoActivity";
import { useVideoActivity } from "../../../contexts/ModuleActivityContext";
import ActivitySlidePage from "../../ActivitySlidePage";
import VideoId from "shared/lib/enums/VideoId";

const activityKind =
  ModuleActivityKind.COPING_WITH_STRESS_BREATHING_EXAMPLE_VIDEO;

const videoId = VideoId.COPING_WITH_STRESS_BREATHING_EXAMPLE;

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
  isReview?: boolean;
}

const ManagingStressContent4Slide: FC<Props> = (props) => {
  const { module, section, onNext, isReview, ...rest } = props;
  const [onPlayStart, onPlayEnd, videoCompleted] = useVideoActivity(
    activityKind,
    videoId
  );
  const [showSectionOverlay, setShowSectionOverlay] = useState(false);

  const handleSlideNext = useCallback(() => {
    onPlayEnd(true);
    setShowSectionOverlay(true);
  }, [onPlayEnd]);

  return (
    <ActivitySlidePage
      {...rest}
      hideNextButton={!videoCompleted && !isReview}
      isReview={isReview}
      activityKind={activityKind}
      title={module.name}
      onNext={handleSlideNext}
      bearIcon={BearIcons.GIRL_THUMBS_UP}
    >
      <section>
        <h3>MANAGING STRESS</h3>
        <p>Watch this video to see how one mom used breathing to calm down.</p>
      </section>
      <VideoActivity
        onEnded={onPlayEnd}
        onStarted={onPlayStart}
        videoId={videoId}
      />
      <section>
        <p>
          In the coming week, use one of the strategies you learned to help you
          cope when you’re feeling stressed!
        </p>
      </section>
      <ModuleSegmentCompletionOverlay
        open={showSectionOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
      />
    </ActivitySlidePage>
  );
};

export default styled(ManagingStressContent4Slide)`
  ${VideoActivity} {
    margin-bottom: 3rem;
  }
`;
