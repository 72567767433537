import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import { DepressionManagementPlanValue } from "shared/lib/interfaces/ModuleActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import ModuleSegmentCompletionOverlay from "../../../../common/components/ModuleSegmentCompletionOverlay";
import {
  SlidePageProgress,
  SlidePageProps,
  SlidePageFooter,
} from "../../SlidePage";
import { useActivity } from "../../../contexts/ModuleActivityContext";
import Card from "../../../../common/components/Card";
import ActivityBox from "../../ActivityBox";
import { DARKEST_BLUE, YELLOW } from "../../../../colors";
import ActivitySlidePage from "../../ActivitySlidePage";

const activityKind = ModuleActivityKind.DEPRESSION_MANAGEMENT_PLAN;

interface Props {
  module: Pick<Module, "icon" | "name" | "sections">;
  section: Pick<ModuleSection, "name" | "id">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ManagingDepressionPlanSlide: FC<Props> = (props) => {
  const { module, section, onNext, ...rest } = props;
  const [showSectionOverlay, setShowSectionOverlay] = useState(false);
  const [activityValue, setActivityValue] = useActivity(activityKind);

  const handleSlideNext = useCallback(() => {
    setShowSectionOverlay(true);
  }, []);

  return (
    <ActivitySlidePage
      {...rest}
      activityKind={activityKind}
      light
      lightNextButton
      lightWhite
      title={module.name}
      onNext={handleSlideNext}
    >
      <ActivityBox
        icon="pencil"
        instructions="Your turn: Start small. Think of one fun thing you could do this week."
      >
        <DepressionManagementPlanActivity
          value={activityValue}
          onChange={setActivityValue}
        />
      </ActivityBox>
      <ModuleSegmentCompletionOverlay
        open={showSectionOverlay}
        onNext={onNext}
        module={module}
        currentSection={section}
      />
    </ActivitySlidePage>
  );
};

export const DepressionManagementPlanActivity = styled(
  ({
    value,
    onChange,
    ...rest
  }: {
    value: DepressionManagementPlanValue | undefined;
    onChange(value: DepressionManagementPlanValue): unknown;
  }) => {
    return (
      <Card {...rest}>
        <ActivityPrompt>
          This week I will _____________________ to improve my mood.
        </ActivityPrompt>
        <ActivityTextArea
          placeholder="Type your idea."
          value={value?.input ?? ""}
          onChange={(event) => onChange({ input: event.currentTarget.value })}
        />
      </Card>
    );
  }
)``;

export default styled(ManagingDepressionPlanSlide)`
  ${ActivityBox} {
    flex: 1;
    margin-top: 4rem;
  }

  ${SlidePageFooter} {
    background-color: ${YELLOW};
  }
`;

const ActivityPrompt = styled("div")`
  color: ${DARKEST_BLUE};
  font-size: 0.9rem;
  font-weight: bold;
`;

const ActivityTextArea = styled("textarea")`
  box-sizing: border-box;
  height: 5rem;
  width: 100%;
  border: 2px solid #ebebeb;
  border-radius: 0.5rem;
  background-color: white;
  resize: none;
  margin-top: 0.9rem;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  font-family: inherit;
  font-size: inherit;
`;
