import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const ParentingAndSubstanceUseContent1Slide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
      </section>
      <section>
        <p>
          Substance use does not only affect the person using. It can cause
          stress to other people in their life too, especially children or a
          partner.
        </p>
      </section>
      <section>
        <p>
          Most people who use substances do so to cope with body aches, pains,
          and emotional distress. When reducing or stopping use of substances,
          people may experience both physical and psychological problems, such
          as increased muscle tensions, anxiety, depression, runny nose, watery
          eyes, yawning, and frequent mood swings.
        </p>
      </section>
      <section>
        <p>
          <b>Next, think about some reasons you have used substances.</b>
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(ParentingAndSubstanceUseContent1Slide)``;
