import React, { FC, useState, useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import Page from "@emberex/components/lib/Page";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import replaceWhere from "@emberex/array-utils/lib/replaceWhere";
import Column from "@emberex/components/lib/Column";
import Row from "@emberex/components/lib/Row";
import {
  DraftActivity,
  ModuleActivityValue,
} from "shared/lib/interfaces/ModuleActivity";
import ModuleActivityKind from "shared/lib/enums/ModuleActivityKind";
import Module from "shared/lib/interfaces/Module";
import PlanActivity from "shared/lib/interfaces/PlanActivity";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import ParticipantNav from "../components/ParticipantNav";
import ParticipantPlanList from "../components/ParticipantPlanList";
import { useParticipantContext } from "../contexts/ParticipantContext";
import getDetailedParticipant from "../api/getDetailedParticipant";
import ModuleActivityContext from "../../modules/contexts/ModuleActivityContext";
import getModules from "../../modules/api/getModules";
import getPlanActivities from "../../plan/api/getPlanActivities";
import { FcuLogoCompact } from "../../common/components/FcuLogo";
import saveModuleActivity from "../../modules/api/saveModuleActivity";
import Link from "../../common/components/Link";
import VideoId from "shared/lib/enums/VideoId";
import viewVideo from "../../videos/viewVideo";

interface State {
  loading: boolean;
  modules: Module[];
  planActivities: PlanActivity[];
  draftActivities: DraftActivity<any>[];
}

const ParticipantPlanPage: FC = (props) => {
  const { user, canViewModule } = useParticipantContext();
  const participantId = user.id;
  const [
    { loading, draftActivities, modules, planActivities },
    setState,
  ] = useState<State>({
    loading: true,
    draftActivities: [],
    modules: [],
    planActivities: [],
  });

  useAsyncEffect(async () => {
    const [participant, modules, planActivitiesResult] = await Promise.all([
      getDetailedParticipant(participantId),
      getModules(),
      getPlanActivities(),
    ]);

    if (participant) {
      setState({
        loading: false,
        modules: (modules ?? [])
          .slice()
          .reverse()
          .filter((module) => canViewModule(module.id)),
        draftActivities: participant.activities ?? [],
        planActivities: planActivitiesResult?.planActivities ?? [],
      });
    }
  }, [participantId]);

  const handleActivityChange = useCallback(
    <T extends ModuleActivityKind>(kind: T, value: ModuleActivityValue<T>) => {
      const existingActivity = draftActivities.find(
        (activity) => activity.kind === kind
      );

      setState((state) => {
        if (!state) {
          return state;
        }

        if (existingActivity) {
          return {
            ...state,
            draftActivities: replaceWhere(
              state.draftActivities,
              (activity) => activity.kind === kind,
              (activity) => ({ ...activity, value, unsaved: true })
            ),
          };
        }

        return {
          ...state,
          draftActivities: [
            ...state.draftActivities,
            {
              kind,
              value,
              unsaved: true,
            },
          ],
        };
      });
    },
    [draftActivities]
  );

  const handleVideoView = useCallback(
    (videoId: VideoId) => {
      viewVideo({ videoId, participantId }).catch((error) => {
        console.error(
          `An error occurred while marking a view of ${videoId}`,
          error
        );
      });
    },
    [participantId]
  );

  // Save unsaved activities (debounced)
  useEffect(() => {
    const timeout = setTimeout(async () => {
      const hasUnsavedActivities = draftActivities.some(
        (activity) => activity.unsaved
      );

      if (!hasUnsavedActivities) {
        return;
      }

      setState((state) => {
        if (!state) {
          return state;
        }
        return {
          ...state,
          draftActivities: state.draftActivities.map((response) => ({
            ...response,
            unsaved: false,
          })),
        };
      });

      await saveUnsavedDraftActivities({
        participantId,
        planActivities,
        draftActivities,
      });
    }, 500);

    return () => clearTimeout(timeout);
  }, [participantId, planActivities, draftActivities]);

  useDocumentTitle("My Plan");

  if (loading) {
    return (
      <Page {...props}>
        <ParticipantNav />
      </Page>
    );
  }

  return (
    <ModuleActivityContext.Provider
      value={{
        activities: draftActivities,
        updateActivity: handleActivityChange,
        updateVideoView: handleVideoView,
      }}
    >
      <Page {...props}>
        <ParticipantNav />
        <Header>
          <Link to="/">
            <FcuLogoCompact />
          </Link>
        </Header>
        <Title>My Plan</Title>
        <Content>
          <ParticipantPlanList
            modules={modules}
            planActivities={planActivities}
          />
        </Content>
      </Page>
    </ModuleActivityContext.Provider>
  );
};

export default styled(ParticipantPlanPage)`
  background-color: #0e3750;
  padding-bottom: 2rem;
`;

const Header = styled(Row)`
  height: 60px;
  width: 100%;
  background-color: #0e3750;
  justify-content: center;

  > ${Link} {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
`;

const Title = styled("h1")`
  color: #4dacbe;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 2.5rem 0;
`;

const Content = styled(Column)`
  padding: 0 0.6rem 0 0.6rem;
  flex: 1;
`;

async function saveUnsavedDraftActivities({
  participantId,
  planActivities,
  draftActivities,
}: {
  participantId: number;
  planActivities: PlanActivity[];
  draftActivities: DraftActivity<any>[];
}) {
  const unsavedActivities = draftActivities.filter(
    (activity) => activity.unsaved
  );

  if (unsavedActivities.length === 0) {
    return;
  }

  await Promise.all(
    unsavedActivities.map((activity) => {
      const planActivity = planActivities.find(
        (planActivity) => planActivity.activityKind === activity.kind
      );

      if (!planActivity) {
        console.error(
          `Could not find plan activity for unsaved activity ${activity.kind}.`
        );
        return null;
      }

      return saveModuleActivity({
        participantId,
        moduleId: planActivity.moduleId,
        value: activity.value,
        kind: activity.kind,
      }).catch((error) =>
        console.error(`Failed to save activity ${activity.kind}`, error)
      );
    })
  );
}
