import React, { FC } from "react";
import styled from "styled-components/macro";
import SlidePage, { SlidePageProps } from "../../modules/components/SlidePage";
import bearImageSrc from "../images/ready_to_take_survey_bear.png";

const SurveyQuestionIntroPage: FC<Omit<SlidePageProps, "nextButtonText">> = (
  props
) => {
  return (
    <SlidePage {...props} nextButtonText="Start">
      <section>
        <Header>SURVEYS</Header>
        <p>
          To start each topic, you will answer questions to get a snapshot of
          how your family is doing right now. This will highlight your family’s
          strengths and any areas that might benefit from your focused
          attention.
        </p>
      </section>
      <BearImage />
    </SlidePage>
  );
};

export default styled(SurveyQuestionIntroPage)``;

const Header = styled("h4")`
  margin-bottom: 0.5rem;
`;

const BearImage = styled("img").attrs({
  src: bearImageSrc,
  alt: "Ready to take a survey?",
})`
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  padding-top: 1rem;
  background-color: #ccfcee;
`;
