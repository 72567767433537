import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const WhatIsPositiveParentingIntroSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          Positive parenting is a way of interacting with your child that helps
          you focus on the positive behaviors your child is doing, instead of
          the negative.
        </p>
      </section>
    </SlidePage>
  );
};

export default styled(WhatIsPositiveParentingIntroSlide)``;
