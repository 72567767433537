import React, { FC, useCallback } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import Column from "@emberex/components/lib/Column";
import Page from "@emberex/components/lib/Page";
import waveBackgroundCss from "../../common/utils/waveBackgroundCss";
import PillButton from "../../surveys/components/PillButton";
import { YELLOW } from "../../colors";
import Confetti from "./Confetti";

const ProgramCompleteSlide: FC = (props) => {
  const history = useHistory();

  const handleHomeClick = useCallback(() => {
    history.push("/dashboard");
  }, [history]);

  return (
    <Page {...props}>
      <Confetti />
      <Column>
        <Title>Wow!</Title>
        <Info>
          You made it through the whole program, great work! Keep coming back to
          refresh your strategies when you need to!
          <br />
          <br />
          Best wishes from Family Check-Up!
        </Info>
      </Column>
      <HomeButton onClick={handleHomeClick}>Dashboard</HomeButton>
    </Page>
  );
};

export default styled(ProgramCompleteSlide)`
  ${waveBackgroundCss}
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 6rem 2rem 6rem 2rem;
`;

const Title = styled("h1")`
  color: ${YELLOW};
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
`;

const Info = styled("p")`
  color: #fdd965;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
`;

const HomeButton = styled(PillButton)`
  padding-left: 0;
  padding-right: 0;
  width: 200px;
`;
