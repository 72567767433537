import React, { FC } from "react";
import styled from "styled-components/macro";
import Module from "shared/lib/interfaces/Module";
import ModuleSection from "shared/lib/interfaces/ModuleSection";
import SlidePage, { SlidePageProgress, SlidePageProps } from "../../SlidePage";
import YellowCircleList from "../../../../common/components/YellowCircleList";

interface Props {
  module: Pick<Module, "icon" | "name">;
  section: Pick<ModuleSection, "name">;
  progress: SlidePageProgress;
  onNext: SlidePageProps["onNext"];
  onBack: SlidePageProps["onBack"];
}

const UsingIncentivesExampleSlide: FC<Props> = (props) => {
  const { module, section, ...rest } = props;

  return (
    <SlidePage {...rest} title={module.name}>
      <section>
        <h3>{section.name}</h3>
        <p>
          For this strategy to work you must notice that your child did what you
          wanted, and then follow up by giving them the reward!
        </p>
      </section>
      <section>
        <YellowCircleList>
          <li>
            <b>Incentive:</b> Tell your child, “If you put your shoes away when
            you come inside from playing, you will get a juice box.”
          </li>
          <li>
            <b>Notice:</b> Your child comes inside from playing and puts their
            shoes away
          </li>
          <li>
            <b>Reward:</b> You thank them for doing their job and give them a
            juice box
          </li>
        </YellowCircleList>
      </section>
    </SlidePage>
  );
};

export default styled(UsingIncentivesExampleSlide)``;
