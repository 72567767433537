import React, { FC } from "react";
import styled from "styled-components/macro";

interface Props {
  html?: string;
}

/**
 * Do not use with user input. This component does not sanitize the HTML, so it needs to be trusted.
 */
const RawHTML: FC<Props> = (props) => {
  const { html = "", ...rest } = props;

  return <span {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default styled(RawHTML)``;
