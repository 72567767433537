import React, { FC, FormEvent, useCallback, useState } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { EffectiveCommunicationStatementValue } from "shared/lib/interfaces/ModuleActivity";
import Row from "@emberex/components/lib/Row";
import TextButton from "@emberex/components/lib/TextButton";
import ActivityBox from "./ActivityBox";
import { DARKEST_BLUE } from "../../colors";
import ShoeList, { ShoeListItem } from "./ShoeList";
import CircleStarImage from "./CircleStarImage";
import PillButton from "../../surveys/components/PillButton";
import useSearchParamState, {
  patchSearchParams,
  useSearchParamBooleanState,
} from "../../common/hooks/useSearchParamState";

const defaultResponses: EffectiveCommunicationStatementValue = {
  response1: "",
  response2: "",
  response3: "",
  response4: "",
  response5: "",
};

interface Statement {
  id: number;
  text: string;
  recommendedResponse: string;
  response: keyof EffectiveCommunicationStatementValue;
}

const statements: Statement[] = [
  {
    id: 1,
    text: "You never help around the house!",
    recommendedResponse: "I would really like more help with household chores.",
    response: "response1",
  },
  {
    id: 2,
    text: "You complain too much!",
    recommendedResponse:
      "I want to hear about the good parts of your day, too!",
    response: "response2",
  },
  {
    id: 3,
    text: "You and the kids are driving me crazy!",
    recommendedResponse: "I need a few minutes to relax.",
    response: "response3",
  },
  {
    id: 4,
    text: "You always criticize my parenting!",
    recommendedResponse:
      "It hurts my feelings when you comment on my parenting. Sometimes an I statement can be a focus on your feelings, rather than using an actual “I.”",
    response: "response4",
  },
  {
    id: 5,
    text: "You always talk about yourself.",
    recommendedResponse: "I want you to ask me how I’m doing.",
    response: "response5",
  },
];

interface Props {
  responseResult?: EffectiveCommunicationStatementValue;
  onSubmit(value: EffectiveCommunicationStatementValue): any;
  onFinished(): void;
  hideIcon?: boolean;
}

const EffectiveCommunicationStatementComparisonActivity: FC<Props> = (
  props
) => {
  const history = useHistory();
  const { responseResult, onSubmit, hideIcon, onFinished, ...rest } = props;
  const previousResponses: Partial<EffectiveCommunicationStatementValue> =
    responseResult ?? {};
  const defaultValue: EffectiveCommunicationStatementValue = {
    ...defaultResponses,
    ...previousResponses,
  };
  const [responseValue, setResponseValue] = useState(defaultValue);
  const [showingResponses, setShowingResponses] = useSearchParamBooleanState(
    "showResponses"
  );
  const [
    showingRecommendedResponses,
    setShowingRecommendedResponses,
  ] = useSearchParamBooleanState("recommended");
  const [currentResponseKey = "response1"] = useSearchParamState<
    keyof EffectiveCommunicationStatementValue
  >("responseKey");

  const handleShowResponses = useCallback(() => setShowingResponses(true), [
    setShowingResponses,
  ]);

  const handleRecommendedClick = useCallback(
    () => setShowingRecommendedResponses(true),
    [setShowingRecommendedResponses]
  );

  const handleYourResponsesClick = useCallback(
    () => setShowingRecommendedResponses(false),
    [setShowingRecommendedResponses]
  );

  const handleResponseChange = useCallback(
    (
      response: keyof EffectiveCommunicationStatementValue,
      event: FormEvent<HTMLTextAreaElement>
    ) => {
      const currentResponseValue = event.currentTarget.value;
      setResponseValue((prev) => ({
        ...prev,
        [response]: currentResponseValue,
      }));
    },
    []
  );

  const handleSubmit = useCallback(() => {
    onSubmit(responseValue);
    handleShowResponses();
  }, [responseValue, handleShowResponses, onSubmit]);

  const handleNext = useCallback(() => {
    const currentIndex = statements.findIndex(
      (statement) => statement.response === currentResponseKey
    );
    if (currentIndex < statements.length - 1) {
      patchSearchParams(history, {
        showResponses: null,
        recommended: null,
        responseKey: statements[currentIndex + 1].response,
      });
    } else {
      onFinished();
    }
  }, [history, currentResponseKey, onFinished]);

  const { text, response, recommendedResponse } =
    statements.find((s) => s.response === currentResponseKey) ?? statements[0];

  if (showingResponses) {
    return (
      <ActivityBox {...rest} icon={hideIcon ? undefined : "chat"}>
        <Header>How do your responses compare?</Header>
        <ResponseNav>
          <ResponseNavButton
            onClick={handleYourResponsesClick}
            selected={!showingRecommendedResponses}
          >
            Your Responses
          </ResponseNavButton>
          <ResponseNavButton
            onClick={handleRecommendedClick}
            selected={showingRecommendedResponses}
          >
            Recommended Responses
            <ResponseNavIcon dark={showingRecommendedResponses} />
          </ResponseNavButton>
        </ResponseNav>
        <ResponseContainer>
          <TextHeader>TRY USING “I” INSTEAD</TextHeader>
          <ResponseText>
            {showingRecommendedResponses
              ? recommendedResponse
              : responseValue[currentResponseKey] ?? ""}
          </ResponseText>
        </ResponseContainer>
        <SubmitButton onClick={handleNext} dark={false}>
          Next
        </SubmitButton>
      </ActivityBox>
    );
  }

  return (
    <ActivityBox {...rest} icon={hideIcon ? undefined : "chat"}>
      <Header>
        Your turn: Turn these “You” statements into “I” statements.
      </Header>
      <SituationSection>
        <SituationHeader>Prompt</SituationHeader>
        <SituationText>{text}</SituationText>
      </SituationSection>
      <TextAreaContainer>
        <TextHeader>TRY USING “I” INSTEAD</TextHeader>
        <ResponseTextArea
          value={responseValue[response] ?? ""}
          placeholder="Enter what you would say."
          onChange={(e) => handleResponseChange(response, e)}
        />
      </TextAreaContainer>
      <SubmitButton
        onClick={handleSubmit}
        hide={!responseValue[response]}
        dark={false}
      >
        Submit
      </SubmitButton>
    </ActivityBox>
  );
};

export default styled(EffectiveCommunicationStatementComparisonActivity)`
  ${ShoeList} {
    margin-left: 1rem;
    margin-right: 2rem;
  }

  ${ShoeListItem} {
    color: ${DARKEST_BLUE};
  }

  ${ShoeListItem} > h4 {
    margin-bottom: 0.5rem;
  }
`;

const Header = styled("h4")`
  color: ${DARKEST_BLUE};
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.35rem;
  text-align: center;
  margin-bottom: 1.25rem;
`;

const SituationHeader = styled("h5")`
  color: ${DARKEST_BLUE};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const SituationText = styled("p")`
  color: #0e3750;
`;

const SituationSection = styled("div")`
  margin-left: 1rem;
  margin-bottom: 1.25rem;
`;

const TextAreaContainer = styled("div")`
  width: 100%;
  height: 150px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.14);
  padding: 0.9rem 1.125rem;
  margin-bottom: 5rem;
`;

const TextHeader = styled("p")`
  color: ${DARKEST_BLUE};
  font-size: 0.94rem;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0.3rem;
`;

const ResponseTextArea = styled("textarea")`
  box-sizing: border-box;
  height: 90px;
  width: 100%;
  border: 2px solid #ebebeb;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 7px 8px;
  font-family: Montserrat;
  font-size: 0.875rem;
  ::placeholder {
    color: ${DARKEST_BLUE};
  }
`;

const ResponseNav = styled(Row)`
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  margin-bottom: 1.3rem;
`;

const ResponseNavButton = styled(TextButton)<{ selected?: boolean }>`
  border: 4px solid white;
  width: 50%;
  text-align: center;
  color: ${DARKEST_BLUE};
  padding: 0 0 0 0;
  height: 54px;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  background-color: ${(props) => (props.selected ? "white" : "transparent")};
  transition: background-color 0.2s;
  position: relative;

  &:first-child {
    border-radius: 27px 0 0 27px;
    border-right-width: 0px;
  }

  &:last-child {
    border-radius: 0 27px 27px 0;
    border-left-width: 0px;
    padding-right: 1.5rem;
  }
`;

const ResponseContainer = styled("div")`
  padding: 0 2rem;
  margin-bottom: 5rem;
`;

const ResponseText = styled("p")`
  color: #0e3750;
`;

const ResponseNavIcon = styled(CircleStarImage)`
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translate(0, -50%);
  width: 22px;
  height: 22px;
`;

const SubmitButton = styled(PillButton)`
  margin-left: auto;
  margin-right: auto;
  width: 200px;
`;
